import React, { useEffect, useRef, useState } from "react";

// import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { getPetNameByWorkspaceId } from "../../../Api/Services/PetServices";
import {
  createPetVetThrapist,
  createVetPractice,
  createVetTherapist,
  getAllVetPractice,
  getbyIDVetTherapist,
  getVetByPracticeID,
} from "../../../Api/Services/VetServices";
import { useAuth } from "../../../ContextProvider/AuthProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../../Css";
import { VetAndTherapistFormValidation } from "../../../From/Validation";
import { typeOptions } from "../../../Options";
import { getCountryAsOption, getStateAsOptions } from "../../../Utils";
import AutoComplete from "../../Common/AutoComplete";
import AutoCompleteAddress from "../../Common/AutoCompleteAddress";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import InputField from "../../Common/InputField";
import CustomPhoneInput from "../../Common/PhoneInput";
import CustomSnackbar from "../../Common/Snackbar";

import "./form.css";
import "react-phone-input-2/lib/style.css";

// const useStyles = makeStyles((theme) => ({
//   input: {
//     letterSpacing: '2px', // Adjust the spacing as needed
//   },
// }));
const defaultValue = {
  pet_id: "",
  vet_id: "",
  vet_full_name: "",
  vet_practice_id: "",
  email: "",
  fax: "",
  primary_phone_number: "",
  type: "",
  zip_code: "",
  street_address: "",
  street_address_2: "",
  city: "",
  country: "",
  state: "",
  vet_practice_name: "",
  vet_portal: "",
};

export default function VetForm() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [shoefield, setShoefield] = useState(false);
  const [shvet, setShVet] = useState(false);
  const [readonly, setReadonly] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [petName, setPetName] = useState([]);
  const [vetpracticename, setVetPracticeName] = useState([]);
  const [vetname, setVetName] = useState([
    { value: "vet", label: "Add A New Vet" },
  ]);
  const { workspaceId: w_id } = useAuth();
  // const classes = useStyles();

  const getPetNames = () => {
    getPetNameByWorkspaceId(w_id)
      .then((res) => {
        const filteredData = res?.data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        if (filteredData?.length === 0) {
          <></>;
        } else {
          setPetName(filteredData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getVetPracticeName = () => {
    getAllVetPractice(w_id)
      .then((res) => {
        const uniqueValues = res?.data?.data?.filter(
          (obj, index, self) =>
            index === self.findIndex((el) => el.id === obj.id)
        );
        const vetnam = uniqueValues?.map((vet) => {
          return {
            value: vet?.id,
            label: vet?.vet_practice_name,
          };
        });
        vetnam?.push({ value: "other", label: "Add A New Practice" });
        if (vetnam.length === 0) {
          <></>;
        } else {
          setVetPracticeName(vetnam);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (w_id) {
      getPetNames();
      getVetPracticeName();
    }
  }, [w_id]);

  const sendVetData = async (values) => {
    if (values.vet_practice_id === "other" && values.vet_id === "vet") {
      const prac = {
        vet_practice_name: values?.vet_practice_name,
      };
      try {
        const res = await createVetPractice(prac);
        const value = {
          vet_full_name: values?.vet_full_name,
          vet_practice_id: res?.data?.data?.id,
          email: values?.email,
          fax: values?.fax,
          primary_phone_number: values?.primary_phone_number,
          type: values?.type,
          zip_code: values?.zip_code,
          street_address: values?.street_address,
          street_address_2: values?.street_address_2,
          city: values?.city,
          country: values?.country,
          state: values?.state,
          vet_practice_name: values?.vet_practice_name,
          vet_portal: values?.vet_portal,
          pet_id: values?.pet_id,
        };
        try {
          const res = await createVetTherapist(value);
          const id_values = {
            vet_id: res?.data?.data?.id,
            pet_id: values?.pet_id,
          };
          try {
            const res = await createPetVetThrapist(id_values);
            return res;
          } catch (error) {
            console.error(error);
            throw error;
          }
        } catch (err) {
          console.error(err);
          throw err;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      if (values?.vet_id === "vet") {
        const value = {
          vet_full_name: values?.vet_full_name,
          vet_practice_id: values?.vet_practice_id,
          email: values?.email,
          fax: values?.fax,
          primary_phone_number: values?.primary_phone_number,
          type: values?.type,
          zip_code: values?.zip_code,
          street_address: values?.street_address,
          street_address_2: values?.street_address_2,
          city: values?.city,
          country: values?.country,
          state: values?.state,
          vet_portal: values?.vet_portal,
          pet_id: values?.pet_id,
        };
        try {
          const res = await createVetTherapist(value);
          const id_values = {
            vet_id: res?.data?.data?.id,
            pet_id: values?.pet_id,
          };
          try {
            const res = await createPetVetThrapist(id_values);
            return res;
          } catch (error) {
            console.error(error);
            throw error;
          }
        } catch (err) {
          console.error(err);
          throw err;
        }
      } else {
        const value = {
          vet_id: values?.vet_id,
          pet_id: values?.pet_id,
        };
        try {
          const res = await createPetVetThrapist(value);
          return res;
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    }
  };

  const sendData = async (values) => {
    try {
      const addressRes = await sendVetData(values);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleVetPracNameSelector = (e, newvalue) => {
    if (newvalue?.value === "other") {
      setShoefield(true);
      setReadonly(false);
      setVetName([{ value: "vet", label: "Add A New Vet" }]);
    } else {
      setShoefield(false);
      //   setReadonly(true);
      getVetByPracticeID(newvalue?.value)
        .then((res) => {
          const nam = res?.data?.data?.map((vet) => {
            return {
              value: vet?.id,
              label: vet?.vet_full_name,
            };
          });
          if (nam.length > 0) {
            nam?.push({ value: "vet", label: "Add A New Vet" });
            setVetName(nam);
          } else {
            setVetName([{ value: "vet", label: "Add A New Vet" }]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleVetName = (e, newvalue) => {
    formikRef.current.setFieldValue("vet_id", newvalue?.value);
    if (newvalue?.value === "vet") {
      setShVet(true);
      setReadonly(false);

      setInitialValue({
        ...formikRef.current?.values,
        vet_id: newvalue?.value,
        vet_full_name: "",
        email: "",
        fax: "",
        primary_phone_number: "",
        // type: "",
        zip_code: "",
        street_address: "",
        street_address_2: "",
        city: "",
        country: "",
        state: "",
      });
    } else {
      setShVet(false);
      setReadonly(true);
      getbyIDVetTherapist(newvalue?.value)
        .then((res) => {
          const data = res.data.data[0];
          setInitialValue({
            ...formikRef.current?.values,
            vet_full_name: data?.vet_full_name,
            email: data?.email,
            fax: data?.fax,
            primary_phone_number: data?.primary_phone_number,
            type: data?.type,
            zip_code: data?.zip_code,
            street_address: data?.street_address,
            street_address_2: data?.street_address_2,
            city: data?.city,
            country: data?.country,
            state: data?.state,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
  }, []);

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  const handleFaxNumberChange = (e) => {
    // Remove non-numeric characters
    const formattedFax = e.target.value.replace(/\D/g, "");

    // Format the fax number as +1 (123) 456-7890
    let formattedValue = "";
    if (formattedFax.length >= 1) {
      formattedValue += `+${formattedFax[0]}`;
    }
    if (formattedFax.length >= 2) {
      formattedValue += ` (${formattedFax.substring(1, 4)}`;
    }
    if (formattedFax.length >= 5) {
      formattedValue += `) ${formattedFax.substring(4, 7)}`;
    }
    if (formattedFax.length >= 8) {
      formattedValue += `-${formattedFax.substring(7, 11)}`;
    }
    formikRef?.current?.setFieldValue("fax", formattedValue);
  };

  return (
    <FormLayout title="Vet and Therapist Information">
      <Formik
        initialValues={initialValue}
        innerRef={formikRef}
        onSubmit={sendData}
        validationSchema={VetAndTherapistFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box className="scroll2" sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    Vet and Therapist Details
                  </Typography>
                </Box>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={petName}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    {shoefield ? (
                      <FormGroup
                        name="vet_practice_name"
                        label={"Veterinary Practice"}
                      >
                        <InputField
                          {...getFieldProps("vet_practice_name")}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                position="end"
                                onClick={() => {
                                  setShoefield(false);
                                  setFieldValue("vet_practice_id", "");
                                  setFieldValue("vet_practice_name", "");
                                }}
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "transparent",
                                  zIndex: 1,
                                  padding: 0,
                                  cursor: "pointer",
                                  width: "auto"
                                }}
                              >
                                <ClearIcon
                                  sx={{ backgroundColor: "rgb(252, 132, 51)" }}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup
                        name="vet_practice_id"
                        label={"Veterinary Practice"}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("vet_practice_id")}
                          options={vetpracticename}
                          onChange={handleVetPracNameSelector}
                          isOnChange
                        />
                      </FormGroup>
                    )}
                  </Column>
                  <Column>
                    {shvet ? (
                      <FormGroup
                        name="vet_full_name"
                        label={"New Vet Full Name"}
                      >
                        <InputField
                          {...getFieldProps("vet_full_name")}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                position="end"
                                onClick={() => {
                                  setShVet(false);
                                  setFieldValue("vet_id", "");
                                  setFieldValue("vet_full_name", "");
                                }}
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "transparent",
                                  zIndex: 1,
                                  padding: 0,
                                  cursor: "pointer",
                                  width: "auto"
                                }}
                              >
                                <ClearIcon
                                  sx={{ backgroundColor: "rgb(252, 132, 51)" }}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup
                        name="vet_id"
                        label={"Vet Full Name"}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("vet_id")}
                          // otherType="vet"
                          options={vetname}
                          onChange={handleVetName}
                          isOnChange
                        />
                      </FormGroup>
                    )}
                  </Column>
                  <Column>
                    <FormGroup name="type" label={"Type"} requiredField>
                      <AutoComplete
                        {...getFieldProps("type")}
                        options={typeOptions}
                        placeholder={"Select a type"}
                        otherType="Other"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="fax" label={"Fax"}>
                      <InputField
                        {...getFieldProps("fax")}
                        onChange={handleFaxNumberChange}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="vet_portal" label={"Vet Portal"}>
                      <InputField
                        {...getFieldProps("vet_portal")}
                        InputProps={{
                          readOnly: readonly,
                        }}
                      />
                    </FormGroup>
                  </Column>
                </Body>
                <Box
                  sx={{
                    borderBottom: "1px solid #FF8533",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    Contact Details
                  </Typography>
                </Box>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                <Column>
                    <FormGroup
                      name="email"
                      label={"Email Address"}
                      requiredField
                    >
                      <InputField {...getFieldProps("email")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="primary_phone_number"
                      label={"Phone Number"}
                      requiredField
                    >
                      <CustomPhoneInput
                        {...getFieldProps("primary_phone_number")}
                        onChange={(value) =>
                          setFieldValue("primary_phone_number", value)
                        }
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="street_address" label={"Street Address"}>
                      <AutoCompleteAddress
                        formikRef={formikRef}
                        setFieldValue={setFieldValue}
                        value={values?.street_address}
                        address="street_address"
                        city="city"
                        state="state"
                        zipcode="zip_code"
                        country="country"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="street_address_2"
                      label={"Street Address 2"}
                    >
                      <InputField
                        {...getFieldProps("street_address_2")}
                        InputProps={{
                          readOnly: readonly,
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="country" label={"Country"}>
                      <AutoComplete
                        {...getFieldProps("country")}
                        name="country"
                        options={countryOptions}
                        placeholder="Select a country"
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="state" label={"State"}>
                      <AutoComplete
                        {...getFieldProps("state")}
                        name="state"
                        otherType="Other"
                        options={stateOptions}
                        placeholder={"Select a State"}
                        readOnly={readonly}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="city" label={"City"}>
                      <InputField
                        {...getFieldProps("city")}
                        InputProps={{
                          readOnly: readonly,
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="zip_code" label={"Zip Code"}>
                      <InputField
                        {...getFieldProps("zip_code")}
                        InputProps={{
                          readOnly: readonly,
                        }}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </Box>
              {/* </Wrapper> */}
              <Box sx={ButtonBoxStyle}>
                <CustomButton
                  label="Cancel"
                  type="button"
                  style={CancelButton}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  label={isSubmitting ? "Submitting..." : "Submit"}
                  disabled={isSubmitting}
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New vet has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/vet-and-therapist");
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
