import React from "react";

import Journal from "../../Components/Item-List/Journal";
import Layout from "../../Components/Side bar/Layout";

// const base = `http://ec2-3-215-135-177.compute-1.amazonaws.com:8000`;

const ItemListJournal = () => {

  return (
    <Layout>
      <Journal />
    </Layout>
  );
};

export default ItemListJournal;
