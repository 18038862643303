import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { getCarearsByUserId } from "../../Api/Services/UserServices.js";
import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, formatPhoneNumber } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";

export default function UserInformation() {
  const { petOwnerId: user_id, workspaceId } = useAuth();
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const fetchIdRef = useRef(0);

  const [selectedUserName, setSelectedUserName] = useState("");
  let navigate = useNavigate();
  const {
    data = [],
    isLoading,
  } = useSWR(
    workspaceId ? `userData_${workspaceId}` : null,
    async () => {
      const { limit, from, sort, ...props } = filter;
      const { data } = await getCarearsByUserId(workspaceId, user_id);
      const filterData = data.data.map((item) => {
        return {
          ...item,
          name: item.first_name,
          id: item.id,
          phone_number: formatPhoneNumber(item?.contact_no),
        };
      });
      return filterData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil((data?.length) / limit));
      }
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort, from, limit);
      setUserData(filteredResult);
    }
  }, [filter, data]);

  const result = userData
    ? userData.map((user) => {
        const { id, first_name, email, image, last_name, contact_no, user_id } =
          user;

        return {
          id,
          user_id,
          image,
          first_name,
          last_name,
          email: email,
          phone_number: formatPhoneNumber(contact_no),
        };
      })
    : [];

  const filteredData =
    selectedUserName === ""
      ? result
      : result.filter((item) => {
          const userName = item.first_name.toLowerCase() || "";
          const query = selectedUserName.toLowerCase();
          return userName.includes(query);
        });

  const userNamesSet = new Set();
  const filteredUserNames = data.filter((user) => {
    const lowercaseName = user.first_name?.toLowerCase();
    if (!userNamesSet.has(lowercaseName)) {
      userNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              alt="userImage"
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        width: "12.5vw",
      },
      {
        Header: `Last Name`,
        accessor: "last_name",
        width: "12.5vw",
      },
      {
        Header: "Email",
        accessor: "email",
        width: "12.5vw",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        width: "12.5vw",
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => navigate(props.viewDataLink(row.original))}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, []);

  const defaultOptions = {
    pageSize: 5,
  };

  const props = {
    label: "User Information",
    setSelectedUserName: setSelectedUserName,
    items: filteredUserNames,
    dataAddLink: "/user-information/Form",
    isLoading: isLoading,
    columns,
    columnsData: filteredData,
    viewDataLink: (data) => `/user-information/user-info/${data?.id}`,
    placeholder: "user name",
    details: "There are no other user details.",
    isSelectHide: true,
    fetchData,
    pageCount,
    defaultOptions,
  };
  return <PawItemList {...props} />;
}
