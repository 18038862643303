import React, { useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { createNewDocument } from "../../Api/Services/DocumentServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { useAuth } from "../../ContextProvider/AuthProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { DocumentFormValidation } from "../../From/Validation";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import UploadDocument from "../Common/UploadDocument";

const defaultValue = {
  documment_name: "",
  date: "",
  documment: "",
  pet_id: "",
};

const FormSection = () => {
  const { petOwnerId: user_id, workspaceId: w_id } = useAuth();
  const [items, setItems] = useState([]);
  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
  }, []);

  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const sendData = async (values) => {
    try {
      const formData = new FormData();
      formData.append("documment_name", values?.documment_name);
      formData.append("date", values?.date);
      formData.append("documment", values?.documment);
      formData.append("pet_id", values?.pet_id);
      formData.append("user_id", user_id);
      formData.append("w_id", w_id);

      await createNewDocument(formData);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      alert("Error please try again!");
    }
  };
  const getCurrentDate = () => {
    const now = new Date();
    let month = (now.getMonth() + 1).toString();
    let day = now.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${now.getFullYear()}-${month}-${day}`;
  };

  return (
    <FormLayout title="Document">
      <Formik
        initialValues={defaultValue}
        onSubmit={sendData}
        validationSchema={DocumentFormValidation}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Document Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="documment_name"
                      label={"Document Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("documment_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="date" label={"Date"} requiredField>
                      <InputField
                        type="date"
                        {...getFieldProps("date")}
                        inputProps={{ max: getCurrentDate() }}
                      />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup
                      name="documment"
                      label={"Upload Documents"}
                      requiredField
                    >
                      <UploadDocument {...getFieldProps("documment")} />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New document has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            // window.location.reload();
            navigate("/document");
          }}
        />
      )}
    </FormLayout>
  );
};

export default FormSection;
