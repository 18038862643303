import React from "react";

import { Grid } from "@mui/material";
import styled from "styled-components";

const FormSection = () => {
  return (
    <FormWrapper>
      <Grid
      className="scroll"
        container
        alignItems="flex-start"
        spacing={1.5}
      >
        <Grid item xs={6} md={8}>
          <Label>Pet Name</Label>
          <Input type="text" />
        </Grid>
        <Grid item xs={6} md={8}>
          <Label>Vet Name</Label>
          <Input type="text" />
        </Grid>
        <Grid item xs={6} md={8}>
          <Label>Surgery Type</Label>
          <Input type="text" />
        </Grid>
        <Grid item xs={6} md={8}>
          <LabelDate>Date</LabelDate>
          <InputDate type="date" />
        </Grid>
        <Grid item xs={6} md={8}>
          <LabelDescription>Description</LabelDescription>
          <InputDescription type="te" />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

export default FormSection;

const FormWrapper = styled.div`
  font-weight: 500;
  font-size: 20px;
  /* line-height: 23px; */
  color: #ffffff;
  margin-top: 20px;
  margin-left: 100px;
`;

const Label = styled.label`
  display: block;
  width: 124px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`;
const Input = styled.input`
  width: 420px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 11px;
  color: #ffffff;
  padding-left: 10px;
  :focus {
    outline: none;
    color: #ffffff;
  }
`;
const LabelDate = styled.label`
  display: block;
  width: 124px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`;
const InputDate = styled.input`
  width: 200px;
  height: 40px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 11px;
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-year-field {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin-right: 10px;
    font-size: 20px;
  }
  :focus {
    outline: none;
    color: #ffffff;
  }
`;
const LabelDescription = styled.label`
  display: block;
  width: 124px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
`;
const InputDescription = styled.textarea`
  width: 420px;
  height: 100px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  backdrop-filter: blur(10px);
  border-radius: 11px;
  padding-left: 10px;
  padding-top: 5px;
  :focus {
    outline: none;
    color: #ffffff;
  }
`;
