import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function MenuButton(props) {
  const { item } = props;
  let navigate = useNavigate();
  return (
    <div>
      <div
        onClick={() => {
          navigate(item.link);
        }}
        style={{ margin: "16px 0"}}
      >
        <MenuItem title={item.title}>
          <img src={item.icon} />
          {item.title}
        </MenuItem>
      </div>
    </div>
  );
}

export default MenuButton;
const MenuItem = styled.div`
  color: rgba(255, 255, 255, 0.8);
  display: grid;
  padding: 0px 0px;
  transition: 0.5s ease-out;
  border-radius: 10px;
  text-align: center;
  justify-items: center;
  :hover {
    background: #ff8533;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
  }
`;
