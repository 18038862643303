import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getImmunizationByID } from "../../../Api/Services/ImmunizationServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchiveImmunizationComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [petData, setPetData] = useState();

  const { data: immunizationData = [], isLoading } = useSWR(
    id ? `immunizationData${id}` : null,
    async () => {
      const { data } = await getImmunizationByID(id);
      const { data: petInfo } = await getPetByID(data?.data?.[0]?.pet_id);
      setPetData(petInfo?.data);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Immunization Information",
    isLoading,
    onBack: () => {
      navigate("/archive/immunization");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Vaccination Type",
        value: "vaccination_type",
      },
      {
        label: "Date",
        value: "date",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        vaccination_type: immunizationData?.vaccination_type,
        date: formatDate(immunizationData?.date),
      },
    ],
    detailsData: [
      [{ name: "Information", value: immunizationData?.information }],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
