import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { getImmunizationByWorkspaceID, setImmunizationArchieve } from "../../Api/Services/ImmunizationServices.js";
import { getPetNamesByWorkspaceId } from "../../Api/Services/PetServices.js";
import "./GlobalItemList.css";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from '../../ContextProvider/AuthProvider'
import { filterAscDescData, formatDate } from "../../Utils.js";
import DetailsModal from "../Common/DetailsModal.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";

export default function Immunization() {
  const { workspaceId: w_id } = useAuth();
  const [selectedPetName, setSelectedPetName] = useState("");
  const [open, setOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [archiveId, setArchiveId] = useState(null);
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [selImmunizationData, setSelImmunizationData] = useState([]);
  const fetchIdRef = useRef(0);
  const navigate = useNavigate();
  const handleArchiveClick = (medication_id) => {
    setShowSuccessMessage(true);
    setOpen(true);
    setArchiveId(medication_id); 
  };
  const handleArchive = () => {
    setIsUpdating(true);

    setImmunizationArchieve(archiveId)
      .then((response) => {
        setIsUpdating(false);
        console.log(response);
        if (response.data.success === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setIsUpdating(false);
      });
  };

  const { data: immunizationData, isLoading } = useSWR(
    w_id ? `immunizationData${w_id}` : null,
    async () => {
      const { data } = await getImmunizationByWorkspaceID(w_id);
      const Dates = data?.data.map((item) => {
        const date = formatDate(item?.date);
        return { ...item, id: item?.immunization_id, date: date };
      });
      return Dates || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petNames = [] } = useSWR(
    w_id ? `petNames${w_id}` : null,
    async () => {
      const { data } = await getPetNamesByWorkspaceId(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  let filteredImmunizationData;

  if (selectedPetName === "") {
    filteredImmunizationData = selImmunizationData;
  } else if (!selImmunizationData) {
    filteredImmunizationData = [];
  } else {
    filteredImmunizationData = selImmunizationData?.filter((item) => {
      const petName = item.name.toLowerCase() || "";
      const query = selectedPetName.toLowerCase();
      return petName.includes(query);
    });
  }

  const petNamesSet = new Set();
  const filteredPetNames = petNames.filter((pet) => {
    const lowercaseName = pet.name.toLowerCase();
    if (!petNamesSet.has(lowercaseName)) {
      petNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const defaultOptions = {
    pageSize: 5,
  };

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (immunizationData?.length && limit) {
        setPageCount(Math.ceil((immunizationData?.length) / limit));
        const allData = [...immunizationData];
        const filteredResult = filterAscDescData(allData, sort, from, limit);
        setSelImmunizationData(filteredResult);
      }
    }
  }, [filter, immunizationData]);

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              alt="petImage"
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "Pet Name",
        accessor: "name",
        width: "12.5vw",
      },
      {
        Header: `Vaccination Type`,
        accessor: "vaccination_type",
        width: "12.5vw",
      },
      {
        Header: "Date",
        accessor: "date",
        width: "12.5vw",
      },
      {
        Header: "",
        accessor: "immunization_id",
        width: "8vw",
        isArchive: true,
        disableSortBy: true,
        disabled: isUpdating,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <UnarchiveIcon
              style={{
                color: "#000000",
              }}
              onClick={() =>
                handleArchiveClick(row?.original?.immunization_id)
              }
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => navigate(props.viewDataLink(row.original))}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, []);

  const props = {
    label: "Immunizations",
    setSelectedUserName: setSelectedPetName,
    items: filteredPetNames,
    isLoading: isLoading,
    dataAddLink: "/immunization/form",
    columns,
    columnsData: filteredImmunizationData,
    viewDataLink: (data) =>
      `/immunization/immunization-info/${data.immunization_id}`,
    placeholder: "pet name",
    details: "There are no immunization details for this user.",
    fetchData,
    pageCount,
    defaultOptions,
  };

  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        handleArchive();
        setShowSuccessMessage(false);
      },
    },
    {
      name: "no",
      onClick: () => {
        setOpen(false);
        setShowSuccessMessage(false);
      },
    },
  ];
  return (
    <>
      <PawItemList {...props} />
      {showSuccessMessage && (
        <>
          <DetailsModal
            open={open}
            severity="warning"
            buttonList={ButtonItem}
            title="Do you want to archive this immunization?"
            handleClose={()=> setOpen(false)}
          />
        </>
      )}
    </>
  );
}
