import React, { useRef } from "react";

import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { get } from "lodash";
import styled from "styled-components";

function UploadDocument({ name }) {
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.click();
  };

  const { errors, values, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  const value = get(values, name);
  var myDiv = document.getElementById("myDiv");
  if (value?.name) {
    myDiv.style.borderColor = "White";
  }

  return (
    <>
      <Input4
        id="myDiv"
        style={error && fieldTouched && { borderColor: "rgb(230, 0, 0)" }}
      >
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(event) => {
            const file = event.target.files[0];
            setFieldTouched(name, true);
            setFieldValue(name, file);
          }}
          //   onBlur={handleDocumentBlur}
        />
        {value && (
          <div style={{ color: "black" }}>
            <p>{value?.name}</p>
            {/* <p>Type: {value?.type}</p> */}
          </div>
        )}
        {!values && (
          <>
            <UploadImage
              src={require("../../Assets/Images/Document/upload.png")}
            />
            <UploadText>Upload Here</UploadText>
          </>
        )}
        <Button style={{ color: "white", background: '#1c93ed', width: 'auto' }} onClick={handleDivClick}>
          Upload
        </Button>
      </Input4>
      {/* {error && fieldTouched && (
        <FormHelperText
          id="myDiv1"
          sx={{
            // paddingLeft: "2vw",
            position: "relative",
            color: "rgb(189,36,24)",
            display: value?.name ? "none" : "",
          }}
        >
          {error}
        </FormHelperText>
      )} */}
    </>
  );
}

export default UploadDocument;

const Input4 = styled.div`
  width: calc(100% - 20px);
  height: 120px;
  background: rgba(255, 133, 51, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  backdrop-filter: blur(10px);
  border-radius: 11px;
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
  &:hover {
    border-color: white;
  }

  // @media (max-width: 599px) {
  //   width: 230px;
  // }
`;

const UploadImage = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
`;

const UploadText = styled.p`
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.593);
  font-size: 15px;
  color: black;
  opacity: 0.5;
`;
