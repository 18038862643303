import React from "react";


import AllergyInfoComponent from "../../Components/Selected-item-view/AllergyInformation/AllergyComponent";

const AllergySecond = () => {

  return (
    <>
      <AllergyInfoComponent />
    </>
  );
};

export default AllergySecond;
