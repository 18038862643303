import React, { useEffect } from "react";
import HomeSection1 from "../../Components/Home/HomeSection1/HomeSection1";
import HomeSection2 from "../../Components/Home/HomeSection2/HomeSection2";
import HomeSection3 from "../../Components/Home/HomeSection3/HomeSection3";
import HomeSection4 from "../../Components/Home/HomeSection4/HomeSection4";
import styled from "styled-components";
import "../../App.css";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HomeWrapper>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />      
      <HomeSection4 />
    </HomeWrapper>
  );
}

const HomeWrapper = styled.div`  
  padding-bottom: 100px;  
`
