import { api } from "../axios";

export const deleteJournal = async (id) => {
  const res = await api.delete(`/journal/deleteJournal/${id}`);
  return res;
};

//archieve journal
export const getArchiveJournalByWorkspaceID = async (id) => {
  const res = await api.get(`/journal/getArchievedatabyWorkspaceId/${id}`);
  return res;
};

//Journal
// get journal by workspace id
export const getJournalByWorkspaceID = async (w_id) => {
  const res = await api.get(`/journal/getdatabyWorkspaceId/${w_id}`);
  return res;
};
// get journal by id
export const getJournalById = async (id) => {
  const res = await api.get(`/journal/getJournalById/${id}`);
  return res;
};
//create journal
export const createJournal = async (formData) => {
  const res = await api.post(`/journal/createJournal`, formData);
  return res;
};
//update journal
export const updateJournal = async (id, formData) => {
  const res = await api.put(`/journal/updateJournal/${id}`, formData);
  return res;
};

export const getJounalHistoryById = async (id) => {
  const res = await api.get(`/history/getJournalHistoryById/${id}`);
  return res;
};

//set journal archive
export const setJournalArchieve = async (id) => {
  const res = await api.post(`/journal/setArchieve/${id}`);
  return res;
};
// journal unarchive
export const setJournalUnarchive = async (id) => {
  const res = await api.post(`/journal/setunArchieve/${id}`);
  return res;
};
