import React, { useEffect, useRef, useState } from "react";

// import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import MenuButton from "./MenuButton";
import Menutool from "./Menutool";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  // let navigate = useNavigate();
  const tooltipRef = useRef();

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !tooltipRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Wrapper>
        {" "}
        <img
          alt="logo"
          // onClick={() => {
          //   navigate("https://www.pawprintid.com/");
          // }}
          src={require("../../Assets/Images/Sidebar/pawprint-logo.png")}
        />
        <MenuWrapper>
          <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
            <MenuButton
              item={{
                icon: require("../../Assets/Images/Sidebar/hamburger-menu.png"),
              }}
            />
          </HamburgerWrapper>
        </MenuWrapper>
      </Wrapper>
      <div ref={tooltipRef}></div>
      <Menutool isOpen={isOpen} />
    </div>
  );
}

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 890px) {
    display: block;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 150px;
  position: fixed;
  display: grid;
  gap: 200px;
  grid-template-columns: 10px auto;
  justify-content: space-around;
  align-items: center;
  background: rgb(252, 132, 51);
  z-index: 100;
  display: none;

  @media (max-width: 890px) {
    display: grid;
    gap: 300px;
    grid-template-columns: 10px auto;
    justify-content: space-around;
    height: 120px;
    position: relative;
  }
  @media (max-width: 350px) {
    gap: 200px;
  }
`;

const MenuWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(8, auto);

  @media (max-width: 1130px) {
    > a {
      display: none;
    }
    grid-template-columns: auto;
  }
  .active {
    background: #ff8533;
    border-radius: 10px;
    padding: 10px 15px;
  }

  a {
    text-decoration: none;
  }
`;
