import styled from "styled-components";
import { tooltipData } from "../Data/Header/Navdata";
import MenuButton from "./MenuButton";

function Menutool(props) {
  const { isOpen } = props;
  return (
    <Wrapper isOpen={isOpen}>
      {tooltipData.map((item, index) => (
        <MenuButton item={item} key={index} />
      ))}
    </Wrapper>
  );
}

export default Menutool;
const Wrapper = styled.div`
  text-align: center;
  position: fixed;
  top: 120px;
  right: 15px;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.25),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(40px);
  border-radius: 20px;
  padding: 10px;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  z-index: 100;
  display: grid;
  gap: 1px;
  grid-template-columns: 200px;
  transition: 0.3s ease-in-out;
  @media (max-width: 768px) {
    grid-template-columns: 150px;
  }

`;
