import React from "react";

import PrescriptionComponent from "../../Components/Selected-item-view/Prescription/PrescriptionComponent";

const Second = () => {

  return (
    <>
      <PrescriptionComponent />
    </>
  );
};

export default Second;
