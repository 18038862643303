import { api } from "../axios";

export const getSurgeryTreatment = async (id) => {
  const res = await api.get(`/surgery/getSurgeryById/${id}`);
  return res;
};

export const getAllItemListSurgeryAndTreatment = async () => {
  const res = await api.get(`/surgery/getAll`);
  return res;
};

export const getSurgeryByWorkspaceID = async (id) => {
  const res = await api.get(`/surgery/getDataByWorkspaceId/${id}`);
  return res;
};

export const getSurgeryByID = async (id) => {
  const res = await api.get(`/surgery/getSurgeryById/${id}`);
  return res;
};

//pet_surgery
export const createPetsurgery = async (formData) => {
  const res = await api.post(`/surgery/createSurgery`, formData);
  return res;
};

export const Updatepetsurgery = async (id, formData) => {
  const res = await api.put(`/surgery/updateSurgery/${id}`, formData);
  return res;
};

export const getpetsurgery = async (id) => {
  const response = await api.get(`/surgery/getSurgeryById/${id}`);
  return response;
};

//delete petsurgery
export const DeleteSurgery = async (id) => {
  const res = await api.delete(`/surgery/deleteSurgery/${id}`);
  return res;
};

//archieve surgery
export const getArchiveSurgeryByWorkspaceID = async (id) => {
  const res = await api.get(`/surgery/getArchieveDataByWorkspaceId/${id}`);
  return res;
};

//set surgery archive
export const setSurgeryArchieve = async (id) => {
  const res = await api.post(`/surgery/setArchieve/${id}`);
  return res;
};
// surgery unarchive
export const setSurgeryUnarchive = async (id) => {
  const res = await api.post(`/surgery/setunArchieve/${id}`);
  return res;
};
