import React from "react";

import ArchiveAllergyInfoComponent from "../../Components/Selected-item-view-Ar/AllergyInformation/AllergyComponent";

const ArchiveAllergySecond = () => {

  return (
    <>
      <ArchiveAllergyInfoComponent />
    </>
  );
};

export default ArchiveAllergySecond;
