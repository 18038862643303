import React from "react";

import ArchivePetInformationList from "../../Components/Archive-Items/ArchivePetInformation";
import Layout from "../../Components/Side bar/Layout";

export default function ArchivePetInformation() {
  return (
    <Layout>
      <ArchivePetInformationList />
    </Layout>
  );
}
