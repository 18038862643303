import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import "./GlobalItemList.css";
import { getProviderByWorkspaceId } from "../../Api/Services/ProviderServices";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider/AuthProvider.js";
import { filterAscDescData } from "../../Utils";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";

export default function ProviderInformation() {
  const { workspaceId: w_id } = useAuth();
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [providerData, setProviderData] = useState([]);
  const fetchIdRef = useRef(0);

  const navigate = useNavigate();

  const {
    data: data = [],
    isLoading
  } = useSWR(
    w_id ? `Providerdata${w_id}` : null,
    async () => {
      const { data } = await getProviderByWorkspaceId(w_id);
      const filterData = data?.data?.map(item => {
        return {
          ...item,
          provider_name: `${item.first_name} ${item.last_name}`
        }
      })
      return filterData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  
  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil((data?.length) / limit));
      }
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort, from, limit);
      setProviderData(filteredResult);
    }
  }, [filter, data]);

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              alt="petImage"
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "Pet Name",
        accessor: "pet_name",
        width: "12.5vw",
      },
      {
        Header: `Provider Name`,
        accessor: "provider_name",
        width: "12.5vw",
      },
      {
        Header: "Provider Email",
        accessor: "email",
        width: "12.5vw",
      },
      {
        Header: "Status",
        accessor: "status",
        width: "12.5vw",
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => navigate(props.viewDataLink(row.original))}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, []);

  const defaultOptions = {
    pageSize: 5,
  };


    const props = {
      label: "Provider Information",
      dataAddLink: "/provider-information/form",
      isSelectHide: true,
      isLoading,
      columns,
      columnsData: providerData,
      viewDataLink: (data) => `/provider-information/info/${data.id}`,
      details: 'There are no provider details for this workspace.',
      fetchData,
      pageCount,
      defaultOptions,
    };

  return (
    <PawItemList {...props} />
  );
}
