import React from "react";

import { Button } from "@mui/material";

function CustomButton({ onClick, label, style, ...params }) {
  return (
    <Button variant="outlined" sx={{ ...btnStyle, ...style}} onClick={onClick} {...params}>
      {label}
    </Button>
  );
}

export default CustomButton;

const btnStyle = {
  mx: 0.5,
  color: "white",
  background: "#FF8533 !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 40,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid #FF8533",
  },
  "@media (max-width: 374px)": {
    width: "auto",
  },
};
