import React from "react";

import JournalInfom from "../../Components/Selected-item-view/Journal/JournalInfo";

const JournalInfo = () => {

  return (
    <>
      <JournalInfom />
    </>
  );
};

export default JournalInfo;
