import React from "react";

import ArchivePrescriptionList from "../../Components/Archive-Items/ArchivePrescription&Medication";
import Layout from "../../Components/Side bar/Layout";

export default function ArchivePrescription() {
  return (
    <Layout>
      <ArchivePrescriptionList />
    </Layout>
  );
}
