import React from "react";

import PetInformation from "../../Components/Item-List/PetInformation";
import Layout from "../../Components/Side bar/Layout";

const ItemListPetInformation = () => {

  return (
    <Layout>
      <PetInformation />
    </Layout>
  );
};

export default ItemListPetInformation;
