import React from "react";

import ArchiveSurgeryList from "../../Components/Archive-Items/ArchiveSurgery&Treatment";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveSurgery() {
  return (
    <Layout>
      <ArchiveSurgeryList />
    </Layout>
  );
}
