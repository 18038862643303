import React from "react";

import SettingSProfile from "../../Components/Setting/EditUser";
import Layout from "../../Components/Side bar/Layout";

const SettingProfile = () => {

  return (
    <Layout>
      <SettingSProfile />
    </Layout>
  );
};

export default SettingProfile;
