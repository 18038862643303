import React, { useEffect, useRef, useState } from "react";
import { navData } from "../Data/Header/Navdata";
import styled from "styled-components";
import MenuButton from "../Header/MenuButton";
import Menutool from "../Header/Menutool";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const tooltipRef = useRef();
  const navigate = useNavigate();

  function handleClick(event) {
    setIsOpen(!isOpen);
    event.preventDefault();
  }
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !tooltipRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <HeaderWrapper>
      <Container maxWidth="xl">
        <Wrapper>
          <NavLink to="/">
            <Logo
              src={require("../../Assets/Images/Navbar/pawprint-icon.png")}
              alt="Pawprint Logo"
            />
          </NavLink>
          <MenuWrapper>
            {navData.map((item, index) => (
              <NavLink to={item.link} activeclassname="active" key={index}>
                <MenuItem title={item.title}>
                  <img src={item.icon} alt={item.title} />
                  {item.title}
                </MenuItem>
              </NavLink>
            ))}
            <MenuSymbol style={{ marginBottom: "20px" }}>
              <img
                src={require("../../Assets/Images/Navbar/account-icon.png")}
                alt="Account Icon"
                onClick={() => {
                  navigate("/account");
                }}
              />
            </MenuSymbol>
            <HamburgerWrapper onClick={(event) => handleClick(event)} ref={ref}>
              <MenuButton
                item={{
                  icon: require("../../Assets/Images/Navbar/hamburger-menu.png"),
                }}
              />
            </HamburgerWrapper>
          </MenuWrapper>
        </Wrapper>
      </Container>
      <div ref={tooltipRef}></div>
      <Menutool isOpen={isOpen} />
    </HeaderWrapper>
  );
}

const HamburgerWrapper = styled.div`
  display: none;
  @media (max-width: 1130px) {
    display: block;
  }

  @media (max-width: 390px) {
    padding-right: 0px;
  }

  @media (max-width: 390px) {
    padding-right: 10px;
  }

  @media (max-width: 365px) {
    padding-right: 20px;
  }

  @media (max-width: 350px) {
    padding-right: 50px;
  }

  @media (max-width: 320px) {
    padding-right: 60px;
  }

  @media (max-width: 320px) {
    padding-right: 80px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 150px;
  // position: fixed;
  display: grid;
  gap: 10rem;
  grid-template-columns: 10px auto;
  justify-content: space-between;
  align-items: center;
  background: #fc8433;
  z-index: 100;
  padding: 0rem 0rem;

  @media (max-width: 1130px) {
    display: grid;
    gap: 300px;
    grid-template-columns: 10px auto;
    justify-content: space-between;
    height: 120px;
  }
  @media (max-width: 375px) {
    gap: 240px;
  }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  height: 150px;
  position: fixed;
  align-items: center;
  background: #fc8433;
  z-index: 100;
  padding: 0rem 0rem;

  @media (max-width: 1130px) {
    height: 120px;
  }
`;

const MenuWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(6, auto);

  @media (max-width: 1130px) {
    > a {
      display: none;
    }
    grid-template-columns: auto;
  }
  .active {
    background: rgba(0, 143, 211, 1);
    border-radius: 10px;
    padding: 10px 15px;
  }

  a {
    text-decoration: none;
  }
`;
const MenuItem = styled.div`
  color: rgba(255, 255, 255, 0.7);
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${(props) => (props.title ? "12px" : "0px")};
  align-items: center;
  padding: 0px;
  transition: 0.5s ease-out;
  border-radius: 10px;
  justify-items: center;
  font-weight: bold;

  :hover {
    background: rgba(0, 143, 211, 1);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
  }
`;

const MenuSymbol = styled.div`
  color: rgba(255, 255, 255, 0.7);
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${(props) => (props.title ? "12px" : "0px")};
  align-items: center;
  padding: 0px;
  ${"" /* margin-bottom: 15px; */}
  transition: 0.5s ease-out;
  border-radius: 10px;
  justify-items: center;
  /* font-style: normal !important; */

  :hover {
    background: rgba(0, 143, 211, 1);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
  }

  @media (max-width: 1130px) {
    display: none;
    grid-template-columns: auto;
  }
`;

const Logo = styled.img`
  transition: transform 0.8s;

  :hover {
    transform: scale(1.03);
  }

  @media (max-width: 380px) {
    padding-left: 10px;
  }

  @media (max-width: 365px) {
    padding-left: 0px;
  }

  @media (max-width: 320px) {
    padding-left: 0px;
  }

  @media (max-width: 300px) {
    padding-left: 0px;
  }
`;
