import React from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import InputField from "./InputField";

const AutoCompleteAddress = ({
  formikRef,
  setFieldValue,
  address,
  city,
  state,
  zipcode,
  value,
    country,
  ...params
}) => {
  const handleChange = (addressValue) => {
    if (formikRef?.current) {
      formikRef?.current?.setFieldValue(`${address}`, addressValue);
    }
  };

  const fillInAddress = (results) => {
    let street_number;
    let address_area1;
    let address_area2;
    let address_line1;
    let city_1;
    let state_province;
    let postal_code;
    let country_name;
    if (results.address_components) {
      for (const component of results.address_components) {
        for (const type of component.types) {
          switch (type) {
            case "street_number":
              street_number = component.long_name;
              break;
            case "route":
              address_line1 = `${component.long_name}`;
              break;
            case "administrative_area_level_2":
              address_area1 = component.long_name;
              break;
            case "administrative_area_level_3":
              address_area2 = component.long_name;
              break;
            case "locality":
              city_1 = component.long_name;
              break;
            case "administrative_area_level_1":
              state_province = component.short_name;
              break;
            case "postal_code":
              postal_code = component.long_name;
              break;
            case "country":
              country_name = component.long_name;
              break;
          }
        }
      }

      const fullAddress =
        street_number && address_area1 && address_area2
          ? `${street_number} ${address_line1} ${address_area1} ${address_area2}`
          : street_number && address_area1
          ? `${street_number} ${address_line1} ${address_area1}`
          : street_number
          ? `${street_number} ${address_line1}`
          : `${address_line1}`;

      if (address_line1) {
        setFieldValue(`${address}`, fullAddress);
      } else {
        setFieldValue(`${address}`, `${city_1} ${state_province}`);
      }

      if (state_province) {
        setFieldValue(`${state}`, state_province);
      }
      if (city_1) {
        setFieldValue(`${city}`, city_1);
      }
      if (postal_code) {
        setFieldValue(`${zipcode}`, postal_code);
      }
      if (country_name) {
        setFieldValue(`${country}`, country_name);
      }
    }
  };

  const handleSelect = (addressData) => {
    geocodeByAddress(addressData)
      .then(async (results) => {
        fillInAddress(results[0]);
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={
        {
          // types: ['(regions)'],
          // componentRestrictions: { country: 'us' }
        }
      }
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div style={{ position: "relative" }}>
            <InputField
              id={address}
              name={address}
              value={value || ""}
              onChange={handleChange}
              {...getInputProps({
                  placeholder: "Street Address",
                  className: "location-search-input",
                })}
              {...params}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                position: "absolute",
                zIndex: "1",
                width: "100%",
                backgroundColor: "white",
                boxShadow:
                  "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
              }}
            >
              {loading && <div style={{ padding: "6px 16px" }}>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#0000000a",
                      cursor: "pointer",
                      padding: "6px 16px",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "6px 16px",
                    };
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AutoCompleteAddress;
