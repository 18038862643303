import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_CMS_BACKEND_BASE_URL,
  //baseURL: "http://ec2-44-213-168-246.compute-1.amazonaws.com:8000/",
});

export const getAllBlogs = async () => {
  const res = await api.get(`/resources/getAllBlogs`);
  return res;
};

export const getContactUs = async () => {
  const res = await api.get(`/contactUs/getAllContacts`);
  return res;
};

//home page
export const getHomeHeader = async () => {
  const res = await api.get(`/home/getAllHomeHeader`);
  return res;
};

export const getHomeSlider1 = async () => {
  const res = await api.get(`/home/getAllHomeSliderOne`);
  return res;
};

export const getHomeSlider2 = async () => {
  const res = await api.get(`/home/getAllHomeSliderTwo`);
  return res;
};

export const getHomeParagraph = async () => {
  const res = await api.get(`/home/getAllHomeParagraph`);
  return res;
};

export const getHomeOverview = async () => {
  const res = await api.get(`/home/getAllHomeService`);
  return res;
};

//buttons
export const getButton = async () => {
  const res = await api.get(`/button/getAllButton`);
  return res;
};

// ForBusiness
export const getForBusinessCard = async () => {
  const res = await api.get(`/business/getBusinessCardById/1`);
  return res;
};
export const getForBusinessImage = async () => {
  const res = await api.get(`/business/getBusinessHeaderById/1`);
  return res;
};
export const getBusinessParagraph2 = async () => {
  const res = await api.get(`/business/getAllBusinessParagraph`);
  return res;
};
export const getBusinessBottom = async () => {
  const res = await api.get(`/business/getAllBusinessFooter`);
  return res;
};

// ForIndividuals
export const getForIndividualsStep = async () => {
  const res = await api.get(`/forIndividuals/getStepById/1`);
  return res;
};
export const getForIndividualsPara = async () => {
  const res = await api.get(`/forIndividuals/getByParagraph/2`);
  return res;
};
export const getForIndividualsComment = async () => {
  const res = await api.get(`/forIndividuals/getCommentById/1`);
  return res;
};

// About Us
export const getAboutUsHeader = async () => {
  const res = await api.get(`/abouteUsHeader/getById/1`);
  return res;
};
export const getAboutUsParagraph = async () => {
  const res = await api.get(`/abouteUsParagraph/getById/1`);
  return res;
};
export const getAboutUsLastTitle = async () => {
  const res = await api.get(`/abouteUsLastTittle/getById/1`);
  return res;
};
export const getAboutUsTeamSection = async () => {
  const res = await api.get(`/abouteUsTeamSectionNew/getAllTeamSection`);
  return res;
};
export const getAboutUsStorySection = async () => {
  const res = await api.get(`/abouteUsStorySection/getbyId/1`);
  return res;
};
export const getAboutUsStoryPara = async () => {
  const res = await api.get(`/abouteUsStoryPara/getById/2`);
  return res;
};
export const getAboutUsStepCards = async () => {
  const res = await api.get(`abouteUsStepCard/getById/1`);
  return res;
};
export const getAboutUsOurMisson = async () => {
  const res = await api.get(`/abouteUsMission/getAll`);
  return res;
};
export const getAboutUsTeamParagraph = async () => {
  const res = await api.get(`/abouteUsTeamSection/getAllTeamParagraph`);
  return res;
};
//footer
export const getFooter = async () => {
  const res = await api.get(`/footer/getFooterById/7`);
  return res;
};

//vrify admin account
export const verifyAdminAccount = async (token) => {
  const res = await api.get(`/admin/VerifyAccount?token=${token}`);
  return res;
};

//send contact form to admin
export const sendContactUsForm = async (data) => {
  const res = await api.post(`/contactUs/sendContactFormEmail`, data);
  return res;
};