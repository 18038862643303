import React from "react";

import FormHeader from "../../Components/SurgeryandTreatInfo/FormHeader";
import FormSection from "../../Components/SurgeryandTreatInfo/FormSection";

const FirstStep = () => {
  return (
    <>
      <FormHeader />
      <FormSection />
    </>
  );
};

export default FirstStep;
