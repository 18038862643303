import React from "react";

import Immunization from "../../Components/Item-List/Immunization";
import Layout from "../../Components/Side bar/Layout";

const ItemListImmunization = () => {

  return (
    <Layout>
      <Immunization />
    </Layout>
  );
};

export default ItemListImmunization;
