import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  deleteEmergency,
  getEmergencyContactById,
} from "../../../Api/Services/EmergencyServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import {
  getPetHistoryById,
} from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import {
  addItemtoObject,
  formatPhoneNumber,
} from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
export default function EmergencyInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [petInfo, setPetInfo] = useState([]);

  const {
    data: emergencyInfo,
    isLoading: IsEmergencyDataLoading
  } = useSWR(
    id ? "emergencyData" : null,
    async () => {
      const { data } = await getEmergencyContactById(id);
      const petId = data?.data?.[0]?.pet_id;
      if (petId) {
        const { data: petRes } = await getPetByID(petId);
        setPetInfo(petRes?.data);
      }
      return data?.data[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: emergencyHistory } = useSWR(
    id ? `emergencyHistory${id}` : null,
    async () => {
      const { data } = await getPetHistoryById("emergency-contact", id);
      const propertiesToUpdate = ["pet_name"];
      const propertiesToDelete = ["id", "pet_id", "w_id"];
      const filteredData = data?.data?.map((item) =>
        addItemtoObject(item, propertiesToUpdate, propertiesToDelete)
      );
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  const onDelete = async () => {
    await deleteEmergency(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Emergency Contact Information",
    isLoading: IsEmergencyDataLoading,
    onBack: () => {
      navigate("/emergency-contact");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Relationship",
        value: "relationship",
      },
      {
        label: "Phone Number",
        value: "phone_number",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        ...emergencyInfo,
        phone_number: formatPhoneNumber(emergencyInfo?.phone_number),
      },
    ],
    detailsData: [
      [
        { name: "First Name", value: emergencyInfo?.first_name },
        { name: "Last Name", value: emergencyInfo?.last_name },
        { name: "Email", value: emergencyInfo?.email },
        {
          name: "Second Number",
          value: formatPhoneNumber(emergencyInfo?.secondary_number),
        },
      ],
      [
        { name: "Street Add", value: emergencyInfo?.street_address },
        { name: "Street Add 2", value: emergencyInfo?.street_address_2 },
        { name: "City", value: emergencyInfo?.apt_city },
        { name: "State", value: emergencyInfo?.state },
        { name: "Zip", value: emergencyInfo?.zip_code },
        { name: "Country", value: emergencyInfo?.country },
      ],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/emergency-contact/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Emergency Contact History",
    historyColumnsData: emergencyHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}
const FullPageWrapper = styled("div")(() => ({}));
