import { React, useState, useRef } from "react";
import styled from "styled-components";
import "../../Assets/Styles/ContactUs/form.css";
import validator from "validator";
import PhoneNumberInput from "./phone-number-input";
// import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import { sendContactUsForm } from "../../api/axios";

const defaultValue = {
  name: "",
  email: "",
  address: "",
  message: "",
  phoneNumber: "",
}

export default function Form() {
  const [toSend, setToSend] = useState(defaultValue);
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const res = await sendContactUsForm(toSend);
      if (res?.data?.success) {
        swal(
          "Thank you for contacting PawprintID®. Our Customer Care Team will respond to your inquiry within 2 business days.",
          "",
          "success"
        ).then(() => {
          window.location.reload();
          setToSend(defaultValue);
        });
      }
    } catch (error) {
      console.log("error: ", error);
      swal(
        "Oops!",
        "An error occurred while sending your message. Please try again later.",
        "error"
      );
    }
    // emailjs
    //   .sendForm(
    //     "service_u4ctbh3",
    //     "template_jfxhdkm",
    //     form.current,
    //     "rEhWtNg6b_t0GRoL6"
    //   )
    //   .then(() => {
    //     swal(
    //       "Thank you for contacting PawprintID®. Our Customer Care Team will respond to your inquiry within 2 business days.",
    //       "",
    //       "success"
    //     ).then(() => {
    //       window.location.reload();
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error sending email:", error);
    //     // Show an error message to the user
    //     swal(
    //       "Oops!",
    //       "An error occurred while sending your message. Please try again later.",
    //       "error"
    //     );
    //   });
  };

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;
    handleChange(e);
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   send("service_8a4swgl", "template_b63ds6d", toSend, "xwcj27mC_tIE7Oxg0")
  //     .then((response) => {
  //       console.log("SUCCESS!", response.status, response.text);
  //     })
  //     .catch((err) => {
  //       console.log("FAILED...", err);
  //     });
  // };

  return (
    <Contact>
      <p className="title" style={{ marginTop: 0 }}>
        We will get in touch shortly.
      </p>
      {/* <form onSubmit={onSubmit}> */}
      <form ref={form} onSubmit={sendEmail}>
        <ContactForm>
          <label className="inputTitle" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            className="ContactInput"
            name="name"
            id="name"
            required
            autoComplete="off"
            // defaultValue={toSend.from_name}
            onChange={handleChange}
          />

          <label className="inputTitle" htmlFor="address">
            Address
          </label>
          <input
            type="text"
            className="ContactInput"
            name="address"
            id="address"
            autoComplete="off"
            onChange={handleChange}
          />

          <div className="phone">
            <PhoneNumberInput setToSend={setToSend} />
          </div>
          <label className="inputTitle" htmlFor="email">
            Email
          </label>
          <input
            type="text"
            className="ContactInput"
            id="email"
            name="email"
            onChange={(e) => validateEmail(e)}
            required
            autoComplete="off"
            // defaultValue={toSend.to_name}
          ></input>
          <span
            style={{
              color: "red",
            }}
          >
            {emailError}
          </span>
          <label className="inputTitle">Questions /Comments</label>
          <textarea
            className="textBox"
            id="comment"
            name="message"
            onChange={handleChange}
            // style={{ fontFamily: "Omnes, sans-serif !important", fontStyle: "normal !important", fontWeight:"500" }}
            // defaultValue={toSend.message}
          ></textarea>
          <SubmitButton>
            <button type="submit" className="submitbtn">
              Submit
            </button>
          </SubmitButton>
        </ContactForm>
      </form>
    </Contact>
  );
}

const Contact = styled.div`
  display: grid;
  line-height: 170%;
  justify-content: center;
  /* font-family: "omnes", sans-serif;
  font-style: normal; */
  margin: auto;
  align-items: center;
  justify-items: center;
  max-width: 1080px;
  margin-bottom: 50px;
  padding: 0 150px;
  box-sizing: border-box;
  body {
    /* font-family: "omnes", sans-serif; */
  }
  @media (max-width: 720px) {
    max-width: 600px;
    padding: 0px;
    margin: 0 auto;
  }
  @media (max-width: 620px) {
    max-width: 500px;
  }
  @media (max-width: 520px) {
    max-width: 400px;
  }
  @media (max-width: 420px) {
    width: 300px;
  }
  @media (max-width: 305px) {
    width: 290px;
  }
`;

const ContactForm = styled.div`
  display: flex;
  display: grid;
  width: 700px;
  justify-content: space-evenly;
  border-color: #3ca899;
  /* font-family: "omnes", sans-serif;
  font-style: normal; */
  box-sizing: border-box;
  background: rgba(217, 217, 217, 0.3);
  border-radius: 30px;
  line-height: 250%;
  height: auto;
  border: 3px dashed #3ca899;
  border-radius: 30px;
  @media (max-width: 720px) {
    width: 600px;
    margin-bottom: 20px;
  }
  @media (max-width: 620px) {
    width: 500px;
  }
  @media (max-width: 520px) {
    width: 400px;
    border: 2px dashed #3ca899;
  }
  @media (max-width: 420px) {
    width: 300px;
  }
  @media (max-width: 305px) {
    width: 250px;
  }
`;

const SubmitButton = styled.div`
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  /* font-family: "omnes", sans-serif;
  font-style: normal; */
  padding-bottom: 20px;
  padding-top: 15px;
  margin: auto;
  width: 180px;
  /* body {
    font-family: "omnes", sans-serif;
  } */
  :hover {
    transform: translateY(-3px);
  }
`;
