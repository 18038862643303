import React from "react";

import Layout from "../../../Components/Side bar/Layout";
import MembershipDetails from "../../../Components/SuperAdmin/MembershipDetails/MembershipDetails";

const MembershipList = () => {

  return (
    <Layout>
      <MembershipDetails />
    </Layout>
  );
};

export default MembershipList