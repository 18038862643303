import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Container from "@mui/material/Container";
import styled from "styled-components";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import AboutUS from "./Pages/AboutUs/AboutUS";
import BusinessComponent from "./Pages/Business Page/BusinessComponent";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Forindividuals from "./Pages/For individuals/Forindividuals";
import Home from "./Pages/Home/Home";
import ResourcePg from "./Pages/ResourcePage/ResourcePg";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ChangePassword from "./Pages/ForgetPassword/ChangePassword";
import SubUserChangePassword from "./Pages/ForgetPassword/SubUserChangePassword";
import SuccessPage from "./Pages/ForgetPassword/SuccessPage";
import VerifyAccount from "./Pages/Registration/VerifyAccount";
import SubUserVerifyAccount from "./Pages/Registration/subUserAccountVerify";
import Register from "./Pages/Registration/Register";
import TermCondition from "./Pages/TermCondition/TermCondition";
import usePageTracking from "./Utils/usePageTracking";

//FrontEnd

import ConditionFormSection from "./Frontend/Components/Conditions/ConditionsForm/FormSection";
import ConditionsFormSectionEdit from "./Frontend/Components/Conditions/ConditionsForm/FormSectionEdit";
import DocumentEdit from "./Frontend/Components/Document/DocumentEdit";
// import DocumentPreview from "./Frontend/Components/Document/DocumentPreview";
import EmergencyEdit from "./Frontend/Components/Emergency/EmergencyEdit";
import EmergencyForm from "./Frontend/Components/Emergency/EmergencyForm/EmergencyForm";
import EmergencyPreview from "./Frontend/Components/Emergency/EmergencyPreview";
import EditJournalForm from "./Frontend/Components/Journal/EditJournal";
import JournalForm from "./Frontend/Components/Journal/Journal";
import PetInfoEdit from "./Frontend/Components/pet-information/PetInfoEdit";
import PetInfoForm from "./Frontend/Components/pet-information/PetInfoForm";
import PetInfoPreview from "./Frontend/Components/pet-information/PetInfoPreview";
import Petinsurance from "./Frontend/Components/PetInsurance/Petinsurance";
import Petinsuranceedit from "./Frontend/Components/PetInsurance/Petinsuranceedit";
import PrescriptEditForm from "./Frontend/Components/Prescription/PrescriptEdit/PrescriptEditForm";
import PrescriptForm from "./Frontend/Components/Prescription/PrescriptForm";
import ProviderForm from "./Frontend/Components/Provider-Information/ProviderForm";
import ProviderComponent from "./Frontend/Components/Selected-item-view/ProviderInformation/ProviderComponent";
import UserInfo from "./Frontend/Components/Selected-item-view/UserInformation/UserInfo";
import ProviderEmailForm from "./Frontend/Components/Provider-Information/ProvideEmailForm";
import UserEditForm from "./Frontend/Components/user-information/UserInfoForm/UserEditForm";
import UserForm from "./Frontend/Components/user-information/UserInfoForm/UserForm";
import EditForm from "./Frontend/Components/VetTherapist/VetTherapistEdit/EditForm";
import VetForm from "./Frontend/Components/VetTherapist/VetTherapistForm/Form";
import VetInfoPreview from "./Frontend/Components/VetTherapist/VetTherapistPreview/preview/VetInfoPreview";
import { AuthProvider } from "./Frontend/ContextProvider/AuthProvider/AuthProvider";
import AllergyForm from "./Frontend/Pages/AllergyInformation/AllergyForm";
import AllergyFormEdit from "./Frontend/Pages/AllergyInformation/AllergyFormEdit";
import AllergyInfo from "./Frontend/Pages/AllergyInformation/AllergyInfo";
import AllergySecond from "./Frontend/Pages/AllergyInformation/AllergySecond";
import ArchiveAllergySecond from "./Frontend/Pages/AllergyInformation/AllergySecondArchive";
import ArchiveAllegyList from "./Frontend/Pages/Archive/ArchiveAllergyPge";
import ArchiveConditionList from "./Frontend/Pages/Archive/ArchiveConditionPage";
import ArchiveDocumentList from "./Frontend/Pages/Archive/ArchiveDocumentPage";
import ArchiveEmergencyContactList from "./Frontend/Pages/Archive/ArchiveEmergencyContactPage";
import ArchiveImmunizationList from "./Frontend/Pages/Archive/ArchiveImmunizationPage";
import ArchiveJournalList from "./Frontend/Pages/Archive/ArchiveJournalPage";
import Archive from "./Frontend/Pages/Archive/ArchiveMenuset";
import ArchivePetInformationList from "./Frontend/Pages/Archive/ArchivePetInformationPage";
import ArchivePetInsuranceList from "./Frontend/Pages/Archive/ArchivePetInsurancePage";
import ArchivePrescriptionMedicationList from "./Frontend/Pages/Archive/ArchivePrescription&MedicationPage";
import ArchiveSurgeryTreatmentList from "./Frontend/Pages/Archive/ArchiveSurgery&TreatmentPage";
import ArchiveUserInformationList from "./Frontend/Pages/Archive/ArchiveUserInformationPage";
import ArchiveVetTherapistList from "./Frontend/Pages/Archive/ArchiveVet&TherapistPage";
import ArchiveConditionsSecond from "./Frontend/Pages/ConditionsInfo/ArchiveConditionsSecond";
import ConditionsInfo from "./Frontend/Pages/ConditionsInfo/ConditionsInfo";
import ConditionsSecond from "./Frontend/Pages/ConditionsInfo/ConditionsSecond";
import ArchiveDocumentSecond from "./Frontend/Pages/Document/ArchiveDocumentSecond";
import Document from "./Frontend/Pages/Document/Document";
import DocumentForm from "./Frontend/Pages/Document/DocumentForm";
import DocumentSecond from "./Frontend/Pages/Document/DocumentSecond";
import DragandDrop from "./Frontend/Pages/DragAndDrop/DragAndDrop";
import ArchiveEmergencySecondView from "./Frontend/Pages/Emergency/ArchiveEmergencySecondView";
import Emergency from "./Frontend/Pages/Emergency/Emergency";
import EmergencySecond from "./Frontend/Pages/Emergency/EmergencySecondView";
import ArchiveImmunizationSecond from "./Frontend/Pages/Immunization/ArchiveImmunizationSecond";
import Immunization from "./Frontend/Pages/Immunization/Immunization";
import ImmunizationForm from "./Frontend/Pages/Immunization/ImmunizationForm";
import ImmunizationFormEdit from "./Frontend/Pages/Immunization/ImmunizationFormEdit";
import ImmunizationSecond from "./Frontend/Pages/Immunization/ImmunizationSecond";
import ArchiveJournalInfo from "./Frontend/Pages/Journal/ArchiveJournalInfo";
import Journal from "./Frontend/Pages/Journal/Journal";
import JournalInfo from "./Frontend/Pages/Journal/JournalInfo";
import { LandingPg } from "./Frontend/Pages/LandingPage/LandingPg";
import ArchivePetSecondView from "./Frontend/Pages/PetInformation/ArchivePetSecondView";
import PetInformation from "./Frontend/Pages/PetInformation/PetInformation";
import PetInfoSecond from "./Frontend/Pages/PetInformation/PetSecondView";
import ArchivePetInsuSecond from "./Frontend/Pages/PetInsuarance/ArchivePetInsuSecond";
import PetInsuarance from "./Frontend/Pages/PetInsuarance/PetInsuarance";
import PetInsuSecond from "./Frontend/Pages/PetInsuarance/PetInsuSecond";
import ArchiveSecond from "./Frontend/Pages/Prescription/ArchiveSecond";
import Prescription from "./Frontend/Pages/Prescription/Prescription";
import Second from "./Frontend/Pages/Prescription/Second";
import ProviderInformation from "./Frontend/Pages/ProviderInformation/ProviderInformation";
import SelectUserPage from "./Frontend/Pages/SelectUser/SelectUserPage";
import EditUserInformation from "./Frontend/Pages/Settings/SettingProfile";
import Settings from "./Frontend/Pages/Settings/Settings";
import UserProfileEdit from "./Frontend/Pages/Settings/UserProfileDetails";
import AdminUserList from "./Frontend/Pages/SuperAdmin/AdminUserList";
import WorkspaceList from "./Frontend/Pages/SuperAdmin/WorkspaceList";
import ArchiveSurgerySecond from "./Frontend/Pages/SurgeryTreatInfo/ArchiveSurgerySecond";
import SurgeryTreatInfo from "./Frontend/Pages/SurgeryTreatInfo/SurgeryandTreatAdd";
import SurgeryandTreatForm from "./Frontend/Pages/SurgeryTreatInfo/SurgeryandTreatForm";
import Surgeryedit from "./Frontend/Pages/SurgeryTreatInfo/Surgeryedit";
import Surgeryform from "./Frontend/Pages/SurgeryTreatInfo/Surgeryform";
import SurgerySecond from "./Frontend/Pages/SurgeryTreatInfo/SurgerySecond";
import UserInformation from "./Frontend/Pages/UserInformation/UserInformation";
import ArchiveSecondView from "./Frontend/Pages/VetTherapist/ArchiveSecondView";
import SecondView from "./Frontend/Pages/VetTherapist/SecondView";
import VetTherapist from "./Frontend/Pages/VetTherapist/VetTherapist";
import { CMS_ROUTES, TOKEN_KEY } from "./Frontend/Constant";
import MembershipList from "./Frontend/Pages/SuperAdmin/MembershipInformation/MembershipList";
import AdminVerifyAccount from "./Pages/Admin/AdminVerifyAccount";

function App() {
  usePageTracking();
  const token = localStorage.getItem(TOKEN_KEY);
  const checkToken = token && token !== "null" ? token : "";
  const isNotLogin = CMS_ROUTES.includes(window?.location?.pathname) || !checkToken;  

  return (
    <>
      <Router>
        {isNotLogin && <Header />}
        <Content>
          {isNotLogin && <Container maxWidth="xl">
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route
                exact
                path="/for-individuals"
                element={<Forindividuals />}
              ></Route>
              <Route exact path="/account" element={<Login />}></Route>
              <Route
                exact
                path="/forbusiness"
                element={<BusinessComponent />}
              ></Route>
              <Route exact path="/aboutus" element={<AboutUS />}></Route>
              <Route exact path="/resources" element={<ResourcePg />}></Route>
              <Route exact path="/contactus" element={<ContactUs />}></Route>
              <Route exact path="/register" element={<Register />}></Route>
              <Route
                exact
                path="/terms-conditions"
                element={<TermCondition />}
              ></Route>

              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              ></Route>
              <Route
                exact
                path="/user/reset"
                element={<ChangePassword />}
              ></Route>
              <Route
                exact
                path="/subuser/reset"
                element={<SubUserChangePassword />}
              ></Route>
              <Route
                exact
                path="/success-page"
                element={<SuccessPage />}
              ></Route>
              <Route
                exact
                path="/user/VerifyAccount"
                element={<VerifyAccount />}
              ></Route>
              <Route
                exact
                path="/subuser/VerifyAccount"
                element={<SubUserVerifyAccount />}
              ></Route>
              <Route
                exact
                path="/admin/VerifyAccount"
                element={<AdminVerifyAccount />}
              ></Route>
            </Routes>
          </Container>}
          <Routes>
            <Route exact path="/provider/form" element={<ProviderEmailForm />}></Route>
          </Routes>
          {/* -------------------------Frontend----------------------------- */}
          <AuthProvider>
            <Routes>
              <Route
                exact
                path="/workspace-information"
                element={<WorkspaceList />}
              ></Route>
              <Route
                exact
                path="/workspace-information/info/:id"
                element={<AdminUserList />}
              ></Route>
              <Route
                exact
                path="/memberShip-information"
                element={<MembershipList />}
              ></Route>
              <Route
                exact
                path="/selectWorkspace"
                element={<SelectUserPage />}
              ></Route>
              <Route
                exact
                path="/provider-information"
                element={<ProviderInformation />}
              ></Route>
              <Route
                exact
                path="/provider-information/form"
                element={<ProviderForm />}
              ></Route>
              <Route
                exact
                path="/provider-information/info/:id"
                element={<ProviderComponent />}
              ></Route>
              <Route exact path="/dashboard" element={<LandingPg />}></Route>
              <Route exact path="/journal" element={<Journal />}></Route>
              {/* <Route exact path="/summary" element={<Summary />}></Route> */}
              <Route exact path="/drag-and-drop" element={<DragandDrop />} />
              <Route
                exact
                path="/pet-information"
                element={<PetInformation />}
              />
              <Route
                exact
                path="/pet-information/info/:id"
                element={<PetInfoSecond />}
              />
              <Route
                exact
                path="/user-information"
                element={<UserInformation />}
              />
              <Route
                exact
                path="/user-information/edit/:id"
                element={<UserEditForm />}
              />
              <Route
                exact
                path="/user-information/user-info/:id"
                element={<UserInfo />}
              />
              <Route exact path="/emergency-contact" element={<Emergency />} />
              <Route
                exact
                path="/vet-and-therapist"
                element={<VetTherapist />}
              />
              <Route
                exact
                path="/prescription-and-medication"
                element={<Prescription />}
              />
              <Route exact path="/pet-insurance" element={<PetInsuarance />} />
              <Route exact path="/immunization" element={<Immunization />} />
              <Route exact path="/allergy" element={<AllergyInfo />}></Route>
              <Route exact path="/conditions" element={<ConditionsInfo />} />
              <Route
                exact
                path="/surgery-and-treatment"
                element={<SurgeryTreatInfo />}
              />
              <Route exact path="/document" element={<Document />}></Route>
              <Route exact path="/archive" element={<Archive />}></Route>
              <Route
                exact
                path="/archive/allergy"
                element={<ArchiveAllegyList />}
              />
              <Route
                exact
                path="/archive/condition"
                element={<ArchiveConditionList />}
              />
              <Route
                exact
                path="/archive/document"
                element={<ArchiveDocumentList />}
              />
              <Route
                exact
                path="/archive/emergency-contact"
                element={<ArchiveEmergencyContactList />}
              />
              <Route
                exact
                path="/archive/immunization"
                element={<ArchiveImmunizationList />}
              />
              <Route
                exact
                path="/archive/journal"
                element={<ArchiveJournalList />}
              />
              <Route
                exact
                path="/archive/pet-information"
                element={<ArchivePetInformationList />}
              />
              <Route
                exact
                path="/archive/pet-insurance"
                element={<ArchivePetInsuranceList />}
              />
              <Route
                exact
                path="/archive/prescription-medication"
                element={<ArchivePrescriptionMedicationList />}
              />
              <Route
                exact
                path="/archive/surgery-treatment"
                element={<ArchiveSurgeryTreatmentList />}
              />
              <Route
                exact
                path="/archive/user-information"
                element={<ArchiveUserInformationList />}
              />
              <Route
                exact
                path="/archive/vet-therapist"
                element={<ArchiveVetTherapistList />}
              />
              <Route
                exact
                path="/archive/journal-info/:id"
                element={<ArchiveJournalInfo />}
              />
              <Route
                exact
                path="/archive/pet-info/:id"
                element={<ArchivePetSecondView />}
              />
              <Route
                exact
                path="/archive/emergency-info/:id"
                element={<ArchiveEmergencySecondView />}
              />
              <Route
                exact
                path="/archive/vet-and-therapist-info/:id"
                element={<ArchiveSecondView />}
              />
              <Route
                exact
                path="/archive/prescription-info/:id"
                element={<ArchiveSecond />}
              />
              <Route
                exact
                path="/archive/Insurance-Info/:id"
                element={<ArchivePetInsuSecond />}
              />
              <Route
                exact
                path="/archive/immunization-info/:id"
                element={<ArchiveImmunizationSecond />}
              />
              <Route
                exact
                path="/archive/allergy-info/:id"
                element={<ArchiveAllergySecond />}
              />
              <Route
                exact
                path="/archive/conditions-info/:id"
                element={<ArchiveConditionsSecond />}
              />
              <Route
                exact
                path="/archive/surgery-info/:id"
                element={<ArchiveSurgerySecond />}
              />
              <Route
                exact
                path="/archive/document-info/:id"
                element={<ArchiveDocumentSecond />}
              />
              <Route
                exact
                path="/vet-and-therapist/vet-and-therapist-info/:id"
                element={<SecondView />}
              />
              <Route
                exact
                path="/emergency-contact/emergency-info/:id"
                element={<EmergencySecond />}
              />
              <Route
                exact
                path="/journal/form"
                element={<JournalForm />}
              ></Route>
              <Route
                exact
                path="/journal/edit/:id"
                element={<EditJournalForm />}
              />
              <Route exact path="/journal/info/:id" element={<JournalInfo />} />
              <Route exact path="/settings" element={<Settings />}></Route>
              <Route
                exact
                path="/settings/profile-information/:id"
                element={<EditUserInformation />}
              />
              <Route
                exact
                path="/settings/edit-user-information/:id"
                element={<UserProfileEdit />}
              />
              <Route
                exact
                path="/prescription-and-medication/prescription-info/:id"
                element={<Second />}
              />
              <Route
                exact
                path="/pet-insurance/insurance-info/:id"
                element={<PetInsuSecond />}
              />
              <Route
                exact
                path="/immunization/immunization-info/:id"
                element={<ImmunizationSecond />}
              />
              <Route
                exact
                path="/allergy/info/:id"
                element={<AllergySecond />}
              />
              <Route
                exact
                path="/conditions/info/:id"
                element={<ConditionsSecond />}
              />
              <Route
                exact
                path="/surgery-and-treatment/info/:id"
                element={<SurgerySecond />}
              />
              <Route
                exact
                path="/document/info/:id"
                element={<DocumentSecond />}
              />
              <Route
                exact
                path="/surgery-and-treatment/surgery-and-treatment-form"
                element={<SurgeryandTreatForm />}
              />
              <Route
                exact
                path="/surgery-and-treatment/form"
                element={<Surgeryform />}
              />
              <Route
                exact
                path="/conditions/form"
                element={<ConditionFormSection />}
              />
              <Route
                exact
                path="/user-information/form"
                element={<UserForm />}
              />
              <Route
                exact
                path="/pet-information/form"
                element={<PetInfoForm />}
              />
              {/* <Route exact path="/pet-insurance/Form" element={<FormandStep />} /> */}
              <Route
                exact
                path="/prescription-and-medication/form"
                element={<PrescriptForm />}
              />
              <Route
                exact
                path="/vet-and-therapist/form"
                element={<VetForm />}
              />
              <Route
                exact
                path="/vet-and-therapist/edit/:id"
                element={<EditForm />}
              />
              <Route
                exact
                path="/vet-and-therapist/preview"
                element={<VetInfoPreview />}
              />
              <Route
                exact
                path="/emergency-contact/form"
                element={<EmergencyForm />}
              />
              <Route exact path="/document/form" element={<DocumentForm />} />
              <Route exact path="/allergy/edit" element={<AllergyFormEdit />} />
              <Route
                exact
                path="/immunization/form"
                element={<ImmunizationForm />}
              />
              <Route
                exact
                path="/immunization/edit"
                element={<ImmunizationFormEdit />}
              />
              <Route
                exact
                path="/allergy/form"
                element={<AllergyForm />}
              ></Route>
              <Route
                exact
                path="/pet-information/preview/:id"
                element={<PetInfoPreview />}
              />
              <Route
                exact
                path="/pet-information/edit/:id"
                element={<PetInfoEdit />}
              />
              <Route
                exact
                path="/prescription-and-medication/edit/:id"
                element={<PrescriptEditForm />}
              />
              <Route
                exact
                path="/emergency-contact/preview/:id"
                element={<EmergencyPreview />}
              />
              <Route
                exact
                path="/document/edit/:id"
                element={<DocumentEdit />}
              />
              {/* <Route
                exact
                path="/document/preview/:id"
                element={<DocumentPreview />}
              /> */}
              <Route
                exact
                path="/immunization/edit/:id"
                element={<ImmunizationFormEdit />}
              />
              <Route
                exact
                path="/allergy/edit/:id"
                element={<AllergyFormEdit />}
              />
              <Route
                exact
                path="/conditions/edit/:id"
                element={<ConditionsFormSectionEdit />}
              />
              <Route
                exact
                path="/pet-insurance/pet-insurance-form"
                element={<Petinsurance />}
              />
              <Route
                exact
                path="/pet-insurance/pet-insurance-edit-form/:id"
                element={<Petinsuranceedit />}
              />
              <Route
                exact
                path="/surgery-and-treatment/edit/:id"
                element={<Surgeryedit />}
              />
              <Route
                exact
                path="/emergency-contact/edit/:id"
                element={<EmergencyEdit />}
              />
            </Routes>
          </AuthProvider>
        </Content>
        {isNotLogin && <Footer />}
      </Router>
    </>
  );
}
const Content = styled.div`
  ${
    "" /* position: absolute;
  margin-top: 10%; */
  }
`;

export default App;
