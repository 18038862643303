import React from "react";
import styled from "styled-components";
import { getHomeOverview } from "../../../../api/axios";
import { MediumText } from "../../../../Assets/Styles/GlobleStyles/TextStyles";
import useSWR from "swr";

export default function FeatureIcons() {
  const { data: overview } = useSWR(
    `getHomeOverview`,
    async () => {
      try {
        const { data } = await getHomeOverview();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <Wrapper>
      <FeatureDetails>
        <Image src={overview?.icon1} />
        <Title style={{ color: "#FF8533" }}>{overview?.name1}</Title>
      </FeatureDetails>
      <FeatureDetails>
        <Image src={overview?.icon2} />
        <Title style={{ color: "#249FDA" }}>{overview?.name2}</Title>
      </FeatureDetails>
      <FeatureDetails>
        <Image src={overview?.icon3} />
        <Title style={{ color: "#3CA899" }}>{overview?.name3}</Title>
      </FeatureDetails>
      <FeatureDetails>
        <Image src={overview?.icon4} />
        <Title style={{ color: "#B5A488" }}>{overview?.name4}</Title>
      </FeatureDetails>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 200px;

  @media (max-width: 975px) {
    flex-wrap: wrap;
    gap: 30px;
  }

  @media (max-width: 540px) {
    flex-wrap: wrap;
    padding: 10px 10px;
  }

  @media (max-width: 410px) {
    flex-wrap: wrap;
    padding: 10px 10px;
  }
`;

const FeatureDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  line-height: 2;
  place-items: center;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 975px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 690px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 91px;
  height: 91px;
  object-fit: contain;
`;

const Title = styled(MediumText)`
  font-weight: 500;
  font-size: 20px;
  display: inline;
  margin-block-start: 20%;
  text-align: center;

  @media (max-width: 975px) {
    margin-block-start: 10%;
  }

  @media (max-width: 690px) {
    margin-block-start: 5%;
  }
`;
