import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  DeleteConditions,
  getConditionByID,
} from "../../../Api/Services/ConditionsServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { getPetHistoryById } from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from '../../../Constant';
import { addItemtoObject } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ConditionInfoComponent() {
  const { id } = useParams();
  const [petData, setPetData] = useState()
  const navigate = useNavigate();

  const { data: conditionData, isLoading } = useSWR(
    id ? `conditionData${id}` : null,
    async () => {
      const { data } = await getConditionByID(id);
      if (data?.data?.length) {
        const pet_id = data?.data?.[0].pet_id;
        const { data: petInfo } = await getPetByID(pet_id);
        setPetData(petInfo?.data);
      }
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: conditionHistory } = useSWR(
    id ? `conditionHistory${id}` : null,
    async () => {
      try {
        const { data } = await getPetHistoryById("condition", id);
        const propertiesToUpdate = ['pet_name'];
        const propertiesToDelete = [ 'id', 'pet_id'];
        const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
        return filteredData || [];
      } catch (error) {
        console.error('error: ', error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await DeleteConditions(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Condition Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/conditions");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Condition",
        value: "conditions",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        conditions: conditionData?.conditions,
      },
    ],
    detailsData: [
      [{ name: "Information", value: conditionData?.condition_note }],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/conditions/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Condition History",
    historyColumnsData: conditionHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} maxLength={1000} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
