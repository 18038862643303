import React from "react";

import DocumentInfoComponent from "../../Components/Selected-item-view/Document/DocumentInfoComponent";

const DocumentSecond = () => {

  return (
    <>
      <DocumentInfoComponent />
    </>
  );
};

export default DocumentSecond;
