import React from "react";
import "../../Assets/Styles/AboutUs/OurStory.css";
import { getAboutUsStorySection, getAboutUsStoryPara } from "../../api/axios";
import useSWR from "swr";

function OurStory() {
  const { data: storyData } = useSWR(
    `aboutUsStorySection`,
    async () => {
      try {
        const { data } = await getAboutUsStorySection();
        return data?.data?.[0] || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: paragraph } = useSWR(
    `aboutUsStoryParagraphs`,
    async () => {
      try {
        const { data } = await getAboutUsStoryPara();
        return data?.data?.[0] || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div className="OurStoryWrapper">
      <div className="OurStoryHeading">
        Our<span className="StorychangeColor"> Story</span>
      </div>
      <div className="OurStoryContent">
        <div className="StoryImg">
          <img
            className="member"
            src={storyData?.image1}
            width={"150px"}
            alt=""
            height={"150px"}
            style={{ objectFit: "cover" }}
          />
          <p color="#000000">
            {storyData?.name1}
            <br />
            {storyData?.position1}
          </p>
          <br />
          <img
            className="member"
            src={storyData?.image2}
            width={"150px"}
            alt=""
            height={"150px"}
            style={{ objectFit: "cover" }}
          />
          <p>
            {storyData?.name2}
            <br />
            {storyData?.position2}
          </p>
        </div>
        <div className="StoryText">
          <p>{paragraph?.paragraph1}</p>
          <p>{paragraph?.paragraph2}</p>
          <p>{paragraph?.paragraph3}</p>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
