// ----pet infoFrom start----
export const usStates = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
  { value: "Other", label: "Other" },
];

export const genderOptionsCustom = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const chipOptionsCustom = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const eyeColorOptions = [
  { value: "Brown", label: "Brown" },
  { value: "Green", label: "Green" },
  { value: "Blue", label: "Blue" },
  { value: "Other", label: "Other" },
];

export const petTypeOptions = [
  { value: "Dog", label: "Dog" },
  { value: "Cat", label: "Cat" },
  { value: "Bird", label: "Bird" },
  { value: "Reptile", label: "Reptile" },
  { value: "Rabbit", label: "Rabbit" },
  { value: "Guinea Pig", label: "Guinea Pig" },
  { value: "Horse", label: "Horse" },
  { value: "Other", label: "Other" },
];

export const weightMeasurementOptions = [
  { value: "kg", label: "kg" },
  { value: "lbs", label: "lbs" },
  { value: "ounce", label: "ounce" },
];
export const furColorOptions = [
  { value: "Black", label: "Black" },
  { value: "White", label: "White" },
  { value: "Brown", label: "Brown" },
  { value: "Tan", label: "Tan" },
  { value: "Other", label: "Other" },
];

// -----------pet infoFrom end---------------------

export const ConditionType = [
  { value: "Type A", label: "Type A" },
  { value: "Type B", label: "Type B" },
  { value: "Type C", label: "Type C" },
  { value: "Type D", label: "Type D" },
  { value: "Other", label: "Other" },
];

export const AllergyTypes = [
  { value: "Food", label: "Food" },
  { value: "Environment", label: "Environment" },
  { value: "Medication", label: "Medication" },
  { value: "Insect", label: "Insect" },
  { value: "Contact", label: "Contact" },
  { value: "Other", label: "Other" },
];

export const VaccinationType = [
  { value: "Rabies", label: "Rabies" },
  { value: "Distemper", label: "Distemper" },
  { value: "Parovirus", label: "Parovirus" },
  { value: "Adenovirus", label: "Adenovirus" },
  { value: "Bordetella", label: "Bordetella" },
  { value: "Leptospira", label: "Leptospira" },
  { value: "Borrelia Burgdorferi", label: "Borrelia Burgdorferi" },
  { value: "CPIV", label: "CPIV" },
  { value: "CIV", label: "CIV" },
  { value: "HEN2", label: "HEN2" },
  { value: "FVRCP", label: "FVRCP" },
  { value: "FeLV", label: "FeLV" },
  { value: "Other", label: "Other" },
];

export const memberNames = [
  { value: "John", label: "John" },
  { value: "Jane", label: "Jane" },
  { value: "David", label: "David" },
  { value: "Emma", label: "Emma" },
  { value: "Type here", label: "Type here" },
];

export const DurationOptions = [
  { value: "On going", label: "On going" },
  { value: "1 year", label: "1 year" },
  { value: "2 years", label: "2 years" },
  { value: "3 years", label: "3 years" },
];

// -----------Prescription and medication---------------------

export const dosageOptions = [
  { value: "teaspoon", label: "teaspoon" },
  { value: "tablespoon", label: "tablespoon" },
  { value: "pill", label: "pill" },
  { value: "mg", label: "mg" },
  { value: "Other", label: "Other" },
];

export const frequencyOptions = [
  { value: "daily", label: "daily" },
  { value: "weekly", label: "weekly" },
  { value: "monthly", label: "monthly" },
  { value: "annually", label: "annually" },
  { value: "Other", label: "Other" },
];

export const durationOptions = [
  { value: "Day(s)", label: "Day(s)" },
  { value: "Week(s)", label: "Week(s)" },
  { value: "Month(s)", label: "Month(s)" },
  { value: "Year(s)", label: "Year(s)" },
  { value: "Ongoing", label: "Ongoing" },
  { value: "Other", label: "Other" },
];

export const relationshipOptions = [
  { value: "Family Member", label: "Family Member" },
  { value: "Friend", label: "Friend" },
  { value: "Pet Sitter", label: "Pet Sitter" },
  { value: "Other", label: "Other" },
];
//--- emergency-contact end -----

//--- vet and therapy -----
export const typeOptions = [
  { value: "General Vet", label: "General Vet" },
  { value: "Other", label: "Other" },
];

export const allergiesNamesOptions = [
  {
    "type": "Food",
    "names": ["Fish", "Dairy", "Eggs"]
  },
  {
    "type": "Environment",
    "names": ["Pollen", "Grasses"]
  },
  {
    "type": "Medication",
    "names": ["Antibiotics", "Topical Medications"]
  },
  {
    "type": "Insect",
    "names": ["Fleas", "Bees", "Wasps"]
  },
  {
    "type": "Contact",
    "names": ["Certain Fabrics", "Cleaning Products", "Grooming Products", "Metals", "Plastic"]
  },
  {
    "type": "Other",
    "names": []
  }
];