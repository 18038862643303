import React from "react";
import BusinessBodyCard from "./BusinessBodyCard";
import styled, { keyframes } from "styled-components";
import useSWR from "swr";

import bImg8 from "../../Assets/Images/Icons/businessIcon8.png";

import { getForBusinessCard } from "../../api/axios";

export default function BusinessBodySection() {
  const { data: data = [] } = useSWR(
    `getForBusinessCard`,
    async () => {
      try {
        const { data } = await getForBusinessCard();
        const cardData = data.data[0];
        return { ...cardData, index: data.data.indexOf(cardData) };
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <BusinessBodyCardContainer>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#FF8533"
          image={data.card_image1}
          text={data.content1}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#249FDA"
          image={data.card_image2}
          text={data.content2}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#3CA899"
          image={data.card_image3}
          text={data.content3}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#B5A488"
          image={data.card_image4}
          text={data.content4}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#FFBA33"
          image={data.card_image5}
          text={data.content5}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#06476C"
          image={data.card_image6}
          text={data.content6}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard
          color="#6F8A86"
          image={data.card_image7}
          text={data.content7}
        />
      </div>
      <div className="BusinessBodyCard">
        <BusinessBodyCard color="#000000" image={bImg8} text={data.content8} />
      </div>
    </BusinessBodyCardContainer>
  );
}

const animation = keyframes`
   from { opacity: 0; filter: blur(10px); }
  to { opacity: 1; filter: blur(0px);}
`;

export const BusinessBodyCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  margin: 70px 0px;
  gap: 3vw;
  opacity: 0;
  animation: ${animation} 1s 0.1s forwards;

  @media (max-width: 1300px) {
    column-gap: 2.5vw;
  }
  @media (max-width: 900px) {
    max-width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin: 50px 0px;
  }

  .BusinessBodyCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
