import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { getLastPetsByWorkspaceId, uploadS3Bucket } from "../../Api/axios";
import {
  createNewPet,
  updatePetImageById,
} from "../../Api/Services/PetServices";
import { MEMBERSHIP_NUMBER_KEY, WORKSPACEID_KEY } from "../../Constant";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  newSmallFieldBoxStyle,
  Column,
  Body,
  FormHeaderBox,
  FormHeaderTitle,
  CancelButton,
  ButtonBoxStyle,
} from "../../Css";
import { PetInfoFormValidation } from "../../From/Validation";
import {
  chipOptionsCustom,
  genderOptionsCustom,
  eyeColorOptions,
  petTypeOptions,
  weightMeasurementOptions,
  furColorOptions,
} from "../../Options";
import { getCountryAsOption, getStateAsOptions } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import AutoCompleteAddress from "../Common/AutoCompleteAddress";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import ImageUpload from "../Common/ImageUpload";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";

const defaultValue = {
  user_id: "",
  name: "",
  family_name: "",
  dob: "",
  breed: "",
  company_name: "",
  company_contact_number: "",
  marks: "",
  reg_number: "",
  chip_availability: "",
  fur_color: "",
  eye_Color: "",
  gender: "",
  weight: "",
  weightMeasurement: "",
  pet_type: "",
  zip_code: "",
  street_address: "",
  apt_city: "",
  country: "",
  state: "",
  membership_no: "",
};

export default function PetInfoForm() {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  // Address
  const [image, setImage] = useState(null);

  const maxDate = new Date().toISOString().substr(0, 10);

  const { petOwnerId: user_id } = useAuth();
  const membership_id = localStorage.getItem(MEMBERSHIP_NUMBER_KEY);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);
  const [membershipNo, setMembershipNo] = useState("");

  const setNextMemberId = async () => {
    try {
      const { data } = await getLastPetsByWorkspaceId(w_id);
      let membership_no = `${membership_id}-0-001`;
      let maxMembershipNo = 1;
      if (data?.data?.length) {
        const pet = data.data[0];
        const petMembershipNo = pet.membership_no;
        if (petMembershipNo) {
          const [, , , , , , number] = petMembershipNo.split("-");
          const parsedNumber = parseInt(number, 10);
          maxMembershipNo = Math.max(maxMembershipNo, parsedNumber);
          // Increment the maximum membership number and append it to the membership ID
          const nextNumber = (maxMembershipNo + 1).toString().padStart(3, "0");
          membership_no = `${membership_id}-0-${nextNumber}`;
        }
      }
      setMembershipNo(membership_no);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setNextMemberId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership_id, user_id]);

  const sendPetData = async (values) => {
    const value = {
      name: values?.name,
      family_name: values?.family_name,
      dob: values?.dob,
      breed: values?.breed,
      company_name: values?.company_name,
      company_contact_number: values?.company_contact_number,
      marks: values?.marks,
      reg_number: values?.reg_number,
      chip_availability: values?.chip_availability,
      fur_color: values?.fur_color,
      eye_Color: values?.eye_Color,
      gender: values?.gender,
      weight: values?.weight + values?.weightMeasurement,
      pet_type: values?.pet_type,
      zip_code: values?.zip_code,
      street_address: values?.street_address,
      apt_city: values?.apt_city,
      country: values?.country,
      state: values?.state,
      membership_no: membershipNo,
      w_id: w_id,
    };
    try {
      const res = await createNewPet(value);
      if (image) {
        try {
          const petId = res.data.pet_details.id;
          const updateData = {
            history_id: res?.data?.history_id,
            type: image?.type,
            file_name: `${Date.now()}-${image?.name}`
          };
          const res2 = await updatePetImageById(petId, updateData);
          const url = res2?.data?.data;
          const decodedUrl = decodeURIComponent(url);
          const uploadRes = await uploadS3Bucket(decodedUrl, image);
          if (uploadRes?.status === 200) {
            console.log("success", uploadRes)
          }
        } catch (error) {
          console.error(error);
        }
      }
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const sendData = async (values) => {
    // e.preventDefault();
    setIsUpdating(true);
    try {
      await sendPetData(values);
      setShowSuccessMessage(true);
      setOpen(true);
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
  }, []);

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  return (
    <FormLayout title="Pet Information">
      <Formik
        initialValues={defaultValue}
        innerRef={formikRef}
        onSubmit={sendData}
        validationSchema={PetInfoFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, setFieldValue, errors, touched }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Pet Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%"}}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <ImageUpload
                    alt="Uploaded_Image"
                    id="imageInput"
                    image={image}
                    setImage={setImage}
                    accept="image/*"
                    boxSx={{
                      width: {
                        sm: 120,
                        md: 120,
                        xs: 93,
                        lg: 130,
                        xl: 130,
                      },
                      height: {
                        sm: 120,
                        md: 120,
                        xs: 105,
                        lg: 130,
                        xl: 130,
                      },
                    }}
                  />
                </Box>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_type" label={"Pet Type"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_type")}
                        name="pet_type"
                        options={petTypeOptions}
                        placeholder="Select a type"
                        otherType="Other"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="name" label={"Pet Name"} requiredField>
                      <InputField {...getFieldProps("name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="breed" label={"Breed"} requiredField>
                      <InputField {...getFieldProps("breed")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="marks" label={"Marks"}>
                      <InputField {...getFieldProps("marks")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="family_name"
                      label={"Family Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("family_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="street_address" label={"Street Address"}>
                      <AutoCompleteAddress
                        formikRef={formikRef}
                        setFieldValue={setFieldValue}
                        value={values?.street_address}
                        address="street_address"
                        city="apt_city"
                        state="state"
                        zipcode="zip_code"
                        country="country"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="dob" label={"Date of Birth"} requiredField>
                      <InputField
                        type="date"
                        className="Date"
                        inputProps={{ max: maxDate }}
                        {...getFieldProps("dob")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="country" label={"Country"}>
                      <AutoComplete
                        {...getFieldProps("country")}
                        name="country"
                        options={countryOptions}
                        placeholder="Select a country"
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "20%" }}>
                        <FormGroup name="weight" label={"Weight"} requiredField>
                          <InputField
                            type="number"
                            {...getFieldProps("weight")}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "40%" }}>
                        <FormGroup
                          name="weightMeasurement"
                          label={"Measure"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("weightMeasurement")}
                            name="weightMeasurement"
                            options={weightMeasurementOptions}
                            placeholder="Select a Measure"
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "40%" }}>
                        <FormGroup name="gender" label={"Gender"} requiredField>
                          <AutoComplete
                            {...getFieldProps("gender")}
                            name="gender"
                            options={genderOptionsCustom}
                            placeholder="Select a gender"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup name="state" label={"State"}>
                          <AutoComplete
                            {...getFieldProps("state")}
                            name="state"
                            otherType="Other"
                            options={stateOptions}
                            placeholder={"Select a type"}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="zip_code" label={"Zip Code"}>
                          <InputField
                            {...getFieldProps("zip_code")}
                            name="zip_code"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="fur_color"
                          label={"Color"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("fur_color")}
                            name="fur_color"
                            options={furColorOptions}
                            placeholder={"Select a color"}
                            otherType="Other"
                          />
                        </FormGroup>
                      </Box>

                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="eye_Color"
                          label={"Eye Color"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("eye_Color")}
                            name="eye_Color"
                            options={eyeColorOptions}
                            otherType="Other"
                            placeholder={"Select a color"}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <FormGroup name="apt_city" label={"City"}>
                      <InputField {...getFieldProps("apt_city")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="chip_availability"
                          label={"Micro Chip Availability"}
                        >
                          <AutoComplete
                            {...getFieldProps("chip_availability")}
                            name="chip_availability"
                            options={chipOptionsCustom}
                            placeholder="Select a Micro Chip Availability"
                          />
                        </FormGroup>
                      </Box>
                      {values.chip_availability === "Yes" && (
                        <Box style={{ paddingLeft: "5px", width: "50%" }}>
                          <FormGroup
                            name="reg_number"
                            label={"Register Number"}
                          >
                            <InputField
                              {...getFieldProps("reg_number")}
                              name="reg_number"
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </Box>
                  </Column>
                  {values.chip_availability === "Yes" && (
                    <Column>
                      <Box
                        sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                      >
                        <Box style={{ width: "50%" }}>
                          <FormGroup name="company_name" label={"Company Name"}>
                            <InputField
                              {...getFieldProps("company_name")}
                              name="company_name"
                            />
                          </FormGroup>
                        </Box>
                        <Box style={{ paddingLeft: "5px", width: "50%" }}>
                          <FormGroup
                            name="company_contact_number"
                            label={"Company Phone Number"}
                          >
                            <CustomPhoneInput
                              {...getFieldProps("company_contact_number")}
                            />
                          </FormGroup>
                        </Box>
                      </Box>
                    </Column>
                  )}
                </Body>
              </div>
              {/* </Grid> */}
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isUpdating ? "Submitting..." : "Submit"}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/pet-information");
          }}
        />
      )}
    </FormLayout>
  );
}
