import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Box,
  Typography,
  CircularProgress,
  Button,
  TableContainer,
  Paper,
  Modal,
} from "@mui/material";
import "../Selected-item-view/PetInformation/Petdetails.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import DetailsModal from "./DetailsModal";
import FormLayout from "./FormHeader";
import HistoryModal from "./HistoryModal";
import DeleteIcon from "../../Assets/Images/DeleteIcon.svg";
import EditIcon from "../../Assets/Images/EditIcon.svg";
import { Scroll } from "../../Css";

export default function PawDataView(props) {
  const [modelOpen, setModelOpen] = useState("");
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [showDeleteSuccessMessage, setShowDeleteSuccessMessage] =
  useState(false);
  const navigate = useNavigate();
  const {
    onBack = () => {},
    label = "",
    petInfo = {},
    isLoading = false,
    columns = [],
    columnsData = [],
    detailsData = [],
    allowEditOrDelete = false,
    onDelete = async () => {},
    editLink = "",
    historyColumns,
    historyColumnsData,
    historyTitle,
    isHistory,
  } = props;

  const onDeleteConfirm = async () => {
    try {
      await onDelete();
      setShowDeleteSuccessMessage(true);
    } catch (e) {
      console.error("Error in delete");
    }
  };

  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        setAskConfirmation(false);
        onDeleteConfirm();
      },
    },
    {
      name: "no",
      onClick: () => {
        setAskConfirmation(false);
      },
    },
  ];
  const successPopupButton = [
    {
      name: "yes",
      onClick: () => {
        onBack();
      },
    },
  ];

  return (
    <React.Fragment>
      {/* TITLE AND INFO */}
      <FormLayout title={label} onBack={onBack}>
        <Scroll>
          {isLoading ? (
            <Box
              sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <CircularProgress style={{ color: "black" }} />
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  boxShadow: "none",
                  border: "1px solid #FF8533",
                  borderRadius: "10px",
                  height: "20%",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        background: "rgba(255, 133, 51, 0.20)",
                        borderBottom: "1px solid #FF8533",
                      }}
                    >
                      {props?.columns.map((column, index) => {
                        if (index === 0) {
                          return (
                            <TableCell
                              key={index}
                              style={{
                                width: column.width || "10vw ",
                                borderBottom: "none",
                              }}
                            > </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={index}
                              style={{
                                width: column.width || "10vw ",
                                fontSize: "12px",
                                color: "#000000",
                                fontWeight: "700",
                                borderBottom: "none",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ overflow: "auto" }}>
                    {props?.columnsData?.length > 0 ? (
                      props?.columnsData?.map((cell, index) => (
                        <TableRow
                          key={`column${index}`}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            borderRadius: "10px 10px 0px 0px",
                            cursor: "pointer",
                          }}
                        >
                          {props?.columns.map((column, cellIndex) => {
                            if (cellIndex === 0) {
                              return (
                                <TableCell
                                  key={`${index}-${cellIndex}`}
                                  align="center"
                                  style={{
                                    width: column.width || "10vw",
                                    borderBottom: "none",
                                  }}
                                >
                                  {cell[column.value] ? (
                                    <img
                                      alt="image1"
                                      src={cell[column.value]}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "10px",
                                        objectFit: "cover",
                                        margin: "0 auto",
                                        verticalAlign: "middle",
                                      }}
                                    />
                                  ) : (
                                    <AccountCircleIcon
                                      sx={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  )}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                key={`${index}-${cellIndex}`}
                                style={{
                                  width: column.width || "10vw",
                                  fontSize: "16px",
                                  borderBottom: "none",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {cell[column.value] || "N/A"}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "#000000",
                            textAlign: "center",
                          }}
                        >
                          No Data Found.
                        </Typography>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <MainBox>
                <CustomTable
                  style={
                    props.detailsData?.length !== 2 ? { width: "50%" } : {}
                  }
                >
                  <CustomTableHeader>
                    <Box
                      sx={{
                        fontSize: "18px",
                        fontWeight: 600,
                        textAlign: "start",
                      }}
                    >
                      {label}
                    </Box>
                  </CustomTableHeader>
                  <Box sx={{ padding: "10px 20px" }}>
                    {props.detailsData?.[0]?.map((item, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: { xs: "15px", sm: "20px", md: "50px" },
                            width: { xs: "100%" },
                            overflowX: { xs: "auto" },
                          }}
                        >
                          <Typography sx={TextStyle}>{item?.name}</Typography>
                          {item?.url ? (
                            <a
                              href={item?.value}
                              target="blank"
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              {item.value ? item.value : "N/A"}
                            </a>
                          ) : item?.pdf ? (
                            <p
                              style={{
                                margin: "8px 0px",
                                textAlign: "start",
                                cursor: "pointer",
                                color: "black",
                                textDecoration: "underline",
                                textUnderlineOffset: "2px",
                              }}
                              onClick={() => setModelOpen(item)}
                            >
                              {item.value ? "View Document" : "N/A"}
                            </p>
                          ) : (
                            <Typography sx={TextStyle}>
                              {item?.value || "NA"}
                            </Typography>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                </CustomTable>
                {props.detailsData?.length === 2 && (
                  <CustomTable>
                    <CustomTableHeader>
                      <Box sx={{ fontSize: "18px", fontWeight: 600 }}>
                        Contact Information
                      </Box>
                    </CustomTableHeader>
                    <Box sx={{ padding: "10px 20px" }}>
                      {props.detailsData?.[1]?.map((item, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={TextStyle}>{item?.name}</Typography>
                            <Typography sx={TextStyle}>
                              {item?.value || "NA"}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </CustomTable>
                )}
              </MainBox>

              <Box
                sx={{
                  width:
                    props.detailsData?.length !== 2
                      ? { sm: "100%", md: "50%" }
                      : "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Box>
                  {isHistory && (
                    <HistoryModal
                      columns={historyColumns}
                      data={historyColumnsData}
                      title={historyTitle}
                    />
                  )}
                </Box>
                {allowEditOrDelete && editLink && (
                  <Box sx={{ textAlign: "end" }}>
                    <Button
                      sx={{
                        minWidth: "40px",
                        background: "rgba(255, 133, 51, 0.20) !important",
                        borderRadius: "10px",
                        width: "24px",
                      }}
                      onClick={() => navigate(editLink)}
                    >
                      <img src={EditIcon} alt="edit icon" />
                    </Button>
                    <Button
                      sx={{
                        minWidth: "40px",
                        background: "rgba(255, 133, 51, 0.20) !important",
                        borderRadius: "10px",
                        width: "24px",
                        marginLeft: "5px",
                      }}
                      onClick={() => {
                        setAskConfirmation(true);
                      }}
                    >
                      <img src={DeleteIcon} alt="delete icon" />
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Scroll>
      </FormLayout>
      {askConfirmation && (
        <>
          <DetailsModal
            label="Would you like to perform this delete action?"
            open={askConfirmation}
            severity="warning"
            buttonList={ButtonItem}
            handleClose={() => setAskConfirmation(!askConfirmation)}
          />
        </>
      )}
      {showDeleteSuccessMessage && (
        <>
          <DetailsModal
            label="Successfully Deleted ! "
            open={showDeleteSuccessMessage}
            severity="success"
            buttonList={successPopupButton}
            handleClose={() =>
              setShowDeleteSuccessMessage(!showDeleteSuccessMessage)
            }
          />
        </>
      )}
      <Modal
        open={!!modelOpen}
        onClose={() => setModelOpen("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Close
              onClick={() => setModelOpen(false)}
              sx={{ cursor: "pointer" }}
            />
          </div>
          <div>
            <iframe
              title="pdf"
              src={modelOpen?.value}
              style={{ width: "50vw", height: "70vh" }}
            />
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const CustomTableHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  background: "rgba(255, 133, 51, 0.20)",
  padding: "10px 20px",
  borderBottom: "1px solid #FF8533",
}));

const TextStyle = {
  color: "black",
  fontSize: "16px",
  fontWeight: 400,
  paddingTop: "5px",
  textAlign: "start",
};

const CustomTable = styled("div")(() => ({
  border: "1px solid #FF8533",
  width: "100%",
  borderRadius: "10px",
  margin: "20px 0px 10px 0px",
  height: "auto",

  "@media (max-width: 900px)": {
    width: "100% !important",
  },
}));

const MainBox = styled("div")(() => ({
  display: "flex",
  gap: "20px",

  "@media (max-width: 900px)": {
    flexDirection: "column",
    gap: "0px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "70%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
};
