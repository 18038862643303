import React from "react";
import styled from "styled-components";
import Slider2 from "../HomeSection3/slider2/Slider2";

export default function HomeSection3() {
  return (
    // main div
    <Wrapper>
      {/* box */}
      <ContentWrapper>
        {/* box content */}
        <Slider2 />
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
    background: #ffffff;    
    overflow: hidden; 
    margin: 0 auto;  
    padding: 0px;

    // @media (max-width: 435px){
    //     padding: 0 30px;
    // }
`
const ContentWrapper = styled.div`
    // height: 371px;    
    margin: 0 auto;
    background: rgba(217, 217, 217, 0.17);
    /* border: 1px solid #06476C; */
    border-radius: 30px;
    overflow: hidden;   
 `
