import React from "react";
import "../../Assets/Styles/AboutUs/AUUpper.css";
import { useNavigate } from "react-router-dom";
import { getAboutUsHeader, getAboutUsParagraph } from "../../api/axios";
import useSWR from "swr";

function AUUpper({ button }) {
  let navigate = useNavigate();

  const { data: header } = useSWR(
    `aboutUsHeader`,
    async () => {
      try {
        const { data } = await getAboutUsHeader();
        return data?.data?.[0]?.paragraph || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: headerParagraph } = useSWR(
    `aboutUsParagraph`,
    async () => {
      try {
        const { data } = await getAboutUsParagraph();
        return data?.data?.[0]?.paragraph || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div>
      <div style={{ paddingTop: "100px" }}></div>
      <div className="Wrapper">
        <div className="TitleWrapper">{header}</div>
        <div className="BodyWrapper">
          <p>{headerParagraph}</p>
          <br />
          <a
            onClick={() => {
              navigate(button?.[5]?.buttonLink);
            }}
          >
            {button?.[5]?.button_name}
          </a>
        </div>
      </div>
    </div>
  );
}

export default AUUpper;
