import React, { useState, useRef, useEffect } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getJournalById,
  updateJournal,
} from "../../Api/Services/JournalServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { getUsersByWorkspaceId } from "../../Api/Services/UserServices";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { JournalFormValidation } from "../../From/Validation";
import { formatDate } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import TextArea from "../Common/TextArea";
const defaultValue = {
  note: "",
  date: "",
  petid: "",
  user: "",
};

export default function EditJournal() {
  const { workspaceId: w_id } = useAuth();
  const [initialValue, setInitialValue] = useState(defaultValue);
  let navigate = useNavigate();
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [usersOptions, setusersOptions] = useState([]);
  const maxDate = new Date().toISOString().substr(0, 10);

  const noteDateRef = useRef(null);
  const noteRef = useRef(null);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getPetNames = async () => {
      try {
        const { data } = await getPetNameByWorkspaceId(w_id);
        const filteredData = data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setItems(filteredData);
      } catch (error) {
        console.error("error: ", error);
      }
    };

    const getData = async () => {
      try {
        const { data } = await getJournalById(id);
        setInitialValue({
          ...initialValue,
          note: data?.data?.note,
          date: formatDate(data?.data?.date),
          petid: data?.data?.petid,
          user: data?.data?.user,
        });
      } catch (error) {
        console.error("error: ", error);
      }
    };

    const getUsersByWorkspaceID = async () => {
      try {
        const res = await getUsersByWorkspaceId(w_id);
        const filteredData = res?.data?.data?.map((item) => {
          return {
            value: `${item?.first_name} ${item?.last_name}`,
            label: `${item?.first_name} ${item?.last_name}`,
          };
        });
        if (filteredData?.length === 0) {
          <></>;
        } else {
          setusersOptions(filteredData);
        }
      } catch (error) {
        console.error("error: ", error);
      }
    };

    getPetNames();
    getData();
    getUsersByWorkspaceID();
  }, [id, w_id]);

  const updateForm = async (values) => {
    const escapedNote = values?.note.replace(/'/g, "\\'");
    const data = {
      date: values?.date,
      petid: values?.petid,
      note: escapedNote,
      user: values?.user,
    };
    try {
      const res = await updateJournal(id, data);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (error) {
      alert("Invalid data enter again");
      console.error("error: ", error);
    }
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Surgery",
  });

  return (
    <FormLayout title="Edit Journal">
      <Formik
        initialValues={initialValue}
        onSubmit={updateForm}
        validationSchema={JournalFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Journal Details
                </Typography>
              </Box>
              {/*-------------------------------------- Form --------------------------------*/}
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="petid" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("petid")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="date" label={"Date of Note"} requiredField>
                      <InputField
                        type="date"
                        {...getFieldProps("date")}
                        inputProps={{ max: maxDate }}
                        inputRef={noteDateRef}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            noteRef.current.focus();
                          }
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="user" label={"User"} requiredField>
                      <AutoComplete
                        {...getFieldProps("user")}
                        options={usersOptions}
                      />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="note" label={"Notes"} requiredField>
                      <TextArea
                        rows={7}
                        {...getFieldProps("note")}
                        inputRef={noteRef}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your journal information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/journal/info/${id}`);
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
