import { api } from "../axios";

export const getPharmacy = async (id) => {
  const res = await api.get(`/pharmacy/getPharmacyById/${id}`);
  return res;
};

//Update Pharmacy
export const updatePharmacy = async (id, formData) => {
  const res = await api.put(`/pharmacy/updatePharmacy/${id}`, formData);
  return res;
};

export const getAllPharmacynames = async () => {
  const res = await api.get(`/pharmacy/getAllPharmacy`);
  return res;
};

export const getPharmacyByWorkspaceId = async (w_id) => {
  const res = await api.get(`/pharmacy/getPharmacyByWorkspaceId/${w_id}`);
  return res;
};
export const getPharmacyById = async (id) => {
  const res = await api.get(`/pharmacy/getPharmacyById/${id}`);
  return res;
};

//Create pharmacy
export const createPharmacy = async (formData) => {
  const res = await api.post(`/pharmacy/createPharmacy`, formData);
  return res;
};
