import React, { useEffect } from "react";
import styled from "styled-components";
import ContentSection from "../../Components/Resources/ContentSection";
// import ResHero from "../../Components/Resources/ResHero";



function ResourcePg() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ResourceHead><b>Resources</b></ResourceHead>
      {/* <ResHero /> */}
      <ContentSection />
    </div>
  );
}

export default ResourcePg;

const ResourceHead = styled.h1`
  margin: 0;
  padding: 170px 0px 50px;
  text-align: left;
  /* font-family: "Omnes"; */
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 112.4%;
  color: #008fd3;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  @media (max-width: 1100px) {
    font-size: 45px;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    letter-spacing: -0.01em;
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    padding: 170px 0px 10px;
  }
`;
