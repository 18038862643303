import React from "react";
import styled from "styled-components";
import { H2, MediumText } from "../../../../Assets/Styles/GlobleStyles/TextStyles";

export default function ScrollCard () {
    return(
        <Wrapper>
            <ContentWrapper>
                <Title>
                <span style={{color:'#ffffff'}}>The global pet industry...</span>
                {/* <span style={{color:'#ffffff'}}> fun stats.</span> */}
                </Title>
                <BoxWrapper>
                    <BodySection>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><span style={{color:'#ffffff'}}>$</span><BoxHighlight>260+</BoxHighlight> Billion</BoxTitle>
                                    <BoxDescription>global pet industry valuation as of 2023</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>80</BoxHighlight> Million+</BoxTitle>
                                    <BoxDescription>households with a pet</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                    </BodySection>
                    <BodySection>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>4</BoxHighlight> Million</BoxTitle>
                                    <BoxDescription>animals travel via airplane worldwide</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>23</BoxHighlight> Million</BoxTitle>
                                    <BoxDescription>pets rescued during COVID-19 pandemic</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                    </BodySection>
                    <BodySection>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle>$<BoxHighlight>114</BoxHighlight> Billion</BoxTitle>
                                    <BoxDescription>spent on vet bills each year worldwide</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>20%</BoxHighlight></BoxTitle>
                                    <BoxDescription>of the global population subscribe to pet related product boxes</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                    </BodySection>   
                    <BodySection>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>22%</BoxHighlight></BoxTitle>
                                    <BoxDescription>increase in call volumes and over 401,550 calls related to pet exposures to toxic substances</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                        <BoxDetailWrapper>
                            <Box>
                                <BoxDetails>
                                    <BoxTitle><BoxHighlight>6.3</BoxHighlight> Million</BoxTitle>
                                    <BoxDescription>animals enter shelters each year</BoxDescription>
                                </BoxDetails>
                            </Box>
                        </BoxDetailWrapper>
                    </BodySection>
                </BoxWrapper>
            </ContentWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin: 0 auto;
    overflow: hidden;
    padding: 50px 0px;
    padding-bottom: 0px;

    @media (max-width: 530px) {
        padding: 50px 0px 20px;
    }

    @media (max-width: 330px) {
        padding: 50px 0px 5px;
    }
`

const ContentWrapper = styled.div`
    padding: 0px 27px 27px 27px;
    display: grid;
    grid-template-columns: 400px auto;
    gap: 30px;
    border: 2px solid #ffffff;
    border-radius: 20px;
    background: #FF8533;

    @media (max-width: 1325px) {
        grid-template-columns: 300px auto;
        gap: 10px;
    }

    @media (max-width: 1160px) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }

    @media (max-width: 470px) {
        padding: 0px 20px 15px 20px;
    }

    @media (max-width: 330px) {
        padding: 0px 0px 15px 0px;
    }
`

const Title = styled.p`
    font-weight: bold;
    font-size: 36px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    line-height: 120px;
    // margin-top:60px;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin: 0;
    line-height: 50px;

    @media (max-width: 1290px) {
        font-size: 34px;
    }

    @media (max-width: 1160px) {
        text-align: center;
        font-size: 28px;
    }

    @media (max-width: 690px) {
        font-size: 26px;
    }
    @media (max-width: 425px) {
        font-size: 24px;
    }
`

const BoxWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 35px;

    // @media (max-width: 2001px) {
    //     grid-template-columns: 1fr 1fr;
    // }
    @media (max-width: 1290px) {
        gap: 0px 20px;
    }

    @media (max-width: 855px) {
        display: flex;
        flex-direction: column;
    }
`

const BodySection = styled.div``

const BoxDetailWrapper = styled.div`
    margin-top: 27px;
`

const Box = styled.div`
    border: 1px solid #ffffff;
    max-width: 410px;
    height: 100px;
    place-items: center;
    margin: 0 auto;
    padding: 27px 27px 40px 27px;
    // padding-top: 2rem;
    // padding-bottom: 3rem;
    background: #FF8533;
    
    @media (max-width: 2001px) {
        padding: 27px;
    }

    @media (max-width: 1510px) {
        padding: 27px 27px 40px 27px;
    }

    @media (max-width: 420px) {
        width: 280px;
        padding: 1rem 0 2rem 0;
    }

    @media (max-width: 370px) {
        width: 255px;
    }

    @media (max-width: 340px) {
        width: 245px;
    }
`

const BoxDetails = styled.div`
    justify-content: space-around;
    line-height: 0;
    margin: 0 auto;
    text-align: center;
`

const BoxTitle = styled(H2)`
    font-size: 36px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 420px) {
        font-size: 28px;
    }

    @media (max-width: 370px) {
        font-size: 25px;
    }

    @media (max-width: 340px) {
        font-size: 25px;
    }
`;

const BoxHighlight = styled.span`
    font-size: 56px;
    color: #008FD3;

    @media (max-width: 1290px) {
        font-size: 38px;
    }

    // @media (max-width: 1160px) {
    //     text-align: center;
    //     font-size: 28px;
    // }

    // @media (max-width: 690px) {
    //     font-size: 26px;
    // }
    // @media (max-width: 425px) {
    //     font-size: 24px;
    // }
`

const BoxDescription = styled(MediumText)`
    font-size: 18px;
    font-weight: 500px;
    color: #ffffff;
`