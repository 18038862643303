import React, { useState, useEffect, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getMedicationById,
  updateMedication,
} from "../../../Api/Services/MedicationServices";
import {
  getPharmacyById,
  getPharmacyByWorkspaceId,
  updatePharmacy,
} from "../../../Api/Services/PharmacyServices";
import {
  getAllVetnamePres
} from "../../../Api/Services/VetServices";
import useAuth from "../../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../../Css";
import { EditPrescriptionFormValidation } from "../../../From/Validation";
import {
  dosageOptions,
  durationOptions,
  frequencyOptions,
} from "../../../Options";
import { formatDate } from "../../../Utils";
import AutoComplete from "../../Common/AutoComplete";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import InputField from "../../Common/InputField";
import CustomPhoneInput from "../../Common/PhoneInput";
import "../input.css";
import "react-phone-input-2/lib/style.css";
import "../../pet-information/petPrint.css";
import CustomSnackbar from "../../Common/Snackbar";
import TextArea from "../../Common/TextArea";

const defaultValue = {
  type: "",
  medication_name: "",
  durtion: "",
  dosage: "",
  frequency: "",
  start_date: "",
  reason: "",
  prescripton_number: "",
  pet_id: "",
  vet_id: "",
  pharmacy_id: "",
  pharmacy_contact: "",
  durationMeasurement: "",
  dosageMeasurement: "",
  frequencyMeasurement: "",
  pharmacy_name: "",
};

export default function PrescriptEditForm() {
  const { workspaceId: w_id } = useAuth();
  const [initialValue, setInitialValue] = useState(defaultValue);
  const navigate = useNavigate();
  const formRef = useRef();
  const { id } = useParams();

  const [vetNameOptions, setVetNameOptions] = useState([]);
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  //Prescrption Details
  useEffect(() => {
    //getPetName();
    getMedicationById(id)
      .then((response) => {
        const prescriptData = response?.data?.data?.[0];
        const dosageArray = prescriptData.dosage.match(/^([\d.]+)(\D+)$/);
        const frequencyArray = prescriptData.frequency.match(/^([\d.]+)(\D+)$/);
        const durationArray = prescriptData.durtion.match(/^([\d.]+)(\D+)$/);

        setInitialValue({
          ...formRef.current.values,
          pet_name: prescriptData.pet_name,
          type: prescriptData?.type,
          medication_name: prescriptData.medication_name,
          // vet_practice_name: prescriptData.vet_full_name,
          durtion: durationArray?.[1],
          dosage: dosageArray?.[1],
          frequency: frequencyArray?.[1],
          start_date: formatDate(prescriptData.start_date),
          reason: prescriptData.reason,
          prescripton_number: prescriptData.prescripton_number,
          vet_id: prescriptData?.vet_id,
          pharmacy_id: prescriptData.pharmacy_id,
          durationMeasurement: durationArray?.[2],
          dosageMeasurement: dosageArray?.[2],
          frequencyMeasurement: frequencyArray?.[2],
        });
        getPharmacyById(prescriptData.pharmacy_id)
          .then((response) => {
            const pharmacyData = response.data.data[0];
            // setPharmacy_name(pharmacyData.pharmacy_name);
            // setPharmacy_contact(pharmacyData.pharmacy_contact);
            formRef.current?.setFieldValue(
              "pharmacy_name",
              pharmacyData.pharmacy_name
            );
            formRef.current?.setFieldValue(
              "pharmacy_contact",
              pharmacyData.pharmacy_contact
            );
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const updatePrescriptionFrom = (values) => {
    const value = {
      type: values?.type,
      medication_name: values?.medication_name,
      durtion: values?.durtion + values?.durationMeasurement,
      dosage: values?.dosage + values?.dosageMeasurement,
      frequency: values?.frequency + values?.frequencyMeasurement,
      start_date: values?.start_date,
      reason: values?.reason,
      prescripton_number: values?.prescripton_number,
      vet_id: values?.vet_id,
      pharmacy_id: values?.pharmacy_id,
    };
    const value2 = {
      pharmacy_name: values?.pharmacy_name,
      pharmacy_contact: values?.pharmacy_contact,
      w_id,
    };
    Promise.all([
      updateMedication(id, value),
      updatePharmacy(values?.pharmacy_id, value2),
    ])
      .then((res) => {
        setShowSuccessMessage(true);
        setOpen(true);
        //alert("Medication Form updated successfully!")
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getAllVetnamePres(w_id)
      .then((res) => {
        const uniqueValues = res?.data?.data?.filter(
          (obj, index, self) =>
            index === self.findIndex((el) => el.id === obj.id)
        );
        const filteredData = uniqueValues?.map((item) => {
          return {
            value: item?.id,
            label: item?.vet_full_name,
          };
        });
        // const vetNames = res.data.data.map((vetPrac) => vetPrac.vet_full_name);
        // setVetNameOptions(vetNames);
        setVetNameOptions(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching vet data: ", error);
      });
  }, []);

  useEffect(() => {
    getPharmacyByWorkspaceId(w_id)
      .then((res) => {
        const PharmacyNames = res?.data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.pharmacy_name,
          };
        });
        setPharmacyOptions(PharmacyNames);
      })
      .catch((error) => {
        console.error("Error fetching pharmacy data: ", error);
      });
  }, []);

  const pharmacySelector = (e, newvalue) => {
    // setPharmacy_id(e.target.value);
    formRef.current?.setFieldValue("pharmacy_id", newvalue?.value);
    getPharmacyById(newvalue?.value)
      .then((response) => {
        const data = response.data.data[0];
        // setPharmacy_name(data.pharmacy_name);
        formRef.current?.setFieldValue("pharmacy_name", data.pharmacy_name);
        formRef.current?.setFieldValue(
          "pharmacy_contact",
          data.pharmacy_contact
        );

        // setPharmacy_contact(data.pharmacy_contact);
        // console.log(data.pharmacy_contact);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Pet Information",
    pageStyle: `
      @page {
        size: A3; 
        margin: 200px; 
      }
      body {
        margin: 100px; 
        
    `,

    printStyle: `
    
    .print-text-field {
      font-size: 14px; /* Adjust the font size for printing */
     
  `,
  });

  return (
    <FormLayout title="Edit Prescription and Medication Information">
      <Formik
        initialValues={initialValue}
        onSubmit={updatePrescriptionFrom}
        validationSchema={EditPrescriptionFormValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting, setFieldValue, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Prescription and Medication Details
                </Typography>
              </Box>
              <div className="scroll2" ref={componentPDF} style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_name" label={"Pet Name"} requiredField>
                      <InputField
                        {...getFieldProps("pet_name")}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="type"
                      label={"Medication Type"}
                      requiredField
                    >
                      <InputField {...getFieldProps("type")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="medication_name"
                      label={"Medication Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("medication_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="vet_id"
                      label={"Prescribing Vet"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("vet_id")}
                        options={vetNameOptions}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup name="dosage" label={"Dosage"} requiredField>
                          <InputField {...getFieldProps("dosage")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="dosageMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("dosageMeasurement")}
                            options={dosageOptions}
                            otherType="Other"
                            placeholder="Select Dosage"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <FormGroup
                      name="pharmacy_id"
                      label={"Pharmacy Name"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("pharmacy_id")}
                        options={pharmacyOptions}
                        onChange={pharmacySelector}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="frequency"
                          label={"Frequency"}
                          requiredField
                        >
                          <InputField {...getFieldProps("frequency")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="frequencyMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("frequencyMeasurement")}
                            options={frequencyOptions}
                            otherType="Other"
                            placeholder="Select Frequency"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <FormGroup
                      name="pharmacy_contact"
                      label={"Pharmacy Phone Number"}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("pharmacy_contact")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="durtion"
                          label={"Duration"}
                          requiredField
                        >
                          <InputField {...getFieldProps("durtion")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="durationMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("durationMeasurement")}
                            options={durationOptions}
                            otherType="Other"
                            placeholder="Select Duration"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <FormGroup
                      name="start_date"
                      label={"Start Date"}
                      requiredField
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("start_date")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="prescripton_number"
                      label={"Prescription Number"}
                    >
                      <InputField {...getFieldProps("prescripton_number")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                <Column>
                    <FormGroup
                      name="reason"
                      label={"Reason for Use"}
                      requiredField
                    >
                      <TextArea
                        rows={10}
                        placeholder="Enter the reason"
                        inputProps={{ maxLength: 300 }}
                        {...getFieldProps("reason")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
            {showSuccessMessage && (
              <CustomSnackbar
                title="Data updated successfully!"
                desc="Your prescription has been updated."
                open={open}
                okOnclick={() => {
                  setShowSuccessMessage(false);
                  navigate(
                    `/prescription-and-medication/prescription-info/${id}`
                  );
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}
