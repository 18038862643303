import React from "react";
import "../../Assets/Styles/AboutUs/GetInTouch.css";
import { useNavigate } from "react-router-dom";
import { getAboutUsLastTitle } from "../../api/axios";
import useSWR from "swr";

function GetInTouch({ button }) {
  let navigate = useNavigate();

  const { data: title } = useSWR(
    `aboutUsLastTitle`,
    async () => {
      try {
        const { data } = await getAboutUsLastTitle();
        return data?.data?.[0]?.tittle || "";
      } catch (error) {
        console.error("error: ", error);
        alert(error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div className="GetInTouchWrapper">
      <div
        className="GetTopic"
      >
        {title}
      </div>
      <a
        onClick={() => {
          navigate(button?.[6]?.buttonLink);
        }}
      >
        {button?.[6]?.button_name}
      </a>
    </div>
  );
}

export default GetInTouch;
