import React, { useEffect, useState, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getEmergencyContactById,
  updateEmergencyContact,
} from "../../Api/Services/EmergencyServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { useAuth } from "../../ContextProvider/AuthProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { EmergencyFromValidation } from "../../From/Validation";
import { relationshipOptions } from "../../Options";
import { getCountryAsOption, getStateAsOptions } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import AutoCompleteAddress from "../Common/AutoCompleteAddress";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../Emergency/EmergencyForm/form.css";
import "./EmergencyForm/form.css";
import "react-phone-input-2/lib/style.css";
import "../pet-information/petPrint.css";

const defaultValue = {
  zip_code: "",
  street_address: "",
  street_address_2: "",
  apt_city: "",
  state: "",
  country: "",
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  relationship: "",
  secondary_number: "",
  pet_id: "",
  user_id: "",
};

export default function EmergencyEdit() {
  const navigate = useNavigate();
  const { petOwnerId: petowner_id, workspaceId: w_id } = useAuth();
  defaultValue.user_id = petowner_id;
  const [initialValue, setInitialValue] = useState(defaultValue);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { id } = useParams();
  const formikRef = useRef();

  const [nameOptions, setNameOptions] = useState([]);

  useEffect(() => {
    const getEmergencyContact = () => {
      getEmergencyContactById(id)
        .then((response) => {
          const emData = response.data.data[0];
          setInitialValue({
            ...initialValue,
            user_id: petowner_id || "",
            zip_code: emData.zip_code,
            street_address: emData.street_address,
            street_address_2: emData.street_address_2,
            apt_city: emData.apt_city,
            state: emData.state,
            country: emData.country,
            email: emData.email,
            phone_number: emData.phone_number,
            first_name: emData.first_name,
            last_name: emData.last_name,
            secondary_number: emData.secondary_number,
            pet_id: emData.pet_id,
            relationship: emData.relationship,
          });
          const country = getCountryAsOption();
          setCountryOptions(country);
          const countryCode = country?.find(
            (country) => country.label === emData.country
          );
          const state = getStateAsOptions(countryCode?.isoCode);
          const filteredState = state?.map((item) => {
            return {
              value: item.label,
              label: item.label,
            };
          });
          filteredState.push({ value: "Other", label: "Other" });
          setStateOptions(filteredState);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getEmergencyContact();
  }, [id]);

  useEffect(() => {
    getPetNameByWorkspaceId(w_id)
      .then((res) => {
        const petnames = res.data.data.map((pet) => {
          return {
            value: pet?.id,
            label: pet?.name,
          };
        });
        if (petnames.length === 0) {
          console.log("No pet names found for user ID:", petowner_id);
        } else {
          setNameOptions([...petnames]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [petowner_id]);

  const updateEmergencyForm = (values) => {
    const value = {
      zip_code: values.zip_code,
      street_address: values.street_address,
      street_address_2: values.street_address_2,
      apt_city: values.apt_city,
      state: values.state,
      country: values.country,
      email: values.email,
      phone_number: values.phone_number,
      first_name: values.first_name,
      last_name: values.last_name,
      relationship: values.relationship,
      secondary_number: values.secondary_number,
      pet_id: values.pet_id,
      // user_id: user.id,
      w_id: w_id,
    };
    Promise.all([updateEmergencyContact(id, value)])
      .then((res) => {
        setShowSuccessMessage(true);
        setOpen(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Pet Information",
    pageStyle: `
    @page {
      size: A3; 
      margin: 200px; 
    }
    body {
      margin: 100px; 
      
  `,

    printStyle: `
  
  .print-text-field {
    font-size: 14px; /* Adjust the font size for printing */
   
`,
  });

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  return (
    <FormLayout title="Edit Emergency Contact Information">
      <Formik
        initialValues={initialValue}
        innerRef={formikRef}
        onSubmit={updateEmergencyForm}
        validationSchema={EmergencyFromValidation}
        enableReinitialize
        validateOnMount={false}
      >
        {({ getFieldProps, values, setFieldValue, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <div ref={componentPDF} style={{ width: "100%", height: "100%" }}>
                <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    Emergency Contact Details
                  </Typography>
                </Box>
                <Body className="scroll2" style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        name="pet_id"
                        options={nameOptions}
                        placeholder="Select a Pet Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="relationship"
                      label={"Relationship"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("relationship")}
                        name="relationship"
                        options={relationshipOptions}
                        placeholder="Select a Relationship"
                        otherType="Other"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="first_name"
                      label={"First Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("first_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="last_name"
                      label={"Last Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("last_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="phone_number"
                      label={"Primary Phone Number"}
                      requiredField
                    >
                      <CustomPhoneInput
                        {...getFieldProps("phone_number")}
                        onChange={(value) =>
                          setFieldValue("phone_number", value)
                        }
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="secondary_number"
                      label={"Secondary Phone Number"}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("secondary_number")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="email"
                      label={"Email Address"}
                      requiredField
                    >
                      <InputField {...getFieldProps("email")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="street_address" label={"Street Address 1"}>
                      <AutoCompleteAddress
                      formikRef={formikRef}
                      setFieldValue={setFieldValue}
                      value={values?.street_address}
                      address="street_address"
                      city="apt_city"
                      state="state"
                      zipcode="zip_code"
                      country="country"
                    />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="street_address_2"
                      label={"Street Address 2"}
                    >
                      <InputField {...getFieldProps("street_address_2")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="country" label={"Country"}>
                      <AutoComplete
                        {...getFieldProps("country")}
                        name="country"
                        options={countryOptions}
                        placeholder="Select a country"
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="state" label={"State"}>
                      <AutoComplete
                        {...getFieldProps("state")}
                        name="state"
                        otherType="Other"
                        options={stateOptions}
                        placeholder={"Select a type"}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="apt_city" label={"City"}>
                      <InputField {...getFieldProps("apt_city")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="zip_code" label={"Zip Code"}>
                      <InputField {...getFieldProps("zip_code")} />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    type="submit"
                  />
                  <CustomButton
                    onClick={handlePrint}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your pet emergency contact has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/emergency-contact/emergency-info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
}
