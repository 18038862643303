import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  // baseURL: "http://ec2-44-212-62-215.compute-1.amazonaws.com:8000",
  // baseURL: "http://ec2-18-208-195-98.compute-1.amazonaws.com:8000",
  //baseURL: "http://ec2-54-82-51-255.compute-1.amazonaws.com:8000",
  //baseURL: "http://localhost:8000",
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    // Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  },
});
export default instance;
