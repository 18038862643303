import React from "react";

import ArchiveEmergencyInfo from "../../Components/Selected-item-view-Ar/Emergency/Emergency";

const ArchiveEmergencySecondView = () => {

  return (
    <>
      <ArchiveEmergencyInfo />
    </>
  );
};

export default ArchiveEmergencySecondView;
