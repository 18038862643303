import React, { useEffect } from "react";

import styled from "styled-components";

import ProviderInformation from "../../Components/Item-List/ProviderInformation";
import Layout from "../../Components/Side bar/Layout";

const ItemListProviderInformation = () => {

  return (
    <Layout>
      <ProviderInformation />
    </Layout>
  );
};

export default ItemListProviderInformation;

const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  /* background: rgba(255, 255, 255, 0.2); */
  background-color: #fff;
  border-radius: 37px;

  @media (max-width: 1280px) {
    padding: 10px;
    height: 90vh;
  }
  @media (max-width: 1131px) {
    left: 215px;
  }
  @media (max-width: 1024px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;
