import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import { getProviderById } from "../../../Api/Services/ProviderServices";
import ParDataView from "../../Common/PawDataView";
import "../PetInformation/Petdetails.css";

export default function ProviderComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [providerData, setProviderData] = useState([]);

  const { data: providerInfo, isLoading: IsProviderInfoLoading } = useSWR(
    id ? `providerData_${id}` : null,
    async () => {
      const { data } = await getProviderById(id);
      const filterData = data?.data?.map(item => {
        const newProvider_data = item?.provider_data?.replace(/\n/g, ' ');
        return {
          ...item,
          provider_data: item.provider_data ? JSON.parse(newProvider_data) : null
        }
      })
      return { ...filterData?.[0], petInfo: data?.petData } || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  
  useEffect(() => {
    const detailsData = [{ name: `Status`, value: providerInfo?.status }, { name: `Date`, value: providerInfo?.date }];
    if (providerInfo) {
      const result = providerInfo?.provider_data?.map((item, index) => {
        detailsData.push({ name: `Procedure ${index + 1}`, value: item.procedure})
        detailsData.push({ name: `Procedure Description ${index + 1}`, value: item.procedure_description})
        detailsData.push({ name: `Additional Procedures Information ${index + 1}`, value: item.additional_procedures_information})
        detailsData.push({ name: `Document ${index + 1}`, value: item.document, pdf: true})
      })
    }
    setProviderData([detailsData]);
  }, [providerInfo])
  
  const props = {
    label: "Provider Information",
    isLoading: IsProviderInfoLoading,
    onBack: () => {
      navigate('/provider-information');
    },
    petInfo: providerInfo?.petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "name",
      },
      {
        label: "Provider name",
        value: "provider_name",
        width: "15.5vw",
      },
      {
        label: "Provider Email",
        value: "email", 
        width: "12.5vw",
      }
    ],
    columnsData: [
      {
        ...providerInfo,
        provider_name: `${providerInfo?.first_name} ${providerInfo?.last_name}`,
        image: providerInfo?.petInfo?.image,
        name: providerInfo?.petInfo?.name ? providerInfo?.petInfo?.name.slice(0, 10) : "-",
      },
    ],
    detailsData: providerData,
  };

  return (
    <>
      <FullPageWrapper>
        <ParDataView {...props} maxLength={40}/>
      </FullPageWrapper>
    </>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
