import React from "react";

import ArchiveJournalInfom from "../../Components/Selected-item-view-Ar/Journal/JournalInfo";

const ArchiveJournalInfo = () => {

  return (
    <>
      <ArchiveJournalInfom />
    </>
  );
};

export default ArchiveJournalInfo;
