import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  deleteImmunization,
  getImmunizationByID,
} from "../../../Api/Services/ImmunizationServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { getPetHistoryById } from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from '../../../Constant'
import { addItemtoObject, formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ImmunizationComponent() {
  const { id } = useParams();
  const [petInfo, setPetInfo] = useState([]);
  const navigate = useNavigate();

  const { data: immunizationInfo, isLoading: IsimmunizationInfoLoading } =
    useSWR(
      id ? `immunizationInfo${id}` : null,
      async () => {
        const { data } = await getImmunizationByID(id);
        if (data?.data?.length) {
          const pet_id = data?.data?.[0].pet_id;
          const { data: petData } = await getPetByID(pet_id);
          setPetInfo(petData?.data);
        }
        return data?.data?.[0] || [];
      },
      {
        revalidateOnFocus: false,
      }
    );
  
    const { data: immunizationHistory } = useSWR(
      id ? `immunizationHistory${id}` : null,
      async () => {
        try {
          const { data } = await getPetHistoryById("immunization", id);
          const propertiesToUpdate = ['pet_name'];
          const propertiesToDelete = ['id', 'pet_id'];
          const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
          return filteredData || [];
        } catch (error) {
          console.error('error: ', error);
        }
      },
      {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        errorRetryCount: 0,
        errorRetryInterval: 0,
      }
    );

  const onDelete = async () => {
    return await deleteImmunization(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Immunization Information",
    isLoading: IsimmunizationInfoLoading,
    onBack: () => {
      navigate("/immunization");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Vaccination Type",
        value: "vaccination_type",
      },
      {
        label: "Date",
        value: "date",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        vaccination_type: immunizationInfo?.vaccination_type,
        date: formatDate(immunizationInfo?.date),
      },
    ],
    detailsData: [
      [
        { name: "Information", value: immunizationInfo?.information },
      ]
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/immunization/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Immunization History",
    historyColumnsData: immunizationHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petInfo?.userDetails?.first_name}
          lastName={petInfo?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
