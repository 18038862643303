import { api, ImageAPI } from "../axios";

export const createProvider = async (data) => {
  const res = await api.post(`/provider/addProvider`, data);
  return res;
};

export const updateProvider = async (id, data) => {
  const res = await api.put(`/provider/updateProvider/${id}`, data);
  return res;
};

export const uploadDocument = async (data) => {
  const res = await ImageAPI.post(`/provider/uploadDocument`, data);
  return res;
};

export const verifyProviderToken = async (data) => {
  const res = await api.post(`/provider/verifyToken`, data);
  return res;
};

export const getProviderByWorkspaceId = async (id) => {
  const res = await api.get(`/provider/getProviderByWorkspaceId/${id}`);
  return res;
};

export const getProviderById = async (id) => {
  const res = await api.get(`/provider/getProviderById/${id}`);
  return res;
};
