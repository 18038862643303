import React, { useState, useEffect } from "react";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { getPetByID } from "../../Api/Services/PetServices";
import Step2 from "../../Assets/Images/PetInformation/stepper/step 2.svg";
import { inputstyle } from "../../Css";
import "../Item-List/GlobalItemList.css";
import Layout from "../Side bar/Layout";

export default function PetInfoPreview() {
  const { id } = useParams();

  // const id = 2;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [family_name, setFamily_name] = useState("");
  const [dob, setDob] = useState("");
  const [breed, setBreed] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [company_contact_number, setCompany_contact_number] = useState("");
  const [marks, setMarks] = useState("");
  const [reg_number, setReg_number] = useState("");
  const [chip_availability, setchip_availability] = useState("");
  const [fur_color, setFur_color] = useState("");
  const [eye_Color, setEye_Color] = useState("");
  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const [pet_type, setPet_type] = useState("");

  // Address
  const [street_address, setStreet_address] = useState("");
  const [apt_city, setApt_city] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip_code, setZip_code] = useState("");
  const [image, setImage] = useState("");



  useEffect(() => {
    const getPetDetails = () => {
      getPetByID(id)
        .then((response) => {
          const petData = response.data.data;
          setFamily_name(petData.family_name);
          setDob(petData.dob.substring(0, 10));
          setBreed(petData.breed);
          setchip_availability(petData.chip_availability);
          setCompany_contact_number(petData.company_contact_number);
          setCompany_name(petData.company_name);
          setEye_Color(petData.eye_Color);
          setFur_color(petData.fur_color);
          setGender(petData.gender);
          setMarks(petData.marks);
          setName(petData.name);
          setReg_number(petData.reg_number);
          setWeight(petData.weight);
          setPet_type(petData.pet_type);
          setStreet_address(petData.street_address);
          setZip_code(petData.zip_code);
          setApt_city(petData.apt_city);
          setCountry(petData.country);
          setState(petData.state);
          setImage(petData.image);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getPetDetails();
  }, [id]);

  return (
    <Layout>
      <Container
        sx={{ padding: "0px", justifyContent: "center", alignItems: "center" }}
      >
        <Box display={"flex"} sx={{ justifyContent: "space-between" }} px={0}>
          <Grid
            container
            spacing={1}
            flexGrow={1}
            columns={12}
            pt={1}
            sx={{
              pl: { sm: 3, md: 4, xs: 3, l: 4 },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={headerGridStyle}
              style={{ marginTop: "40px" }}
            >
              <Box
                display={"flex"}
                sx={{ justifyContent: "left", alignItems: "center" }}
                pb="15px"
              >
                <ArrowBackIosNewRoundedIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Typography
                  variant="h6"
                  color="white"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "23px",
                    paddingLeft: "40px",
                  }}
                >
                  Preview
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
          </Grid>
        </Box>

        <Box>
          <Grid
            className="scroll"
            padding="12px"
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
            flexGrow={1}
            columns={12}
            pt={1}
            overflowx="hidden"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "3vw",
                  }}
                >
                  <img
                    src={image}
                    style={{
                      objectFit: "cover",
                      width: "130px",
                      height: "130px",
                      borderRadius: "20px",
                      border: "2px solid rgba(255, 255, 255, 0.5)",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                    }}
                    alt="userImage"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel sx={inputstyle} flexdirection={"row"}>
                    Pet Type
                  </InputLabel>
                  <TextField
                    id="pet_type"
                    name="pet_type"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={pet_type}
                  />

                  <InputLabel sx={inputstyle}>Pet Name</InputLabel>
                  <TextField
                    id="name"
                    name="name"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={name}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Street Address</InputLabel>
              <TextField
                id="streetAddress"
                name="streetAddress"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={street_address}
              />
              <InputLabel sx={inputstyle}>AptCity</InputLabel>
              <TextField
                id="aptCity"
                name="aptCity"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={apt_city}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Family Name</InputLabel>
              <TextField
                id="familyName"
                name="familyName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={family_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box sx={smallFieldBoxStyle}>
                <Box mr={2}>
                  <InputLabel sx={inputstyle}>State</InputLabel>
                  <TextField
                    id="state"
                    name="state"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={state}
                  />
                </Box>
                <Box>
                  <InputLabel sx={inputstyle}>Zip Code</InputLabel>
                  <TextField
                    id="zipCode"
                    name="zipCode"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={zip_code}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box sx={DOBStyle}>
                <InputLabel sx={inputstyle}>Date of Birth</InputLabel>
                <TextField
                  className="Date"
                  id="Date"
                  name="Date"
                  label=""
                  autoComplete="off"
                  type="date"
                  sx={inputDateStyle}
                  value={dob}
                  disabled
                  // onChange={handleDateChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Breed</InputLabel>
              <TextField
                id="breed"
                name="breed"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={breed}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box sx={smallFieldBoxStyle}>
                <Box>
                  <InputLabel sx={inputstyle} flexdirection={"row"}>
                    Weight
                  </InputLabel>
                  <TextField
                    id="weight"
                    name="weight"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={weight}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <InputLabel sx={inputstyle}>Gender</InputLabel>
                  <TextField
                    id="gender"
                    name="gender"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={gender}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Country</InputLabel>
              <TextField
                id="country"
                name="country"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box sx={smallFieldBoxStyle}>
                <Box>
                  <InputLabel sx={inputstyle} flexdirection={"row"}>
                    Fur Color
                  </InputLabel>
                  <TextField
                    id="furColor"
                    name="furColor"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={fur_color}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <InputLabel sx={inputstyle}>Eye Color</InputLabel>
                  <TextField
                    id="eyeColor"
                    name="eyeColor"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={eye_Color}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Marks</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={marks}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <Box sx={smallFieldBoxStyle}>
                <Box>
                  <InputLabel sx={inputstyle} flexdirection={"row"}>
                    Micro Chip Availability?
                  </InputLabel>
                  <TextField
                    id="chip_availability"
                    name="chip_availability"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    sx={smallTextStyle}
                    value={chip_availability}
                  />
                </Box>
                {chip_availability === "No" ? null : (
                  <Box sx={{ marginLeft: "10px" }}>
                    <InputLabel sx={inputstyle}>Register Number</InputLabel>
                    <TextField
                      id="reg_number"
                      name="reg_number"
                      label=""
                      autoComplete="off"
                      variant="outlined"
                      sx={smallTextStyle}
                      value={reg_number}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            {chip_availability === "No" ? null : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
                <InputLabel sx={inputstyle}>Company Name</InputLabel>
                <TextField
                  id="CName"
                  name="CName"
                  label=""
                  autoComplete="off"
                  variant="outlined"
                  sx={textFieldStyle}
                  value={company_name}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
            {chip_availability === "No" ? null : (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
                <InputLabel sx={inputstyle}>Company Contact Number</InputLabel>
                <TextField
                  id="CCnumber"
                  name="CCnumber"
                  label=""
                  autoComplete="off"
                  variant="outlined"
                  sx={textFieldStyle}
                  value={company_contact_number}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <Box
          display="flex"
          p={4}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box display="flex">
            {/* <Button
              variant="outlined"
              sx={SavebtnStyle}
              startIcon={<SaveOutlinedIcon />}
            ></Button> */}

            <Button
              variant="outlined"
              sx={PrintbtnStyle}
              startIcon={<LocalPrintshopOutlinedIcon />}
            ></Button>
          </Box>
        </Box>

        <Box
          display="flex"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <img src={Step2} alt="step1" />
        </Box>
        {/* <Box
          display="flex"
          sx={{ justifyContent: "right", alignItems: "center" }}
        >
          <img
            src={Exit}
            alt="logout"
            style={{
              cursor: "pointer",
              top: "-4vw",
              position: "relative",
              width: "2em",
              height: "2em",
            }}
          />
        </Box> */}
      </Container>
    </Layout>
  );
}

const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 37px;
  backdrop-filter: blur(15px);

  @media (max-width: 1130px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;

const headerGridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "left",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-root ": {
    width: { xs: 300, sm: 300, md: 270, lg: 350, xl: 350 },
    height: "35px",
  },
  "& .MuiFormControl-root": {
    padding: "0px 10px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

const inputDateStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 255, 255, 0.2)",
  },
  "&:hover fieldset": {
    borderColor: "grey",
  },

  "& .MuiInputBase-root ": {
    width: { sm: 150, md: 190, xs: 160 },
    height: "35px",
    color: "white",
  },
  "& .MuiFormControl-root": {
    padding: "0px 10px",
    height: "35px",
    color: "white",
  },

  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

const smallTextStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-root ": {
    width: { sm: 144, md: 140, xs: 144, xl: 167, lg: 167 },
    height: "35px",
  },
  "& .MuiFormControl-root": {
    padding: "0px 10px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  // change textfield border color on focus mode
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};
const gridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
};
const DOBStyle = {
  paddingLeft: { xl: "3.5vw", lg: "3.5vw", md: "12.5vw", sm: "0vw", xs: "0vw" },
  width: { sm: 298, md: 400, xs: 300 },
};
const smallFieldBoxStyle = {
  width: { sm: 304, md: 270, xs: 300, lg: 350, xl: 400 },
  display: "flex",
  alignItems: "center",
  justifyContent: { md: "left", sm: "left" },
  marginLeft: { xl: "0vw", lg: "0vw", md: "0vw" },
  paddingLeft: { xl: "3vw" },
};

export const SavebtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px 0 0 11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  height: 40,
  backdropFilter: "blur(10px)",
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
export const PrintbtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 40,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
