import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";


import { getArchivePetInsuranceByWorkspaceID, setPetInsuranceUnarchive } from "../../Api/Services/PetInsuranceServices.js";
import { getArchivePetNamesByWorkspaceID } from "../../Api/Services/PetServices.js";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from '../../ContextProvider/AuthProvider'
import { filterAscDescData } from "../../Utils.js";
import DetailsModal from "../Common/DetailsModal.js";
import PawItemList from "../Common/PawItemList.js";

import "./ArchiveGlobalItemList.css";
import { Button } from "@mui/material";

export default function ArchivePetInsurance() {
  const { workspaceId: w_id } = useAuth();
  let navigate = useNavigate();
  const [selectedPetName, setSelectedPetName] = useState("");
  const [open, setOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [archiveId, setArchiveId] = useState(null);
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [selInsuranceData, setSelInsuranceData] = useState([]);
  const fetchIdRef = useRef(0);

  const handleArchiveClick = (medication_id) => {
    setShowSuccessMessage(true);
    setOpen(true);
    setArchiveId(medication_id); 
  };
  const handleArchive = () => {
    setIsUpdating(true);

    setPetInsuranceUnarchive(archiveId)
      .then((response) => {
        setIsUpdating(false);
        if (response.data.success === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setIsUpdating(false);
      });
  };

  const { data: insuranceData, isLoading } = useSWR(
    w_id ? `archiveInsuranceData${w_id}` : null,
    async () => {
      const { data } = await getArchivePetInsuranceByWorkspaceID(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (insuranceData?.length && limit) {
        setPageCount(Math.ceil((insuranceData?.length) / limit));
        const allData = [...insuranceData];
        const filteredResult = filterAscDescData(allData, sort, from, limit);
        setSelInsuranceData(filteredResult);
      }
    }
  }, [filter, insuranceData]);

  const { data: petNames = [] } = useSWR(
    w_id ? `petNames${w_id}` : null,
    async () => {
      const { data } = await getArchivePetNamesByWorkspaceID(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  let filteredInsuranceData;

  if (selectedPetName === "") {
    filteredInsuranceData = selInsuranceData;
  } else if (!selInsuranceData) {
    filteredInsuranceData = [];
  } else {
    filteredInsuranceData = selInsuranceData?.filter((item) => {
      const petName = item.name || "";
      const query =
        selectedPetName && selectedPetName.length > 0
          ? selectedPetName[0].toLowerCase()
          : "";
      return petName.toLowerCase().includes(query);
    });
  }

  const petNamesSet = new Set();
  const filteredPetNames = petNames?.filter((pet) => {
    const lowercaseName = pet.name.toLowerCase();
    if (!petNamesSet.has(lowercaseName)) {
      petNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const defaultOptions = {
    pageSize: 5,
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "Pet Name",
        accessor: "name",
        width: "12.5vw",
      },
      {
        Header: `Insurance provider name`,
        accessor: "provider_name",
        width: "12.5vw",
      },
      {
        Header: "Member ID",
        accessor: "member_id",
        width: "12.5vw",
      },
      {
        Header: "",
        accessor: "id",
        width: "8vw",
        isArchive: true,
        disableSortBy: true,
        disabled: isUpdating,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <UnarchiveIcon
              style={{
                color: "#000000",
              }}
              onClick={() =>
                handleArchiveClick(row?.original?.id)
              }
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => navigate(props.viewDataLink(row.original))}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, []);

  const props = {
    label: "Archive Pet Insurance",
    setSelectedUserName: setSelectedPetName,
    items: filteredPetNames,
    isLoading,
    dataAddLink: "/archive",
    columns,
    columnsData: filteredInsuranceData,
    viewDataLink: (data) =>
    `/archive/insurance-info/${data.id}`,
    placeholder: "pet name",
    details: " There are no pet insurance details for this user.",
    isArchive: true,
    AddPetArchive: true,
    fetchData,
    pageCount,
    defaultOptions,
  };

  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        handleArchive();
        setShowSuccessMessage(false);
      },
    },
    {
      name: "no",
      onClick: () => {
        setOpen(false);
        setShowSuccessMessage(false);
      },
    },
  ];

  return (
    <>
      <PawItemList {...props} />
      {showSuccessMessage && (
        <>
          <DetailsModal
            open={open}
            severity="warning"
            buttonList={ButtonItem}
            title="Do you want to unarchive this pet insurance?"
            handleClose={()=> setOpen(false)}
          />
        </>
      )}
    </>
  );
}
