import React, { useState, useRef, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { createJournal } from "../../Api/Services/JournalServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { getUsersByWorkspaceId } from "../../Api/Services/UserServices";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { JournalFormValidation } from "../../From/Validation";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import TextArea from "../Common/TextArea";

const defaultValue = {
  note: "",
  date: "",
  petid: "",
  user: "",
};

export default function Journal() {
  const { workspaceId: w_id } = useAuth();
  let navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [usersOptions, setusersOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const [petName, setPetName] = useState([]);
  const noteDateRef = useRef(null);
  const noteRef = useRef(null);
  const maxDate = new Date().toISOString().substr(0, 10);

  const getUsersByWorkspaceID = async () => {
    try {
      const res = await getUsersByWorkspaceId(w_id);
      const filteredData = res?.data?.data?.map((item) => {
        return {
          value: `${item?.first_name} ${item?.last_name}`,
          label: `${item?.first_name} ${item?.last_name}`,
        };
      });
      if (filteredData?.length === 0) {
        <></>;
      } else {
        setusersOptions(filteredData);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      if (filteredData?.length === 0) {
        <></>;
      } else {
        setPetName(filteredData);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (w_id) {
      getPetNames();
      getUsersByWorkspaceID();
    }
  }, [w_id]);

  const sendData = async (values) => {
    try {
      const res = await createJournal(values);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout title="Journal">
      <Formik
        initialValues={defaultValue}
        onSubmit={sendData}
        validationSchema={JournalFormValidation}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Journal Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="petid" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("petid")}
                        options={petName}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="date" label={"Date of Note"} requiredField>
                      <InputField
                        type="date"
                        {...getFieldProps("date")}
                        inputProps={{ max: maxDate }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="user" label={"User"} requiredField>
                      <AutoComplete
                        {...getFieldProps("user")}
                        options={usersOptions}
                        inputRef={noteDateRef}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            noteRef.current.focus();
                          }
                        }}
                      />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="note" label={"Notes"} requiredField>
                      <TextArea
                        rows={5}
                        {...getFieldProps("note")}
                        inputRef={noteRef}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New journal has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/journal");
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
