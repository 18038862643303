import React from "react";

import FirstStep from "../../Layout/Document/FirstStep";
const Document = () => {
  return (
    <>
      <FirstStep />
    </>
  );
};

export default Document;
