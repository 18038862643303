import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import CustomButton from "../../Components/Common/Button";
import Layout from "../../Components/Side bar/Layout";

const tasks = [
  { id: "1", content: "User Information" },
  { id: "2", content: "Pet Information" },
  { id: "3", content: "Emergency Contacts" },
  { id: "4", content: "Prescriptions and Medications" },
  { id: "5", content: "Pet Insurance Information" },
  { id: "6", content: "Immunization" },
  { id: "7", content: "Allergies" },
  { id: "8", content: "Conditions" },
  { id: "9", content: "Journal" },
  { id: "10", content: "Documents" },
  { id: "11", content: "Provider Information" },
  { id: "12", content: "Archive" },
];

const taskStatus = {
  reorder: {
    name: "Reorder",
    items: [],
  },
  allMenuItems: {
    name: "All Menu Items",
    items: tasks,
  },
};

const generateRandomId = () => {
  return Math.random().toString(36).substring(7);
};

export default function DragandDrop() {
  const [columns, setColumns] = useState(taskStatus);

  const [reorderColumns, setReorderColumns] = useState();
  console.log("--------reorderColumns", reorderColumns);

  const handleSave = () => {
    const NewReorder = columns?.reorder?.items;
    setReorderColumns(NewReorder);
  };

  const handleDelete = (itemId) => {
    const newItems = columns.reorder.items.filter((item) => item.id !== itemId);

    setColumns({
      ...columns,
      reorder: {
        ...columns.reorder,
        items: newItems,
      },
    });
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);

      const randomId = generateRandomId();
      const updatedItem = { ...removed, id: randomId };
      destItems.splice(destination.index, 0, updatedItem);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          // items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <Layout>
      <div>
        <div style={{ textAlign: "center" }}>
          <h1>Reorder Menu Items</h1>
        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "91%" }}>
          <Box display="flex">
            <CustomButton label={"Save"} onClick={handleSave} type="submit" />
          </Box>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <h2 style={{ margin: "0px" }}>{column.name}</h2>
                  <div style={{ margin: 10 }}>
                    <Droppable
                      droppableId={columnId}
                      key={columnId}
                      direction="vertical"
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: "white",
                              padding: 2,
                              width: 500,
                              height: "670px",
                              border: "1px solid #FF8533",
                              borderRadius: "6px",
                            }}
                          >
                            {column.items.length === 0 &&
                            !snapshot.isDragging ? (
                              <div
                                style={{
                                  color: "black",
                                  textAlign: "center",
                                  justifyContent: "center",
                                  marginTop: "300px",
                                }}
                              >
                                Drop Here
                              </div>
                            ) : (
                              column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 14,
                                            margin: "0 0 8px 0",
                                            height: "20px",
                                            backgroundColor: snapshot.isDragging
                                              ? "rgba(255, 133, 51, 0.5)"
                                              : "rgba(255, 133, 51, 0.2)",
                                            color: "black",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.content}
                                          {columnId === "reorder" && (
                                            <div
                                              style={{
                                                marginLeft: "450px",
                                                cursor: "pointer",
                                                justifyContent: "end",
                                                alignItems: "center",
                                                marginTop: "-20px",
                                              }}
                                            >
                                              <CloseIcon
                                                style={{
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                onClick={() =>
                                                  handleDelete(item.id)
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })
                            )}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </Layout>
  );
}
