import React from "react";

import Layout from "../../Components/Side bar/Layout";
import AdminUserlist from "../../Components/SuperAdmin/AdminUserlist";

const AdminUserList = () => {

  return (
    <Layout>
      <AdminUserlist />
    </Layout>
  );
};

export default AdminUserList