import React from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { getBusinessParagraph2, getForBusinessImage } from "../../api/axios";
import useSWR from "swr";

export default function BusinessHeroSection({ buttons }) {
  const { data: data = [] } = useSWR(
    `getForBusinessImage`,
    async () => {
      try {
        const { data } = await getForBusinessImage();
        const headerData = data.data[0];
        return { ...headerData, index: data.data.indexOf(headerData) };
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: paragraph } = useSWR(
    `getForBusinessParagraph2`,
    async () => {
      try {
        const { data } = await getBusinessParagraph2();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  let navigate = useNavigate();
  return (
    <BusinessHeroWrapper>
      <HeroImageContainer>
        <HeroImage src={data?.image} />
      </HeroImageContainer>
      <HeroTextWrapper>
        <p>{data?.paragraph}</p>
        <p>
          {paragraph?.paragraph1} <span>{paragraph?.paragraph2} </span> <br />
          {paragraph?.paragraph3}
        </p>
        <button
          onClick={() => {
            navigate(buttons?.[1]?.buttonLink);
          }}
        >
          {buttons?.[1]?.button_name}
        </button>
      </HeroTextWrapper>
    </BusinessHeroWrapper>
  );
}

const animation = keyframes`
  from { opacity: 0; filter: blur(10px); }
  to { opacity: 1; filter: blur(0px);}
`;

const BusinessHeroWrapper = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  margin: auto;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  opacity: 0;
  animation: ${animation} 1s 0.2s forwards;

  @media (max-width: 850px) {
    grid-template-columns: auto;
    div {
      place-self: center;
      width: 100%;
    }
  }
`;

const HeroImageContainer = styled.div`
  max-width: 340px;
  height: 353px;
  border: 1px solid #ff8533;
  box-shadow: 0px 30px 50px rgba(0, 75, 117, 0.2);
  border-radius: 60px;
  overflow: hidden;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  mix-blend-mode: normal;
  backdrop-filter: blur(20px);
  padding: 0;
  position: relative;

  @media (max-width: 850px) {
    height: 330px;
    margin-bottom: 40px;
  }
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  transition: all 1s;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;

const HeroTextWrapper = styled.div`
  /* color: #06476c; */
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.039em;
  font-size: 23px;
  padding: 0 30px 0;

  @media (max-width: 1455px) {
    font-size: 20px;
    padding: 10px 30px;
  }
  @media (max-width: 1265px) {
    padding: 5px 20px;
  }
  @media (max-width: 900px) {
    font-size: 17px;
  }
  @media (max-width: 850px) {
    text-align: center;
    font-size: 20px;
    padding: 10px 30px;
  }
  @media (max-width: 450px) {
    font-size: 17px;
    padding: 10px 15px;
  }
  p:nth-child(1) {
    line-height: 35px;
    margin: 0;

    @media (max-width: 1400px) {
      line-height: 30px;
    }
    @media (max-width: 950px) {
      padding: 0;
      line-height: 27px;
      margin: 5px 0 10px 0;
    }
  }
  p:nth-child(2) {
    line-height: 35px;
    letter-spacing: 0.039em;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    @media (max-width: 1115px) {
      line-height: 33px;
      margin: 0;
    }
    @media (max-width: 950px) {
      padding-top: 0;
      line-height: 30px;
      margin-top: 0;
    }
  }
  p:nth-child(2) span {
    color: #ff8533;
  }
  button {
    width: 209px;
    height: 55px;
    background: #008fd3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 30px;
    color: #ffff;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    margin: 0;
    cursor: pointer;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

    :hover {
      transform: translateY(-3px);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 20px 20px rgba(0, 0, 0, 0.15),
        inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 1455px) {
      font-size: 20px;
      margin-top: 15px;
      width: 170px;
      height: 50px;
    }
    @media (max-width: 850px) {
      margin: 25px auto 0;
    }
  }
`;
