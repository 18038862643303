import React from "react";

import ArchivePetInfo from "../../Components/Selected-item-view-Ar/PetInformation/PetInfo";

const ArchivePetSecondView = () => {

  return (
    <>
      <ArchivePetInfo />
    </>
  );
};

export default ArchivePetSecondView;
