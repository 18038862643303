import React, { createContext, useContext, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
// import swal from "sweetalert";

import AuthServices from "../../Api/Services/AuthServices";
import { CMS_ROUTES, PAWPRINT_USER_ROLE_KEY, PET_OWNER_ID_KEY, TOKEN_KEY, USERID_KEY, WORKSPACEID_KEY } from '../../Constant'

const PROTECTED_ROUTES = [];

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const _token = localStorage.getItem(TOKEN_KEY);

  const [isAuthenticated, setIsAuthenticated] = useState(!!_token);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);

  const redirectToLogin = () => {
    navigate("/account");
  };

  const setUserToken = async (value) => {
    await localStorage.setItem(TOKEN_KEY, value);
    setToken(value);
  };
  const removeUserToken = async () => {
    await localStorage.removeItem(TOKEN_KEY);
    setToken(undefined);
  };

  const isStoragePresent = async () => {
    if (
      localStorage.getItem("pawprint_user_id") === null &&
      localStorage.getItem("pawprint_token") === null
    ) {
      localStorage.clear();
      redirectToLogin();
    }
  };

  const getAuthenticatedUsers = async (getUserId) => {
    try {
      const { data } = await AuthServices.getUser(getUserId);
        if (data?.message === "This Workspace Membership has expired") {
          // swal("Plan Expired!", data?.message, "error");
          localStorage.clear();
          window.location.href = `${window?.location?.origin}/account`;
          return;
        }
        if (!data?.success) {
          if (!CMS_ROUTES.includes(window?.location?.pathname)) {
            redirectToLogin();
          }
        } else {
          setUser(data?.data);
          if (CMS_ROUTES.includes(window?.location?.pathname)) {
            navigate("/dashboard");
          }
        }
        return data;
    } catch (e) {
      if (e?.response?.data?.message === "Access denied! Unautherized user." && window?.location?.pathname !== "/selectWorkspace" && window?.location?.pathname !== "/" && window?.location?.pathname !== "/provider/form") {
        if (CMS_ROUTES.includes(window?.location?.pathname)) {
          return;
        } else {
          navigate(-1);
        }
      }
      if (PROTECTED_ROUTES.includes(window?.location?.pathname))
        redirectToLogin();
      throw e;
    }
  };

  const { isLoading } = useSWRImmutable(
    "checkAuth",
    async () => {
      try {
        const queryParameters = new URLSearchParams(window.location.search);

        const getUserId = await localStorage.getItem(USERID_KEY);
        const token = await localStorage.getItem(TOKEN_KEY);

        const user_id = queryParameters.get("id");
        const QToken = queryParameters.get("token");

        await setUserToken(token && token !== "null" ? token : QToken);
        await setUserId(getUserId || user_id);
        await getAuthenticatedUsers(getUserId || user_id);
        if (token) {
          setIsAuthenticated(true);
        }
      } catch (e) {
        setIsAuthenticated(false);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const login = () => {
    // Implement your login logic here
    setIsAuthenticated(true);
  };

  const resetData = async () => {
    // removeCookie({ key: 'token', params: { path: '' } })
    await removeUserToken(null);
    setIsAuthenticated(false);
    localStorage.clear();
  };

  const doLogout = async () => {
    try {
      await resetData();
      redirectToLogin();
    } catch (e) {
      console.error({ e });
    }
  };

  useEffect(() => {
    isStoragePresent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const value = {
    isAuthenticated,
    login,
    doLogout,
    user,
    redirectToLogin,
    userId,
    token,
    workspaceId: localStorage.getItem(WORKSPACEID_KEY),
    petOwnerId: localStorage.getItem(PET_OWNER_ID_KEY),
    userRole: localStorage.getItem(PAWPRINT_USER_ROLE_KEY),
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading ? <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress style={{ color: 'black'}}/>
    </Box> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
