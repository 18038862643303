import React, { useEffect, useRef, useState } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  UpdateImmunizationById,
  getImmunizationById,
} from "../../Api/Services/ImmunizationServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import "./immunization.css";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { ImmunizationFormValidation } from "../../From/Validation";
import { VaccinationType } from "../../Options";
import { formatDate } from "../../Utils";

const defaultValue = {
  vaccination_type: "",
  date: "",
  information: "",
  pet_id: "",
};

const ImmunizationFormEdit = () => {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const [items, setItems] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
  }, []);

  const getImmuData = async () => {
    try {
      const { data } = await getImmunizationById(id);
      setInitialValue({
        ...initialValue,
        vaccination_type: data?.data?.[0]?.vaccination_type,
        date: formatDate(data?.data?.[0]?.date),
        information: data?.data?.[0]?.information,
        pet_id: data?.data?.[0]?.pet_id,
      });
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getImmuData();
  }, []);

  const updateimmunizationForm = async (values) => {
    const value = {
      vaccination_type: values?.vaccination_type,
      date: values?.date,
      information: values?.information,
      pet_id: values?.pet_id,
    };
    try {
      const res = await UpdateImmunizationById(id, value);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    // documentTitle:"Data",
  });

  return (
    <FormLayout title="Edit Immunization">
      <Formik
        initialValues={initialValue}
        onSubmit={updateimmunizationForm}
        validationSchema={ImmunizationFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Immunization Details
                </Typography>
              </Box>
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="vaccination_type"
                      label={"Vaccination Type"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("vaccination_type")}
                        options={VaccinationType}
                        otherType="Other"
                        placeholder="Select a type"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="date" label={"Date"} requiredField>
                      <InputField type="date" {...getFieldProps("date")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="information" label={"Information"}>
                      <TextArea rows={10} {...getFieldProps("information")} />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your Immunization information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/immunization/immunization-info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
};

export default ImmunizationFormEdit;
