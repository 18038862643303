import React, { useEffect, useState, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getAllergyById,
  UpdateAllergyById,
} from "../../Api/Services/AllergyServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import "../Immunization/immunization.css";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { AllergyFormValidation } from "../../From/Validation";
import { allergiesNamesOptions, AllergyTypes } from "../../Options";

const defaultValue = {
  allergy_type: "",
  allergy_name: "",
  reaction: "",
  information: "",
  pet_id: "",
};

const AllergyFormEdit = () => {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [allergyNamesOptions, setAllergyNamesOptions] = useState([]);

  const [items, setItems] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);
  const formRef = useRef();

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getAllergyData = async () => {
    try {
      const { data } = await getAllergyById(id);
      setInitialValue({
        ...initialValue,
        allergy_type: data?.data?.[0]?.allergy_type,
        allergy_name: data?.data?.[0]?.allergy_name,
        reaction: data?.data?.[0]?.reaction,
        information: data?.data?.[0]?.information,
        pet_id: data?.data?.[0]?.pet_id,
      });
      handleAllergyTypeChange("", { value: data?.data?.[0]?.allergy_type})
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getAllergyData();
    getPetNames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendAllergy = async (values) => {
    const value = {
      allergy_type: values?.allergy_type,
      allergy_name: values?.allergy_name,
      reaction: values?.reaction,
      information: values?.information,
      pet_id: values?.pet_id,
    };
    try {
      const res = await UpdateAllergyById(id, value);
      if (res?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (error) {
      console.error("error: ", error);
      alert(error);
    }
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    // documentTitle:"Data",
  });

  const handleAllergyTypeChange = (e, newValue) => {
    const selectedType = allergiesNamesOptions?.filter((item) => item.type === newValue?.value);
    const filteredAllergy = selectedType?.[0]?.names?.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    filteredAllergy.push({ value: "Other", label: "Other" });
    setAllergyNamesOptions(filteredAllergy);
    formRef?.current?.setFieldValue("allergy_name", "");
  }

  return (
    <FormLayout title="Edit Allergy Information">
      <Formik
        initialValues={initialValue}
        onSubmit={sendAllergy}
        validationSchema={AllergyFormValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Allergy Details
                </Typography>
              </Box>
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="allergy_type"
                      label={"Allergy Type"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("allergy_type")}
                        options={AllergyTypes}
                        otherType="Other"
                        placeholder="Select a type"
                        onChange={handleAllergyTypeChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  {values?.allergy_type && <Column>
                    <FormGroup name="allergy_name" label={"Allergy Name"}>
                      <AutoComplete
                        {...getFieldProps("allergy_name")}
                        options={allergyNamesOptions}
                        otherType="Other"
                        placeholder="Select a Allergy name"
                      />
                    </FormGroup>
                  </Column>}
                  <Column>
                    <FormGroup name="reaction" label={"Reaction"}>
                      <InputField {...getFieldProps("reaction")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="information" label={"Information"}>
                      <TextArea
                        maxLength={300}
                        rows={10}
                        {...getFieldProps("information")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your pet allergy information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/allergy/info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
};

export default AllergyFormEdit;
