import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  deletePrescription,
  getMedicationById,
  getMedicationHistoryById,
} from "../../../Api/Services/MedicationServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { PAWPRINT_USER_ROLE_KEY } from '../../../Constant'
import { addItemtoObject, formatDate, formatPhoneNumber } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function PrescriptionComponent() {
  const { id } = useParams();
  const [petInfo, setPetInfo] = useState();
  const navigate = useNavigate();

  const { data: medicationHistory } = useSWR(
    id ? `medicationHistory${id}` : null,
    async () => {
      const { data } = await getMedicationHistoryById("medication", id);
      const propertiesToUpdate = ['pet_name', 'pharmacy_name', 'vet'];
      const propertiesToDelete = ['id', 'pet_id', 'pharmacy_id', 'vet_id'];
      const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: medicationInfo, isLoading: IsMedicationInfoLoading } = useSWR(
    id ? `medicationData_${id}` : null,
    async () => {
      const { data } = await getMedicationById(id);
      if (data?.data?.length) {
        const pet_id = data?.data[0].pet_id;
        const { data: petData } = await getPetByID(pet_id);
        setPetInfo(petData?.data);
      }
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await deletePrescription(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Prescription and Medication Information",
    isLoading: IsMedicationInfoLoading,
    onBack: () => {
      navigate("/prescription-and-medication");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Medication Type",
        value: "type",
      },
      {
        label: "Prescribing Vet",
        value: "vet_practice_name",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        ...medicationInfo,
      },
    ],
    detailsData: [
    [
      { name: "Vet Full Name", value: medicationInfo?.vet_full_name },
      { name: "Medication Name", value: medicationInfo?.medication_name },
      { name: "Duration", value: medicationInfo?.durtion },
      { name: "Dosage", value: medicationInfo?.dosage },
      { name: "Frequency", value: medicationInfo?.frequency },
      {
        name: "Start Date",
        value: formatDate(medicationInfo?.start_date),
      },
      { name: "Reason for Use", value: medicationInfo?.reason },
      {
        name: "Prescription Number",
        value: medicationInfo?.prescripton_number,
      },
      { name: "Pharmacy Name", value: medicationInfo?.pharmacy_name },
        { name: "Pharmacy Phone", value: formatPhoneNumber(medicationInfo?.pharmacy_contact) },
    ]
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/prescription-and-medication/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Prescription and Medication History",
    historyColumnsData: medicationHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
