import React, { useState } from "react";
import "./ForgetPassword.css";
import HttpCommon from "../../Utils/http-common";
import swal from "sweetalert";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [Errors, setErrors] = useState({});
  const [check, setCheck] = useState(false);
  const [forgetPasswordForm, setForgetPasswordForm] = useState(true);
  const [showUserAccounts, setShowUserAccounts] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [userArray, setUserArray] = useState([]);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!check) {
      try {
        const res = await HttpCommon.post("/user/forgotpassword", { email });
        if (res.data.success) {
          swal("Email Sent!", "Check your inbox.", "success");
          setEmail("");
        } else {
          setErrors({ message: "Email Address is not found" });
          setEmail("");
        }
      } catch (error) {
        console.error(error);
        if (error.code === "ERR_NETWORK") {
          swal("Network Error!", "Failed to connect server.", "error");
          return;
        }
        swal("Failed!", "Failed to send email.", "error");
      }
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();

    if (!email) {
      setErrors({ message: "Email Address is not found" });
    }

    if (email !== "") {
      HttpCommon.post("/subUser/checkEmail", { email: email })
        .then((res) => {
          if (res.data.status === "success") {
            setForgetPasswordForm(false);
            setShowUserAccounts(true);
            setIsLoadingAccounts(true);
            HttpCommon.post("/subUser/getSubUsersviaEmail", {
              email: email,
            })
              .then((res) => {
                setUserArray(res.data.data);
                setIsLoadingAccounts(false);
              })
              .catch((err) => {
                console.error(err);
                if (err.code === "ERR_NETWORK") {
                  swal("Network Error!", "Failed to connect server.", "error");
                }
              });
          } else {
            setErrors({
              message: "Invalid username. Email does not exists",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === "ERR_NETWORK") {
            swal("Network Error!", "Failed to connect server.", "error");
          }
        });
    }
  };

  const subUserSubmit = (userId) => {
    const value = {
      email: email,
      petOwner: userId,
    };
    if (value.email !== "" && value.petOwner !== "") {
      HttpCommon.post("/subUser/forgotPassword", value)
        .then((res) => {
          if (res.data.success) {
            swal("Email Sent!", "Check your inbox.", "success").then(() => {
              window.location.reload();
            });
            setEmail("");
          } else {
            setErrors({ message: "Email Address is not found" });
            setEmail("");
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.code === "ERR_NETWORK") {
            swal("Network Error!", "Failed to connect server.", "error");
            return;
          }
          swal("Failed!", "Failed to send email.", "error");
        });
    }
  };

  return (
    <div className="main">
      {forgetPasswordForm && (
        <div className="container-forget">
          <form>
            <h1>Forgot Password</h1>
            <br />
            <div className="field-forget">
              <label>Enter Your Email</label>
              <img
                className="uname-forget"
                src={require("../../Assets/Images/mail-account.png")}
                alt="user"
              />
              <input
                type="text"
                name="email"
                className="textarea"
                value={email}
                onChange={(e) => {
                  setErrors({});
                  setEmail(e.target.value);
                }}
              />
            </div>
            <p>{Errors.message}</p>

            {/* <div className="left">
              <input
                type="checkbox"
                id="check"
                onChange={() => {
                  setCheck(!check);
                }}
                checked={check}
              />
              <label for="check"> Sub user account</label>
            </div> */}
            <br />
            {!check ? (
              <button className="btn1-forget" onClick={handleSubmit}>
                Send email
              </button>
            ) : (
              <button className="btn1-forget" onClick={handleContinue}>
                Continue
              </button>
            )}

            <br />
            <p id="account" style={{ margin: 0, padding: 0}}>or</p>
            <p id="l4" style={{ paddingTop: "10px", fontSize: "18px", cursor: "pointer", margin: 0}} onClick={() => {
                navigate("/account");
              }}>
              Login
            </p>
          </form>
        </div>
      )}

      {showUserAccounts && (
        <div className="container">
          <form style={{ paddingBottom: 30 }}>
            <h1>Select the pet owner</h1>

            <br />
            {isLoadingAccounts ? (
              <h3>Loading your data...</h3>
            ) : (
              userArray.map((user, i) => (
                <Card
                  onClick={() => {
                    subUserSubmit(user.userId);
                  }}
                  key={i}
                >
                  {user.userImage === null ? (
                    <img
                      src={require("../../Assets/Images/profile.png")}
                      alt="ProfileLogo"
                    />
                  ) : (
                    <img src={user.userImage} alt="ProfileLogo" />
                  )}

                  <Name>
                    {user.userFirstName} {user.userLastName}
                  </Name>
                  <Email>{user.userEmail}</Email>
                </Card>
              ))
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #249fda;
  border-radius: 20px;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px 20px 5px 20px;
  :hover {
    box-shadow: 10px 10px 10px gray;
    transition: 0.3s;
  }
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 40px;
  }
`;
const Name = styled.h3`
  width: 250px;
  margin-right: 20px;
`;
const Email = styled.h3``;
