import React from "react";

import ConditionInfoComponent from "../../Components/Selected-item-view/ConditionsInfo/ConditionInfoComponent";

const ConditionsSecond = () => {

  return (
    <>
      <ConditionInfoComponent />
    </>
  );
};

export default ConditionsSecond;
