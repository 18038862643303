import React from "react";

import UserProfileEdit from "../../Components/Setting/UserProfileEdit";
import Layout from "../../Components/Side bar/Layout";

const UserProfileDetails = () => {
  return (
    <Layout>
      <UserProfileEdit />
    </Layout>
  );
};

export default UserProfileDetails;
