import React from "react";

import { ErrorMessage, useFormikContext } from "formik";
import { get } from "lodash";

function FormGroup({
  children,
  label,
  name,
  renderError = true,
  className,
  requiredField = false,
  labelClassName,
  direction = "vertical",
  type = "ltr",
  svgPath
}) {
  const { touched,  } = useFormikContext();
  const fieldTouched = get(touched, name);

  const renderLabel = () => (
    <label>
      {label}
      {requiredField ? <span>&nbsp;*</span> : ""}
    </label>
  );
  return (
    <div
      className={`${
            direction === "horizontal"? "" : ""}
          ${className ?? ""}
        `}
    >
      {label  && renderLabel()}
      <div
        className={`userIcon ${labelClassName}`}
      >
        {svgPath &&   <img
                className="uImage"
                src={svgPath}
                alt="user"
              /> }
       
        {children}
      
      </div>
      {renderError && fieldTouched && (
      <div>
          <ErrorMessage
            component="p"
            name={name}
            render={(msg) => (
              <p
                style={{
                  position: "relative",
                  color: "#d32f2f",
                  margin:0
                }}
              >
                {msg}
              </p>
            )}
          />
      {label && type === "rtl" && renderLabel()}
      </div>
        )}
      
    </div>
  );
}

export default FormGroup;

