import React from "react";
import styled from "styled-components";
import { H1 } from "../../../../Assets/Styles/GlobleStyles/TextStyles";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { getHomeSlider2 } from "../../../../api/axios";
// import { getHomeSlider2 } from "../../../../api/axios";
import useSWR from "swr";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaAngleRight
      className={className}
      style={{
        ...style,
        display: "block",
        width: "50px",
        height: "80px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "45%",
        right: "-80px",
        color: "#06476C",
        fontWeight: "normal",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <FaAngleLeft
      className={className}
      style={{
        ...style,
        display: "block",
        width: "50px",
        height: "80px",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "45%",
        left: "-30px",
        color: "#06476C",
        fontWeight: "normal",
      }}
      onClick={onClick}
    />
  );
}

export default function Slider2() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 565,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data: slidetwo = {} } = useSWR(
    `getHomeSlider2`,
    async () => {
      try {
        const { data } = await getHomeSlider2();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const logos = [
    { logo: slidetwo?.logo1 },
    { logo: slidetwo?.logo2 },
    { logo: slidetwo?.logo3 },
    // {logo: slidetwo.logo1},
    // {logo: slidetwo.logo2},
    // {logo: slidetwo.logo3},
  ];

  return (
    // grid rows
    <Wrapper>
      {/* row1 */}
      <Title>
        <b>
          Trusted by <span>Leading</span> Organizations
        </b>
      </Title>

      {/* row2 */}
      <IconWrapper>
        <Slider {...settings}>
          {logos.map((logo, i) => (
            <div key={i}>
              <Image src={logo.logo} />
            </div>
          ))}
        </Slider>
      </IconWrapper>
    </Wrapper>
  );
}
// const ImageWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
//   grid-gap: 20px;

//   `;

const Wrapper = styled.div`
  padding: 60px 150px;
  margin: 0 auto;

  @media (max-width: 1040px) {
    padding: 60px 100px;
  }
  @media (max-width: 830px) {
    padding: 0 50px;
  }
`;
const Title = styled(H1)`
  margin: 0 auto;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  letter-spacing: 0.02em;

  color: rgb(31, 85, 165);

  span {
    color: #008fd3;
  }
  @media (max-width: 1120px) {
    font-size: 40px;
    padding-top: 20px;
  }
  @media (max-width: 910px) {
    line-height: 50px;
    padding-top: 20px;
  }
  @media (max-width: 785px) {
    font-size: 37px;
  }
  @media (max-width: 725px) {
    font-size: 34px;
  }
  @media (max-width: 680px) {
    font-size: 31px;
  }
  @media (max-width: 635px) {
    font-size: 30px;
  }
  @media (max-width: 595px) {
    font-size: 30px;
  }
  @media (max-width: 550px) {
    font-size: 30px;
  }
  @media (max-width: 535px) {
    font-size: 30px;
  }
  @media (max-width: 520px) {
    line-height: 30px;
    padding-top: 20px;
    font-size: 30px;
  }
`;
const IconWrapper = styled.div`
  margin: 0 auto;
  padding-top: 50px;
`;
const Image = styled.img`
  max-width: 100vw;
  width: auto;
  height: 100px;
  margin: 0 auto;

  opacity: 1;
  animation: fadein 2s;
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 820px) {
    max-width: 20vw;
  }
  @media (max-width: 740px) {
    max-width: 25vw;
  }
  @media (max-width: 555px) {
    max-width: 40vw;
  }
`;
