import React from 'react'

import styled from "styled-components";

import Sidebar from './Sidebar';
function Layout({ children }) {
    return (
        <New>
          <Sidebar />
          <div style={{ height: '100%', width: '100%', background: 'white'}}>
            {children}
            </div>
        </New>
      );
}

export default Layout

const New = styled.div`
  display: flex;
  @media (max-width: 890px) {
    display: block;
  }
`;