import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getBusinessBottom } from "../../api/axios";
import useSWR from "swr";

export default function ScheduleDemo({ buttons }) {
  let navigate = useNavigate();

  const { data: bottomTitle = [] } = useSWR(
    `getForBusinessBottom`,
    async () => {
      try {
        const { data } = await getBusinessBottom();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <ScheduleDemoWrapper>
      <h1>{bottomTitle.paragraph}</h1>
      <button
        onClick={() => {
          navigate(buttons?.[2]?.buttonLink);
        }}
      >
        {buttons?.[2]?.button_name}
      </button>
    </ScheduleDemoWrapper>
  );
}

export const ScheduleDemoWrapper = styled.div`
  margin: 20px 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: rgb(31, 85, 165);
  h1 {
    font-size: 50px;
    /*color: rgb(31, 85, 165); */
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 1100px) {
    margin: 2vw 8vw 8vw;
    h1 {
      font-size: 45px;
      margin: 0rem;
    }
  }
  @media (max-width: 975px) {
    margin: 2vw 8vw 8vw;
    h1 {
      font-size: 40px;
      margin: 0rem;
    }
  }
  @media (max-width: 895px) {
    h1 {
      font-size: 38px;
      margin: 0rem;
    }
  }
  @media (max-width: 715px) {
    margin: 2vw 40px 5vw;
    h1 {
      font-size: 30px;
      margin: 0rem;
    }
  }
  button {
    width: 259px;
    height: 55px;
    background: #008fd3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 30px;
    color: #ffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    margin: 0;
    cursor: pointer;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    :hover {
      transform: translateY(-3px);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 20px 20px rgba(0, 0, 0, 0.15),
        inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
    }
    @media (max-width: 1455px) {
      font-size: 20px;
      margin-top: 15px;
      width: 250px;
      height: 50px;
    }
    @media (max-width: 990px) {
      width: 200px;
      /* margin: 25px auto 0; */
    }
  }
`;
