import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getMedicationHistoryById } from "../../../Api/Services/MedicationServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import {
  deleteVet,
  getPetVetTherapistById,
  getVetByID
} from "../../../Api/Services/VetServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import {
  addItemtoObject,
  formatPhoneNumber,
} from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function VetTherapistInfo() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vetId, setVetId] = useState("");
  const [petInfo, setPetInfo] = useState("");

  const {
    data: vetInfo = [],
    isLoading
  } = useSWR(
    id ? `vetInfo${id}` : null,
    async () => {
      const { data } = await getPetVetTherapistById(id);
      const vetPetData = data?.data[0];
      setVetId(vetPetData?.vet_id);
      const { data: vetData } = await getVetByID(vetPetData?.vet_id);
      const { data: petData } = await getPetByID(vetPetData?.pet_id);
      setPetInfo(petData?.data);
      return vetData?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: vetTherapyHistory } = useSWR(
    vetId ? `vetTherapyHistory${vetId}` : null,
    async () => {
      const { data } = await getMedicationHistoryById("pet-vet-therapist", id);
      const propertiesToUpdate = ["pet_name", "vet_practice_name"];
      const propertiesToDelete = ["id", "pet_id", "vet_practice_id"];
      const filteredData = data?.data?.map((item) =>
        addItemtoObject(item, propertiesToUpdate, propertiesToDelete)
      );
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await deleteVet(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Vet and Therapist Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/vet-and-therapist");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Veterinary Name",
        value: "vet_practice_name",
      },
      {
        label: "Phone Number",
        value: "phone_number",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        type: vetInfo?.type,
        vet_practice_name: vetInfo?.vet_full_name,
        phone_number: formatPhoneNumber(vetInfo?.primary_phone_number),
      },
    ],
    detailsData: [
      [
        { name: "Full Name", value: vetInfo?.vet_full_name },
        { name: "Email", value: vetInfo?.email },
        { name: "Fax", value: vetInfo?.fax },
        { name: "Zip Code", value: vetInfo?.zip_code },
        { name: "Vet Portal", value: vetInfo?.vet_portal, url: true },
      ],
      [
        { name: "Street Add", value: vetInfo?.street_address },
        { name: "Street Add 2", value: vetInfo?.street_address_2 },
        { name: "City", value: vetInfo?.city },
        { name: "State", value: vetInfo?.state },
        { name: "Country", value: vetInfo?.country },
      ],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/vet-and-therapist/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Vet and Therapists History",
    historyColumnsData: vetTherapyHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
