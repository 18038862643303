import React from "react";

import PetInfoComponent from "../../Components/Selected-item-view/PetInformation/PetInfo";

const PetSecondView = () => {

  return (
      <PetInfoComponent />
  );
};

export default PetSecondView;
