import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getAllergyByID } from "../../../Api/Services/AllergyServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchiveAllergyInfoComponent() {
  const { id } = useParams();

  const [petId, setPetId] = useState("");
  const navigate = useNavigate();

  const { data: allergyData = [], isLoading } = useSWR(
    id ? `allergyData${id}` : null,
    async () => {
      const { data } = await getAllergyByID(id);
      setPetId(data?.data?.[0]?.pet_id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petData = {}, isLoading: isPetLoading } = useSWR(
    petId ? `petData${petId}` : null,
    async () => {
      const { data } = await getPetByID(petId);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Allergies Information",
    isLoading: isLoading || isPetLoading,
    onBack: () => {
      navigate("/archive/allergy");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Allergy type",
        value: "allergy_type",
      },
      {
        label: "Allergy Name",
        value: "allergy_name",
      },
      {
        label: "Reaction",
        value: "reaction",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        ...allergyData,
      },
    ],
    detailsData: [[{ name: "Information", value: allergyData?.information }]],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
      {petData?.userDetails && (
        <OwnerInfo
          key={petData?.id}
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
          location={petData?.userDetails.country}
        />
      )}
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
