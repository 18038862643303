import React, { useEffect, useRef, useState } from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { createPetsurgery } from "../../Api/Services/SurgeryServices";
import { getAllVetnamePres } from "../../Api/Services/VetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { SurgeryFormValidation } from "../../From/Validation";

const defaultValue = {
  date: "",
  surgery_type: "",
  description: "",
  pet_id: "",
  vet_id: "",
};

function Surgeryform() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setdata] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);
  const formRef = useRef();

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getVetNames = async () => {
    try {
      let vetNames = [];
      const { data } = await getAllVetnamePres(w_id);
      vetNames = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.vet_full_name,
        };
      });
      vetNames?.push({ value: "vet", label: "Add a new Vet" });
      setdata(vetNames);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (w_id) {
      getVetNames();
      getPetNames();
    }
  }, []);

  const handleSelectChange = (e, newValues) => {
    const selectedValue = newValues.value;
    if (selectedValue === "vet") {
      navigate("/vet-and-therapist/form");
    } else {
      formRef.current.setFieldValue("vet_id", newValues?.value);
    }
  };

  const sendData = async (values) => {
    try {
      const surgeryres = await createPetsurgery(values);
      if (surgeryres?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout title="Procedure and Treatment">
      <Formik
        initialValues={initialValue}
        onSubmit={sendData}
        validationSchema={SurgeryFormValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Procedure and Treatment Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
              <Body style={{ gap: "10px 20px" }}>
                <Column>
                  <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                    <AutoComplete
                      {...getFieldProps("pet_id")}
                      options={items}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="vet_id" label={"Vet Name"} requiredField>
                    <AutoComplete {...getFieldProps("vet_id")} options={data} onChange={handleSelectChange}
                        isOnChange/>
                  </FormGroup>
                </Column>
                <Column>
                  <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}>
                    <Box style={{ width: "50%" }}>
                      <FormGroup
                        name="surgery_type"
                        label={"Surgery Type"}
                        requiredField
                      >
                        <InputField {...getFieldProps("surgery_type")} />
                      </FormGroup>
                    </Box>
                    <Box style={{ paddingLeft: "5px", width: "50%" }}>
                      <FormGroup name="date" label={"Date"} requiredField>
                        <InputField type="date" inputProps={{ max: "9999-12-12" }} {...getFieldProps("date")} />
                      </FormGroup>
                    </Box>
                  </Box>
                </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                <Column>
                  <FormGroup
                    name="description"
                    label={"Description"}
                    requiredField
                  >
                    <TextArea
                      rows={3}
                      inputProps={{ maxLength: 200 }}
                      {...getFieldProps("description")}
                    />
                  </FormGroup>
                </Column>
              </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New Pet Procedure details have been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/surgery-and-treatment");
          }}
        />
      )}
    </FormLayout>
  );
}

export default Surgeryform;
