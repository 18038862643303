import React from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import arrow from "../../Assets/Images/Immunization/Vector (1).png";

const FormHeader2 = () => {
  return (
    <HeaderWrapper>
    <Link to="/surgery-and-treatment">
            <img src={arrow} alt=""></img>
          </Link>
      <Header>Priview</Header>
      <Icons>
        {/* <Button>
          <img
            src={require("../../Assets/Images/SurgeryAndTreatment/edit.png")}
          />
        </Button>
        <Button>
          <img
            src={require("../../Assets/Images/SurgeryAndTreatment/Outline.png")}
          />
        </Button> */}
      </Icons>
    </HeaderWrapper>
  );
};

export default FormHeader2;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr 60px 60px;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 50px;
`;

// const Back = styled.div`
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 23px;
//   color: #ffffff;
// `;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  display: flex;
`;

const Icons = styled.div`
  display: flex;
  justify-content: end;
`;

// const Button = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: none;
//   border: 0;
//   border-radius: 5px;
//   transition: 0.5s;
//   :hover {
//     background: rgba(255, 255, 255, 0.25);
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
//     backdrop-filter: blur(15px);
//     border-radius: 5px;
//     cursor: pointer;
//   }
// `;
