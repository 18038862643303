import React from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import ArchiveServices from "../../../Api/Services/ArchiveServices";
import { formatPhoneNumber } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function ArchiveVetTherapistInfo() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: vetTherapistInfo,
    isLoading
  } = useSWR(
    id ? `ArchiveVetTherapistInfo-${id}` : null,
    async () => {
      const { data } = await ArchiveServices.getPetVetTherapistById(id);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );
  const props = {
    label: "Vet and Therapist Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/archive/vet-therapist");
    },
    petInfo: vetTherapistInfo?.petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Type",
        value: "type",
      },
      {
        label: "Veterinary Name",
        value: "vet_practice_name",
      },
      {
        label: "Phone Number",
        value: "phone_number",
      },
    ],
    columnsData: [
      {
        image: vetTherapistInfo?.petInfo?.image,
        pet_name: vetTherapistInfo?.petInfo?.name,
        type: vetTherapistInfo?.vetInfo?.type,
        vet_practice_name: vetTherapistInfo?.vetInfo?.vet_full_name,
        phone_number: formatPhoneNumber(
          vetTherapistInfo?.vetInfo?.primary_phone_number
        ),
      },
    ],
    detailsData: [
      [
        { name: "Full Name", value: vetTherapistInfo?.vetInfo?.vet_full_name },
        { name: "Email", value: vetTherapistInfo?.vetInfo?.email },
        { name: "Fax", value: vetTherapistInfo?.vetInfo?.fax },
      ],
      [
        {
          name: "Street Add",
          value: vetTherapistInfo?.vetInfo?.street_address,
        },
        {
          name: "Street Add 2",
          value: vetTherapistInfo?.vetInfo?.street_address_2,
        },
        { name: "City", value: vetTherapistInfo?.vetInfo?.city },
        { name: "State", value: vetTherapistInfo?.vetInfo?.state },
        { name: "Country", value: vetTherapistInfo?.vetInfo?.country },
        { name: "Zip Code", value: vetTherapistInfo?.vetInfo?.zip_code },
      ],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
