import React, { useState } from "react";

import { Box, FormHelperText, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { get } from "lodash";

function TextArea({
  id,
  name,
  label,
  autoComplete = "off",
  sx,
  onBlur,
  onChange,
  width,
  required,
  value,
  inputSx,
  rows,
  ...params
}) {
  const { errors, values, touched, submitCount } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);
  return (
    <>
      <TextField
        id={id}
        name={name}
        label=""
        multiline
        rows={rows}
        // autoComplete="off"
        variant="outlined"
        sx={{ ...textAreaStyle, ...sx }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={fieldTouched && error}
        {...params}
      />
    </>
  );
}

export default TextArea;

const textAreaStyle = {
  width: "100%",
  background: "transparent",
  border: "none",
  boxShadow: "none",
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #FF8533",
    borderRadius: "6px",
    width: '100%',

    margin: "none",
    padding: "0px",
    textAlign: "center",
    background: "#FFF",

    "&:hover fieldset": {
      borderColor: "#FF8533",
    },
  },
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-root:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF8533",
    },
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
    "&.Mui-focused fieldset": {
      borderColor: "#FF8533",
    },
  },
  "& .input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
    {
      transition: "background-color 5000s ease-in-out 0s",
      background: "#FFA500 !important",
      color: "black !important",
      padding: "0px 14px  !important",
      appearance: "none  !important",
  },
  
  "@media (max-width: 600px)": {
    width: "98%"
  },
};
