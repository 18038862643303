import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getConditionByID } from "../../../Api/Services/ConditionsServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchiveConditionInfoComponent() {
  const { id } = useParams();
  const [petId, setPetId] = useState("");
  const navigate = useNavigate();

  const { data: conditionData = [], isLoading } = useSWR(
    id ? `conditionData${id}` : null,
    async () => {
      const { data } = await getConditionByID(id);
      setPetId(data?.data?.[0]?.pet_id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petData = {}, isLoading: isPetLoading } = useSWR(
    petId ? `petdata${petId}` : null,
    async () => {
      const { data } = await getPetByID(petId);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Condition Information",
    isLoading: isLoading || isPetLoading,
    onBack: () => {
      navigate("/archive/condition");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Condition",
        value: "conditions",
      },
      {
        label: "Notes",
        value: "condition_note",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        conditions: conditionData?.conditions,
        condition_note: conditionData?.condition_note,
      },
    ],
    detailsData: [
      [{ name: "Information", value: conditionData?.condition_note }]
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
