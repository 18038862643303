export const TOKEN_KEY = "pawprint_token";
export const USERID_KEY = "pawprint_user_id";
export const PAWPRINT_PERMISSION_KEY = "pawprint_permission";
export const PAWPRINT_USER_ROLE_KEY = "pawprint_User_role";
export const PET_OWNER_ID_KEY = "petowner_id";
export const WORKSPACEID_KEY = "workspace_id";
export const MEMBERSHIP_NUMBER_KEY = "membership_id";

export const USER = "User";
export const GENERAL_AUTHORIZED_USER = "General Authorized User";
export const ADMIN = "Admin";
export const VIEW_ONLY_USER = "View Only User";
export const SUPER_ADMIN_USER = "SuperUser";

export const CMS_ROUTES = [
  "/",
  "/for-individuals",
  "/account",
  "/forbusiness",
  "/aboutus",
  "/resources",
  "/contactus",
  "/register",
  "/terms-conditions",
  "/forget-password",
  "/user/reset",
  "/subuser/reset",
  "/success-page",
  "/user/VerifyAccount",
  "/subuser/VerifyAccount",
  "/admin/VerifyAccount"
];
