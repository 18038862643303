import React, { useEffect } from "react";
import BusinessHeroSection from "../../Components/Business/BusinessHeroSection";
import BusinessBodySection from "../../Components/Business/BusinessBodySection";
import styled, { keyframes } from "styled-components";
import ScheduleDemo from "../../Components/Business/ScheduleDemo";
import useSWR from "swr";
import { getButton } from "../../api/axios";

export default function BusinessComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: buttons = [] } = useSWR(
    `getForBusinessgetButton`,
    async () => {
      try {
        const { data } = await getButton();
        return data?.data;
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <BusinessComponentWrapper>
      <BusinessHeader>
        For{" "}
        <span>
          <b>Businesses</b>
        </span>
      </BusinessHeader>
      <BusinessHeroSection buttons={buttons} />
      <BusinessBodySection />
      <ScheduleDemo buttons={buttons} />
    </BusinessComponentWrapper>
  );
}

const animation = keyframes`
   from { opacity: 0; filter: blur(10px); }
  to { opacity: 1; filter: blur(0px);}
`;
export const BusinessComponentWrapper = styled.div``;
export const BusinessHeader = styled.h1`
  margin: 0;
  padding: 180px 0px 50px;
  text-align: left;
  /* font-weight: 400; */
  font-size: 50px;
  line-height: 112.4%;
  color: rgb(31, 85, 165);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  opacity: 0;
  animation: ${animation} 1s 0s forwards;

  span {
    color: #008fd3;
  }
  @media (max-width: 1100px) {
    font-size: 45px;
  }
  @media (max-width: 900px) {
    font-size: 40px;
    text-align: center;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
  }
  @media (max-width: 850px) {
    padding: 140px 0 40px;
  }
`;
