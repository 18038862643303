import React from "react";

import ArchiveList from "../../Components/Item-List/ArchiveList";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveMenuset() {
  return (
    <Layout>
      <ArchiveList />
    </Layout>
  );
}
