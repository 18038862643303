import React, { useEffect, useState } from "react";
import pawPrintlogo from "../../Assets/Images/Navbar/pawprint-icon.png";
import checkmark from "../../Assets/Images/Check.png";
import styled from "styled-components";
import { verifyAdminAccount } from "../../api/axios";

const AdminVerifyAccount = () => {
  const [isVerified, setIsVerified] = useState(true);
  const queryParameters = new URLSearchParams(window.location.search);
  let token = queryParameters.get("token");

  const verifyAccount = async (token) => {
    try {
      const res = await verifyAdminAccount(token);
      if (res?.data?.status === "Unauthorized") {
        setIsVerified(false);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    verifyAccount(token);
  }, [token]);

  return (
    <div className="container-verify">
      <form>
        <div className="verifyImage">
          <img src={pawPrintlogo} className="pawPrintlogo" alt="logo" />
        </div>
        <br />
        <div className="form-message" style={{ width: "100%" }}>
          {isVerified ? (
            <>
              <Welcome>
                <h2>Welcome</h2>
                <img src={checkmark} className="checkmark" alt="checkmark" />
              </Welcome>
              <h3>Your admin account has been verified.</h3>
            </>
          ) : (
            <h3 style={{ width: "100%", textAlign: "center" }}>
              Invalid Token
            </h3>
          )}
        </div>
        <br />
      </form>
    </div>
  );
};

export default AdminVerifyAccount;

const Welcome = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0px;
  justify-items: center;
`;
