import React from "react";

import EmergencyContact from "../../Components/Item-List/EmergencyContact";
import Layout from "../../Components/Side bar/Layout";

const Emergency = () => {

  return (
    <Layout>
      <EmergencyContact />
    </Layout>
  );
};

export default Emergency;
