import React, { useState } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import swal from "sweetalert";
import { Container as HeaderContainer } from "@mui/material";

import {
  getUserWorkspaceList,
  selectUserWorkspace,
} from "../../Api/Services/UserServices";
import {
  BodyWrapper,
  RowWrapper,
  StyledMessage,
  Title,
  Wrapper,
} from "../../Components/Item-List/GlobalItemList";
import {
  MEMBERSHIP_NUMBER_KEY,
  PAWPRINT_PERMISSION_KEY,
  PAWPRINT_USER_ROLE_KEY,
  PET_OWNER_ID_KEY,
  TOKEN_KEY,
  USERID_KEY,
  WORKSPACEID_KEY,
} from "../../Constant";
import useAuth from "../../ContextProvider/AuthProvider/AuthProvider";

const SelectUserPage = () => {
  const [loading, setLoading] = useState(false);
  const { userId } = useAuth();
  const navigate = useNavigate();

  const { data: userList = [], isLoading } = useSWR(
    userId ? `petNames${userId}` : null,
    async () => {
      try {
        const { data } = await getUserWorkspaceList(userId);
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
        if (
          error?.response?.data?.message === "Access denied! Unautherized user."
        ) {
          navigate(-1);
        }
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const handleSelectWorkspace = async (item) => {
    const data = {
      userId: userId,
      w_id: item.w_id,
    };
    try {
      setLoading(true);
      const res = await selectUserWorkspace(data);
      if (res?.data?.message === "This Workspace Membership has expired") {
        swal("Plan Expired!", res?.data?.message, "error");
        setLoading(false);
        return;
      }
      localStorage.setItem(TOKEN_KEY, res?.data?.data?.token);
      localStorage.setItem(USERID_KEY, res?.data?.data?.userData?.id);
      localStorage.setItem(
        PAWPRINT_PERMISSION_KEY,
        res?.data?.data?.permission
      );
      localStorage.setItem(PAWPRINT_USER_ROLE_KEY, res?.data?.data?.role_name);
      localStorage.setItem(PET_OWNER_ID_KEY, res?.data?.data?.userData?.id);
      localStorage.setItem(WORKSPACEID_KEY, res?.data?.data?.workspace_id);
      localStorage.setItem(
        MEMBERSHIP_NUMBER_KEY,
        res?.data?.data?.workspace?.membership_no
      );
      // navigate("/user-information");
      window.location.href = "/user-information";
      // setLoading(false);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  return (
    <MainWrapper>
      <HeaderWrapper>
        <HeaderContainer maxWidth="xl">
          <LogoWrapper>
            <Logo
              src={require("../../../Assets/Images/Navbar/pawprint-icon.png")}
              alt="Pawprint Logo"
            />
          </LogoWrapper>
        </HeaderContainer>
      </HeaderWrapper>
      <New>
        {loading ? (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "black" }} />
          </Box>
        ) : (
          <FullPageWrapper style={{ height: "calc(100% - 40px)", marginBottom: "10px"}}>
            <MainWrapper>
              <Title style={{ width: 'auto'}}>Select Workspace</Title>
            </MainWrapper>
            <Container className="scroll2">
              <Grid
                container
                wrap="nowrap"
                spacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Wrapper>
                  <Table style={{ tableLayout: "fixed" }}>
                    {userList?.length === 0 && !isLoading ? (
                      <StyledMessage
                        style={{
                          color: "#000000",
                        }}
                      >
                        No Workspace Found for this user
                      </StyledMessage>
                    ) : (
                      <div
                        style={{
                          // height: "70vh",
                          overflowY: "auto",
                          width: "52vw",
                          margin: "10px 5px",
                          padding: "5px",
                          textAlign: "justify",
                        }}
                      >
                        <TableBody>
                          {isLoading ? (
                            <TableRow>
                              <Typography
                                variant="subtitle1"
                                style={{
                                  color: "#000000",
                                  textAlign: "center",
                                }}
                              >
                                Loading your data...
                              </Typography>
                            </TableRow>
                          ) : (
                            <>
                              {userList?.length > 0 ? (
                                userList?.map((cell, index) => (
                                  <BodyWrapper
                                    key={index?.id}
                                    sx={{
                                      "&:first-child": {
                                        borderTopLeftRadius: "20px",
                                        borderTopRightRadius: "20px",
                                        "--mask":
                                          "radial-gradient(20px at 35px 100%,#0000 98%,#000) -35px",
                                        "-webkit-mask": "var(--mask)",
                                        mask: "var(--mask)",
                                        paddingBottom: "4px",
                                      },

                                      "--mask":
                                        "radial-gradient(20px at 35px 35px,#0000 98%,rgb(0, 0, 0)) -35px -35px",
                                      "-webkit-mask": "var(--mask)",
                                      mask: "var(--mask)",
                                      "box-shadow":
                                        "2px 2px 7px 0px rgb(0 0 0 / 58%)",
                                      "z-index": "1",
                                      paddingBottom: "5px",

                                      "&:last-child": {
                                        borderBottomLeftRadius: "20px",
                                        borderBottomRightRadius: "20px",
                                        "--mask":
                                          "radial-gradient(20px at 35px 0,#0000 98%,#000) -35px",
                                        "-webkit-mask": "var(--mask)",
                                        mask: "var(--mask)",
                                        paddingBottom: "0",
                                      },
                                    }}
                                  >
                                    <TableRow
                                      key={index.id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        borderRadius: "10px 10px 0px 0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleSelectWorkspace(cell)
                                      }
                                    >
                                      <RowWrapper
                                        style={{
                                          height: "92px",
                                          width: "50vw",
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            // width: "15vw",
                                            fontSize: "16px",
                                            borderBottom: "none",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          {cell?.name}
                                        </TableCell>
                                      </RowWrapper>
                                    </TableRow>
                                  </BodyWrapper>
                                ))
                              ) : (
                                <TableRow>
                                  <Typography
                                    variant="subtitle1"
                                    style={{
                                      color: "#000000",
                                      textAlign: "center",
                                    }}
                                  >
                                    No users found.
                                  </Typography>
                                </TableRow>
                              )}
                            </>
                          )}
                        </TableBody>
                      </div>
                    )}
                  </Table>
                </Wrapper>
              </Grid>
            </Container>
          </FullPageWrapper>
        )}
      </New>
    </MainWrapper>
  );
};

export default SelectUserPage;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const New = styled.div`
  background-color: #fff;
  border-radius: 37px;
  height: calc(100vh - 120px);

  @media (max-width: 1280px) {
    padding: 10px;
  }
  @media (max-width: 1131px) {
    left: 215px;
  }
  @media (max-width: 1024px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 120px;
  align-items: center;
  background: #fc8433;
  z-index: 100;
  padding: 0rem 0rem;

  @media (max-width: 1130px) {
    height: 100px;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: grid;
  gap: 10rem;
  grid-template-columns: 10px auto;
  justify-content: space-between;
  align-items: center;
  background: #fc8433;
  z-index: 100;
  padding: 0rem 0rem;

  @media (max-width: 1130px) {
    display: grid;
    gap: 300px;
    grid-template-columns: 10px auto;
    justify-content: space-between;
    height: 100px;
  }
  @media (max-width: 375px) {
    gap: 240px;
  }
`;

const Logo = styled.img`
  transition: transform 0.8s;

  :hover {
    transform: scale(1.03);
  }

  @media (max-width: 380px) {
    padding-left: 10px;
  }

  @media (max-width: 365px) {
    padding-left: 0px;
  }

  @media (max-width: 320px) {
    padding-left: 0px;
  }

  @media (max-width: 300px) {
    padding-left: 0px;
  }
`;

const Container = styled('div')(() => ({
  overflow: 'hidden auto',
  padding: '1rem 0 1rem 1.5rem',
  margin: '0 auto',  
  maxHeight: "calc(100% - 63px) !important",
  height: "auto",
}));

const FullPageWrapper = styled('div')(() => ({
  padding: "20px",
  height: "calc(100% - 40px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  '@media (max-width: 1024px)': {
      paddingTop: '1rem',
  }
}));