import React, { useEffect, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  emailValidation,
  getEmail,
  getUserById,
  updateSubUserImageById,
  updateUserById,
  updateUserImageById,
} from "../../Api/Services/UserServices";
import SuccessIcon from "../../Assets/Images/success.png";
import { PAWPRINT_USER_ROLE_KEY } from "../../Constant";
import {
  Body,
  ButtonBoxStyle,
  Column,
  FormHeaderBox,
  newSmallFieldBoxStyle,
} from "../../Css";
import { UserProfileEditValidation } from "../../From/Validation";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import CustomSnackbar from "../Common/Snackbar";
import "./settingScroll.css";
import { uploadS3Bucket } from "../../Api/axios";

export default function UserProfileEdit() {
  const user_id = localStorage.getItem("pawprint_user_id");

  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState([]);
  const [email2, setEmail2] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [image, setImage] = useState("");
  const formRef = useRef(null);
  const defaultValue = {
    first_name: "",
    last_name: "",
    email: "",
  };
  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showCodeBox, setShowCodeBox] = useState(false);
  const [verifyButton, setVerifyButton] = useState("0");
  const [secret, setSecret] = useState("");
  const [initialValue, setInitialValue] = useState(defaultValue);

  const inputRef = useRef(null);

  useEffect(() => {
    if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      getUserById(user_id)
        .then((response) => {
          setProfileDetails(response.data.data);
          setIsLoading(false);
          const userData = response.data.data;
          setInitialValue({
            first_name: userData.first_name,
            email: userData.email,
            last_name: userData.last_name,
          });
          setEmail2(userData.email);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const onChange = (e, name) => {
    formRef.current?.setFieldValue(name, e);
    if (e === email2) {
      setDisableSubmit(false);
      setIsButtonDisabled(true);
    } else {
      setDisableSubmit(true);
      setIsButtonDisabled(false);
    }
  };

  const updateProfileImage = async (values) => {
    setDisableSubmit(true);

    const formData = new FormData();
    if (image instanceof File) {
      formData.append("image", image);
    }

    if (localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User") {
      const updateData = {
        type: image?.type,
        file_name: `${Date.now()}-${image?.name}`,
      };
      try {
        const res = await updateUserImageById(user_id, updateData);
        const url = res?.data?.url;
        const decodedUrl = decodeURIComponent(url);
        const uploadRes = await uploadS3Bucket(decodedUrl, image);
        if (uploadRes?.status === 200) {
          setOpenSuccess(true);
          setDisableSubmit(false);
        }
      } catch (error) {
        console.error("error: ", error);
        setOpen(true);
        setErrorMessage(error.response.data.message);
        setDisableSubmit(false);
        formRef?.current?.isSubmitting(false);
      }
    } else if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      updateSubUserImageById(user_id, formData)
        .then((response) => {
          setOpenSuccess(true);
          setDisableSubmit(false);
        })
        .catch((error) => {
          setOpen(true);
          console.error(error);
          setErrorMessage(error.response.data.message);
          setDisableSubmit(false);
        });
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setDisableSubmit(true);
    const userData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    };
    if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      updateUserById(user_id, userData)
        .then(async (response) => {
          if (image) {
            await updateProfileImage(values);
          }
          setOpenSuccess(true);
          setDisableSubmit(false);
        })
        .catch((error) => {
          setSubmitting(false);
          setOpen(true);
          console.error(error);
          setErrorMessage(error.response.data.message);
          setDisableSubmit(false);
        });
    }
  };

  const handleVerifyEmail = () => {
    if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      getEmail({ email: formRef?.current?.values?.email })
        .then((response) => {
          if (response.data.message === "Email Exists.") {
            setErrorMessage("Email Exists");
            setOpen(true);
            return;
          } else {
            emailValidation({ email: formRef?.current?.values?.email })
              .then((response) => {
                if (response.data.status !== "success") {
                  setErrorMessage(response.data.message);
                  setOpen(true);
                  return;
                } else {
                  setSecret(response.data.secret);
                  setSuccessMessage(
                    "Verification Email send. Check your email."
                  );
                  setOpenSuccessMessage(true);
                  setVerifyButton("1");
                  setShowCodeBox(true);
                  setVerifyCode(response?.data?.secret);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const handleVerifyCode = () => {
    if (verifyCode === secret) {
      setVerifyButton("2");
      setShowCodeBox(false);
      setDisableSubmit(false);
    } else {
      setErrorMessage(
        "Verification Code is incorrect. Please enter Valid Code"
      );
      setOpen(true);
    }
  };

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert severity="error" onClose={() => setOpen(false)}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setOpenSuccessMessage(false)}
      >
        <Alert severity="success" onClose={() => setOpen(false)}>
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ArrowBackIosNewRoundedIcon
          sx={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <FormTitle>Edit Your Profile Details</FormTitle>
      </Box>
      {isLoading ? (
        <Typography
          variant="subtitle1"
          style={{ color: "#000000", textAlign: "center", marginTop: "5vw" }}
        >
          Loading your data...
        </Typography>
      ) : (
        <FormWrapper>
          <Formik
            initialValues={initialValue}
            innerRef={formRef}
            onSubmit={handleSubmit}
            validationSchema={UserProfileEditValidation}
            validateOnMount={false}
            enableReinitialize
          >
            {({ getFieldProps, values, setFieldValue, isSubmitting }) => (
              <Form>
                <Box sx={FormHeaderBox}>
                  <div
                    className="scroll2"
                    style={{ maxHeight: "calc(100vh - 239px)", width: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        id="userImage"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                        }}
                        style={{ display: "none" }}
                        ref={inputRef}
                      />
                      {image && image instanceof File ? (
                        <>
                          <div style={{ position: "relative" }}>
                            <img
                              src={URL.createObjectURL(image)}
                              style={{
                                objectFit: "cover",
                                width: "200px",
                                height: "200px",
                                cursor: "pointer",
                                borderRadius: "50%",
                                border: "2px solid rgba(255, 255, 255, 0.5)",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                                backgroundColor: "rgb(252, 132, 51)",
                              }}
                              alt="user_image"
                              onClick={() => {
                                inputRef.current.click();
                              }}
                            />
                            <CloseIcon
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 1,
                                cursor: "pointer",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                              }}
                              onClick={() => {
                                setImage(null);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {profileDetails?.image ? (
                            <img
                              src={profileDetails?.image}
                              style={{
                                objectFit: "cover",
                                width: "200px",
                                height: "200px",
                                borderRadius: "50%",
                                border: "2px solid rgba(255, 255, 255, 0.5)",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                              }}
                              alt=" "
                              cursor="pointer"
                              onClick={() => {
                                inputRef.current.click();
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.opacity = 0.8;
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = 1;
                              }}
                            />
                          ) : (
                            <AccountCircleIcon
                              sx={{
                                width: "200px",
                                height: "200px",
                                color: "black",
                              }}
                              onClick={() => {
                                inputRef.current.click();
                              }}
                              cursor="pointer"
                              onMouseEnter={(e) => {
                                e.target.style.opacity = 0.8;
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = 1;
                              }}
                            />
                          )}
                        </>
                      )}
                    </Box>
                    <Body style={{ gap: "10px 20px" }}>
                      <Column>
                        <Box
                          sx={{
                            ...newSmallFieldBoxStyle,
                            paddingBottom: "0px",
                          }}
                        >
                          <Box style={{ width: "80%" }}>
                            <FormGroup
                              name={showCodeBox ? "verifyCode" : "email"}
                              label={
                                showCodeBox
                                  ? "Verification Code"
                                  : " Email Address"
                              }
                              requiredField
                            >
                              <InputField
                                {...getFieldProps(
                                  showCodeBox ? "verifyCode" : "email"
                                )}
                                onChange={(e) =>
                                  onChange(
                                    e.target.value,
                                    showCodeBox ? "verifyCode" : "email"
                                  )
                                }
                              />
                            </FormGroup>
                          </Box>
                          <Box style={{ width: "20%" }}>
                            {verifyButton === "0" ? (
                              <Box sx={{ marginTop: "15px" }}>
                                <Button
                                  sx={{ ...verifybtnStyle, width: "100%" }}
                                  variant="contained"
                                  disabled={isButtonDisabled}
                                  onClick={handleVerifyEmail}
                                  style={{ color: "white" }}
                                >
                                  Verify
                                </Button>
                              </Box>
                            ) : verifyButton === "1" ? (
                              <Box sx={{ marginTop: "15px" }}>
                                <Button
                                  sx={{ ...verifybtnStyle, width: "100%" }}
                                  variant="contained"
                                  disabled={isButtonDisabled}
                                  onClick={handleVerifyCode}
                                >
                                  Submit
                                </Button>
                              </Box>
                            ) : (
                              <Box>
                                <img
                                  src={SuccessIcon}
                                  alt="SuccessIcon"
                                  style={{ height: 40, width: 40 }}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Column>
                      <Column>
                        <FormGroup
                          name="first_name"
                          label={"First Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("first_name")} />
                        </FormGroup>
                      </Column>
                      <Column>
                        <FormGroup
                          name="last_name"
                          label={"Last Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("last_name")} />
                        </FormGroup>
                      </Column>
                    </Body>
                  </div>
                  <Box sx={ButtonBoxStyle}>
                    <CustomButton
                      type="submit"
                      disabled={isSubmitting}
                      label={isSubmitting ? "Submitting..." : "Submit"}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}
      {openSuccess && (
        <>
          <CustomSnackbar
            title="Data updated successfully!"
            desc="Your profile has been updated."
            open={openSuccess}
            okOnclick={() => {
              setOpenSuccess(false);
              navigate("/settings");
            }}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
`;

const FormTitle = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
`;

const FormWrapper = styled.div`
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  // margin: 0 auto;
  // padding: 20px;
  width: 100%;
`;

const verifybtnStyle = {
  marginLeft: "1vw",
  mx: 1,
  color: "white !important",
  background: "#FF8533 !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  backdropFilter: "blur(10px)",
  borderRadius: "11px",
  width: 80,
  textTransform: "none",
  fontSize: "13px",
  "&:hover": {
    border: "2px solid white",
  },
};
