import React from "react";
import styled from "styled-components";
import Cell from "./Cell";
import { getAllBlogs } from "../../api/axios";
import useSWR from "swr";

function ContentSection() {
  const { data = [] } = useSWR(
    `getAllBlogs`,
    async () => {
      try {
        const { data } = await getAllBlogs();
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <SectionCellGroup>
      {data?.map((blog, i) => (
        <Cell
          key={i}
          title={blog?.blog_title}
          image={blog?.blog_img}
          description={blog?.blog_content}
          link={blog?.blog_readMore}
        />
      ))}
    </SectionCellGroup>
  );
}

export default ContentSection;

const SectionCellGroup = styled.div`
  padding: 10px 0px;

  @media (max-width: 900px) {
    padding: 10px 0px;
  }
  @media (max-width: 450px) {
    padding: 0px;
  }
  @media (min-width: 2300px) and (min-height: 985px) {
    height: calc(100vh - 620px);
  }

`;

