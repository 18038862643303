import React from "react";

import SurgeryInfoComponent from "../../Components/Selected-item-view/SurgeryTreatInfo/SurgeryInfoComponent";

const SurgerySecond = () => {

  return (
    <>
      <SurgeryInfoComponent />
    </>
  );
};

export default SurgerySecond;
