import React, { useState } from 'react'

import { useFormikContext } from 'formik'
import { get } from 'lodash'
import styled from "styled-components";

import closedEye from "../../../Assets/Images/closed_eye.png";
import openedEye from "../../../Assets/Images/open_eye.png";


const PasswordInput = ({
  name,
  showPasswordStrength = false,
  showPasswordStrengthList = false,
  hint,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { touched, errors } = useFormikContext()
  const fieldTouched = get(touched, name);
  const error = get(errors, name);

  const toggleShowPassword = () => setShowPassword(prev => !prev)

  return (
    <>
      <input
        {...props}
        name={name}
        style={{borderColor: error && fieldTouched ? '#DC3838' : '#808080'}}
        type={showPassword ? 'text' : 'password'}
      />
       {showPassword ? (
                <Image
                  src={openedEye}
                  alt="hide password"
                  onClick={toggleShowPassword}
                />
              ) : (
                <Image
                  src={closedEye}
                  alt="hide password"
                  onClick={toggleShowPassword}
                />
              )}
    </>
  )
}

export default PasswordInput

const Image = styled.img`
  position: absolute;
  right: 25px;
  width: 18px;
  height: 18px;
  bottom: 0;
  margin:auto;
  top:0;
  cursor: pointer;
`;