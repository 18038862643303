import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { CreateImmunization } from "../../Api/Services/ImmunizationServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import "./immunization.css";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  ButtonBoxStyle,
  Column,
  CancelButton,
} from "../../Css";
import { ImmunizationFormValidation } from "../../From/Validation";
import { VaccinationType } from "../../Options";

const defaultValue = {
  vaccination_type: "",
  date: "",
  information: "",
  pet_id: "",
};

const ImmunizationForm = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const [items, setItems] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
  }, []);

  const handleSubmit = async (values) => {
    const value = {
      vaccination_type: values?.vaccination_type,
      date: values?.date,
      information: values?.information,
      pet_id: values?.pet_id,
    };
    try {
      const immuRes = await CreateImmunization(value);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return (
    <FormLayout title="Immunization Information">
      <Formik
        initialValues={defaultValue}
        onSubmit={handleSubmit}
        validationSchema={ImmunizationFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Immunization Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%"}}>
              <Body style={{ gap: "10px 20px" }}>
                <Column>
                  <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                    <AutoComplete
                      {...getFieldProps("pet_id")}
                      options={items}
                    />
                  </FormGroup>
                </Column>
                <Column>
                      <FormGroup
                        name="vaccination_type"
                        label={"Vaccination Type"}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("vaccination_type")}
                          options={VaccinationType}
                          otherType="Other"
                          placeholder="Select a type"
                        />
                      </FormGroup>
                  </Column>
                  <Column>
                      <FormGroup name="date" label={"Date"} requiredField>
                        <InputField type="date" {...getFieldProps("date")} />
                      </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                <Column>
                  <FormGroup name="information" label={"Information"}>
                    <TextArea
                      maxLength={300}
                      rows={10}
                      {...getFieldProps("information")}
                    />
                  </FormGroup>
                  </Column>
                  </Body>
                </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet immunization has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/immunization");
          }}
        />
      )}
    </FormLayout>
  );
};

export default ImmunizationForm;
