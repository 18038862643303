import React, { useEffect, useState, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { getPetNameByWorkspaceId } from "../../../Api/Services/PetServices";
import {
  getAllVetPractice,
  getPetVetTherapistById,
  getVetById,
  updateVetPractice,
  updateVetTherapist,
} from "../../../Api/Services/VetServices";
import useAuth from "../../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../../Css";
import { EditVetAndTherapistFormValidation } from "../../../From/Validation";
import { typeOptions } from "../../../Options";
import { getCountryAsOption, getStateAsOptions } from "../../../Utils";
import AutoComplete from "../../Common/AutoComplete";
import AutoCompleteAddress from "../../Common/AutoCompleteAddress";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import InputField from "../../Common/InputField";
import CustomPhoneInput from "../../Common/PhoneInput";
import CustomSnackbar from "../../Common/Snackbar";

import "./form.css";
import "react-phone-input-2/lib/style.css";
import "../../pet-information/petPrint.css";

const defaultValue = {
  pet_id: "",
  vet_id: "",
  vet_full_name: "",
  vet_practice_id: "",
  email: "",
  fax: "",
  primary_phone_number: "",
  type: "",
  zip_code: "",
  street_address: "",
  street_address_2: "",
  city: "",
  country: "",
  state: "",
  vet_practice_name: "",
  vet_portal: "",
};

export default function EditForm() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [vetpracticename, setVetPracName] = useState([]);
  const { workspaceId: w_id } = useAuth();

  useEffect(() => {
    getPetNames();
  }, []);
  const getPetNames = () => {
    getPetNameByWorkspaceId(w_id)
      .then((res) => {
        const filteredData = res?.data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        if (filteredData?.length === 0) {
          <></>;
        } else {
          setItems(filteredData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getVetDetails = () => {
    getPetVetTherapistById(id)
      .then((res) => {
        const resData = res.data.data[0];
        getVetById(resData?.vet_id)
          .then((res) => {
            const vetData = res?.data?.data?.[0];
            setInitialValue({
              ...initialValue,
              pet_id: resData?.pet_id,
              vet_id: resData?.vet_id,
              vet_full_name: vetData?.vet_full_name,
              vet_practice_id: vetData?.vet_practice_id,
              email: vetData?.email,
              fax: vetData?.fax,
              primary_phone_number: vetData?.primary_phone_number,
              type: vetData?.type,
              zip_code: vetData?.zip_code,
              street_address: vetData?.street_address,
              street_address_2: vetData?.street_address_2,
              city: vetData?.city,
              country: vetData?.country,
              state: vetData?.state,
              vet_practice_name: "",
              vet_portal: vetData?.vet_portal,
            });
            const country = getCountryAsOption();
            setCountryOptions(country);
            const countryCode = country?.find(
              (country) => country.label === vetData?.country
            );
            const state = getStateAsOptions(countryCode?.isoCode);
            const filteredState = state?.map((item) => {
              return {
                value: item.label,
                label: item.label,
              };
            });
            filteredState.push({ value: "Other", label: "Other" });
            setStateOptions(filteredState);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const getVetPracticeName = () => {
    getAllVetPractice(w_id)
      .then((res) => {
        const uniqueValues = res?.data?.data?.filter(
          (obj, index, self) =>
          index === self.findIndex((el) => el.id === obj.id)
          );
        const filteredData = uniqueValues?.map((item) => {
          return {
            value: item?.id,
            label: item?.vet_practice_name,
          };
        });
        if (filteredData?.length === 0) {
          <></>;
        } else {
          setVetPracName(filteredData);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getVetDetails();
    getVetPracticeName();
  }, []);

  const updateVet = (values) => {
    const value = {
      name: values?.pet_id,
      vet_full_name: values?.vet_full_name,
      vet_practice_id: values?.vet_practice_id,
      email: values?.email,
      fax: values?.fax,
      primary_phone_number: values?.primary_phone_number,
      type: values?.type,
      zip_code: values?.zip_code,
      street_address: values?.street_address,
      street_address_2: values?.street_address_2,
      city: values?.city,
      country: values?.country,
      state: values?.state,
      vet_portal: values?.vet_portal,
      pet_vet_id: id,
    };
    const practice = {
      vet_practice_name: values?.vet_practice_name,
    };
    Promise.all([
      updateVetTherapist(values?.vet_id, value),
      updateVetPractice(values?.vet_practice_id, practice),
    ])
      .then((res) => {
        setShowSuccessMessage(true);
        setOpen(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Vet therapist",
    pageStyle: `
    @page {
      size: A3; 
      margin: 200px; 
    }
    body {
      margin: 100px; 
      
  `,

    printStyle: `
  
  .print-text-field {
    font-size: 14px; 
   
`,
  });

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  const handleFaxNumberChange = (e) => {
    // Remove non-numeric characters
    const formattedFax = e.target.value.replace(/\D/g, "");

    // Format the fax number as +1 (123) 456-7890
    let formattedValue = "";
    if (formattedFax.length >= 1) {
      formattedValue += `+${formattedFax[0]}`;
    }
    if (formattedFax.length >= 2) {
      formattedValue += ` (${formattedFax.substring(1, 4)}`;
    }
    if (formattedFax.length >= 5) {
      formattedValue += `) ${formattedFax.substring(4, 7)}`;
    }
    if (formattedFax.length >= 8) {
      formattedValue += `-${formattedFax.substring(7, 11)}`;
    }
    formikRef?.current?.setFieldValue("fax", formattedValue);
  };

  return (
    <FormLayout title="Edit Vet and Therapist Information">
      <Formik
        initialValues={initialValue}
        innerRef={formikRef}
        onSubmit={updateVet}
        validationSchema={EditVetAndTherapistFormValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, errors, values, isSubmitting, setFieldValue }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <div ref={componentPDF} style={{ width: "100%", height: "100%" }}>
                <Box className="scroll2" sx={{ width: "100%" }}>
                  <Box
                    sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}
                  >
                    <Typography variant="h6" sx={FormHeaderTitle}>
                      Vet and Therapist Details
                    </Typography>
                  </Box>
                  <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                    <Column>
                      <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                        <AutoComplete
                          {...getFieldProps("pet_id")}
                          options={items}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="vet_practice_id"
                        label={"Veterinary Name"}
                        requiredField
                      >
                        <AutoComplete
                          {...getFieldProps("vet_practice_id")}
                          options={vetpracticename}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup name="type" label={"Type"} requiredField>
                        <AutoComplete
                          {...getFieldProps("type")}
                          options={typeOptions}
                          placeholder={"Select a type"}
                          otherType="Other"
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="vet_full_name"
                        label={"Vet Full Name"}
                        requiredField
                      >
                        <InputField {...getFieldProps("vet_full_name")} />
                      </FormGroup>
                    </Column>
                    
                    <Column>
                      <FormGroup name="fax" label={"FAX"}>
                        <InputField
                          {...getFieldProps("fax")}
                          onChange={handleFaxNumberChange}
                        />
                      </FormGroup>
                    </Column>
                    
                    <Column>
                      <FormGroup name="vet_portal" label={"Vet Portal"}>
                        <InputField {...getFieldProps("vet_portal")} />
                      </FormGroup>
                    </Column>
                  </Body>

                  <Box
                    sx={{
                      borderBottom: "1px solid #FF8533",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={FormHeaderTitle}>
                      Contact Details
                    </Typography>
                  </Box>
                  <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                      <FormGroup name="email" label={"Email Address"}>
                        <InputField {...getFieldProps("email")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="primary_phone_number"
                        label={"Phone Number"}
                      >
                        <CustomPhoneInput
                          {...getFieldProps("primary_phone_number")}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="street_address"
                        label={"Street Address 1"}
                      >
                        <AutoCompleteAddress
                          formikRef={formikRef}
                          setFieldValue={setFieldValue}
                          value={values?.street_address}
                          address="street_address"
                          city="city"
                          state="state"
                          zipcode="zip_code"
                          country="country"
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup
                        name="street_address_2"
                        label={"Street Address 2"}
                      >
                        <InputField {...getFieldProps("street_address_2")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup name="country" label={"Country"}>
                        <AutoComplete
                          {...getFieldProps("country")}
                          name="country"
                          options={countryOptions}
                          placeholder="Select a country"
                          onChange={handleCountryChange}
                          isOnChange
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup name="state" label={"State"}>
                        <AutoComplete
                          {...getFieldProps("state")}
                          name="state"
                          otherType="Other"
                          options={stateOptions}
                          placeholder={"Select a type"}
                        />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup name="city" label={"City"}>
                        <InputField {...getFieldProps("city")} />
                      </FormGroup>
                    </Column>
                    <Column>
                      <FormGroup name="zip_code" label={"Zip Code"}>
                        <InputField {...getFieldProps("zip_code")} />
                      </FormGroup>
                    </Column>
                  </Body>
                </Box>
              </div>
              <Box sx={ButtonBoxStyle}>
                <CustomButton
                  label="Cancel"
                  type="button"
                  style={CancelButton}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  label={isSubmitting ? "Saving" : "Save"}
                  disabled={isSubmitting}
                  type="submit"
                />
                <CustomButton
                  startIcon={<LocalPrintshopOutlinedIcon />}
                  onClick={handlePrint}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your vet information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/vet-and-therapist/vet-and-therapist-info/${id}`);
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}

