import React from "react";

import { useAuth } from "./AuthProvider"; // Path to your AuthContext

const withAuth = (WrappedComponent) => {
  return (props) => {
    const { isAuthenticated, redirectToLogin } = useAuth();

    console.log({
      isAuthenticated,
    });

    if (isAuthenticated) {
      return <WrappedComponent {...props} />;
    }

    // Redirect to login using the navigate function
    // navigate('/login');
    redirectToLogin();
    return null; // You can also return a loading state or a message here
  };
};

export default withAuth;
