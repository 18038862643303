import React, { useEffect, useState } from "react";

import "./EmergencyForm/form.css";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { InputLabel, Box, Button, Typography, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

// import Exit from "../../Assets/Images/PetInformation/Logout.svg";
import { getEmergencyContactById } from "../../Api/Services/EmergencyServices";
import Step2 from "../../Assets/Images/PetInformation/stepper/step 2.svg";
import Layout from "../Side bar/Layout";

export default function EmergencyPreview() {
  const navigate = useNavigate();
  const { id } = useParams();

  //petname
  const [pet_name, setPet_name] = useState("");

  //Contact
  const [email, setEmail] = useState();
  const [phone_number, setPhone_number] = useState();
  const [first_name, setFirst_name] = useState();
  const [last_name, setLast_name] = useState();
  const [relationship, setRelationship] = useState();
  const [secondary_number, setSecondary_number] = useState();
  const [zip_code, setZip_code] = useState();
  const [street_address, setStreet_address] = useState();
  const [street_address_2, setStreet_address_2] = useState();
  const [apt_city, setApt_city] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();



  const getEmergencyContact = () => {
    getEmergencyContactById(id)
      .then((response) => {
        const emData = response.data.data[0];
        setEmail(emData.email);
        setPhone_number(emData.phone_number);
        setFirst_name(emData.first_name);
        setLast_name(emData.last_name);
        setRelationship(emData.relationship);
        setSecondary_number(emData.secondary_number);
        setZip_code(emData.zip_code);
        setStreet_address(emData.street_address);
        setStreet_address_2(emData.street_address_2);
        setApt_city(emData.apt_city);
        setState(emData.state);
        setCountry(emData.country);
        setPet_name(emData.pet_name);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getEmergencyContact();
  });

  return (
    <Layout>
      <Container
        sx={{ padding: "0px" }}
        alignItems="center"
        justifyContent="center"
      >
        <Box display={"flex"} justifyContent="space-between" px={0}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            columns={12}
            pt={1}
            sx={{ pl: { sm: 3, md: 4, xs: 3, l: 4 } }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={headerGridStyle}
              style={{ marginTop: "40px" }}
            >
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent="left"
                pb="15px"
              >
                <ArrowBackIosNewRoundedIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Typography
                  variant="h6"
                  color="white"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "23px",
                    paddingLeft: "40px",
                  }}
                >
                  Preview
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
          </Grid>
        </Box>

        <Box>
          <Grid
            className="scroll"
            padding="12px"
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            columns={12}
            pt={1}
            overflowX="hidden"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Pet Name</InputLabel>
              <TextField
                id="petName"
                name="petName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={pet_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Street Address</InputLabel>
              <TextField
                id="StreetAddress"
                name="StreetAddress"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={street_address}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Relationship</InputLabel>
              <TextField
                id="Relationship"
                name="Relationship"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={relationship}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Street Address 2</InputLabel>
              <TextField
                id="StreetAddress2"
                name="StreetAddress2"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={street_address_2}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>First Name</InputLabel>
              <TextField
                id="First Name"
                name="First Name"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={first_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>City</InputLabel>
              <TextField
                id="City"
                name="City"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={apt_city}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Last Name</InputLabel>
              <TextField
                id="LastName"
                name="LastName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={last_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>State</InputLabel>
              <TextField
                id="State"
                name="State"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={state}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Primary Phone Number</InputLabel>
              <TextField
                id="PrimaryPhone"
                name="PrimaryPhone"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={phone_number}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Zip Code</InputLabel>
              <TextField
                id="ZipCode"
                name="ZipCode"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={zip_code}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Secondary Phone</InputLabel>
              <TextField
                id="secondaryPhone"
                name="secondaryPhone"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={secondary_number}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Country</InputLabel>
              <TextField
                id="Country"
                name="Country"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={country}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Email</InputLabel>
              <TextField
                id="email"
                name="email"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={email}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
          </Grid>
        </Box>

        <Box
          display="flex"
          p={4}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box display="flex">
            {/* <Button
              variant="outlined"
              sx={SavebtnStyle}
              startIcon={<SaveOutlinedIcon />}
            ></Button> */}

            <Button
              variant="outlined"
              sx={PrintbtnStyle}
              startIcon={<LocalPrintshopOutlinedIcon />}
            ></Button>
          </Box>
        </Box>

        <Box
          display="flex"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <img src={Step2} alt="step1" />
        </Box>
        {/* <Box
          display="flex"
          sx={{ justifyContent: "right", alignItems: "center" }}
        >
          {/* <img
            src={Exit}
            alt="logout"
            style={{
              cursor: "pointer",
              top: "-4vw",
              position: "relative",
              width: "2em",
              height: "2em",
            }}
          />
        </Box> */}
      </Container>
    </Layout>
  );
}

const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 37px;
  backdrop-filter: blur(15px);

  @media (max-width: 1130px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;

const gridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
};

const inputstyle = {
  color: "white",
  fontSize: "14px",
  padding: "2px 0",
};

export const SavebtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px 0 0 11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  height: 40,
  backdropFilter: "blur(10px)",
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};

const headerGridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "left",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-root ": {
    width: { xs: 300, sm: 300, md: 270, lg: 350, xl: 350 },
    height: "35px",
  },
  "& .MuiFormControl-root": {
    padding: "0px 10px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};

export const PrintbtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 40,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
