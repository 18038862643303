import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SocialProofBox from "../../Components/For Individuals/SocialProofBox";
import {
  getButton,
  getForIndividualsPara,
  getForIndividualsStep,
} from "../../api/axios";
import useSWR from "swr";

function Forindividuals() {
  const { data: data = {} } = useSWR(
    `getForIndividualsPara`,
    async () => {
      try {
        const { data } = await getForIndividualsPara();
        const paraData = data.data[0];
        return { ...paraData, index: data.data.indexOf(paraData) };
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: step = {} } = useSWR(
    `getForIndividualsStep`,
    async () => {
      try {
        const { data } = await getForIndividualsStep();
        const stepData = data.data[0];
        return { ...stepData, index: data.data.indexOf(stepData) };
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: buttons = [] } = useSWR(
    `getForIndividualsgetButton`,
    async () => {
      try {
        const { data } = await getButton();
        return data?.data;
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const cell_data = [
    {
      no: "01",
      text: step.step1,
    },
    {
      no: "02",
      text: step.step2,
    },
    {
      no: "03",
      text: step.step3,
    },
    {
      no: "04",
      text: step.step4,
    },
  ];

  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Body>
        <Hero>
          <h3>Peace of Mind for</h3>
          <h1>
            <span style={{ color: "rgba(255, 133, 51, 1)" }}> Pet </span>
            Owners
          </h1>
        </Hero>
        <Image>
          <img src={data.headerImage} alt="body" />
        </Image>

        <Maincell>
          <Sec>
            {cell_data.map((item, i) => (
              <CellGroup key={i}>
                <Cellno>{item.no}</Cellno>
                <Celltext>{item.text}</Celltext>
              </CellGroup>
            ))}
          </Sec>
          <Para>
            <Text>{data.paragraph}</Text>
            <br />
            <Button
              onClick={() => {
                navigate(buttons?.[3]?.buttonLink);
              }}
            >
              {buttons?.[3]?.button_name}
            </Button>
          </Para>
        </Maincell>
        <SocialProofBox />

        <button>
          <a onClick={() => navigate(buttons?.[4]?.buttonLink)}>
            {buttons?.[4]?.button_name}
          </a>
        </button>
      </Body>
    </div>
  );
}

export default Forindividuals;

const Body = styled.div`
  padding: 140px 0px 0px;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(2, auto);

  button {
    width: 259px;
    height: 55px;
    background: #ff8533;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 30px;
    color: #ffff;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.011em;
    text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    margin-bottom: 50px;

    :hover {
      transform: translateY(-1px);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 20px 20px rgba(0, 0, 0, 0.15),
        inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
    }
  }
`;

const Sec = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, auto);
  @media (max-width: 830px) {
    display: none;
  }
`;
const Hero = styled.div`
  h1 {
    ${
      "" /* font-family: "Omnes";
    font-style: normal; */
    }
    font-weight: 700;
    font-size: 50px;
    color: rgb(31, 85, 165);
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    line-height: 34px;
    @media (max-width: 1100px) {
      font-size: 45px;
    }
    @media (max-width: 975px) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: 895px) {
      font-size: 38px;
    }
    @media (max-width: 855px) {
      letter-spacing: -0.01em;
      font-size: 35px;
    }
    @media (max-width: 715px) {
      font-size: 30px;
      line-height: 35px;
      text-align: center;
    }
  }
  h3 {
    ${
      "" /* font-family: "Omnes";
    font-style: normal; */
    }
    font-weight: 800;
    font-size: 35px;
    text-align: justify;
    color: #06476c;
    @media (max-width: 1100px) {
      font-size: 33px;
    }
    @media (max-width: 975px) {
      font-size: 30px;
      line-height: 30px;
    }
    @media (max-width: 895px) {
      font-size: 28px;
    }
    @media (max-width: 715px) {
      font-size: 25px;
      line-height: 35px;
      text-align: center;
    }
  }
`;

const Text = styled.div`
  ${
    "" /* font-family: "Omnes";
  font-style: normal; */
  }
  font-weight: 550;
  font-size: 23px;
  line-height: 28px;
  /* or 119% */
  text-align: justify;
  color: #000000;
  /* color: #06476c; */

  @media (max-width: 1028px) {
    font-size: 20px;
  }  
  @media (max-width: 768px) {
    font-size: 15px;
  }  
`;
const Button = styled.div`
  width: 255px;
  height: 55px;
  cursor: pointer;
  background: #ff8533;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* or 100% */
  text-align: center;
  letter-spacing: -0.011em;
  transition-duration: 0.9s;
  line-height: 50px;

  color: #ffffff;

  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  :hover {
    transform: translateY(-3px);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 20px 20px rgba(0, 0, 0, 0.15),
      inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
  }
`;
const Para = styled.div``;

const Image = styled.div`
  img {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
`;

const Maincell = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  display: grid;
  grid-gap: 86px;
  grid-template-columns: 1.32fr 1fr;
  @media (max-width: 1130px) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, auto);
  }
`;

const CellGroup = styled.div`
  padding-left: 30px;
  padding-bottom: 25px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, auto);
  @media (max-width: 500px) {
    padding-left: 0px;
    grid-gap: 10px;
  }
`;

const Cellno = styled.div`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height */
  color: #3ca899;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Celltext = styled.div`
  ${
    "" /* font-family: "Omnes";
  font-style: normal; */
  }

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-align: left;
  letter-spacing: -0.011em;
  width: 100%;
  color: #000000;
  /* color: #06476c; */
`;
