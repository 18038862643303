import React, { useState } from "react";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
    FormControl,
    Select,
    MenuItem,
    Chip,
} from "@mui/material";
import "./GlobalItemList.css";

export default function UserDropDown(props) {
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const handleDelete = () => {
        setSelectedItem("");
        props.setSelectedUserName("");
    }
    return (
        <FormControl
            variant="outlined"
            sx={{ m: 1, width: "20vw", backgroundColor: "#FFFFFF", color: '#000', "@media (max-width: 400px)": {
                width: "30vw"
              },}}
        >
            <Select
                id={props.id}
                defaultValue={true}
                onChange={(event) => {
                    setSelectedItem(event.target.value);
                    props.setSelectedUserName(event.target.value);                 
                }}
                sx={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "0px",
                    borderColor: "transparent",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid #FF8533',
                        borderRadius: '10px'
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                    "& .MuiSelect-icon": { color: "#AEAFB4" },
                    "& .MuiChip-root": {
                        color: "white",
                        backgroundColor: "#FFFFFF",
                    },
                    "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#FF8533",
                        },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FF8533",
                    },
                    "&.Mui-focused .MuiChip-root": {
                        backgroundColor: "#FFFFFF",
                    },
                }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                placeholder={`Select a ${props.placeholder}`}
                renderValue={() =>
                    selectedItem === '' ? (
                        <div style={{color: "black"}}>Select a {props.placeholder}</div>
                    ) : (
                        <Chip
                            key={selectedItem}
                            label={selectedItem}
                            deleteIcon={
                                <CancelRoundedIcon
                                    onMouseDown={(event) => event.stopPropagation()}
                                />
                            }
                            style={{ margin: 2, backgroundColor: 'rgba(255, 133, 51, 0.20)', color: 'black' }}
                            onDelete={handleDelete}
                        />
                    )
                }
            >
                {props.items.length > 0 ? (
                    props.items.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                            {item.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem key={`no-${props.id}`} value="" disabled>
                        No pets found
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}
