import React from "react";

import VetTherapistInfo from "../../Components/Selected-item-view/VetTherapist/VetTherapistInfo";

const SecondView = () => {

  return (
    <>
      <VetTherapistInfo />
    </>
  );
};

export default SecondView;
