import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { deletePetInfo, getPetByID } from "../../../Api/Services/PetServices";
import { getHistoryById } from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import {
  deepJSONDifferences,
  formatDate,
  formatPhoneNumber,
} from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function PetInfo() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: petInfo,
    isLoading: IsPetInfoLoading,
  } = useSWR(
    id ? `getPetByID-${id}` : null,
    async () => {
      try {
        const {
          data: { data },
        } = await getPetByID(id);
        const { image, name, family_name, userDetails } = data;
        return {
          ...data,
          petId: data?.id,
          petImage: image,
          petName: name,
          familyName: family_name,
          first_name: userDetails ? userDetails.first_name : "",
          last_name: userDetails ? userDetails.last_name : "",
          profilePic: userDetails ? (
            userDetails.image
          ) : (
            <AccountCircleIcon
              sx={{
                fontSize: 55,
                color: "white",
              }}
            />
          ),
        };
      } catch (e) {
        return null;
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petHistory } = useSWR(
    id ? `petHistory${id}` : null,
    async () => {
      const { data } = await getHistoryById("pet-info", id);
      const filteredData = data?.data?.map((item) => {
        const dataDifference = deepJSONDifferences(
          JSON.parse(item?.oldData),
          JSON.parse(item?.newData)
        );
        delete dataDifference?.userDetails;
        delete dataDifference?.id;
        delete dataDifference?.w_id;
        return {
          ...item,
          updatedBy: `${item.first_name} ${item.last_name}`,
          created_at: formatDate(item?.created_at),
          old_data: JSON.parse(item?.oldData),
          new_data: JSON.parse(item?.newData),
          data: dataDifference,
        };
      });
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      errorRetryCount: 0,
      errorRetryInterval: 0,
    }
  );

  const onDelete = async () => {
    return await deletePetInfo(id);
  };
  const weightArray = petInfo?.weight?.match(/^([\d.]+)(\D+)$/);

  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);

  const props = {
    label: "Pet Information",
    isLoading: IsPetInfoLoading,
    onBack: () => {
      navigate("/pet-information");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "name",
      },
      {
        label: "Family Name",
        value: "familyName",
      },
      {
        label: "Date of Birth",
        value: "dob",
      },
    ],
    columnsData: [
      {
        image: petInfo?.petImage,
        name: petInfo?.petName ? petInfo?.petName.slice(0, 10) : "-",
        familyName: petInfo?.familyName
          ? petInfo?.familyName.slice(0, 20)
          : "-",
        dob: formatDate(petInfo?.dob_date),
      },
    ],
    detailsData: [
      [
        { name: "Breed", value: petInfo?.breed },
        { name: "Pet Type", value: petInfo?.pet_type },
        { name: "Weight", value: `${weightArray?.[1]} ${weightArray?.[2]}` },
        { name: "Gender", value: petInfo?.gender },
        { name: "Fur Color", value: petInfo?.fur_color },
        { name: "Eye Color", value: petInfo?.eye_Color },
        { name: "Marks", value: petInfo?.marks },
        { name: "Chip Availability", value: petInfo?.chip_availability },
        { name: "Register No.", value: petInfo?.reg_number },
        { name: "Company Name", value: petInfo?.company_name },
      ],
      [
        {
          name: "Contact No.",
          value: formatPhoneNumber(petInfo?.company_contact_number),
        },
        { name: "Street Address", value: petInfo?.street_address },
        { name: "AptCity", value: petInfo?.apt_city },
        { name: "State", value: petInfo?.state },
        { name: "Zip Code", value: petInfo?.zip_code },
        { name: "Country", value: petInfo?.country },
      ],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/pet-information/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Pet History",
    historyColumnsData: petHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
      <OwnerInfo
        key={petInfo?.id}
        profiePic={petInfo?.profilePic}
        firstName={petInfo?.first_name}
        lastName={petInfo?.last_name}
        location={petInfo?.country}
      />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
