import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getDocumentByID } from "../../../Api/Services/DocumentServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchiveDocumentInfoComponent() {
  const { id } = useParams();

  const [petId, setPetId] = useState("");
  const navigate = useNavigate();

  const { data: documentData = [], isLoading } = useSWR(
    id ? `documentData${id}` : null,
    async () => {
      const { data } = await getDocumentByID(id);
      setPetId(data?.data?.[0]?.pet_id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petData = {}, isLoading: isPetLoading } = useSWR(
    petId ? `petData${petId}` : null,
    async () => {
      const { data } = await getPetByID(petId);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Document Information",
    isLoading: isLoading || isPetLoading,
    onBack: () => {
      navigate("/archive/document");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Document Name",
        value: "documment_name",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        documment_name: documentData?.documment_name,
      },
    ],
    detailsData: [
      [{ name: "Date", value: documentData?.date }, { name: 'Document Link', value: documentData?.documment, pdf: true}],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
