import React, { useEffect } from "react";
import Contacts from "../../Components/ContactUs/Contact";
import Form from "../../Components/ContactUs/Form";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Contacts />
      <Form />
    </div>
  );
}

export default ContactUs;
