import React from "react";

import ArchiveJournalList from "../../Components/Archive-Items/ArchiveJournal";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveJournal() {
  return (
    <Layout>
      <ArchiveJournalList />
    </Layout>
  );
}
