import React from "react";

import { NavLink } from "react-router-dom";
import styled from "styled-components";

const FormHeader = () => {
  return (
    <HeaderWrapper>
      <NavLink to="/surgery-and-treatment">
        <Back>
          <img
            src={require("../../Assets/Images/SurgeryAndTreatment/Vector.png")}
            alt=""
          />
        </Back>
      </NavLink>
      <Header>Procedures and Treatments</Header>
    </HeaderWrapper>
  );
};

export default FormHeader;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr auto;
  width: 300px;
  margin-top: 40px;
  margin-left: 100px;
`;

const Back = styled.div`
  font-weight: 500;
  font-size: 20px;
  border: 0;
  background: none;
  line-height: 23px;
  color: #ffffff;
  transition: 0.5s;
  :hover {
    cursor: pointer;
  }
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
`;
