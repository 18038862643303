import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: sans-serif;
  //padding: 0px 0 0px 0px;
  text-align: center;
`;
export const Header = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0px;
  padding: 20px;
  text-align: left;
  border-radius: 5px 5px 0 0;

  @media (max-width: 650px) {
    padding: 10px;
  }
`;
export const Body = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0px;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  h2 {
    padding-top: 20px;
    color: rgb(31, 85, 165);
  }
  button {
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;
    :hover {
      background-color: rgb(31, 85, 150);
    }
    
    :disabled {
        background-color: gray;
      }
  }

  @media (max-width: 650px) {
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
export const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 20px;
  label {
    padding: 10px 0;
    color: rgb(31, 85, 165);
    font-weight: 700;
  }
  input {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;
    :focus {
      border: none;
    }
  }
  p {
    color: red;
    font-size: 14px;
    padding: 0px;
    height: 16.8px;
    margin: 7px 0 7px 0;
  }
  select {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;
    :focus {
      border: none;
    }
  }
`;

export const Error = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: red;
`;
export const Check = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0px;
  padding: 30px 30px 0px 30px;
  text-align: left;
  input {
    transform: scale(1.5);
    margin-right: 10px;
  }

  @media (max-width: 650px) {
    padding: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;
