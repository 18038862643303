import { React, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import "../../Assets/Styles/ContactUs/form.css";
import emailjs from "@emailjs/browser";
// import validator from "validator";

const PhoneNumberInput = ({ setToSend }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.sendForm(
      "service_8a4swgl",
      "template_cyc9d0u",
      form.current,
      "xwcj27mC_tIE7Oxg0"
    );
  };

  // const [emailError, setEmailError] = useState("");
  // const validateEmail = (e) => {
  //   var email = e.target.value;

  //   if (validator.isEmail(email)) {
  //     setEmailError("");
  //   } else {
  //     setEmailError("Enter valid Email!");
  //   }
  // };

  // const [toSend, setToSend] = useState({
  //   // from_name: "",
  //   // to_name: "",
  //   // message: "",
  //   // reply_to: "",
  // });

  // const handleChange = (e) => {
  //   setToSend({ ...toSend, [e.target.name]: e.target.value });
  // };

  const {
    // handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  // const onSubmit = (data) => {
  //   console.log({ data });
  // };

  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);
    return isValid;
  };

  return (
    // <form onSubmit={handleSubmit(onSubmit)} className="user-info-form">
    // <form ref={form} onSubmit={sendEmail}>
      <div>
        <Label>
          <label className="inputTitle">Contact no</label>
        </Label>
        <Controller
          name="phone-input"
          control={control}
          rules={{
            validate: (value) => handleValidate(value),
          }}
          render={({ field: { onChange, value } }) => (
            <Country>
              <PhoneInput
                name="phoneNumber"
                type="text"
                country={"us"}
                enableAreaCodes={true}
                onChange={(value) => setToSend((prevstate) => ({
                  ...prevstate,
                  'phoneNumber': value || ""
                }))}
                // country={'th'}
                // onlyCountries={["us"]}
                // areaCodes={{ us: ["332"] }}
                inputProps={{
                  defaultcountry: "us",
                  // name: "phone",
                  // country: "us",
                  // required: true,
                  // autoFocus: true,
                }}
                containerClass="my-container-class"
                inputClass="my-input-class"
                inputStyle={{
                  fontFamily: "Omnes, sans-serif",
                  fontStyle: "normal",
                  fontWeight:"500",
                  background: "rgba(255, 255, 255, 0.8)",
                  border: "rgba(255, 255, 255, 0.8)",
                  paddingTop: "0px",
                  height: "14px",
                  backgroundColor: "none",
                  color: "rgba(0, 0, 0, 0.763)",
                }}
                containerStyle={{}}
                required
              />
            </Country>
          )}
        />
        {errors["phone-input"] && (
          <p className="error-message">Invalid Phone</p>
        )}
      </div>
    // </form>
  );
};

export default PhoneNumberInput;

const Country = styled.div`
  padding-top: 1px;
  width: 600px;

  @media screen and (max-width: 720px) {
    width: 500px;
  }
  @media screen and (max-width: 620px) {
    width: 450px;
  }
  @media screen and (max-width: 520px) {
    width: 350px;
  }
  @media screen and (max-width: 420px) {
    width: 250px;
  }

  @media screen and (max-width: 305px) {
    width: 210px;
  }
`;

const Label = styled.div`
  margin-left: -4px;
  padding-top: 20px;
  @media screen and (max-width: 520px) {
    padding-top: 0px;
    height: 30px;
  }
`;
