import React from "react";
import "./SuccessPage.css";
import pawPrintlogo from "../../Assets/Images/Navbar/pawprint-icon.png";

const SuccessPage = () => {
  return (
    <div className="main">
      <div className="container-success">
        <form>
          <div className="successImage">
            <img src={pawPrintlogo} className="pawPrintlogo" alt="logo" />
          </div>
          <br />
          <h2>Password Successfully Changed</h2>
          <br />
        </form>
      </div>
    </div>
  );
};

export default SuccessPage;
