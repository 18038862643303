import React from "react";

import SurgeryAndTreatment from "../../Components/Item-List/Surgery&Treatment";
import Layout from "../../Components/Side bar/Layout";

const SurgeryTreatInfo = () => {

  return (
    <Layout>
      <SurgeryAndTreatment />
    </Layout>
  );
};

export default SurgeryTreatInfo;
