import React from "react";

import Document from "../../Components/Item-List/Document";
import Layout from "../../Components/Side bar/Layout";

const ItemListDocument = () => {

  return (
    <Layout>
      <Document />
    </Layout>
  );
};

export default ItemListDocument;
