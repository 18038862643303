import "../../Assets/Styles/AboutUs/AboutUs.css";
import AUUpper from "../../Components/AboutUs/AUUpper";
import OurStory from "../../Components/AboutUs/OurStory";
import OurMission from "../../Components/AboutUs/OurMission";
import HowWork from "../../Components/AboutUs/HowWork";
import Steps from "../../Components/AboutUs/Steps";
import MeetTeam from "../../Components/AboutUs/MeetTeam";
import GetInTouch from "../../Components/AboutUs/GetInTouch";
import React, { useEffect } from "react";
import { getAboutUsStepCards, getButton } from "../../api/axios";
import useSWR from "swr";

function AboutUS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: data = [] } = useSWR(
    `aboutUsStepCards`,
    async () => {
      try {
        const { data } = await getAboutUsStepCards();
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
        alert(error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: button } = useSWR(
    `aboutUsgetButton`,
    async () => {
      try {
        const { data } = await getButton();
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div>
      <AUUpper button={button} />
      <OurStory />
      <OurMission />
      <HowWork />
      {data?.map((item, index) => {
        return (
          <div className="StepWrapperw" key={item.id}>
            <Steps
              buttonText={item.header1}
              stepImage={item.image1}
              Button={item.btn_content1}
              ButtonLink="https://admin.pawprintid.com/products"
            />
            <Steps
              buttonText={item.header2}
              stepImage={item.image2}
              Button={item.btn_content2}
              ButtonLink="https://admin.pawprintid.com/"
            />
            <Steps
              buttonText={item.header3}
              stepImage={item.image3}
              Button={item.btn_content3}
              ButtonLink="https://apps.apple.com/us/app/pawprintid/id1551145317"
            />
          </div>
        );
      })}
      <MeetTeam />
      <GetInTouch button={button} />
    </div>
  );
}

export default AboutUS;
