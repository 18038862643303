import axios from "axios";

import * as Sentry from '@sentry/react';
import { TOKEN_KEY } from '../Constant'
const token = localStorage.getItem("pawprint_token");

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,

  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
    "Content-type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem(TOKEN_KEY)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log errors to Sentry
    if (error.response) {
      Sentry.captureException(error);
    }

    // Forward the error to the calling code
    return Promise.reject(error);
  }
);

export const ImageAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

export const uploadS3Bucket = async (url, file) => {
  const uploadResponse = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return uploadResponse;
}
// getSummary list
export const getSummaryList = async () => {
  const res = await api.get(`/summary/getAllSummary`);
  return res;
};

// role get by ID
export const getRoleById = async () => {
  const res = await api.get(`/role/getRoleByID/1`);
  return res;
};

export const getMembershipById = async (id) => {
  const res = await api.get(`/membership/getMembershipById/${id}`);
  return res;
};

export const getMembershipByMembershipNo = async (formData) => {
  const res = await api.post(`/membership/getMembershipByNo`, formData);
  return res;
};


// pet information item list
export const getAllPetsByUserId = async (user_id) => {
  const res = await api.get(`/pet/getAllPetsByUserId/${user_id}`);
  return res;
};


// last pet information item list
export const getLastPetsByWorkspaceId = async (w_id) => {
  const res = await api.get(`/pet/getNextPetsIdByWorkspaceId/${w_id}`);
  return res;
};


//Medication UPDATE
// export const updatePetInfoById = async (id, formData) => {
//     const res = await api.put(`/pet/updatePet/${id}`, formData);
//     return res;
//   };
