import React, { useState, useEffect } from "react";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  InputLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Step2 from "../../../../Assets/Images/PetInformation/stepper/step 2.svg";
// import { getPetInfoById } from "../../Api/axios";
import "../../../../Components/Item-List/GlobalItemList.css";
import Layout from "../../../Side bar/Layout";

export default function VetInfoPreview() {
  const { id } = useParams();

  // const id = 2;
  const navigate = useNavigate();

  //pet name
  const pet_id = 2;
  // const pet_id = localStorage.setItem(petowner_id);
  const [name, setPetName] = useState("");

  //form data vet
  const vet_id = 6;
  const [vet_full_name, setVetFullName] = useState("");
  const [vet_practice_name, setVetPracticeName] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [primary_phone_number, setPhoneNumber] = useState("");
  const [type, setType] = useState("");

  //vet adres
  const [zip_code, setZipCode] = useState("");
  const [street_address, setStreet_Adress] = useState("");
  const [street_address_2, setStreet_Adress_2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");



  return (
    <Layout>
      <Container
        sx={{ padding: "0px", justifyContent: "center", alignItems: "center" }}
      >
        <Box display={"flex"} sx={{ justifyContent: "space-between" }} px={0}>
          <Grid
            container
            spacing={1}
            flexGrow={1}
            columns={12}
            pt={1}
            sx={{
              pl: { sm: 3, md: 4, xs: 3, l: 4 },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={headerGridStyle}
              style={{ marginTop: "40px" }}
            >
              <Box
                display={"flex"}
                sx={{ justifyContent: "left", alignItems: "center" }}
                pb="15px"
              >
                <ArrowBackIosNewRoundedIcon
                  sx={{ color: "white", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <Typography
                  variant="h6"
                  color="white"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "23px",
                    paddingLeft: "40px",
                  }}
                >
                  Preview
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
          </Grid>
        </Box>

        <Box>
          <Grid
            className="scroll"
            padding="12px"
            container
            spacing={1}
            sx={{ justifyContent: "center", alignItems: "center" }}
            flexGrow={1}
            columns={12}
            pt={1}
            overflowx="hidden"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Pet Name</InputLabel>
              <TextField
                id="familyName"
                name="familyName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Street Address</InputLabel>
              <TextField
                id="petName"
                name="petName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                width="411px"
                value={street_address}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Veterinary Practice Name</InputLabel>
              <TextField
                id="breed"
                name="breed"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={vet_practice_name}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Street Address 2</InputLabel>
              <TextField
                id="petName"
                name="petName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                width="411px"
                value={street_address_2}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Type</InputLabel>
              <TextField
                id="petName"
                name="petName"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                width="411px"
                value={type}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>City</InputLabel>
              <TextField
                id="country"
                name="country"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={city}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Vet Full Name</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={vet_full_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>State</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={state}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Primary Phone Number</InputLabel>
              <Country>
                <PhoneInput
                  country={"us"}
                  enableAreaCodes={true}
                  value={primary_phone_number}
                  inputStyle={{
                    // background: "rgba(255, 255, 255, 0.8)",
                    background: "none",
                    border: "rgba(255, 255, 255, 0.8)",
                    paddingTop: "0px",
                    height: "16px",
                    backgroundColor: "none",
                  }}
                />
              </Country>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Zip Code</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={zip_code}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>FAX</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={fax}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Country</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={country}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={5} sx={gridStyle}>
              <InputLabel sx={inputstyle}>Email</InputLabel>
              <TextField
                id="marks"
                name="marks"
                label=""
                autoComplete="off"
                variant="outlined"
                sx={textFieldStyle}
                value={email}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={5}
              sx={gridStyle}
            ></Grid>
          </Grid>
        </Box>

        <Box
          display="flex"
          p={4}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Box display="flex">
            {/* <Button
              variant="outlined"
              sx={SavebtnStyle}
              startIcon={<SaveOutlinedIcon />}
            ></Button> */}

            <Button
              variant="outlined"
              sx={PrintbtnStyle}
              startIcon={<LocalPrintshopOutlinedIcon />}
            ></Button>
          </Box>
        </Box>

        <Box
          display="flex"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <img src={Step2} alt="step1" />
        </Box>
        {/* <Box
          display="flex"
          sx={{ justifyContent: "right", alignItems: "center" }}
        >
          <img
            src={Exit}
            alt="logout"
            style={{
              cursor: "pointer",
              top: "-4vw",
              position: "relative",
              width: "2em",
              height: "2em",
            }}
          />
        </Box> */}
      </Container>
    </Layout>
  );
}

const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 37px;
  backdrop-filter: blur(15px);

  @media (max-width: 1130px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;

const headerGridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "left",
};

const inputstyle = {
  color: "white",
  fontSize: "14px",
  padding: "2px 0",
};
const textFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    border: "2px solid rgba(255, 255, 255, 0.5)",
    borderRadius: "11px",
    height: "35px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-root ": {
    width: { xs: 300, sm: 300, md: 270, lg: 350, xl: 350 },
    height: "35px",
  },
  "& .MuiFormControl-root": {
    padding: "0px 10px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
};



const gridStyle = {
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
};

export const SavebtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px 0 0 11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  height: 40,
  backdropFilter: "blur(10px)",
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
export const PrintbtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 40,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
const Country = styled.div`
  .react-tel-input .form-control {
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
    border-radius: 11px !important;
    height: 35px !important;
    width: 350px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
    background: rgba(255, 255, 255, 0.2) !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;
