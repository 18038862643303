import React from "react";

import ArchiveVetTherapistInfo from "../../Components/Selected-item-view-Ar/VetTherapist/VetTherapistInfo";

const ArchiveSecondView = () => {

  return (
    <>
      <ArchiveVetTherapistInfo />
    </>
  );
};

export default ArchiveSecondView;
