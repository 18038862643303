import React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import {
  getpetsurgery,
  Updatepetsurgery,
} from "../../Api/Services/SurgeryServices";
import {
  getAllVetnamePres,
  getVetTherapistById,
} from "../../Api/Services/VetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { SurgeryFormValidation } from "../../From/Validation";
import { formatDate } from "../../Utils";
import { Typography } from "@mui/material";

const defaultValue = {
  date: "",
  surgery_type: "",
  description: "",
  pet_id: "",
  vet_id: "",
};

function Surgeryedit() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();

  const navigate = useNavigate();
  const [vetname, setvetname] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [data, setdata] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };
  const getVetNames = async () => {
    try {
      const { data } = await getAllVetnamePres(w_id);
      const filteredVetNames = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.vet_full_name,
        };
      });
      setdata(filteredVetNames);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getVetNames();
    getPetNames();
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await getpetsurgery(id);
      const date = formatDate(data?.data?.[0]?.date);
      setInitialValue({
        ...initialValue,
        date: date,
        surgery_type: data?.data?.[0]?.surgery_type,
        description: data?.data?.[0]?.description,
        pet_id: data?.data?.[0]?.pet_id,
        vet_id: data?.data?.[0]?.vet_id,
      });
      try {
        const response = await getVetTherapistById(data?.data?.[0]?.vet_id);
        setvetname(response?.data?.data?.[0]?.vet_practice_name);
      } catch (error) {
        console.error("error: ", error);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const updatePetInsurance = async (values) => {
    try {
      const res = await Updatepetsurgery(id, values);
      if (res?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (error) {
      console.error("error: ", error);
      alert("Invalid data enter again");
    }
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Surgery",
  });
  return (
    <FormLayout title="Edit Procedure and Treatment">
      <Formik
        initialValues={initialValue}
        onSubmit={updatePetInsurance}
        validationSchema={SurgeryFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Procedure and Treatment Details
                </Typography>
              </Box>
              <div className="scroll2" ref={componentPDF} style={{ width: "98%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="vet_id" label={"Vet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("vet_id")}
                        options={data}
                        textFieldStyle={{
                          width: {
                            xs: 250,
                            sm: 443,
                            md: 443,
                            lg: 443,
                            xl: 443,
                          },
                          height: "35px",
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="surgery_type"
                          label={"Surgery Type"}
                          requiredField
                        >
                          <InputField
                            {...getFieldProps("surgery_type")}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="date" label={"Date"} requiredField>
                          <InputField
                            type="date"
                            {...getFieldProps("date")}
                            inputProps={{ max: "9999-12-12" }}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup
                      name="description"
                      label={"Description"}
                      requiredField
                    >
                      <TextArea
                        rows={3}
                        inputProps={{ maxLength: 200 }}
                        {...getFieldProps("description")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your pet Procedure information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/surgery-and-treatment/info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
}

export default Surgeryedit;
