import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  getpetinsurancebyid,
  getProviderNamesByWorkspaceId,
  getSubscriberNamesByWorkspaceId,
  Updatepetinsuance,
} from "../../Api/Services/PetInsuranceServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { PetInsuranceFormValidation } from "../../From/Validation";
import { DurationOptions, memberNames } from "../../Options";
import { formatDate } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import "../pet-information/petPrint.css";
import { getUsersByWorkspaceId } from "../../Api/Services/UserServices";

const defaultValue = {
  plan: "",
  member_id: "",
  member_name: "",
  effective_date: "",
  duration: "",
  group_name: "",
  pet_id: "",
  provider_name: "",
  provider_phone: "",
  subscriber_name: "",
};

const Petinsuranceedit = () => {
  const { workspaceId: w_id } = useAuth();
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();

  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [memberNamesOptions, setMemberNamesOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [providerNames, setProviderNames] = useState([]);
  const [subscriberNames, setSubscriberNames] = useState([]);


  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getProviderNames = async () => {
    try {
      const { data } = await getProviderNamesByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      filteredData.push({ value: "Type here", label: "Type here" });
      setProviderNames(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getSubscriberNames = async () => {
    try {
      const { data } = await getSubscriberNamesByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      filteredData.push({ value: "Type here", label: "Type here" });
      setSubscriberNames(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getUsersByWorkspaceID = async () => {
    try {
      const res = await getUsersByWorkspaceId(w_id);
      const filteredData = res?.data?.data?.map((item) => {
        return {
          value: `${item?.first_name} ${item?.last_name}`,
          label: `${item?.first_name} ${item?.last_name}`,
        };
      });
      if (filteredData?.length === 0) {
        <></>;
      } else {
        setMemberNamesOptions(filteredData);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
    getData_petinsurance();
    getProviderNames();
    getSubscriberNames();
    getUsersByWorkspaceID()
  }, []);
  const getData_petinsurance = async () => {
    try {
      const { data } = await getpetinsurancebyid(id);
      setInitialValue({
        ...initialValue,
        plan: data?.data?.plan,
        member_id: data?.data?.member_id,
        member_name: data?.data?.member_name,
        effective_date: formatDate(data?.data?.effective_date),
        duration: data?.data?.duration,
        group_name: data?.data?.group_name,
        pet_id: data?.data?.pet_id,
        provider_name: data?.data?.provider_name,
        provider_phone: data?.data?.provider_phone,
        subscriber_name: data?.data?.subscriber_name,
      });
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const updatePetInsurance = async (values) => {
    try {
      const res = await Updatepetinsuance(id, values);
      if (res?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (error) {
      alert("invalid data enter again");
      console.error(error);
    }
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Pet Insurance",
  });
  return (
    <FormLayout title="Edit Pet Insurance Information">
      <Formik
        initialValues={initialValue}
        onSubmit={updatePetInsurance}
        validationSchema={PetInsuranceFormValidation}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Pet Insurance Details
                </Typography>
              </Box>
              <div ref={componentPDF} style={{ width: "100%" }}>
                <Body className="scroll2" style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="plan" label={"Plan"} requiredField>
                      <InputField {...getFieldProps("plan")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="provider_name"
                      label={"Insurance Provider Name"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("provider_name")}
                        options={providerNames}
                        otherType="Type here"
                        placeholder="Select a Provider Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="group_name" label={"Group"} requiredField>
                      <InputField {...getFieldProps("group_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="member_name"
                      label={"Member Name"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("member_name")}
                        options={memberNamesOptions}
                        otherType="Type here"
                        placeholder="Select a Member Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="effective_date"
                      label={"Effective Date"}
                      requiredField
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("effective_date")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="member_id"
                      label={"Member ID"}
                      requiredField
                    >
                      <InputField {...getFieldProps("member_id")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="subscriber_name"
                      label={"Subscriber Name"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("subscriber_name")}
                        options={subscriberNames}
                        otherType="Type here"
                        placeholder="Select a Subscriber Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="provider_phone"
                      label={"Phone Number"}
                      requiredField
                    >
                      <CustomPhoneInput {...getFieldProps("provider_phone")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="duration" label={"Duration"} requiredField>
                      <AutoComplete
                        {...getFieldProps("duration")}
                        options={DurationOptions}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your pet insurance information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/pet-insurance/insurance-info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
};

export default Petinsuranceedit;
