import React from "react";
import styled, { keyframes } from "styled-components";
import ParagraphCard from "./Features/ParagraphCard";
import FeatureIcons from "../HomeSection4/Features/FeatureIcons";
import ScrollCount from "./Features/ScrollCard";

export default function HomeSection4() {
    return(
        <Wrapper>
            <ScrollCount/>
            <ParagraphCard/>        
            <FeatureIcons/>
        </Wrapper>
    )
}

const animation = keyframes`
    from { opacity: 0; filter: blur(10px); }
    to { opacity: 1; filter: blur(0px);}
`

const Wrapper = styled.div`
    animation: ${animation} 1s 0.1s forwards;
    background: #ffffff;
`