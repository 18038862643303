import React, { useState, useEffect } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Grid,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  FullPageWrapper,
  Title,
  Container,
  Wrapper,
  HeaderWrapper,
  HeaderRowWrapper,
  BodyWrapper,
  RowWrapper,
  Image,
  StyledMessage,
} from "./ArchiveGlobalItemList.js";
import {
  getCarearsByUserId,
  getUserContact,
} from "../../Api/Services/UserServices.js";
import useAuth from '../../ContextProvider/AuthProvider'

import "./ArchiveGlobalItemList.css";
export default function ArchiveUserInformation() {
  const { petOwnerId: user_id } = useAuth();
  //const pawprint_user_role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  let navigate = useNavigate();
  const [data, setListData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const [userNames, setUserNames] = useState([]);
  const [selectedUserNames, setSelectedUserNames] = useState([]);

  useEffect(() => {
    getCarearsByUserId(user_id)
      .then((res) => {
        const listData = res.data.data;
        // setListData(listData);
        setListData(listData || []);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user_id]);

  useEffect(() => {
    getUserContact()
      .then((res) => {
        const contactData = res.data.data;
        setContactData(contactData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const result = data
    ? data.map((user) => {
        const { id, first_name, email, image, last_name, phone_number } = user;
        const matchingContacts = contactData.filter(
          (user_contact) => user_contact.user_id === id
        );

        return {
          id,
          image,
          first_name,
          last_name,
          email: email,
          phone_number,
          phoneNumbers: matchingContacts.map((contact) => contact.contact_no),
        };
      })
    : [];

  const filteredData = result.filter((item) => {
    const userName = item.first_name || "";
    const query = selectedUserNames.map((first_name) =>
      first_name.toLowerCase()
    );
    if (query.length === 0) {
      return true; // return all data if selectedPetNames is empty
    }
    return (
      query.includes("all users") || query.includes(userName.toLowerCase())
    );
  });

  const userNamesSet = new Set();
  const filteredUserNames = data.filter((user) => {
    const lowercaseName = user.first_name.toLowerCase();
    if (!userNamesSet.has(lowercaseName)) {
      userNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const [open, setOpen] = useState(false); // add state for the menu

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <FullPageWrapper>
      <Grid container rowSpacing={8} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Title>Archive User Information</Title>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            variant="outlined"
            sx={{ m: 1, width: "24vw", backgroundColor: "rgb(252, 132, 51)" }}
          >
            <InputLabel id="user-name-label" sx={{ color: "white" }}>
              Select a user name
            </InputLabel>
            <Select
              labelId="user-name-label"
              id="demo-multiple-chip"
              multiple
              value={selectedUserNames}
              onChange={(event) => {
                setSelectedUserNames(event.target.value);
                setOpen(false);
              }}
              input={
                <OutlinedInput
                  label="Select a user name"
                  sx={{ color: "white" }}
                />
              }
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .MuiSelect-icon": { color: "white" },
                "& .MuiChip-root": {
                  color: "white",
                  backgroundColor: "rgba(225, 225, 225, 0.3)",
                },
                "&:hover": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(225, 225, 225, 0.5)",
                  },
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-focused .MuiChip-root": {
                  backgroundColor: "rgba(225, 225, 225, 0.5)",
                },
              }}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      style={{ margin: 2 }}
                      onDelete={() => {
                        setSelectedUserNames((prevSelected) =>
                          prevSelected.filter(
                            (selectedValue) => selectedValue !== value
                          )
                        );
                      }}
                    />
                  ))}
                </div>
              )}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={handleCloseMenu}
            >
              {/* <MenuItem key="all-users" value="All Users">
                  All Users
                </MenuItem>
                {data.map((user) => (
                  <MenuItem key={user.id} value={user.first_name}>
                    {user.first_name}
                  </MenuItem>
                ))} */}
              {filteredUserNames.length > 0 ? (
                filteredUserNames.map((user) => (
                  <MenuItem key={user.id} value={user.first_name}>
                    {user.first_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem key="no-users" value="" disabled>
                  No users found
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Container>
        <Grid
          container
          wrap="nowrap"
          spacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Wrapper>
            <Table style={{ tableLayout: "fixed" }}>
              <HeaderWrapper>
                <TableHead>
                  <HeaderRowWrapper>
                    <TableRow>
                      <TableCell
                        style={{ width: "10vw", borderBottom: "none" }}
                      ></TableCell>
                      <TableCell
                        style={{
                          width: "10vw",
                          fontSize: "18.2px",
                          color: "#FFFFFF",
                          borderBottom: "none",
                        }}
                      >
                        First Name
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10vw",
                          fontSize: "18.2px",
                          color: "#FFFFFF",
                          borderBottom: "none",
                        }}
                      >
                        Last Name
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10vw",
                          fontSize: "18.2px",
                          color: "#FFFFFF",
                          borderBottom: "none",
                          textAlign: "center",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{
                          width: "12vw",
                          fontSize: "18.2px",
                          color: "#FFFFFF",
                          borderBottom: "none",
                          textAlign: "center",
                        }}
                      >
                        Phone Number
                      </TableCell>
                    </TableRow>
                  </HeaderRowWrapper>
                </TableHead>

                {/* {pawprint_user_role === "User" ? (
                  <AddIcon
                    sx={{ fontSize: 55 }}
                    style={{
                      color: "white",
                       // backgroundColor: "rgba(255, 255, 255, 0.3)",
                       backgroundColor: "rgb(252, 132, 51)",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
                      borderRadius: "10px",
                    }}
                    onClick={() => {
                      navigate("/user-information/Form");
                    }}
                  />
                  ) : (
                    <></>
                  )} */}
              </HeaderWrapper>

              {data.length === 0 && !isLoading ? (
                <StyledMessage
                  style={{
                    color: "#000000",
                  }}
                >
                  There are no other user details.
                </StyledMessage>
              ) : (
                <div
                  className={filteredData.length >= 4 ? "scroll" : "no-scroll"}
                >
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <Typography
                          variant="subtitle1"
                          style={{ color: "#000000", textAlign: "center" }}
                        >
                          Loading your data...
                        </Typography>
                      </TableRow>
                    ) : (
                      filteredData.map((item) => (
                        <BodyWrapper
                            key={item?.id}
                          sx={{
                            "&:first-child": {
                              borderTopLeftRadius: "20px",
                              borderTopRightRadius: "20px",
                              "--mask":
                                "radial-gradient(20px at 35px 100%,#0000 98%,#000) -35px",
                              "-webkit-mask": "var(--mask)",
                              mask: "var(--mask)",
                              paddingBottom: "4px",
                            },

                            "--mask":
                              "radial-gradient(20px at 35px 35px,#0000 98%,rgb(0, 0, 0)) -35px -35px",
                            "-webkit-mask": "var(--mask)",
                            mask: "var(--mask)",
                            "box-shadow": "2px 2px 7px 0px rgb(0 0 0 / 58%)",
                            "z-index": "1",
                            paddingBottom: "4px",

                            "&:last-child": {
                              borderBottomLeftRadius: "20px",
                              borderBottomRightRadius: "20px",
                              "--mask":
                                "radial-gradient(20px at 35px 0,#0000 98%,#000) -35px",
                              "-webkit-mask": "var(--mask)",
                              mask: "var(--mask)",
                              paddingBottom: "0",
                            },
                          }}
                        >
                          <TableRow
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              borderRadius: "10px 10px 0px 0px",
                              cursor: "pointer",
                            }}
                            // onClick={() => {
                            //   navigate(`/user-information/edit/${item.id}`);

                            // }}
                            onClick={() => {
                              navigate(
                                `/user-information/user-info/${item.id}`
                              );
                            }}
                          >
                            <RowWrapper>
                              <TableCell
                                align="center"
                                style={{
                                  width: "10vw",
                                  borderBottom: "none",
                                }}
                              >
                                {item.image ? (
                                  <Image
                                    src={item.image}
                                    style={{ verticalAlign: "middle" }}
                                  />
                                ) : (
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: 55,
                                      color: "white",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "10vw",
                                  fontSize: "16px",
                                  borderBottom: "none",
                                  overflow: "hidden", // hide any content that overflows the cell
                                  textOverflow: "ellipsis", // show an ellipsis (...) for any truncated text
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.first_name ? item.first_name : "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "13vw",
                                  fontSize: "16px",
                                  borderBottom: "none",
                                  overflow: "hidden", // hide any content that overflows the cell
                                  textOverflow: "ellipsis", // show an ellipsis (...) for any truncated text
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.last_name ? item.last_name : "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "10vw", // set a fixed width for the cell
                                  overflow: "hidden", // hide any content that overflows the cell
                                  textOverflow: "ellipsis", // show an ellipsis (...) for any truncated text
                                  whiteSpace: "nowrap", // prevent text from wrapping to the next line
                                  fontSize: "16px",
                                }}
                              >
                                {item.email ? item.email : "N/A"}
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "12vw",
                                  fontSize: "16px",
                                  borderBottom: "none",
                                  overflow: "hidden", // hide any content that overflows the cell
                                  textOverflow: "ellipsis", // show an ellipsis (...) for any truncated text
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item.phone_number ? item.phone_number : "N/A"}
                              </TableCell>
                            </RowWrapper>
                          </TableRow>
                        </BodyWrapper>
                      ))
                    )}
                  </TableBody>
                </div>
              )}
            </Table>
          </Wrapper>
        </Grid>
      </Container>
    </FullPageWrapper>
  );
}
