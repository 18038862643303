import React from "react";

import ArchivePetInsuranceList from "../../Components/Archive-Items/ArchivePetInsurance";
import Layout from "../../Components/Side bar/Layout";

export default function ArchivePetInsurance() {
  return (
    <Layout>
      <ArchivePetInsuranceList />
    </Layout>
  );
}
