import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getSelectedInsuranceByID } from "../../../Api/Services/PetInsuranceServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function ArchivePetInsuranceComponent() {
  const { id } = useParams();

  const [petData, setPetData] = useState();
  const navigate = useNavigate();
  const { data: insuranceData = [], isLoading } = useSWR(
    id ? `insuranceData${id}` : null,
    async () => {
      const { data } = await getSelectedInsuranceByID(id);
      const { data: petInfo } = await getPetByID(data?.data?.pet_id);
      setPetData(petInfo?.data);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    onBack: () => {
      navigate("/archive/pet-insurance");
    },
    label: "Archive Pet Insurance Information",
    isLoading: isLoading,
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "name",
      },
      {
        label: "Insurance provider name",
        value: "providerName",
      },
      {
        label: "MemberID",
        value: "memberId",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        name: petData?.name,
        providerName: insuranceData?.provider_name
          ? insuranceData?.provider_name.slice(0, 20)
          : "",
        memberId: insuranceData?.member_id,
      },
    ],
    detailsData: [
      [
        { name: "Member Name", value: insuranceData?.member_name },
        { name: "Subscriber Name", value: insuranceData?.subscriber_name },
        { name: "Phone", value: insuranceData?.provider_phone },
        { name: "Plan", value: insuranceData?.plan },
        { name: "Group", value: insuranceData?.group_name },
        {
          name: "Effective Date",
          value: formatDate(insuranceData?.effective_date),
        },
      ],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
