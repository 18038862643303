import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../Login/login.css";
import HttpCommon from "../../Utils/http-common";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import swal from "sweetalert";
import { Form, Formik } from "formik";
import { LoginAccountValidation } from "../../From/Validation";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import PasswordInput from "../Common/FormGroup/PasswordInput";
import ReactGA from "react-ga4";

const defaultValue = {
  email: "",
  password: "",
};

function Login() {
  const formRef = useRef();
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();


  const [initialValue, setInitialValue] = useState(defaultValue);

  useEffect(() => {
    if (localStorage.getItem("password") !== null) {
      setInitialValue({
        email: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
      });
    }
    setCheck(localStorage.getItem("checkbox") || false);
  }, []);

  //send data
  const handleSubmit = (Values, { setFieldError, setSubmitting }) => {
    HttpCommon.post("/user/userLogin", Values)
      .then((res) => {
        if (res.data.success === 1) {
          const user_id = res.data.user_id;
          const token = res.data.token;
          //window.location.href = `https://frontend.pawprintid.com/?id=${user_id}&token=${token}`;
          //window.location.href = `http://ec2-54-144-146-47.compute-1.amazonaws.com:8000/?id=${user_id}&token=${token}&subuser=false`;
          navigate(`/dashboard/?id=${user_id}&token=${token}&subuser=false`);
          ReactGA.gtag("event", "login_user", {
            user_id: user_id,
          });
          ReactGA.set({ userId: user_id });
        } else {
          if (res.data.message === "Invalid Email") {
            setFieldError("email", res.data.message);
            setSubmitting(false);
          } else {
            setFieldError("password", res.data.message);
            setSubmitting(false);
          }
        }
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          swal("Network Error!", "Failed to connect server.", "error");
          setSubmitting(false);
        }
      });
  };

  const handleMember = () => {
    navigate("/register");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="main">
      <div className="container">
        <Formik
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={LoginAccountValidation}
          // enableReinitialize={true}
          innerRef={formRef}
        >
          {({ getFieldProps, values, isSubmitting, errors }) => (
            <Form>
              <h1>Log In to your account</h1>
              <br />
              <FormGroup
                label="Username"
                name="email"
                className="loginField"
                svgPath={require("../../Assets/Images/user.png")}
              >
                <InputField
                  type="text"
                  name="email"
                  className="textarea"
                  {...getFieldProps("email")}
                />
              </FormGroup>
              <FormGroup
                label="Password"
                name="password"
                className="loginField"
                svgPath={require("../../Assets/Images/lock.png")}
              >
                <PasswordInput {...getFieldProps("password")} />
              </FormGroup>
              <div className="middle">
                <div className="left">
                  <input
                    type="checkbox"
                      id="check"
                    onChange={(e) => {
                      setCheck(e.target.checked);
                    }}
                    checked={check}
                    style={{ margin: 0 }}
                  />
                  <label htmlFor="check"> Remember Me</label>
                </div>
                <div className="right">
                  <label>
                    <a href="/forget-password">Forgot password?</a>
                  </label>
                </div>
              </div>

              {isSubmitting ? (
                <button
                  className="btn1"
                  style={{ display: "grid" }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  <Loader />
                </button>
              ) : (
                <button className="btn1" type="submit">
                  Log In
                </button>
              )}

              <p id="account">Not a member? Create an account below</p>
              <button className="btn12" onClick={handleMember}>
                Become Member
              </button>
              <a id="l4" href="/contactus">
                Need Help ?
              </a>
            </Form>
          )}
        </Formik>
        </div>
        </div>
    </>
  );
}

export default Login;

const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
