import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { isEqual } from "date-fns";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { getAllWorkspaceList } from "../../Api/Services/UserServices.js";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import { filterAscDescData, generateCustomId } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";
import { Button } from "@mui/material";

export default function Workspacelist() {
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [workspaceData, setWorkspaceData] = useState([]);

  const navigate = useNavigate();
  const fetchIdRef = useRef(0);

  const { data = [], isLoading } = useSWR(
    `workspace`,
    async () => {
      try {
        const { data } = await getAllWorkspaceList();
        return data?.data || [];
      } catch (error) {
        console.error('error: ', error);
        if (error?.response?.data?.message === "Access denied! Unautherized user.") {
          navigate(-1);
        }
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil(data?.length / limit));
        const allData = [...data];
        const filteredResult = filterAscDescData(allData, sort, from, limit);
        setWorkspaceData(filteredResult);
      }
    }
  }, [filter, data]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Organizations Name",
        accessor: "name",
        width: "15vw",
      },
      {
        Header: `Membership Number`,
        accessor: "membership_no",
        width: "12.5vw",
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <div style={{ display: "flex", height: "40px", alignItems: "center" }}>
              <Button
                sx={{
                  minWidth: "40px",
                  background: "rgba(255, 133, 51, 0.20) !important",
                  borderRadius: "10px",
                  marginRight: "5px",
                  width: "24px",
                }}
                onClick={() => navigate(props.viewDataLink(row.original))}
              >
                <img
                  alt="view"
                  src={viewIcon}
                  style={{
                    color: "#000000",
                    cursor: "pointer",
                  }}
                />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  const defaultOptions = {
    pageSize: 10,
  };

  const props = {
    label: "Organizations Information",
    isLoading: isLoading,
    columns: columns,
    columnsData: workspaceData,
    viewDataLink: (data) => `/workspace-information/info/${data.id}`,
    placeholder: "pet name",
    details: "There are no Workspace details found.",
    isSelectHide: true,
    AddPetArchive: true,
    fetchData,
    pageCount,
    defaultOptions,
  };

  return (
    <>
      <PawItemList {...props} />
    </>
  );
}
