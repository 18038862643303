import React from "react";

import ArchiveImmunizationList from "../../Components/Archive-Items/ArchiveImmunization";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveImmunization() {
  return (
    <Layout>
      <ArchiveImmunizationList />
    </Layout>
  );
}
