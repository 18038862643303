import React from "react";

import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Typography } from "@mui/material";

function ViewMembershipDetail({ data, loading }) {
  return (
    <CustomTable>
      <CustomTableHeader>
        <Box sx={{ fontSize: "18px", fontWeight: 600 }}>Membership Details</Box>
      </CustomTableHeader>
      <Box sx={{ padding: "10px 20px" }}>
        {loading ? (
          <CircularProgress style={{ textAlign: "center", color: "black" }} />
        ) : data?.length ? (
          data?.map((item, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: { xs: "15px", sm: "20px", md: "50px" },
                }}
              >
                <Typography sx={TextStyle}>{item?.name}</Typography>
                <Typography sx={TextStyle}>{item?.value || "NA"}</Typography>
              </Box>
            );
          })
        ) : (
          <p style={{ textAlign: "center" }}>No data Found</p>
        )}
      </Box>
    </CustomTable>
  );
}

export default ViewMembershipDetail;

const CustomTable = styled("div")(() => ({
  border: "1px solid #FF8533",
  width: "100%",
  borderRadius: "10px",
  margin: "20px 0px 10px 0px",
  height: "auto",

  "@media (max-width: 900px)": {
    width: "100% !important",
  },
}));

const CustomTableHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  background: "rgba(255, 133, 51, 0.20)",
  padding: "10px 20px",
  borderBottom: "1px solid #FF8533",
}));

const TextStyle = {
  color: "black",
  fontSize: "16px",
  fontWeight: 400,
  paddingTop: "5px",
};
