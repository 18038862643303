import React from "react";
import "../../Assets/Styles/AboutUs/Step.css";
import styled from "styled-components";

function Steps(props) {
  return (
    <div className="StepWrapper">
      {/* <h2 className="step">{props.buttonText}</h2> */}
      <Wrapper>
        <img className="stepImage" src={props.stepImage} alt="stepimg" />
      </Wrapper>
      {/* <h3>{props.Header}</h3> */}
      <button className="StepButton">
        <a href={props.ButtonLink}>{props.Button}</a>
      </button>
    </div>
  );
}

const Wrapper = styled.div`
  padding: 10px 0px 0px 0px;
  // height: 15vw;
  .stepImage {
    object-fit: cover;
    width: auto;
  }
  /* @media (min-width: 934px) {
    height: 15vw;
  }
  @media (min-width: 933px) {
    height: 9vw;
  } */
  @media (max-width: 1025px) {
    height: 15vw;
  }
`;

export default Steps;
