import { jsonToQueryString } from "../../Utils";
import { api, ImageAPI } from "../axios";

// pet information item list

export const getPetInfoList = async () => {
  const res = await api.get(`/pet/getAllPets`);
  return res;
};


export const getPetAddress = async (id) => {
  const res = await api.get(`/pet_address/getPetAddressById/${id}`);
  return res;
};

//get pet name according to w_id
export const getPetNameByWorkspaceId = async (id) => {
  const res = await api.get(`/pet/getPetIdPetNameByWorkspaceId/${id}`);
  return res;
};

// pet information item list
export const getAllPetsByWorkspaceId = async (w_id) => {
  const res = await api.get(`/pet/getAllPetsByWorkspaceId/${w_id}`);
  return res;
};

export const getPetInformation = async () => {
  const res = await api.get(`/pet/getAllPets`);
  return res;
};

// pet information
export const createNewPet = async (formData) => {
  const res = await api.post(`/pet/create`, formData);
  return res;
};

export const getPetNamesByWorkspaceId = async (id) => {
  const res = await api.get(`/pet/getPetIdPetNameByWorkspaceId/${id}`);
  return res;
};
export const updatePetInfoById = async (id, formData) => {
  const res = await api.put(`/pet/updatePet/${id}`, formData);
  return res;
};
export const updatePetImageById = async (id, formData) => {
  const res = await ImageAPI.put(`/pet/updateImage/${id}`, formData);
  return res;
};

export const createSignedUrl = async (data) => {
  const res = await api.post(`/pet/createSignedUrl`, data);
  return res;
};

export const updatePetNameEContact = async (id, formData) => {
  const res = await api.put(`/pet/updatePet/${id}`, formData);
  return res;
};

export const getAllPetNamesByUserId = async (id) => {
  try {
    const res = await api.get(`/pet/getAllPetsByUserId/${id}`);
    const petNames = res.data.data.map((pet) => pet.name);
    return petNames;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPetByID = async (id) => {
  const res = await api.get(`/pet/getPetById/${id}`);
  return res;
};

export const getPetNameByWorkspaceIdPres = async (w_id) => {
  const res = await api.get(`/pet/getPetIdPetNameByWorkspaceId/${w_id}`);
  return res;
};

//Delete pet
export const deletePetInfo = async (id) => {
  const res = await api.delete(`/pet/deletePet/${id}`);
  return res;
};

// pet archive
export const setArchieve = async (id) => {
  const res = await api.post(`/pet/setArchieve/${id}`);
  return res;
};
// pet unarchive
export const setPetUnarchive = async (id) => {
  const res = await api.post(`/pet/setunArchieve/${id}`);
  return res;
};

//archieve pet info
export const getArchivePetInfoByWorkspaceID = async (id) => {
  const res = await api.get(`/pet/getAllArchievePetsByWorkspaceId/${id}`);
  return res;
};

// get archive pet names by user id
export const getArchivePetNamesByWorkspaceID = async (w_id) => {
  const res = await api.get(`/pet/getArchievePetIdPetNameByWorkspaceId/${w_id}`);
  return res;
};
