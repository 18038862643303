import React, { useEffect } from "react";

import { Box, CircularProgress } from "@mui/material";
import swal from "sweetalert";
import {
  getUserWorkspaceList,
  selectUserWorkspace,
} from "../../Api/Services/UserServices";
import {
  MEMBERSHIP_NUMBER_KEY,
  PAWPRINT_PERMISSION_KEY,
  PAWPRINT_USER_ROLE_KEY,
  PET_OWNER_ID_KEY,
  TOKEN_KEY,
  USERID_KEY,
  WORKSPACEID_KEY,
} from "../../Constant";
import useAuth from "../../ContextProvider/AuthProvider";
import { useNavigate } from "react-router-dom";

export const LandingPg = () => {
  const navigate = useNavigate();
  const { petOwnerId, user } = useAuth();
  const _token = localStorage.getItem(TOKEN_KEY);
  const queryParameters = new URLSearchParams(window.location.search);
  const user_id = queryParameters.get("id");
  const token = queryParameters.get("token");

  const checkWorkspace = async () => {
    try {
      if (user_id) {
        const { data } = await getUserWorkspaceList(user_id);
        if (data?.data?.length === 1) {
          const workspaceData = {
            userId: user_id,
            w_id: data?.data?.[0]?.w_id,
          };
          const res = await selectUserWorkspace(workspaceData);
          if (res?.data?.message === "This Workspace Membership has expired") {
            navigate("/account");
            swal("Plan Expired!", res?.data?.message, "error");
            localStorage.clear();
            return;
          }
          localStorage.setItem(TOKEN_KEY, res?.data?.data?.token);
          localStorage.setItem(USERID_KEY, res?.data?.data?.userData?.id);
          localStorage.setItem(
            PAWPRINT_PERMISSION_KEY,
            res?.data?.data?.permission
          );
          localStorage.setItem(
            PAWPRINT_USER_ROLE_KEY,
            res?.data?.data?.role_name
          );
          localStorage.setItem(PET_OWNER_ID_KEY, res?.data?.data?.userData?.id);
          localStorage.setItem(WORKSPACEID_KEY, res?.data?.data?.workspace_id);
          localStorage.setItem(
            MEMBERSHIP_NUMBER_KEY,
            res?.data?.data?.workspace?.membership_no
          );
          if (res?.data?.data?.userData?.isSuperUser === 'true') {
            window.location.href = "/workspace-information";
            return;
          }
          window.location.href = "/user-information";
        } else {
          window.location.href = "/selectWorkspace";
        }
      } else if (petOwnerId) {
        if (user?.isSuperUser === 'true') {
          window.location.href = "/workspace-information";
          return;
        }
        // navigate("/user-information");
        window.location.href = "/user-information";
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getUserDetails = async (user_id, token) => {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USERID_KEY, user_id);
    await checkWorkspace();
  };

  useEffect(() => {
    if (user_id !== null && token !== null) {
      getUserDetails(user_id, token);
    } else if (petOwnerId) {
      getUserDetails(petOwnerId, _token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, token]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress style={{ color: "black" }} />
    </Box>
  );
};
