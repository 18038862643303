import React from "react";
import styled from "styled-components";

export default function BusinessBodyCard(props) {
  return (
    <BusinessCardWrapper>
      <div className="BusinessCard" style={{ color: props.color }}>
        <img src={props.image} alt="icon" />
        <h4>{props.text}</h4>
      </div>
    </BusinessCardWrapper>
  );
}

export const BusinessCardWrapper = styled.div`
  width: 100%;

  /* @media (max-width: 1200px) {
    width: 100%;
    margin: 0 14px;
    gap: 2.5vw;
  } */
  @media (max-width: 1200px) {
    width: 100%;
    gap: 3vw;
  }
  @media (max-width: 900px) {
     width: 100%;
     grid-template-columns:repeat(1,1fr);
  }

  .BusinessCard {
    display: flex;
    background: rgba(255, 255, 255, 0.3);
    mix-blend-mode: normal;
    border: 3px dashed;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    height: 120px;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.5s;
    width: 100%;
    height: 130px;

    :hover{
    cursor: pointer;
    transform: scale(1.05); 
  }
  @media (max-width: 1655px) { width: 100%; height: 120px; }
  @media (max-width: 1455px) { width: 100%; height: 120px; }
  @media (max-width: 1385px) { width: 100%; height: 120px; }
  @media (max-width: 1300px) { width: 100%; height: 120px; }
  @media (max-width: 1210px) { width: 100%; height: 110px; }
  @media (max-width: 1010px) { width: 100%; height: 110px; }
  @media (max-width: 905px) { width: 100%; height: 120px; }
  @media (max-width: 900px) { width: 100%; height: 110px; margin: 10px auto; }
  @media (max-width: 650px) { width: 100%; height: 100px; }
  @media (max-width: 470px) { width: 350px; height: 100px; }
  @media (max-width: 400px) { width: 300px; height: 100px; }
  @media (max-width: 350px) { width: 230px; height: 145px; }
  @media (max-width: 250px) { width: 200px; height: 155px; margin-bottom:10px;}
}
  .BusinessCard img {
    width: 75px;
    height: 75px;
    padding: 10px;
    @media (max-width: 1455px) { width: 67px; height: 67px; }
    @media (max-width: 1200px) { width: 63px; height: 63px; padding: 5px; }
    @media (max-width: 450px) { width: 53px; height: 53px; }
  }
  .BusinessCard h4 {
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.011em;
    word-spacing: 0.15em;
    padding: 10px;
    width: 337px;
    @media (max-width: 1455px) { font-size: 20px; }
    @media (max-width: 1200px) { font-size: 18px; padding: 5px; }
    @media (max-width: 450px) { font-size: 15px; }
  }
`;
