import React from "react";


import UserInformation from "../../Components/Item-List/UserInformation";
import Layout from "../../Components/Side bar/Layout";

const ItemListUserInformation = () => {

  return (
    <Layout>
      <UserInformation />
    </Layout>
  );
};

export default ItemListUserInformation;

