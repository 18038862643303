import { api, ImageAPI } from "../axios";

export const getDocument = async (id) => {
  const res = await api.get(`/document/documentGetById/${id}`);
  return res;
};

export const getAllItemListDocument = async () => {
  const res = await api.get(`/document/getAllDocument`);
  return res;
};

export const getAllDocuments = async () => {
  const res = await api.get(`/document/getAllDocument`);
  return res;
};

export const getDocumentByWorkspaceID = async (id) => {
  const res = await api.get(`/document/getDetailsByWorkspaceId/${id}`);
  return res;
};

//document
export const createNewDocument = async (formData) => {
  const res = await ImageAPI.post(`/document/createDocument`, formData);
  return res;
};

export const getbyIDDocument = async (id) => {
  const res = await api.get(`/document/getDocumentPetNameById/${id}`);
  return res;
};
export const getbyIDDocument2 = async (id) => {
  const res = await api.get(`/document/getDetailsByUserId/${id}`);
  return res;
};
export const getDetailsByUserId = async (id) => {
  const res = await api.get(`/document/getDetailsByUserId/${id}`);
  return res;
};
export const UpdateDocumentById = async (id, formData) => {
  const res = await ImageAPI.put(`/document/updateDocument/${id}`, formData);
  return res;
};

export const getDocumentByID = async (id) => {
  const res = await api.get(`/document/documentGetById/${id}`);
  return res;
};

//Delete Document
export const DeleteDocumet = async (id) => {
  const res = await api.delete(`/document/deleteDocument/${id}`);
  return res;
};

//archieve document
export const getArchiveDocumentByWorkspaceID = async (id) => {
  const res = await api.get(`/document/getArchieveDetailsByWorkspaceId/${id}`);
  return res;
};

//set document archive
export const setDocumentArchieve = async (id) => {
  const res = await api.post(`/document/setArchieve/${id}`);
  return res;
};
// document unarchive
export const setDocumentUnarchive = async (id) => {
  const res = await api.post(`/document/setunArchieve/${id}`);
  return res;
};