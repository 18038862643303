import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { getArchivePetNamesByWorkspaceID } from "../../Api/Services/PetServices.js";
import {
  getArchiveVetByWorkspaceID,
  setVetUnarchive,
} from "../../Api/Services/VetServices.js";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from "../../ContextProvider/AuthProvider";
import { filterAscDescData, formatPhoneNumber } from "../../Utils.js";
import DetailsModal from "../Common/DetailsModal.js";
import PawItemList from "../Common/PawItemList.js";

import "./ArchiveGlobalItemList.css";
import { Button } from "@mui/material";

export default function ArchiveVet() {
  const { workspaceId: w_id } = useAuth();
  let navigate = useNavigate();
  const [selectedPetName, setSelectedPetName] = useState("");
  const [open, setOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [archivePetVetId, setArchivePetVetId] = useState(null);
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [vetData, setVetData] = useState([]);
  const fetchIdRef = useRef(0);
  // Get the user id from local storage
  const { petOwnerId: user_id } = useAuth();

  const { data: data, isLoading } = useSWR(
    user_id ? `archiveVetdata${user_id}` : null,
    async () => {
      const { data } = await getArchiveVetByWorkspaceID(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          ...item,
          id: item?.petvet_id,
          primary_phone_number: formatPhoneNumber(item?.primary_phone_number),
        };
      });
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil(data?.length / limit));
        const allData = [...data];
        const filteredResult = filterAscDescData(allData, sort, from, limit);
        setVetData(filteredResult);
      }
    }
  }, [filter, data]);

  const { data: petNames = [] } = useSWR(
    user_id ? `petNames${user_id}` : null,
    async () => {
      const { data } = await getArchivePetNamesByWorkspaceID(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  let filteredData;

  if (selectedPetName === "") {
    filteredData = vetData;
  } else if (!vetData) {
    filteredData = [];
  } else {
    filteredData = vetData?.filter((item) => {
      const petName = item.name || "";
      const query =
        selectedPetName && selectedPetName.length > 0
          ? selectedPetName[0].toLowerCase()
          : "";
      return petName.toLowerCase().includes(query);
    });
  }

  const petNamesSet = new Set();
  const filteredPetNames = petNames.filter((pet) => {
    const lowercaseName = pet.name.toLowerCase();
    if (!petNamesSet.has(lowercaseName)) {
      petNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const handleArchiveClick = (petvet_id) => {
    setShowSuccessMessage(true);
    setOpen(true);
    setArchivePetVetId(petvet_id);
  };
  const handleArchive = () => {
    setIsUpdating(true);

    setVetUnarchive(archivePetVetId)
      .then((response) => {
        setIsUpdating(false);
        if (response.data.success === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setIsUpdating(false);
      });
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "Pet Name",
        accessor: "name",
        width: "12.5vw",
      },
      {
        Header: `Type`,
        accessor: "type",
        width: "12.5vw",
      },
      {
        Header: "Veterinarian",
        accessor: "vet_practice_name",
        width: "12.5vw",
      },
      {
        Header: "Phone Number",
        accessor: "primary_phone_number",
        width: "12.5vw",
      },
      {
        Header: "",
        accessor: "petvet_id",
        width: "8vw",
        isArchive: true,
        disableSortBy: true,
        disabled: isUpdating,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <UnarchiveIcon
              style={{
                color: "#000000",
              }}
              onClick={() => handleArchiveClick(row?.original?.petvet_id)}
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                marginRight: "5px",
                width: "24px",
              }}
              onClick={() => navigate(props.viewDataLink(row.original))}
            >
              <img
                alt="view"
                src={viewIcon}
                style={{
                  color: "#000000",
                  cursor: "pointer",
                }}
              />
            </Button>
          );
        },
      },
    ];
  }, []);

  const defaultOptions = {
    pageSize: 5,
  };

  const props = {
    label: "Archive Vets and Therapists",
    setSelectedUserName: setSelectedPetName,
    items: filteredPetNames,
    isLoading,
    dataAddLink: "/archive",
    columns,
    columnsData: filteredData,
    viewDataLink: (data) => `/archive/vet-and-therapist-info/${data.petvet_id}`,
    placeholder: "pet name",
    details: "There are no vet and therapist details for this user.",
    isArchive: true,
    AddPetArchive: true,
    fetchData,
    pageCount,
    defaultOptions,
  };

  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        handleArchive();
        setShowSuccessMessage(false);
      },
    },
    {
      name: "no",
      onClick: () => {
        setOpen(false);
        setShowSuccessMessage(false);
      },
    },
  ];

  return (
    <>
      <PawItemList {...props} />
      {showSuccessMessage && (
        <>
          <DetailsModal
            open={open}
            severity="warning"
            buttonList={ButtonItem}
            title="Do you want to unarchive this vet therapist?"
            handleClose={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
}
