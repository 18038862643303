import React, { useEffect, useState, useRef } from "react";

import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  createSubUser,
  getCarearsByUserId,
  updateUserImageById,
} from "../../../Api/Services/UserServices";
import { PET_OWNER_ID_KEY, WORKSPACEID_KEY } from "../../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  CancelButton,
  ButtonBoxStyle,
} from "../../../Css";
import { UserFormValidation } from "../../../From/Validation";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import ImageUpload from "../../Common/ImageUpload";
import InputField from "../../Common/InputField";
import PasswordField from "../../Common/PasswordField";
import CustomPhoneInput from "../../Common/PhoneInput";
import CustomSnackbar from "../../Common/Snackbar";
import "react-phone-input-2/lib/style.css";
import { uploadS3Bucket } from "../../../Api/axios";

const defaultValue = {
  first_name: "",
  last_name: "",
  email: "",
  role_id: "",
  password: "",
  membership_no: "",
  petowner_id: localStorage.getItem("pawprint_user_id"),
  phone_number: "",
  confPassword: "",
};

export default function UserForm() {
  let navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const membership_id = localStorage.getItem("membership_id");
  const user_id = localStorage.getItem(PET_OWNER_ID_KEY);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);

  const [image, setImage] = useState(null);
  // Validations
  const formikRef = useRef(null);
  useEffect(() => {
    getCarearsByUserId(w_id, user_id)
      .then((res) => {
        const usersArray = res.data;
        const numberOfUsers = usersArray.data.length;

        let membership_no;
        let maxMembershipNo = 0;
        // If no users exist, append "001" to the membership ID
        if (numberOfUsers === 0) {
          membership_no = `${membership_id}-1-001`;
        } else {
          // Find the maximum membership number among existing users
          for (const user of usersArray.data) {
            //console.log(`membership no: ${user.membership_no}`);
            const userMembershipNo = user.membership_no;
            //console.log(`userMembershipNo: ${userMembershipNo}`);
            if (userMembershipNo) {
              const [, , , , number] = userMembershipNo.split("-");
              const parsedNumber = parseInt(number, 10);
              maxMembershipNo = Math.max(maxMembershipNo, parsedNumber);
            }
          }
        }
        // Increment the maximum membership number and append it to the membership ID
        const nextNumber = (maxMembershipNo + 1).toString().padStart(3, "0");
        membership_no = `${membership_id}-1-${nextNumber}`;
        formikRef?.current?.setFieldValue("membership_no", membership_no);
        return membership_no;
      })
      .catch((err) => {
        console.error(err);
      });
  }, [membership_id, user_id, w_id]);

  const sendUserData = async (
    values,
    setSubmitting,
    setFieldError,
    setFieldValue
  ) => {
    const newUserData = {
      ...values,
      w_id: w_id,
    };
    delete newUserData?.confPassword;
    try {
      const res = await createSubUser(newUserData);
      if (res?.data?.message === "This email already exist.") {
        setSubmitting(false);
        setFieldError("email", "This email already exist.");
        return "This email already exist.";
      }
      if (image && res?.data?.data?.id) {
        try {
          const updateData = {
            type: image?.type,
            file_name: `${Date.now()}-${image?.name}`
          };
          const res2 = await updateUserImageById(res.data.data.id, updateData); // pass user ID to updateUserImageById function
          const url = res2?.data?.url;
          const decodedUrl = decodeURIComponent(url);
          const uploadRes = await uploadS3Bucket(decodedUrl, image);
          if (uploadRes?.status === 200) {
            console.log("success", uploadRes)
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
  const borderColor =
    formikRef?.current?.values?.role_id !== "" &&
    formikRef?.current?.touched?.role_id &&
    formikRef?.current?.errors?.role_id
      ? "#d32f2f"
      : "#FF8533";
  const styles = {
    radio: {
      "& .MuiRadio-root": {
        color: `${borderColor}`,
        "&.Mui-checked": {
          color: `${borderColor}`,
        },
      },
    },
  };
  const sendData = async (values, { setSubmitting, setFieldError }) => {
    try {
      const userRes = await sendUserData(values, setSubmitting, setFieldError);
      if (userRes === "This email already exist.") {
        return;
      }
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout title="User Information">
      <Formik
        initialValues={defaultValue}
        innerRef={formikRef}
        onSubmit={sendData}
        validationSchema={UserFormValidation}
        validateOnMount={false}
      >
        {({ getFieldProps, values, isSubmitting, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  User Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <ImageUpload
                    alt="Uploaded_Image"
                    id="imageInput"
                    image={image}
                    setImage={setImage}
                    accept="image/*"
                    width="150px"
                    height="150px"
                  />
                </Box>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="first_name"
                          label={"First Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("first_name")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="last_name"
                          label={"Last Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("last_name")} />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column style={{ gap: "21px", height: "max-content" }}>
                    <FormGroup
                      name="email"
                      label={"Email Address"}
                      requiredField
                    >
                      <InputField {...getFieldProps("email")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="phone_number" label={"Phone Number"}>
                      <CustomPhoneInput {...getFieldProps("phone_number")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="role_id" label={"Role"} requiredField>
                      <RadioGroup
                        name="role_id"
                        {...getFieldProps("role_id")}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          color: "white",
                        }}
                        sx={styles.radio}
                      >
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="General Authorized User"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "0.9rem",
                              color: "black",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="4"
                          control={<Radio />}
                          label="View Only User"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "0.9rem",
                              color: "black",
                            },
                          }}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Column>
                  <Column style={{ height: "max-content" }}>
                    <Box sx={newSmallFieldBoxStyle}>
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="password"
                          label={"Password"}
                          requiredField
                        >
                          <PasswordField {...getFieldProps("password")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="confPassword"
                          label={"Confirm Password"}
                          requiredField
                        >
                          <PasswordField
                            {...getFieldProps("confPassword")}
                            onCut={(e) => e.preventDefault()}
                            onCopy={(e) => e.preventDefault()}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <CustomButton
                  label="Cancel"
                  type="button"
                  style={CancelButton}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  label={isSubmitting ? "Saving..." : "Save"}
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New user has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/user-information");
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
