import React from "react";

function TermConditionC() {
  return (
    <div style={pageStyles}>
      <h1 style={headerStyles}>Terms and Conditions</h1>
      <div style={contentStyles}>
        <p>
          Please read these Terms & Conditions carefully before accessing or
          using our website. By accessing or using pawprintID® (or permitting
          others to access or use pawprintID®), you acknowledge your
          understanding and agreement with these Terms & Conditions. This
          agreement supersedes ANY prior agreement or understanding between you
          and pawprintID®. We may modify these Terms & Conditions from time to
          time, and such modifications will be effective immediately upon
          posting of the modified Terms & Conditions on our website. By
          continuing to access or use the website, you acknowledge your
          understanding and agreement with the modified Terms & Conditions.
          Therefore, it is your obligation to access and review the Terms &
          Conditions frequently.
        </p>
      </div>
    </div>
  );
}

export default TermConditionC;

const pageStyles = {
  maxWidth: "800px",
  margin: "0 auto",
  padding: "20px",
  fontFamily: "Arial, sans-serif",
};

const headerStyles = {
  fontSize: "24px",
  textAlign: "center",
  marginBottom: "20px",
};

const contentStyles = {
  fontSize: "16px",
  lineHeight: "1.6",
};
