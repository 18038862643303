import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  deleteJournal,
  getJounalHistoryById,
  getJournalById,
} from "../../../Api/Services/JournalServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { PAWPRINT_USER_ROLE_KEY } from '../../../Constant'
import { addItemtoObject, formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function JournalInfo() {
  const { id } = useParams();
  const [petInfo, setPetInfo] = useState();
  const navigate = useNavigate();

  const { data: journalInfo, isLoading: IsJournalInfoLoading } = useSWR(
    id ? `journalInfo${id}` : null,
    async () => {
      const { data } = await getJournalById(id);
      const petID = data?.data?.petid;

      const { data: petRes } = await getPetByID(petID);
      const petData = petRes?.data;
      setPetInfo(petData);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: journalHistory } = useSWR(
    id ? `journalHistory${id}` : null,
    async () => {
      try {
        const { data } = await getJounalHistoryById(id);
        const propertiesToUpdate = ['pet_name'];
        const propertiesToDelete = [ 'id', 'petid' ];
        const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
        return filteredData || [];
      } catch (error) {
        console.error('error: ', error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await deleteJournal(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Journal",
    isLoading: IsJournalInfoLoading,
    onBack: () => {
      navigate("/journal");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Date",
        value: "date",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        date: formatDate(journalInfo?.date),
      },
    ],
    detailsData: [[{ name: "User", value: journalInfo?.user },
    { name: "Notes", value: journalInfo?.note }]],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/journal/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Journal History",
    historyColumnsData: journalHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} maxLength={1000} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
