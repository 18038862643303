import React, { useState, useEffect, useRef } from "react";

import { Typography, Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  createEmergencyContact,
  getEmergencyContactById,
  getidfnamebyWorkspace,
} from "../../../Api/Services/EmergencyServices";
import { getPetNameByWorkspaceId } from "../../../Api/Services/PetServices";
import useAuth from "../../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  CancelButton,
  ButtonBoxStyle,
} from "../../../Css";
import { EmergencyFromValidation } from "../../../From/Validation";
import { relationshipOptions } from "../../../Options";
import { getCountryAsOption, getStateAsOptions } from "../../../Utils";
import AutoComplete from "../../Common/AutoComplete";
import AutoCompleteAddress from "../../Common/AutoCompleteAddress";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import InputField from "../../Common/InputField";
import CustomPhoneInput from "../../Common/PhoneInput";
import CustomSnackbar from "../../Common/Snackbar";
import "react-phone-input-2/lib/style.css";
import "./form.css";

const defaultValue = {
  zip_code: "",
  street_address: "",
  street_address_2: "",
  apt_city: "",
  state: "",
  country: "",
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  relationship: "",
  secondary_number: "",
  user_id: "",
  pet_id: "",
};

export default function EmergencyForm() {
  const { workspaceId: w_id } = useAuth();
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(defaultValue);
  const formikRef = useRef(null);

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [readonly, setReadonly] = useState(false);
  const { petOwnerId: petowner_id } = useAuth();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [nameOptions, setNameOptions] = useState([]);

  const [fnameOptions, setFnameOptions] = useState([]);
  const handlefname = (e, newvalue) => {
    const selectedValue = newvalue?.value;

    if (selectedValue === "Add a new first name") {
      // setShVet(true);
      setInitialValue({
        ...formikRef?.current?.values,
        zip_code: "",
        street_address: "",
        street_address_2: "",
        apt_city: "",
        state: "",
        country: "",
        email: "",
        phone_number: "",
        first_name: "",
        last_name: "",
        secondary_number: "",
      });
      setReadonly(false);
    } else {
      setReadonly(true);
      getEmergencyContactById(selectedValue)
        .then((res) => {
          const data = res.data.data[0];
          if (!data) {
            setInitialValue(defaultValue);
          } else {
            formikRef.current.setFieldValue("first_name", data?.first_name);
            setInitialValue({
              ...formikRef?.current?.values,
              user_id: petowner_id || "",
              zip_code: data.zip_code,
              street_address: data.street_address,
              street_address_2: data.street_address_2,
              apt_city: data.apt_city,
              state: data.state,
              country: data.country,
              email: data.email,
              phone_number: data.phone_number,
              first_name: data.first_name,
              last_name: data.last_name,
              secondary_number: data.secondary_number,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const sendEmergencyContact = async (values) => {
    try {
      const res = await createEmergencyContact(values);
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const sendEmergencyData = async (values) => {
    const data = {
      ...values,
      first_name: values?.first_name,
      w_id: w_id,
      // user_id: petowner_id || "",
    };
    setIsSubmiting(true);
    try {
      const EmContactRes = await sendEmergencyContact(data);
      setShowSuccessMessage(true);
      setOpen(true);
      setIsSubmiting(false);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  useEffect(() => {
    getPetNameByWorkspaceId(w_id)
      .then((res) => {
        const petnames = res.data.data.map((pet) => {
          return {
            value: pet?.id,
            label: pet?.name,
          };
        });
        if (petnames.length === 0) {
          // Handle empty array case
          console.log("No pet names found for user ID:", petowner_id);
        } else {
          setNameOptions(petnames);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const country = getCountryAsOption();
    setCountryOptions(country);
  }, []);

  //to get all details
  useEffect(() => {
    getidfnamebyWorkspace(w_id)
      .then((res) => {
        let emvalues = [];
        if (res?.data?.data) {
          emvalues = res?.data?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.first_name || "",
            };
          });
        }
        emvalues?.push({
          value: "Add a new first name",
          label: "Add a new first name",
        });
        if (emvalues?.length === 0) {
          // Handle empty array case
          console.log("No names found");
        } else {
          setFnameOptions(emvalues);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOptions(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  return (
    <FormLayout title="Emergency Contact Information">
      <Formik
        initialValues={initialValue}
        innerRef={formikRef}
        onSubmit={sendEmergencyData}
        validationSchema={EmergencyFromValidation}
        enableReinitialize
        validateOnMount={false}
      >
        {({ getFieldProps, values, setFieldValue }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Emergency Contact Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%"}}>
              <Body style={{ gap: "10px 20px" }}>
                <Column>
                  <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                    <AutoComplete
                      {...getFieldProps("pet_id")}
                      name="pet_id"
                      options={nameOptions}
                      placeholder="Select a Pet Name"
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="relationship"
                    label={"Relationship"}
                    requiredField
                  >
                    <AutoComplete
                      {...getFieldProps("relationship")}
                      name="relationship"
                      options={relationshipOptions}
                      placeholder="Select a Relationship"
                      otherType="Other"
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="first_name" label="First Name" requiredField>
                    <AutoComplete
                      freeSolo
                      {...getFieldProps("first_name")}
                      options={fnameOptions}
                      placeholder="Select a First Name"
                      otherType="Add a new first name"
                      isOnChange
                      onChange={handlefname}
                      onInputChange={(e, value) =>
                        setFieldValue("first_name", value)
                      }
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="last_name" label={"Last Name"} requiredField>
                    <InputField
                      {...getFieldProps("last_name")}
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="phone_number"
                    label={"Primary Phone Number"}
                    requiredField
                  >
                    <CustomPhoneInput
                      {...getFieldProps("phone_number")}
                      onChange={(value) => setFieldValue("phone_number", value)}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="secondary_number"
                    label={"Secondary Phone Number"}
                  >
                    <CustomPhoneInput
                      {...getFieldProps("secondary_number")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="email" label={"Email Address"} requiredField>
                    <InputField
                      {...getFieldProps("email")}
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="street_address" label={"Street Address 1"}>
                    <AutoCompleteAddress
                      formikRef={formikRef}
                      setFieldValue={setFieldValue}
                      value={values?.street_address}
                      address="street_address"
                      city="apt_city"
                      state="state"
                      zipcode="zip_code"
                      country="country"
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="street_address_2" label={"Street Address 2"}>
                    <InputField
                      {...getFieldProps("street_address_2")}
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="country" label={"Country"}>
                    <AutoComplete
                      {...getFieldProps("country")}
                      name="country"
                      options={countryOptions}
                      placeholder="Select a country"
                      onChange={handleCountryChange}
                      readOnly={readonly}
                      isOnChange
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="state" label={"State"}>
                    <AutoComplete
                      {...getFieldProps("state")}
                      name="state"
                      otherType="Other"
                      options={stateOptions}
                      placeholder={"Select a type"}
                      readOnly={readonly}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="apt_city" label={"City"}>
                    <InputField
                      {...getFieldProps("apt_city")}
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="zip_code" label={"Zip Code"}>
                    <InputField
                      {...getFieldProps("zip_code")}
                      InputProps={{
                        readOnly: readonly,
                      }}
                    />
                  </FormGroup>
                </Column>
              </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmiting ? "Submitting..." : "Submit"}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet emergency contact has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/emergency-contact");
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
