import React from "react";

import ArchiveEmergencyItem from "../../Components/Archive-Items/ArchiveEmergencyContact";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveEmergency() {
  return (
    <Layout>
      <ArchiveEmergencyItem />
    </Layout>
  );
}
