import { api } from "../axios";

export const getAllergy = async (id) => {
  const res = await api.get(`/allergy/getAllergyById/${id}`);
  return res;
};

export const getAllItemListAllergy = async () => {
  const res = await api.get(`/allergy/getAllAllergy`);
  return res;
};

//ItemList
export const getAllergyByWorkspaceID = async (id) => {
  const res = await api.get(`/allergy/getDataByWorkspaceId/${id}`);
  return res;
};

//allergy
export const getAllergyById = async (id) => {
  const res = await api.get(`/allergy/getAllergyById/${id}`);
  return res;
};
export const CreateAllergy = async (formData) => {
  const res = await api.post(`/allergy/createAllergy`, formData);
  return res;
};
export const UpdateAllergyById = async (id, formData) => {
  const res = await api.put(`/allergy/updateAllergy/${id}`, formData);
  return res;
};

//selected item list
export const getAllergyByID = async (id) => {
  const res = await api.get(`/allergy/getAllergyById/${id}`);
  return res;
};

//delete
export const deleteAllergy = async (id) => {
  const res = await api.delete(`/allergy/deleteAllergy/${id}`);
  return res;
};

//archieve allergy
export const getArchiveAllergyByWorkspaceID = async (id) => {
  const res = await api.get(`/allergy/getArchieveDataByWorkspaceId/${id}`);
  return res;
};

//set allergy archive
export const setAllergyArchieve = async (id) => {
  const res = await api.post(`/allergy/setArchieve/${id}`);
  return res;
};
// allergy unarchive
export const setAllergyUnarchive = async (id) => {
  const res = await api.post(`/allergy/setunArchieve/${id}`);
  return res;
};

