import React, { useState } from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import styled from "styled-components";
import DetailsModal from "../Common/DetailsModal";
import ReactGA from "react-ga4";

const Logout = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  function handledelete() {
    setShowSuccessMessage(true);
    setOpen(true);
  }
  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        setShowSuccessMessage(false);
        localStorage.clear();
        window.location.href = `${window?.location?.origin}/account`;
        ReactGA.set({ userId: "" });
      },
    },
    {
      name: "no",
      onClick: () => {
        setOpen(false);
        setShowSuccessMessage(false);
      },
    },
  ];
  return (
    <LogoutWrapper>
      <LogoutIcon
        sx={{ color: "#FF8533", fontSize: {sm: '30px', md: "50px" } }}
        cursor="pointer"
        onClick={handledelete}
        // onClick={() => {
        //   localStorage.clear();
        //   window.location.href =
        //     "http://ec2-35-153-232-149.compute-1.amazonaws.com:8000/";
        // }}
      />
      {showSuccessMessage && (
        <>
         <DetailsModal
          label="Do you want to log out of this app?"
          open={open}
          severity="warning"
          buttonList={ButtonItem}
        />
        </>
      )}
    </LogoutWrapper>
  );
};

export default Logout;

const LogoutWrapper = styled.div`
  // position: absolute;
  // right: 50px;
  // bottom: 20px;
  display: flex;
  justify-content: end;
  padding: 0 20px 20px 0;
`;
