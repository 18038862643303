import React from "react";
import styled from "styled-components";
import vect from "../../Assets/Images/chevron-double-right.svg";

function Cell(props) {
  return (
    <CellGroup>
      <CellImgWrap>
        <CellImage image={props.image}></CellImage>
      </CellImgWrap>
      <div>
        <CellTitle>
          <h3>{props.title}</h3>
        </CellTitle>
        <CellDescription>{props.description}</CellDescription>
        <Txt href={props.link} target="_blank">
          Read More <img src={vect} alt="v" />
        </Txt>
      </div>
    </CellGroup>
  );
}

export default Cell;

const CellGroup = styled.div`
  display: grid;
  grid-template-columns: 350px 900px;
  grid-gap: 25px;
  align-items: center;
  justify-content: center;
  max-width: 1280px;
  // min-width: 320px;
  margin-bottom: 30px;

  @media (max-width: 1350px){
    grid-template-columns: 350px 700px;
  }
  @media (max-width: 1120px){
    grid-template-columns: 350px 500px;
  }

  @media (max-width: 920px) {
    grid-template-columns: repeat(1, auto);
    ${"" /* max-width:320px; */}
    grid-gap: 10px;
    margin-left: 0;
    justify-content: center;
  }
`;

const CellImgWrap = styled.div`
  max-width: 300px;
  height: 330px;
  border: 0.001px solid #ff8533;
  box-shadow: 20px 10px 50px rgba(0, 75, 117, 0.3);
  border-radius: 60px;
  overflow: hidden;
  position: relative;
  ${
    "" /* background: rgba(255, 255, 255, 0.3);
  opacity: 0.4; */
  }
  mix-blend-mode: normal;
  ${"" /* backdrop-filter: blur(20px); */}
  padding: 10 0 10 20;
  position: relative;
  transition: all 1.5s;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 920px) {
    ${"" /* display: flex; */}
    align-items: center;
    margin: auto;
    min-width: 300px;
  }
`;

const CellImage = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  transition: all 1.5s;

  :hover {
    transform: translateY(10px) scale(1.2);
  }
`;


const CellTitle = styled.div`
  color: rgba(18, 52, 68, 1);
  /* font-family: 'Omnes'; */
  font-size: 24px;
  padding: 0;

  @media (max-width: 920px) {
    font-size: 20px;
    text-align: justify;
  }
`;

const CellDescription = styled.div`
  /* color: rgba(18, 52, 68, 1); */
  color: #000000;
  /* font-family: 'Omnes'; */
  font-weight: 500;
  font-size: 20px;
  padding: 0;
  margin-bottom: 20px;

  @media (max-width: 920px) {
    font-size: 18px;
    text-align: justify;
  }
`;

const Txt = styled.a`
  padding: 0;
  /* font-family: 'Omnes'; */
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  margin-top: 10px;
  /* identical to box height, or 117% */

  letter-spacing: -0.011em;
  cursor: pointer;
  color: #123444;
  text-decoration: none;

  img {
    padding: 0 10px 0 0;
    height: 20px;
  }

  @media (max-width: 920px) {
    font-size: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
      width: 10px;
      height: auto;
    }
  }
`;
