import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";

import { updateProvider, uploadDocument, verifyProviderToken } from "../../Api/Services/ProviderServices";
import {
  StyledMessage,
} from "../../Components/Item-List/GlobalItemList";
import {
  New,
} from "../../Css";
import { ProviderValidation } from "../../From/Validation";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import InputField from "../Common/InputField";
import TextArea from "../Common/TextArea";
import UploadDocument from "../Common/UploadDocument";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme?.transitions?.create("transform", {
    duration: theme?.transitions?.duration.shortest,
  }),
}));

const newCustomFields = {
  additional_procedures_information: "",
  procedure_description: "",
  procedure: "",
  document: "",
};

const defaultValue = {
  pet_id: "",
  date: new Date().toISOString().substr(0, 10),
  additionalProcedures: [newCustomFields],
};

const ProviderEmailForm = () => {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({ 0: true, 1: true, 2: true});
  const formikRef = useRef(null);
  const queryParameters = new URLSearchParams(window.location.search);
  let token = queryParameters.get("token");
  const data = {
    token: token,
  };

  const verifyToken = async () => {
    try {
      const res = await verifyProviderToken(data);
      if (res?.data?.message === "Token expired.") {
        setErrorMessage("Form link was Expired");
      } else if (res?.data?.data?.status === "submitted") {
        setErrorMessage("You have already submitted this form");
      } else {
        setErrorMessage("");
        setInitialValue({
          ...initialValue,
          id: res?.data?.data?.id,
          pet_id: res?.data?.data?.pet_name,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    verifyToken();
  }, [token]);

  const handleSubmit = async (values) => {
    for (let i = 0; i < values?.additionalProcedures.length; i++) {
      if (values?.additionalProcedures?.[i]?.document) {
        const formData = new FormData();
        formData.append(`document`, values?.additionalProcedures?.[i]?.document);
        const documentResponse = await uploadDocument(formData);
        values.additionalProcedures[i].document = documentResponse?.data?.document_url;
      }
    }
    const value = {
      date: values?.date,
      provider_data: values?.additionalProcedures,
    };
    try {
      const res = await updateProvider(values?.id, value);
      setErrorMessage(
        "Your form submitted successfully Please contact to your administrator."
      );
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleExpandClick = (itemId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [itemId]: !prevExpanded[itemId],
    }));
  };

  return (
    // <Wrapper>
    <New className="scroll3" style={{ background: 'rgba(255, 133, 51, 0.20)'}}>
      {!loading && !errorMessage ? (
        <Container
          sx={{
            padding: "0px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            rowSpacing={8}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                marginLeft: {
                  sm: "20px",
                  md: "40px",
                  xs: "20px",
                  lg: "40px",
                  xl: "40px",
                },
              }}
            >
              <Typography
                sx={{ fontSize: "20px", color: "black", margin: "0 auto" }}
              >
                Fill below Provider Form
              </Typography>
            </Grid>
          </Grid>
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmit}
            validationSchema={ProviderValidation}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ getFieldProps, values, isSubmitting }) => (
              <Form>
                <FieldArray
                  name="additionalProcedures"
                  render={(arrayHelpers) => (
                    <>
                      <Box>
                        <StyledGrid
                          className="scroll2"
                          container
                          spacing={1}
                          paddingLeft={0}
                        >
                          <Col1 item xs={12} md={6}>
                            <Grid
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                              }}
                            >
                              <FormGroup
                                name="pet_id"
                                label={"Pet Name"}
                                requiredField
                              >
                                <InputField
                                  {...getFieldProps("pet_id")}
                                  name="pet_id"
                                  boxStyle={{
                                    "& .MuiInputBase-root": {
                                      width: {
                                        xs: 250,
                                        sm: 443,
                                        md: 443,
                                        lg: 443,
                                        xl: 443,
                                      },
                                      height: "35px",
                                    },
                                  }}
                                  disabled
                                />
                              </FormGroup>
                              <FormGroup
                                name="date"
                                label={"Date"}
                                className={"fromEmergency"}
                                requiredField
                              >
                                <InputField
                                  type="date"
                                  boxStyle={{
                                    "& .MuiInputBase-root": {
                                      width: {
                                        xs: 250,
                                        sm: 443,
                                        md: 443,
                                        lg: 443,
                                        xl: 443,
                                      },
                                      height: "35px",
                                    },
                                  }}
                                  {...getFieldProps("date")}
                                />
                              </FormGroup>

                              {values?.additionalProcedures?.length > 0
                                ? values?.additionalProcedures?.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <Box
                                            sx={{
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "16px",
                                                color: "black",
                                              }}
                                            >
                                              Procedure {index + 1}
                                            </Typography>
                                            <Box>
                                              <ExpandMore
                                                expand={expanded[index]}
                                                onClick={() =>
                                                  handleExpandClick(index)
                                                }
                                                aria-expanded={expanded[index]}
                                                aria-label="show more"
                                                sx={{
                                                  background:
                                                    "transparent !important",
                                                  marginRight: "10px",
                                                  width: "auto",
                                                }}
                                              >
                                                <ExpandMoreIcon />
                                              </ExpandMore>
                                              <IconButton sx={{
                                                  background:
                                                    "transparent !important",
                                                marginRight: "10px",
                                                width: "auto",
                                                }}>
                                                <DeleteIcon onClick={() => arrayHelpers.remove(index)} />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                          <Collapse
                                            in={expanded[index]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <FormGroup
                                              name={`additionalProcedures.${index}.procedure`}
                                              label={"Procedure/Visit"}
                                              // className={"fromEmergency"}
                                            >
                                              <InputField
                                                boxStyle={{
                                                  // ...BoxFieldStyle,
                                                  "& .MuiInputBase-root": {
                                                    width: {
                                                      xs: 250,
                                                      sm: 443,
                                                      md: 443,
                                                      lg: 443,
                                                      xl: 443,
                                                    },
                                                    height: "35px",
                                                  },
                                                }}
                                                {...getFieldProps(
                                                  `additionalProcedures.${index}.procedure`
                                                )}
                                              />
                                            </FormGroup>
                                            <FormGroup
                                              name={`additionalProcedures.${index}.procedure_description`}
                                              label={"Describe Procedure"}
                                              className={"fromEmergency"}
                                            >
                                              <InputField
                                                boxStyle={{
                                                  // ...BoxFieldStyle,
                                                  "& .MuiInputBase-root": {
                                                    width: {
                                                      xs: 250,
                                                      sm: 443,
                                                      md: 443,
                                                      lg: 443,
                                                      xl: 443,
                                                    },
                                                    height: "35px",
                                                  },
                                                }}
                                                {...getFieldProps(
                                                  `additionalProcedures.${index}.procedure_description`
                                                )}
                                              />
                                            </FormGroup>
                                            <FormGroup
                                              name={`additionalProcedures.${index}.document`}
                                              label={"Upload Documents"}
                                              className={"fromEmergency"}
                                            >
                                              <UploadDocument
                                                {...getFieldProps(
                                                  `additionalProcedures.${index}.document`
                                                )}
                                              />
                                            </FormGroup>
                                            <FormGroup
                                              name={`additionalProcedures.${index}.additional_procedures_information`}
                                              label={
                                                "Add Additional Procedure information or message"
                                              }
                                              className={"fromEmergency"}
                                            >
                                              <TextArea
                                                rows={7}
                                                maxLength={300}
                                                {...getFieldProps(
                                                  `additionalProcedures.${index}.additional_procedures_information`
                                                )}
                                                sx={{
                                                  // ...immunizationTextAreaStyle,
                                                  "& .MuiOutlinedInput-root": {
                                                    width: {
                                                      xs: 250,
                                                      sm: 443,
                                                      md: 443,
                                                      lg: 443,
                                                      xl: 443,
                                                    },
                                                    "&.Mui-focused fieldset": {
                                                      borderColor: "white",
                                                    },
                                                  },
                                                }}
                                              />
                                            </FormGroup>
                                          </Collapse>
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </Grid>
                          </Col1>
                        </StyledGrid>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={4}
                      >
                        {/* <Box display="flex"> */}
                        <CustomButton
                          // variant="outlined"
                          style={{ width: 200 }}
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => {
                            arrayHelpers.push(newCustomFields);
                          }}
                          label="Add Additional Procedure"
                        />
                        <CustomButton
                          label={isSubmitting ? "Submitting..." : "Submit"}
                          disabled={isSubmitting}
                          type="submit"
                        />
                        {/* </Box> */}
                      </Box>
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Container>
      ) : (
        <StyledMessage style={{ color: "black" }}>{errorMessage}</StyledMessage>
      )}
    </New>
    // </Wrapper>
  );
};

export default ProviderEmailForm;

const Col1 = styled(Grid)`
  grid-template-columns: repeat(12, 1fr);
  padding-left: 100px;

  @media screen and (max-width: 520px) {
    width: 350px;
    padding-left: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  &.scroll2 {
    padding: 12px;
    justify-content: start;
    flex-grow: 1;
    padding-top: 1px;
    overflow-x: hidden;
    margin-top: 30px;

    @media screen and (min-width: 1485px) {
      margin-left: 110px;
      width: 960px;
    }

    @media screen and (max-width: 600px) {
      padding: 0px;
      margin-top: 10px;
    }
  }
`;
