import React from "react";

import ImmunizationComponent from "../../Components/Selected-item-view/Immunization/ImmunizationComponent";

const ImmunizationSecond = () => {

  return (
    <>
      <ImmunizationComponent />
    </>
  );
};

export default ImmunizationSecond;
