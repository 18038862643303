import React, { useEffect } from "react";

import { Box } from "@mui/system";
import styled from "styled-components";

import Step1 from "../../Assets/Images/SurgeryAndTreatment/Stepper/step 1.svg";
import Step2 from "../../Assets/Images/SurgeryAndTreatment/Stepper/step 2.svg";
import Layout from "../../Components/Side bar/Layout";
import FirstStep from "../../Layout/SurgeryandTreatInfo/FirstStep";
import SecondStep from "../../Layout/SurgeryandTreatInfo/SecondStep";
// import Logout from "../../Components/SurgeryandTreatInfo/Logout";
// import log from "../../Assets/Images/Immunization/Vector.png";

const steps = ["Step 1", "Step 2"];
const SurgeryandTreatForm = () => {
  const [activeStep, setActiveStep] = React.useState(0);



  function showStep(step) {
    switch (step) {
      case 0:
        return <FirstStep />;
      case 1:
        return <SecondStep />;
      default:
        return <></>;
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Layout>
      <Container>
        {showStep(activeStep)}
        {activeStep === steps.length - 1 ? (
          <></>
        ) : (
          <FooterWrapper>
            <Buttons>
              <Button>Submit</Button>
            </Buttons>
            <Buttons>
              <Button onClick={handleNext}>Next</Button>
            </Buttons>
          </FooterWrapper>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {activeStep === 0 && <img src={Step1} alt="step1" />}
          {activeStep === 1 && <img src={Step2} alt="step1" />}
        </Box>
        <div
          className="log"
          style={{ position: "absolute", right: "2vw", bottom: "-10vh" }}
        >
          {/* <img alt="" src={log} style={{ cursor: "pointer" }}></img> */}
        </div>
      </Container>
    </Layout>
  );
};

export default SurgeryandTreatForm;

const New = styled.div`
  position: absolute;
  padding: 20px;
  width: 73vw;
  height: 86vh;
  left: 295px;
  top: 25px;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 37px;
  @media (max-width: 1130px) {
    left: 215px;
  }
  @media (max-width: 890px) {
    left: 80px;
    top: 120px;
    height: 75vh;
    width: 80vw;
  }
  @media (max-width: 640px) {
    left: 45px;
  }
`;

const Container = styled.div`
  margin: 5px;
`;

const FooterWrapper = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const Buttons = styled.div`
  display: inline;
`;

const Button = styled.button`
  box-sizing: border-box;

  width: 113px;
  height: 39px;
  margin: 10px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 11px;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
  :hover {
    background: rgba(255, 255, 255, 0.25);
    border: 2px solid rgba(255, 255, 255, 0.55);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(15px);
    cursor: pointer;
  }
`;
