import React, { useState } from "react";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Chip, FormControl, MenuItem, Select } from "@mui/material";

export const DropdownFilter = ({
  id,
  setFilter,
  options,
  value,
  placeholder,
  className,
}) => {
  const [selectedItem, setSelectedItem] = useState("");
    
  const handleDelete = () => {
    setSelectedItem("");
    setFilter(id, "");
  }
    
  return (
    <FormControl
      variant="outlined"
      sx={{ m: 1, width: "24vw", backgroundColor: "#FFFFFF", color: "#000" }}
    >
      <Select
        id={id}
        defaultValue={true}
        onChange={(e) => {
          setSelectedItem(e.target.value);
          setFilter(id, e.target.value);
        }}
        sx={{
          height: "50px",
          display: "flex",
          alignItems: "center",
          borderRadius: "0px",
          borderColor: "transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FF8533",
            borderRadius: "10px",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .MuiSelect-icon": { color: "#AEAFB4" },
          "& .MuiChip-root": {
            color: "white",
            backgroundColor: "#FFFFFF",
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF8533",
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FF8533",
          },
          "&.Mui-focused .MuiChip-root": {
            backgroundColor: "#FFFFFF",
          },
        }}
        renderValue={() =>
          selectedItem === "" ? (
            <div style={{ color: "black" }}>Select a {placeholder}</div>
          ) : (
            <Chip
              key={selectedItem}
              label={selectedItem}
              deleteIcon={
                <CancelRoundedIcon
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
              style={{
                margin: 2,
                backgroundColor: "rgba(255, 133, 51, 0.20)",
                color: "black",
              }}
              onDelete={handleDelete}
            />
          )
        }
      >
        {options.length > 0 ? (
          options.map((item, i) => (
            <MenuItem key={i} value={item.name}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Data found
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
