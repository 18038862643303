import React from "react";

import ArchiveImmunizationComponent from "../../Components/Selected-item-view-Ar/Immunization/ImmunizationComponent";

const ArchiveImmunizationSecond = () => {

  return (
    <>
      <ArchiveImmunizationComponent />
    </>
  );
};

export default ArchiveImmunizationSecond;
