import React, { useState } from "react";

import { useFormikContext } from "formik";

import styled from "styled-components";
import FormGroup from "../Common/FormGroup";
import StripeCardNumberInput from "../Common/StripeCardNumberInput";
import StripeCardExpiryInput from "../Common/StripeCardExpiryInput";
import StripeCardCvcInput from "../Common/StripeCardCvcInput";
import HttpCommon from "../../Utils/http-common";
import { boolean } from "yup";

const StripeCreditCardFormData = ({ onPrevious }) => {
  const { isSubmitting, setFieldValue } = useFormikContext();

  const [cardNumberError, setCardNumberError] = useState(true);
  const [cardExpiryError, setCardExpiryError] = useState(true);
  const [cardCvcError, setCardCvcError] = useState(true);
  const [checkBox, setCheckBox] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [hasPromoCode, setHasPromoCode] = useState(boolean);
  const [promoCodeDetails, setPromoCodeDetails] = useState({});
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [setIsLoading, setsetIsLoading] = useState(false);

  const handleApplyPromoCode = async () => {
    try {
      if (promoCodeDetails?.promoCode) {
        setPromoCodeDetails({});
        setPromoCode("");
        setFieldValue("coupon", "");
      } else {
        setsetIsLoading(true);
        const res = await HttpCommon.get(`/user/getCoupon/${promoCode}`);
        if (res?.data?.code === 200) {
          setPromoCodeDetails(res?.data?.data);
          setFieldValue("coupon", res?.data?.data?.promoCode);
          setCouponErrorMessage("");
        } else {
          setCouponErrorMessage(res?.data?.message);
          setPromoCodeDetails({});
        }
        setsetIsLoading(false);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  return (
    <>
      <Column>
        <Row>
          <FormGroup
            label="Card Number"
            name=""
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardNumberInput
              onChange={(_, error) => setCardNumberError(Boolean(error))}
            />
          </FormGroup>
          <FormGroup
            label="Expiration Date"
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardExpiryInput
              onChange={(_, error) => setCardExpiryError(Boolean(error))}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup
            label="CVC"
            name=""
            labelClassName="margin-top"
            requiredField
          >
            <StripeCardCvcInput
              onChange={(_, error) => setCardCvcError(Boolean(error))}
            />
          </FormGroup>
        </Row>
      </Column>

      <Check>
        <label>
          <input
            type="checkbox"
            checked={hasPromoCode}
            onChange={(e) => setHasPromoCode(e.target.checked)}
          />
          Have Promo Code?
        </label>
        {/* ... Remaining code for terms checkbox and buttons */}
      </Check>
      <Column style={{ gap: '10px'}}>
        {/* ... Existing Stripe card input fields */}
        {hasPromoCode && (
          <div style={{ display: "flex" }}>
            <FormGroup
              name="promoCode"
              labelClassName="margin-top"
              style={{ marginRight: "10px" }}
            >
              <input
                placeholder="Promo Code"
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                disabled={promoCodeDetails?.promoCode}
                style={{borderColor: couponErrorMessage ? '#DC3838' : '#808080'}}
              />
            </FormGroup>
            <button
              type="button"
              style={{
                maxWidth: 100,
                marginLeft: "10px",
                background: "#fc8433",
              }}
              onClick={handleApplyPromoCode}
            >
              {promoCodeDetails?.promoCode ? "Cancel" :  setIsLoading ? <Loader style={{ height: "8px", width: "8px", textAlign: 'center' }}/> : "Apply"}
            </button>
          </div>
        )}
        {!promoCodeDetails?.promoCode && couponErrorMessage && <p style={{ margin: 0}}>{couponErrorMessage}</p>}
        {promoCodeDetails?.promoCode && (
          <p style={{ fontSize: 16, color: "green", margin: 0 }}>
            {promoCodeDetails?.percent_off
              ? `${promoCodeDetails?.percent_off}%`
              : `$${promoCodeDetails?.total_discount}`}
            &nbsp;discount is applied. Final price: ${promoCodeDetails?.total_amount}
          </p>
        )}
      </Column>
      <Check>
        <input
          type="checkbox"
          onChange={() => {
            setCheckBox(!checkBox);
          }}
        />
        <a target="_blank" href="/terms-conditions">
          Accept Terms & Conditions *
        </a>
        <Buttons>
          <button onClick={onPrevious}>Back</button>
          {isSubmitting ? (
            <button disabled={!checkBox || isSubmitting}>
              <Loader />
            </button>
          ) : (
            <button
              disabled={
                !checkBox ||
                isSubmitting ||
                cardNumberError ||
                cardExpiryError ||
                cardCvcError
              }
              type="submit"
            >
              Complete Order and Pay{" "}
              {promoCodeDetails?.total_amount
                ? `$${promoCodeDetails?.total_amount}`
                : "$40"}
            </button>
          )}
        </Buttons>
      </Check>
    </>
  );
};

export default StripeCreditCardFormData;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 24px;

  label {
    padding: 10px 0;
    color: rgb(31, 85, 165);
    font-weight: 700;
  }

  input {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;

    :focus {
      border: none;
    }
  }

  p {
    color: red;
    font-size: 14px;
    padding: 0px;
    height: 16.8px;
    margin: 7px 0 7px 0;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
  }
`;

const Check = styled.div`
  padding: 30px 0px 0px 0px;
  text-align: left;

  button {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  input {
    transform: scale(1.5);
    margin-right: 10px;
  }

  a {
    font-family: sans-serif;
    text-decoration: underline;
    color: blue;
  }
`;
const Buttons = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  button {
    height: max-content;
    width: max-content;
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;
const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
