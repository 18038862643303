import React, { useEffect } from "react";
import "./VerifyAccount.css";
import pawPrintlogo from "../../Assets/Images/Navbar/pawprint-icon.png";
import checkmark from "../../Assets/Images/Check.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import HttpCommon from "../../Utils/http-common";
import swal from "sweetalert";

const SuccessPage = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  let token = queryParameters.get("token");
  useEffect(() => {
    const data = {
      token: token,
    };
    HttpCommon.post("/user/verifyAccount", data)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
        if (err.code === "ERR_NETWORK") {
          swal("Network Error!", "Failed to connect server.", "error");
          return;
        }
      });
  }, [token]);
  return (
    <div className="container-verify">
      <form>
        <div className="verifyImage">
          <img src={pawPrintlogo} className="pawPrintlogo" alt="logo" />
        </div>
        <br />
        <div className="form-message">
          <Welcome>
            <h2>Welcome</h2>
            <img src={checkmark} className="checkmark" alt="checkmark" />
          </Welcome>

          <h3>Your account has been verified.</h3>
          <div className="btn-div">
            <button
              className="btn"
              onClick={() => {
                navigate("/account");
              }}
            >
              Login
            </button>
          </div>
        </div>

        <br />
      </form>
    </div>
  );
};

export default SuccessPage;

const Welcome = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 0px;
  justify-items: center;
`;
