export const navData = [
  {
    title: "About Us",
    icon: require("../../../Assets/Images/Navbar/about us-icon.png"),
    link: "/aboutus"
  },
  {
    title: "For Individuals",
    icon: require("../../../Assets/Images/Navbar/For individuals-icon.png"),
    link: "/for-individuals",
  },
  {
    title: "For Business",
    icon: require("../../../Assets/Images/Navbar/For business-icon.png"),
    link: "/forbusiness",
  },
  {

    title: "Resources",
    icon: require("../../../Assets/Images/Navbar/resourse-icon.png"),
    link: "/resources",
  },
  {
    title: "Contact Us",
    icon: require("../../../Assets/Images/Navbar/contact us-icon.png"),
    link: "/contactus",
  },
  // {
  //   title: "",
  //   icon: require("../../../Assets/Images/Navbar/search-icon.png"),
  //   link: "/search",
  // },
  // {
  //   title: "",
  //   icon: require("../../../Assets/Images/Navbar/account-icon.png"),
  //   link: "/account",
  // },
  // {
  //   title: "",
  //   icon: require("../../../Assets/Images/Navbar/menu-icon.png"),
  //   link: "/menu",
  // },
];

export const tooltipData = [
  {
    title: "About Us",
    icon: require("../../../Assets/Images/Navbar/about us-icon.png"),
    link: "/aboutus",
  },
  {
    title: "For Business",
    icon: require("../../../Assets/Images/Navbar/For business-icon.png"),
    link: "/forbusiness",
  },
  {
    title: "For Individuals",
    icon: require("../../../Assets/Images/Navbar/For individuals-icon.png"),
    link: "/for-individuals",
  },
  {
    title: "Resources",
    icon: require("../../../Assets/Images/Navbar/resourse-icon.png"),
    link: "/resources",
  },
  {
    title: "Contact Us",
    icon: require("../../../Assets/Images/Navbar/contact us-icon.png"),
    link: "/contactus",
  },
  // {
  //   title: "Search",
  //   icon: require("../../../Assets/Images/Navbar/search-icon.png"),
  //   link: "/search",
  // },
  {
    title: "Account",
    icon: require("../../../Assets/Images/Navbar/account-icon.png"),
    link: "/account",
  },
  // {
  //   title: "Menu",
  //   icon: require("../../../Assets/Images/Navbar/menu-icon.png"),
  //   link: "/menu",
  // },
];
