import React, { useState } from 'react'

import { Button } from '@mui/material'
// import { Button, Spinner } from 'flowbite-react'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useAsyncDebounce } from 'react-table'

// import QuickSearchInput from '../QuickSearchInput'

// Component for Global Filter
export function GlobalFilter({ globalFilter, setGlobalFilter, ...props }) {
  const [value, setValue] = useState(globalFilter)

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 500)

  return (
    <div>
      {/* <QuickSearchInput
        value={value}
        onChange={e => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        sizing="sm"
        inputClassName="bg-white"
      /> */}
    </div>
  )
}

export const DeleteAction = ({ onDelete, row, className }) => {
  const [isLoading, setLoading] = useState(false)

  const onDeleteEvent = async (e) => {
    try {
      setLoading(true)
      await onDelete(row)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className="text-center">
        {/* <Spinner aria-label="Center-aligned spinner example" /> */}
      </div>
    )
  }
  return (
    <Button color="gray" className={`w-6 ${className}`} onClick={onDeleteEvent}>
      <RiDeleteBin5Line className="h-4 w-4 hover:fill-red-600" />
    </Button>
  )
}
