import React from "react";
import ReactDOM from "react-dom/client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { Helmet } from "react-helmet";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_PUBLIC_KEY || "",
  integrations: [
    new CaptureConsole({
      levels: ["error"],
    }),
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function FallbackComponent(e) {
  return <div>An error has occured</div>;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <Helmet>
      <script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`}></script>
    </Helmet>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
