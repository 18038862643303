import React from "react";


import EmergencyInfo from "../../Components/Selected-item-view/Emergency/Emergency";

const EmergencySecondView = () => {

  return (
    <>
      <EmergencyInfo />
    </>
  );
};

export default EmergencySecondView;
