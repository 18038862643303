import { api } from "../axios";

// get pet vet by id
export const getPetVetTherapistById = async (id) => {
  const res = await api.get(`/vet/getPetVetTherapistById/${id}`);
  return res;
};

//vet practice
export const getAllVetPractice = async (w_id) => {
  const res = await api.get(`/vet_practice/getAllVetPractice/${w_id}`);
  return res;
};

export const getVetPracticeById = async (id) => {
  const res = await api.get(`/vet_practice/getVetPracticeById/${id}`);
  return res;
};

export const createVetPractice = async (formData) => {
  const res = await api.post(`/vet_practice/createVetPractice`, formData);
  return res;
};

export const updateVetPractice = async (id, formData) => {
  const res = await api.put(`/vet_practice/updateVetPractice/${id}`, formData);
  return res;
};

//vet therapist
export const getVetByPracticeID = async (id) => {
  const res = await api.get(`/vet/getallVetsByPracticeId/${id}`);
  return res;
};

export const getVetById = async (id) => {
  const res = await api.get(`/vet/getVetTherapistById/${id}`);
  return res;
};

export const createVetTherapist = async (formData) => {
  const res = await api.post(`/vet/createVetTherapist`, formData);
  return res;
};

export const updateVetTherapist = async (id, formData) => {
  const res = await api.put(`/vet/updateVetTherapist/${id}`, formData);
  return res;
};

//pet_vet_therapist
export const createPetVetThrapist = async (formData) => {
  const res = await api.post(`/vet/createPetVetTherapist`, formData);
  return res;
};

export const updatePetVetTherapist = async (id, formData) => {
  const res = await api.put(`/vet/updatePetVetTherapist/${id}`, formData);
  return res;
};

export const getVetTherapist = async (id) => {
  const res = await api.get(`/vet/getVetTherapistById/${id}`);
  return res;
};
export const getVetAddress = async (id) => {
  const res = await api.get(`/vet/getVetAddressById/${id}`);
  return res;
};

// vet & therapist item list
export const getVetTherapistList = async () => {
  const res = await api.get(`/vet/getAllVetTherapist`);
  return res;
};

export const getbyIDVetTherapist = async (id) => {
  const res = await api.get(`/vet/getVetTherapistById/${id}`);
  return res;
};

export const getAllVetTherapists = async () => {
  const res = await api.get(`/vet/getAllVetTherapist`);
  return res;
};

// get vet by user id
export const getAllVetByWorkspaceId = async (w_id) => {
  const res = await api.get(`/vet/getVetDetailsByWorkspaceId/${w_id}`);
  return res;
};

export const getVetByID = async (id) => {
  const res = await api.get(`/vet/getVetTherapistById/${id}`);
  return res;
};

export const getAllVetnamePres = async (wid) => {
  const res = await api.get(`/vet/getVetTherapistByWorkspaceId/${wid}`);
  return res;
};

//pet_surgery
export const getvetidbyname = async (formData) => {
  const res = await api.post(`/vet/getIdByPracticeName`, formData);
  return res;
};

export const getVetTherapistById = async (id) => {
  const response = await api.get(`/vet/getVetTherapistById/${id}`);
  return response;
};

export const getAllVetNames = async () => {
  const res = await api.get(`/vet/getAllVetTherapist`);
  return res;
};

//Delete pet vet
export const deleteVet = async (id) => {
  const res = await api.delete(`/vet/deletePetVetTherapist/${id}`);
  return res;
};

//archieve vet
export const getArchiveVetByWorkspaceID = async (id) => {
  const res = await api.get(`/vet/getArchievedVetDetailsByWorkspaceId/${id}`);
  return res;
};

//set vet archive
export const setVetArchieve = async (id) => {
  const res = await api.post(`/vet/setArchieve/${id}`);
  return res;
};
// vet unarchive
export const setVetUnarchive = async (id) => {
  const res = await api.post(`/vet/setunArchieve/${id}`);
  return res;
};