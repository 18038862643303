import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

// account 
export const LoginAccountValidation = Yup.object({
  email: Yup.string()
    .email(`Invalid User format`)
    .required("User is required"),
  password: Yup.string().required("Invalid Password"),
});

// register
export const RegisterValidation = [Yup.object({
  firstname: Yup.string().required("Please enter first name"),
  lastname: Yup.string().required("Please enter lastname "),
  email: Yup.string().email('Invalid format').required("Please enter email address"),
  phoneNumber: Yup.string().required("Please enter phone number"),
  password: Yup.string()
  .min(6, "Password should be at least 6 characters long")
  .minLowercase(1, "Password should contain at least one lowercase letter")
  .minUppercase(1, "Password should contain at least one uppercase letter")
  .minNumbers(1, "Password should contain at least one number")
  .minSymbols(1, "Password should contain at least one special character")
  .required("Please enter password"),
  confirmpassword: Yup.string()
  .required('Please enter your confirm password')
  .min(6, 'Password must be a minimum length of 6 characters')
  .oneOf([Yup.ref('password'), ''], 'Password must match')
}),
Yup.object({
  firstnameB: Yup.string().required("Please enter first name"),
  streetAddB: Yup.string().required("Please enter street address"),
  CityB: Yup.string().required("Please enter city"),
  zipcodeB: Yup.string().required("Please enter zipcode"),
  lastnameB:Yup.string().required("Please enter last name"),
  stateB:Yup.string().required(" Please enter street address"),
}),
Yup.object({
  fName: Yup.string().required("Please enter first name"),
  address_line_1: Yup.string().required("Please enter address 1"),
  admin_area_2: Yup.string().required("Please enter city"),
  billing_postal_code: Yup.string().required("Please enter zipcode"),
  lName:Yup.string().required("Please enter last name"),
  admin_area_1:Yup.string().required("Please enter state / province / region"),
}),
Yup.object({
}),
];

// Payment
export const PaymentValidation = Yup.object({
  firstnameB: Yup.string().required("Please enter first name"),
  streetAddB: Yup.string().required("Please enter street address"),
  CityB: Yup.string().required("Please enter city"),
  zipcodeB: Yup.string().required("Please enter zipcode"),
  lastnameB:Yup.string().required("Please enter last name"),
  stateB:Yup.string().required(" Please enter street address"),
})