import React from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  getAllMedicationByWorkspaceId,
  getMeditation,
} from "../../../Api/Services/MedicationServices";
import { getPetInfoList } from "../../../Api/Services/PetServices";
import { useAuth } from "../../../ContextProvider/AuthProvider/AuthProvider";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function ArchivePrescriptionComponent() {
  const navigate = useNavigate();
  const { workspaceId: w_id } = useAuth();
  const { id } = useParams();

  const { data, isLoading } = useSWR(
    id ? `getMedicationdata${id}` : null,
    async () => {
      const { data } = await getMeditation(id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: medicationData } = useSWR(
    w_id ? `allmedicationData${w_id}` : null,
    async () => {
      const { data } = await getAllMedicationByWorkspaceId(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const result = data?.map((medication) => {
    const { id } = medication;
    const matchingMedication = medicationData?.find(
      (medicationData) => medicationData.medication_id === id
    );

    return {
      medicationId: id,
      petImage: matchingMedication ? matchingMedication.image : "",
      petName: matchingMedication ? matchingMedication.name : "",
      type: matchingMedication ? matchingMedication.type : "",
      vet: matchingMedication ? matchingMedication.vet_practice_name : "",
    };
  });

  const { data: petData } = useSWR(
    "petData",
    async () => {
      const { data } = await getPetInfoList();
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const petInfo = data?.map((medication) => {
    const { pet_id } = medication;
    const matchingPet = petData?.find((petData) => petData.id === pet_id);

    return {
      petImage: matchingPet ? matchingPet.image : "",
      PetName: matchingPet ? matchingPet.name : "",
      dob: matchingPet ? matchingPet.dob : "",
      country: matchingPet ? matchingPet.country : "",
    };
  });

  const props = {
    label: "Archive Prescription and Medication Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/archive/prescription-medication");
    },
    petInfo: {
      name: petInfo?.[0]?.PetName,
      image: petInfo?.[0]?.petImage,
      dob: petInfo?.[0]?.dob,
    },
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Medication Type",
        value: "type",
      },
      {
        label: "Prescribing Vet",
        value: "vet_practice_name",
      },
    ],
    columnsData: [
      {
        image: petInfo?.[0]?.petImage,
        pet_name: petInfo?.[0]?.PetName,
        type: data?.[0]?.type,
        vet_practice_name: data?.[0]?.vet_practice_name,
      },
    ],
    detailsData: [
    [
      { name: "Vet Full Name", value: data?.[0]?.vet_full_name },
      { name: "Medication Name", value: data?.[0]?.medication_name },
      { name: "Duration", value: data?.[0]?.durtion },
      { name: "Dosage", value: data?.[0]?.dosage },
      { name: "Frequency", value: data?.[0]?.frequency },
      {
        name: "Start Date",
        value: formatDate(data?.[0]?.start_date),
      },
      { name: "Reason for Use", value: data?.[0]?.reason },
      {
        name: "Prescription Number",
        value: data?.[0]?.prescripton_number,
      },
      { name: "Pharmacy Name", value: data?.[0]?.pharmacy_name },
        { name: "Pharmacy Phone", value: data?.[0]?.pharmacy_contact },
    ]
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
