import { Country, State } from "country-state-city";

export const calculateAge = (dateString = "") => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const ageInDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
  if (ageInDays < 30) {
    return `${ageInDays} days`;
  } else if (ageInDays < 365) {
    const ageInMonths = Math.floor(ageInDays / 30);
    return `${ageInMonths} months`;
  } else {
    let ageInYears = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      ageInYears--;
    }
    return `${ageInYears} years`;
  }
};

export const getCountryAsOption = () => {
  const countries = Country.getAllCountries();
  const options = countries?.map((country) => {
    return {
      value: country.name,
      label: country.name,
      isoCode: country.isoCode,
    };
  });
  return options;
};

export const getStateAsOptions = (country) => {
  const statesOfCountry = State.getStatesOfCountry(country);
  return statesOfCountry.map((state) => {
    return {
      value: state.isoCode,
      label: state.name,
      code: state?.isoCode,
    };
  });
};

export const formatDate = (date) => {
  let targetDateFormatted = "";
  if (date) {
    // const options = Intl.DateTimeFormat().resolvedOptions();
    // const localTimezone = options.timeZone;
    // const inputDate = new Date(date);

    // // Set the target timezone
    // const newDate = inputDate?.toLocaleString(options.locale, {
    //   timeZone: localTimezone,
    // });

    // // Calculate the next day
    // // inputDate?.setDate(inputDate?.getDate() + 1);

    // // Format the date as "YYYY-MM-DD"
    // targetDateFormatted = inputDate?.toISOString().split("T")[0];
    targetDateFormatted = date;
  }
  return targetDateFormatted;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const breakLine = (text) => {
  const chars = text?.split("");
  const charChunks = [];
  let currentChunk = "";

  for (let i = 0; i < chars?.length; i++) {
    currentChunk += chars[i];

    if ((i + 1) % 60 === 0) {
      charChunks.push(currentChunk);
      currentChunk = "";
    }
  }
  if (currentChunk !== "") {
    charChunks.push(currentChunk);
  }
  return charChunks;
};

export function deepJSONDifferences(oldObj, newObj) {
  function compareObjects(oldObj, newObj) {
      const differences = {};

      for (const key in newObj) {
          if (typeof newObj?.[key] === "object" && !Array.isArray(newObj?.[key])) {
              // Recursively compare nested objects
              const nestedDiff = compareObjects(oldObj?.[key], newObj?.[key]);
              if (Object.keys(nestedDiff)?.length > 0) {
                  differences[key] = nestedDiff;
              }
          } else if (oldObj?.[key] !== newObj?.[key]) {
              differences[key] = {
                  oldValue: oldObj?.[key],
                  newValue: newObj?.[key]
              };
          }
      }

      return differences;
  }

  return compareObjects(oldObj, newObj);
}

export function addItemtoObject(item, propertiesToUpdate, propertiesToDelete = []) {
  const newData = item?.newData?.replace(/\n/g, ' ');
  const oldData = item?.oldData?.replace(/\n/g, ' ');
  const updatedNewData = newData ? JSON.parse(newData) : {};
  const updatedOldData = oldData ? JSON.parse(oldData) : {};

  propertiesToUpdate.forEach((property) => {
    updatedNewData[property] = item[`new_${property}`];
    updatedOldData[property] = item[`old_${property}`];
  });

  propertiesToDelete.forEach((property) => {
    delete updatedNewData[property];
    delete updatedOldData[property];
  });

  return {
    ...item,
    updatedBy: `${item.first_name} ${item.last_name}`,
    created_at: formatDate(item?.created_at),
    data: deepJSONDifferences(updatedOldData, updatedNewData),
  };
}

export const jsonToQueryString = (json) => {
  if (json.sort) {
    const [sortColumn, sortDirection] = json.sort.startsWith("-")
      ? [json.sort.slice(1), "desc"]
      : [json.sort, "asc"];
    // eslint-disable-next-line no-unused-expressions
    sortDirection === "asc" ? (json.field = sortColumn, json.order = "asc") : (json.field = sortColumn, json.order = "desc");
  }
  return `?${Object.keys(json)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
    })
    .join('&')}`
}

export const filterAscDescData = (data, sort, from, limit) => {
  if (sort) {
    const [sortColumn, sortDirection] = sort.startsWith("-")
      ? [sort.slice(1), "desc"]
      : [sort, "asc"];
      data = data.sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "desc" ? bValue - aValue : aValue - bValue;
      } else {
        const stringA = String(aValue);
        const stringB = String(bValue);
        return sortDirection === "desc"
          ? stringB.localeCompare(stringA)
          : stringA.localeCompare(stringB);
      }
    });
  }
  const filteredResult = data.slice(from, from + limit);
  return filteredResult;
}
