import React from "react";

import ArchiveUserInformationList from "../../Components/Archive-Items/ArchiveUserInformation";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveAllergy() {
  return (
    <Layout>
      <ArchiveUserInformationList />
    </Layout>
  );
}
