import React, { useState, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import useSWR from "swr";

import {
  getPetByID,
  updatePetImageById,
  updatePetInfoById,
} from "../../Api/Services/PetServices";
import { MEMBERSHIP_NUMBER_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { PetInfoFormValidation } from "../../From/Validation";
import {
  chipOptionsCustom,
  genderOptionsCustom,
  eyeColorOptions,
  petTypeOptions,
  weightMeasurementOptions,
  furColorOptions,
} from "../../Options";
// import defaultImage from "../../Assets/Images/add_image.png";
import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import "./petPrint.css";
import { formatDate, getCountryAsOption, getStateAsOptions } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import AutoCompleteAddress from "../Common/AutoCompleteAddress";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import ImageUpload from "../Common/ImageUpload";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import { uploadS3Bucket } from "../../Api/axios";

const defaultValue = {
  user_id: "",
  name: "",
  family_name: "",
  dob: "",
  breed: "",
  company_name: "",
  company_contact_number: "",
  marks: "",
  reg_number: "",
  chip_availability: "",
  fur_color: "",
  eye_Color: "",
  gender: "",
  weight: "",
  weightMeasurement: "",
  pet_type: "",
  zip_code: "",
  street_address: "",
  apt_city: "",
  country: "",
  state: "",
  membership_no: "",
  membership_id: ""
};

export default function PetInfoEdit() {
  const [initialValue, setInitialValue] = useState(defaultValue);

  const navigate = useNavigate();
  const { id } = useParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOption, setStateOption] = useState([]);
  const [open, setOpen] = useState(false);
  const maxDate = new Date().toISOString().substr(0, 10);
  const membership_id = localStorage.getItem(MEMBERSHIP_NUMBER_KEY);
  const formikRef = useRef();
  // let membership_no;

  const [image, setImage] = useState("");

  // eslint-disable-next-line no-unused-vars
  const { data: petInfo = {} } = useSWR(
    id ? `petData${id}` : null,
    async () => {
      try {
        const { data } = await getPetByID(id);
        const petData = data?.data;
        setImage(petData.image);
        const weightArray = petData.weight.match(/^([\d.]+)(\D+)$/);
        if (weightArray && weightArray.length > 2) {
          setInitialValue({
            ...petData,
            weight: weightArray[1],
            weightMeasurement: weightArray[2],
            membership_id,
            dob: formatDate(petData.dob_date),
          });
        } else {
          setInitialValue({
            ...petData,
            membership_id,
            dob: formatDate(petData.dob),
          });
        }
        const country = getCountryAsOption();
        setCountryOptions(country);
        const countryCode = country?.find(
          (country) => country.label === petData?.country
        );
        const state = getStateAsOptions(countryCode?.isoCode);
        const filteredState = state?.map((item) => {
          return {
            value: item.label,
            label: item.label,
          };
        });
        filteredState.push({ value: "Other", label: "Other" });
        setStateOption(filteredState);
        return data?.data;
      } catch (e) {
        console.error({
          e,
        });
        return null;
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  // Update function
  const updateForm = async (values) => {
    try {
      const value = {
        name: values.name,
        family_name: values.family_name,
        dob: new Date(values.dob),
        breed: values.breed,
        company_name: values.company_name,
        company_contact_number: values.company_contact_number,
        marks: values.marks,
        reg_number: values.reg_number,
        chip_availability: values.chip_availability,
        fur_color: values.fur_color,
        eye_Color: values.eye_Color,
        gender: values.gender,
        weight: values.weight + values.weightMeasurement,
        pet_type: values.pet_type,
        zip_code: values.zip_code,
        street_address: values.street_address,
        apt_city: values.apt_city,
        country: values.country,
        state: values.state,
      };
      const res = await updatePetInfoById(id, value);
      if (image instanceof File) {
        await updateImage(res?.data);
      }
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error({ err });
    }
  };

  const updateImage = async (data) => {
    try {
      const updateData = {
        history_id: data?.history_id,
        type: image?.type,
        file_name: `${Date.now()}-${image?.name}`
      };
      const res = await updatePetImageById(id, updateData);
      const url = res?.data?.data;
      const decodedUrl = decodeURIComponent(url);
      const uploadRes = await uploadS3Bucket(decodedUrl, image);
      if (uploadRes?.status === 200) { 
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendData = (e) => {
    updateForm(e);
  };

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Pet Information",
    pageStyle: `
      @page {
        size: A3;
        margin: 200px;
      }
      body {
        margin: 100px;
        padding: 10px;
      }
    `,
    printStyle: `
     
      .print-text-field {
        font-size: 14px;
        padding: 10px;
        margin-bottom: 20px;
        
    `,
  });

  const handleCountryChange = (e, newValue) => {
    const state = getStateAsOptions(newValue?.isoCode);
    const filteredState = state?.map((item) => {
      return {
        value: item.label,
        label: item.label,
      };
    });
    filteredState.push({ value: "Other", label: "Other" });
    setStateOption(filteredState);
    formikRef?.current?.setFieldValue("state", "");
  };

  return (
    <FormLayout title="Edit Pet Information">
      <Formik
        initialValues={initialValue}
        innerRef={formikRef}
        onSubmit={sendData}
        enableReinitialize
        validationSchema={PetInfoFormValidation}
        validateOnMount={false}
      >
        {({ getFieldProps, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Pet Details
                </Typography>
              </Box>
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <ImageUpload
                    alt="Uploaded_Image"
                    id="imageInput"
                    image={image}
                    setImage={setImage}
                    accept="image/*"
                    boxSx={{
                      width: { sm: 120, md: 120, xs: 93, lg: 130, xl: 130 },
                      height: {
                        sm: 120,
                        md: 120,
                        xs: 105,
                        lg: 143,
                        xl: 143,
                      },
                    }}
                  />
                </Box>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_type" label={"Pet Type"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_type")}
                        name="pet_type"
                        options={petTypeOptions}
                        placeholder={"Select a type"}
                        otherType="Other"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="name" label={"Pet Name"} requiredField>
                      <InputField {...getFieldProps("name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="breed" label={"Breed"} requiredField>
                      <InputField {...getFieldProps("breed")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="marks" label={"Marks"}>
                      <InputField {...getFieldProps("marks")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="family_name"
                      label={"Family Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("family_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                      <Box style={{ width: "50%" }}>
                        <FormGroup name="membership_no" label={"Membership No"}>
                          <InputField
                            {...getFieldProps("membership_id")}
                            disabled
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="membership_no" label={"Membership No"}>
                          <InputField
                            {...getFieldProps("membership_no")}
                            disabled
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <FormGroup name="dob" label={"Date of Birth"} requiredField>
                      <InputField
                        type="date"
                        className="Date"
                        inputProps={{ max: maxDate }}
                        {...getFieldProps("dob")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="street_address" label={"Street Address"}>
                      <AutoCompleteAddress
                        formikRef={formikRef}
                        setFieldValue={setFieldValue}
                        value={values?.street_address}
                        address="street_address"
                        city="apt_city"
                        state="state"
                        zipcode="zip_code"
                        country="country"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                      <Box style={{ width: "20%" }}>
                        <FormGroup name="weight" label={"Weight"} requiredField>
                          <InputField
                            type="number"
                            {...getFieldProps("weight")}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "40%" }}>
                        <FormGroup
                          name="weightMeasurement"
                          label={"Measure"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("weightMeasurement")}
                            name="weightMeasurement"
                            options={weightMeasurementOptions}
                            placeholder="Select a Measure"
                          />
                        </FormGroup>
                      </Box>

                      <Box style={{ paddingLeft: "5px", width: "40%" }}>
                        <FormGroup name="gender" label={"Gender"} requiredField>
                          <AutoComplete
                            {...getFieldProps("gender")}
                            name="gender"
                            options={genderOptionsCustom}
                            placeholder="Select a gender"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <FormGroup name="country" label={"Country"}>
                      <AutoComplete
                        {...getFieldProps("country")}
                        name="country"
                        options={countryOptions}
                        placeholder="Select a country"
                        onChange={handleCountryChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>

                  <Column>
                    <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="fur_color"
                          label={"Color"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("fur_color")}
                            name="fur_color"
                            otherType="Other"
                            options={furColorOptions}
                            placeholder={"Select a color"}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="eye_Color"
                          label={"Eye Color"}
                          requiredField
                        >
                          <AutoComplete
                            {...getFieldProps("eye_Color")}
                            name="eye_Color"
                            otherType="Other"
                            options={eyeColorOptions}
                            placeholder={"Select a color"}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                      <Box style={{ width: "50%" }}>
                        <FormGroup name="state" label={"State"}>
                          <AutoComplete
                            {...getFieldProps("state")}
                            name="state"
                            otherType="Other"
                            options={stateOption}
                            placeholder={"Select a state"}
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="zip_code" label={"Zip Code"}>
                          <InputField
                            {...getFieldProps("zip_code")}
                            name="zip_code"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                      <Box style={{ width: "50%" }}>
                        {/* <Box sx={genderStyle}> */}
                        <FormGroup
                          name="chip_availability"
                          label={"Micro Chip Availability"}
                        >
                          <AutoComplete
                            {...getFieldProps("chip_availability")}
                            name="chip_availability"
                            options={chipOptionsCustom}
                            placeholder="Select a Micro Chip Availability"
                            textFieldStyle={{
                              width: { sm: 145, md: 135, xs: 130, lg: 171 },
                              height: 30,
                            }}
                          />
                        </FormGroup>
                        {/* </Box> */}
                      </Box>

                      {values.chip_availability === "Yes" && (
                        <Box style={{ paddingLeft: "5px", width: "50%" }}>
                          <FormGroup
                            name="reg_number"
                            label={"Register Number"}
                          >
                            <InputField
                              {...getFieldProps("reg_number")}
                              name="reg_number"
                            />
                          </FormGroup>
                        </Box>
                      )}
                    </Box>
                  </Column>

                  <Column>
                    <FormGroup name="apt_city" label={"City"}>
                      <InputField {...getFieldProps("apt_city")} />
                    </FormGroup>
                  </Column>
                  {values.chip_availability === "Yes" && (
                    <Column>
                      <Box sx={{ ...newSmallFieldBoxStyle, paddingBottom: 0 }}>
                        <Box style={{ width: "50%" }}>
                          <FormGroup name="company_name" label={"Company Name"}>
                            <InputField
                              {...getFieldProps("company_name")}
                              name="company_name"
                            />
                          </FormGroup>
                        </Box>
                        <Box style={{ paddingLeft: "5px", width: "50%" }}>
                          <FormGroup
                            name="company_contact_number"
                            label={"Company Phone Number"}
                          >
                            <CustomPhoneInput
                              {...getFieldProps("company_contact_number")}
                            />
                          </FormGroup>
                        </Box>
                      </Box>
                    </Column>
                  )}
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    onClick={handlePrint}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your pet information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/pet-information/info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
}

export const smallFieldBoxStyle = {
  width: { sm: 304, md: 270, xs: 250, lg: 350, xl: 400 },
  display: "flex",
  alignItems: "start",
  justifyContent: { md: "left", sm: "left" },
  marginLeft: { xl: "0vw", lg: "0vw", md: "0vw" },
  paddingLeft: { xl: "3vw" },
};
