import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import viewIcon from "../../Assets/Images/viewIcon.svg";
import { formatDate } from "../../Utils";

const historyColumns = [
  {
    label: "Updated At",
    value: "created_at",
  },
  {
    label: "Updated By",
    value: "updatedBy",
  },
  {
    label: "Data",
    value: "data",
  },
];

function HistoryModal({ title, columns = historyColumns, data }) {
  const [historyModel, setHistoryModel] = useState(false);

  return (
    <div>
      {
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            margin: "0 auto",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <Button
              sx={{
                minWidth: "40px",
                background: "rgba(255, 133, 51, 0.20) !important",
                borderRadius: "10px",
                color: "#FF8533",
                fontSize: "14px",
                fontWeight: 400,
              }}
              onClick={() => setHistoryModel(true)}
            >
              <img src={viewIcon} alt="view icon" /> &nbsp; View History
            </Button>
          </div>
          <div>
            <p style={{ color: "black", textAlign: "start" }}>
              Last Updated at: {formatDate(data?.[0]?.created_at)}
            </p>
          </div>
        </Box>
      }
      <Modal
        open={historyModel}
        onClose={() => setHistoryModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Close
              onClick={() => setHistoryModel(false)}
              sx={{ cursor: "pointer" }}
            />
          </div>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "68vh",
              maxWidth: "70vw",
              boxShadow: "none",
              border: "1px solid #FF8533",
              borderRadius: "10px",
            }}
          >
            <Table
              // stickyHeader
              sx={{ minWidth: 650, tableLayout: "auto" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    background: "rgba(255, 133, 51, 0.20)",
                    borderBottom: "1px solid #FF8533",
                  }}
                >
                  {columns?.map((item, index) => (
                    <TableCell key={index} sx={{ fontWeight: 600, width: 100 }}>
                      {item.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((cell, index) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={index}
                    >
                      <React.Fragment key={index}>
                        {columns.map((column, cellIndex) => {
                            return (
                              <TableCell
                                key={cellIndex}
                                component="th"
                                sx={{
                                  width: 150,
                                  fontSize: "14px",
                                  // borderBottom: "none",
                                }}
                              >
                                {column.value === "data" ? (
                                  <div style={{ width: '100%'}}>
                                    <Table
                                      sx={{
                                        width: "auto",
                                        tableLayout: "auto",
                                      }}
                                      aria-label="simple table"
                                    >
                                      <TableHead>
                                        <TableRow
                                          sx={{
                                            background:
                                              "rgba(255, 133, 51, 0.20)",
                                            // borderBottom: "1px solid #FF8533"
                                          }}
                                        >
                                          <TableCell
                                            key={index}
                                            sx={{
                                              fontWeight: 600,
                                              width: 30,
                                            }}
                                          >
                                          </TableCell>
                                          {Object.keys(
                                            cell?.[column?.value]
                                          )?.map((item, index) => (
                                            <TableCell
                                              key={index}
                                              sx={{
                                                fontWeight: 600,
                                                width: 100,
                                              }}
                                            >
                                              {item}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              { border: 0 },
                                          }}
                                        >
                                          <TableCell
                                            sx={{
                                              fontWeight: 600,
                                              width: 30,
                                              borderBottom: "none"
                                            }}
                                          >
                                            OldData
                                          </TableCell>
                                          {Object.entries(
                                            cell?.[column?.value]
                                            )?.map((item, index) => {
                                              if (item[0] === 'image') {
                                                return (
                                                  <TableCell
                                                    key={`${index}-${cellIndex}`}
                                                    sx={{
                                                      width: 100,
                                                      borderBottom: "none",
                                                    }}
                                                  >
                                                    <PetLogo
                                                      src={item[1]?.oldValue}
                                                      style={{ verticalAlign: "middle" }}
                                                    />
                                                  </TableCell>
                                                );
                                              }
                                            return (
                                              <TableCell
                                                key={`${index}-${cellIndex}`}
                                                component="th"
                                                sx={{
                                                  // width: 50,
                                                  fontSize: "14px",
                                                  borderBottom: "none",
                                                  color: "black",
                                                }}
                                              >
                                                {item[1]?.oldValue || "-"}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              { border: 0 },
                                          }}
                                        >
                                          <TableCell
                                            key={index}
                                            sx={{
                                              fontWeight: 600,
                                              // width: 30,
                                            }}
                                          >
                                            NewData
                                          </TableCell>
                                          {Object.entries(
                                            cell?.[column?.value]
                                            )?.map((item, index) => {
                                              if (item[0] === 'image') {
                                                return (
                                                  <TableCell
                                                    key={`${index}-${cellIndex}`}
                                                    sx={{
                                                      width: 100,
                                                      borderBottom: "none",
                                                    }}
                                                  >
                                                    <PetLogo
                                                      src={item[1]?.newValue}
                                                      style={{ verticalAlign: "middle" }}
                                                    />
                                                  </TableCell>
                                                );
                                              }
                                            return (
                                              <TableCell
                                                key={index}
                                                component="th"
                                                sx={{
                                                  // width: 50,
                                                  fontSize: "14px",
                                                  borderBottom: "none",
                                                  color: "black",
                                                }}
                                              >
                                                {item[1]?.newValue || "-"}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </div>
                                ) : (
                                  cell[column.value]
                                )}
                              </TableCell>
                            );
                        })}
                      </React.Fragment>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default HistoryModal;

const PetLogo = styled("img")(() => ({
  borderRadius: "25px",
  width: "69px",
  height: "68px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "70%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  // overflowY: "auto"
};
