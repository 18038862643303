import React from "react";

import VetTherapist from "../../Components/Item-List/Vet&Therapist";
import Layout from "../../Components/Side bar/Layout";

const ItemListVetTherapist = () => {

  return (
    <Layout>
      <VetTherapist />
    </Layout>
  );
};

export default ItemListVetTherapist;
