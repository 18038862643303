import { ADMIN, GENERAL_AUTHORIZED_USER, SUPER_ADMIN_USER, USER, VIEW_ONLY_USER } from "./Constant";

const ADMIN_ROLE = [USER, GENERAL_AUTHORIZED_USER, ADMIN, VIEW_ONLY_USER];
const SUPER_ADMIN_ROLE = [SUPER_ADMIN_USER];
export const config = {
  sidebar: [
    {
      title: "Organizations",
      link: "/workspace-information",
      icon: "",
      roles: SUPER_ADMIN_ROLE,
    },
    {
      title: "Membership Information",
      link: "/memberShip-information",
      icon: "",
      roles: SUPER_ADMIN_ROLE,
    },
    {
      title: "User Information ",
      link: "/user-information",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Pet Information",
      link: "/pet-information",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Emergency Contacts",
      link: "/emergency-contact",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Vets and Therapists",
      link: "/vet-and-therapist",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Prescriptions and Medications",
      link: "/prescription-and-medication",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Pet Insurance Information",
      link: "/pet-insurance",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Immunizations",
      link: "/immunization",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Allergies",
      link: "/Allergy",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Conditions",
      link: "/conditions",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Procedures and Treatments",
      link: "/surgery-and-treatment",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Journal",
      link: "/journal",
      icon: "",
      roles: ADMIN_ROLE,
    },
    {
      title: "Documents",
      link: "/document",
      icon: "",
      roles: ADMIN_ROLE,
    },
    // {
    //   title: "Provider Information ",
    //   link: "/provider-information",
    //   icon: "",
    //   roles: ADMIN_ROLE,
    // },
    {
      title: "Archive",
      link: "/archive",
      icon: "",
      roles: ADMIN_ROLE,
    },
    // {
    //   title: "Drag and Drop",
    //   link: "/drag-and-drop",
    //   icon: "",
    //   roles: ADMIN_ROLE,
    // },
    {
      title: "",
      link: "/settings",
      icon: require("./Assets/Images/Sidebar/icons8-settings-50.png"),
      roles: ADMIN_ROLE,
    },
  ]
};
