import { jsonToQueryString } from "../../Utils";
import { api, ImageAPI } from "../axios";

//User
export const getUser = async (id) => {
  const res = await api.get(`/user/getUserById/${id}`);
  return res;
};
export const getMembershipNoByUserId = async (id) => {
  const res = await api.get(`/user/getMembershipNoByUserId/${id}`);
  return res;
};

// user information item list
export const getUserInfoList = async () => {
  const res = await api.get(`/user/getAllUsers`);
  return res;
};
// user contact
export const getUserContact = async () => {
  const res = await api.get(`/user_contact/getAllUserContacts`);
  return res;
};

//user information
export const createUser = async (formData) => {
  const res = await api.post(`/user/addUser`, formData);
  return res;
};
export const getUserById = async (id) => {
  const res = await api.get(`/user/getUserById/${id}`);
  return res;
};

export const getInviteUserById = async (id, w_id) => {
  const res = await api.get(`/user/getInviteUserById/${w_id}/${id}`);
  return res;
};
export const updateUserById = async (id, formData) => {
  const res = await api.put(`/user/updateUser/${id}`, formData);
  return res;
};
export const createUserContact = async (formData) => {
  const res = await api.post(`/user_contact/create`, formData);
  return res;
};
export const getUserContactById = async (id) => {
  const res = await api.get(`/user_contact/getUserContactById/${id}`);
  return res;
};
export const updateUserContactById = async (id, formData) => {
  const res = await api.put(`/user_contact/updateUserContact/${id}`, formData);
  return res;
};
export const updateUserImageById = async (id, formData) => {
  const res = await ImageAPI.put(`/user/updateImage/${id}`, formData);
  return res;
};

export const getUserRoleByUserId = async (id) => {
  const res = await api.get(`/user/getUserRoleByUserId/${id}`);
  return res;
};

export const getIdbyUserIdAndContact = async (formData) => {
  const res = await api.post(`/user_contact/getusercontactId`, formData);
  return res;
};

export const getEmail = async (formData) => {
  const res = await api.post(`/user/checkEmail`, formData);
  return res;
};

// Password reset
export const updateUserPasswordById = async (id, formData) => {
  const res = await api.put(`/user/resetPassword/${id}`, formData);
  return res;
};

export const updateUser = async (value, id) => {
  const res = await api.put(`user/updateUser/${id}`, value);
  return res;
};

//get contact by user id
export const getContactByUserID = async (id) => {
  const res = await api.get(`/user/getUserAndUserContactByUserId/${id}`);
  return res;
};

//Sub Users
export const getSubUserRole = async (id) => {
  const res = await api.get(`/subUser/getUserRoleByUserId/${id}`);
  return res;
};

export const getCarearsByUserId = async (w_id, user_id) => {
  const res = await api.get(`/user/getCarearsByUserId/${w_id}/${user_id}`);
  return res;
};

export const getUsersList = async (w_id) => {
  const res = await api.get(`/user/getUsersList/${w_id}`);
  return res;
};

export const getUsersByWorkspaceId = async (w_id) => {
  const res = await api.get(`/user/getUsersByWorkspaceId/${w_id}`);
  return res;
};
export const updateSubUserPasswordById = async (id, formData) => {
  const res = await api.put(`/subUser/resetPassword/${id}`, formData);
  return res;
};
export const getUserDetailsContactById = async (id) => {
  const res = await api.get(`/subUser/getUserById/${id}`);
  return res;
};
//Delete
export const deleteUserInfo = async (w_id, id) => {
  const res = await api.delete(`/user/deleteInviteUser/${w_id}/${id}`);
  return res;
};

export const createSubUser = async (formData) => {
  const res = await api.post(`/user/addUser`, formData);
  return res;
};
export const getSubUserById = async (id) => {
  const res = await api.get(`/subUser/getUserById/${id}`);
  return res;
};
export const getMembershipNoBySubUserId = async (id) => {
  const res = await api.get(`/subUser/getMembershipNoBySubUserId/${id}`);
  return res;
};
export const updateSubUserById = async (id, w_id, formData) => {
  const res = await api.put(`/user/updateInviteUser/${w_id}/${id}`, formData);
  return res;
};
export const updateSubUserImageById = async (id, formData) => {
  const res = await ImageAPI.put(`/subUser/updateImage/${id}`, formData);
  return res;
};
export const getSubUserEmail = async (formData) => {
  const res = await api.post(`/subUser/checkEmail`, formData);
  return res;
};
export const emailValidation = async (formData) => {
  const res = await api.post(`/user/emailvalidation`, formData);
  return res;
};

export const getUserWorkspaceList = async (userId) => {
  const res = await api.get(`/user/getUserWorkspaceList/${userId}`);
  return res;
};

export const selectUserWorkspace = async (formData) => {
  const res = await api.post(`/user/selectWorkspace`, formData);
  return res;
};

export const getAllWorkspaceList = async () => {
  const res = await api.get(`/user/getWorkspaceList`);
  return res;
};

export const getHistoryById = async (model, id) => {
  const res = await api.get(`/history/getHistoryById/${model}/${id}`);
  return res;
};

export const getPetHistoryById = async (model, id) => {
  const res = await api.get(`/history/getPetHistoryById/${model}/${id}`);
  return res;
};

export const getAllRoles = async (id) => {
  const res = await api.get(`/role/getAllRole`);
  return res;
};


export const getAllMembership = async (filter) => {
  const res = await api.get(`/mem/getAllMembership${jsonToQueryString(filter)}`);
  return res;
};

export const getMembershipById = async (id) => {
  const res = await api.get(`/mem/getMembershipById/${id}`);
  return res;
};

export const AddMembership = async (value) => {
  const res = await api.post(`/mem/AddMembership`, value);
  return res;
};
//archieve user info
// export const getArchiveUserInfoByUserID = async (id) => {
//   const res = await api.get(`/subUser/getDataByUserId/${id}`);
//   return res;
// }
