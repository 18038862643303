import React, { useState, useEffect, useRef } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { postAllMedicationData } from "../../Api/Services/MedicationServices";
import { getPetNameByWorkspaceIdPres } from "../../Api/Services/PetServices";
import {
  createPharmacy,
  getPharmacyById,
  getPharmacyByWorkspaceId,
} from "../../Api/Services/PharmacyServices";
import { getAllVetnamePres } from "../../Api/Services/VetServices";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { PrescriptionFormValidation } from "../../From/Validation";
import {
  dosageOptions,
  durationOptions,
  frequencyOptions,
} from "../../Options";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";
import TextArea from "../Common/TextArea";
import "react-phone-input-2/lib/style.css";
import "./input.css";
//import axios from "axios";

const defaultValue = {
  type: "",
  medication_name: "",
  durtion: "",
  dosage: "",
  frequency: "",
  start_date: "",
  reason: "",
  prescripton_number: "",
  pet_id: "",
  vet_id: "",
  pharmacy_id: "",
  pharmacy_contact: "",
  durationMeasurement: "",
  dosageMeasurement: "",
  frequencyMeasurement: "",
  pharmacy_name: "",
};

export default function PrescriptForm() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  //let navigate = useNavigate();
  const formRef = useRef();
  const { workspaceId: w_id } = useAuth();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const maxDate = new Date().toISOString().substr(0, 10);

  const [nameOptions, setNameOptions] = useState([]);
  const [vetNameOptions, setVetNameOptions] = useState([]);

  //Pharmacy name
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [pharmacy_id, setPharmacy_id] = useState("");

  const handlePharmacyName = (e, newvalue) => {
    const selectedValue = newvalue?.value;

    if (selectedValue === "Add a new Pharmacy") {
      setPharmacy_id(""); // Clear the pharmacy_id state when "Add a new Pharmacy" is selected
      setPharmacyOpen(true);
      // setPharmacy_contact("");
      formRef.current?.setFieldValue("pharmacy_id", "");
      formRef.current?.setFieldValue("pharmacy_contact", "");
    } else {
      setPharmacy_id(selectedValue);
      setPharmacyOpen(false);
      getPharmacyById(selectedValue)
        .then((res) => {
          const data = res.data.data[0];
          if (data) {
            // setPharmacy_contact(data.pharmacy_contact);
            formRef.current?.setFieldValue(
              "pharmacy_contact",
              data.pharmacy_contact
            );
          } else {
            // setPharmacy_contact("");
            formRef.current?.setFieldValue("pharmacy_contact", "");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //Add new Vet
  const navigate = useNavigate();

  const handleSelectChange = (e, newValues) => {
    const selectedValue = newValues.value;
    if (selectedValue === "vet") {
      navigate("/vet-and-therapist/form");
    } else {
      formRef.current.setFieldValue("vet_id", newValues?.value);
    }
  };

  //Add new Pharmacy
  const [pharmacyOpen, setPharmacyOpen] = useState(false);

  //sendPharmacyData function
  const sendPharmacyData = (values, setSubmitting) => {
    return new Promise((resolve, reject) => {
      const formData = {
        pharmacy_name: values?.pharmacy_name,
        pharmacy_contact: values?.pharmacy_contact,
        w_id,
      };
      createPharmacy(formData)
        .then((res) => {
          const pharmacyId = res.data.Pharmacy_data.id; // Access the ID from the response
          formRef.current?.setFieldValue("pharmacy_id", pharmacyId);
          resolve(pharmacyId);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
          setSubmitting(false);
        });
    });
  };

  const sendMedicationData = async (values, pharmacy_id) => {
    const value = {
      type: values?.type,
      medication_name: values?.medication_name,
      durtion: values?.durtion + " " + values?.durationMeasurement,
      dosage: values?.dosage + " " + values?.dosageMeasurement,
      frequency: values?.frequency + " " + values?.frequencyMeasurement,
      start_date: values?.start_date,
      reason: values?.reason,
      prescripton_number: values?.prescripton_number,
      pet_id: values?.pet_id,
      vet_id: values?.vet_id,
      pharmacy_id: pharmacy_id,
      //pet_name: pet_name,
      //user_id: user_id,
      //vet_practice_name: vet_fulname,
      // pharmacy_name: pharmacy_name,
      pharmacy_contact: values?.pharmacy_contact,
    };

    try {
      postAllMedicationData(value)
        .then((res) => {
          setShowSuccessMessage(true);
          setOpen(true);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getPetNames = async () => {
    try {
      const res = await getPetNameByWorkspaceIdPres(w_id);
      const filteredData = res?.data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setNameOptions(filteredData);
    } catch (error) {
      console.error("Error fetching pet data: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
  }, []);

  const getAllVetNames = async () => {
    try {
      const res = await getAllVetnamePres(w_id);
      const uniqueValues = res?.data?.data?.filter(
        (obj, index, self) => index === self.findIndex((el) => el.id === obj.id)
      );
      let vetNames = [];
      vetNames = uniqueValues?.map((item) => {
        return {
          value: item?.id,
          label: item?.vet_full_name,
        };
      });
      vetNames?.push({ value: "vet", label: "Add a new contact" });
      setVetNameOptions(vetNames);
    } catch (error) {
      console.error("Error fetching vet data: ", error);
    }
  };

  const getAllPharmacyNames = async () => {
    try {
      const res = await getPharmacyByWorkspaceId(w_id);
      let PharmacyNames = [];
      PharmacyNames = res?.data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.pharmacy_name,
        };
      });
      PharmacyNames?.push({
        value: "Add a new Pharmacy",
        label: "Add a new Pharmacy",
      });
      setPharmacyOptions(PharmacyNames);
    } catch (error) {
      console.error("Error fetching pharmacy data: ", error);
    }
  };

  useEffect(() => {
    getAllVetNames();
    getAllPharmacyNames();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (values?.pharmacy_id === "") {
      try {
        let pharmacyId;
        if (values?.pharmacy_name !== "") {
          pharmacyId = await sendPharmacyData(values, setSubmitting);
        }
        await sendMedicationData(values, pharmacyId);
        setSubmitting(false);
      } catch (error) {
        console.error("Failed to send pharmacy or medication data:", error);
      }
    } else {
      // User selected an existing pharmacy from the dropdown
      try {
        await sendMedicationData(values, values?.pharmacy_id);
      } catch (error) {
        console.error("Failed to send medication data:", error);
        setSubmitting(false);
      }
    }
  };

  return (
    <FormLayout title="Prescription and Medication Information">
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={PrescriptionFormValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting, setFieldValue, errors }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Prescription and Medication Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%"}}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={nameOptions}
                        placeholder="Pet Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="type"
                      label={"Medication Type"}
                      requiredField
                    >
                      <InputField {...getFieldProps("type")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="medication_name"
                      label={"Medication Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("medication_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="vet_id"
                      label={"Prescribing Vet"}
                      requiredField
                    >
                      <AutoComplete
                        options={vetNameOptions}
                        {...getFieldProps("vet_id")}
                        onChange={handleSelectChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="frequency"
                          label={"Frequency"}
                          requiredField
                        >
                          <InputField {...getFieldProps("frequency")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="frequencyMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("frequencyMeasurement")}
                            options={frequencyOptions}
                            otherType="Other"
                            // boxStyle={{ ...smallAutoCompleteStyle }}
                            placeholder="Select Frequency"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>

                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup name="dosage" label={"Dosage"} requiredField>
                          <InputField {...getFieldProps("dosage")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="dosageMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("dosageMeasurement")}
                            options={dosageOptions}
                            otherType="Other"
                            // boxStyle={{ ...smallAutoCompleteStyle }}
                            placeholder="Select Dosage"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <FormGroup
                      name="start_date"
                      label={"Start Date"}
                      requiredField
                    >
                      <InputField
                        type="date"
                        {...getFieldProps("start_date")}
                        inputProps={{ max: maxDate }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="durtion"
                          label={"Duration"}
                        >
                          <InputField {...getFieldProps("durtion")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup name="durationMeasurement">
                          <Box sx={{ marginTop: "21px" }}></Box>
                          <AutoComplete
                            {...getFieldProps("durationMeasurement")}
                            options={durationOptions}
                            otherType="Other"
                            placeholder="Select Duration"
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    {pharmacyOpen ? (
                      <FormGroup name="pharmacy_name" label={"Pharmacy Name"}>
                        <InputField
                          {...getFieldProps("pharmacy_name")}
                          onChange={(e) => {
                            // setPharmacy_name(e.target.value);
                            setFieldValue("pharmacy_name", e.target.value);
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                position="end"
                                onClick={() => {
                                  setPharmacyOpen(false);
                                  setFieldValue("pharmacy_name", "");
                                  setFieldValue("pharmacy_id", "");
                                }}
                                sx={{
                                  color: "#fff",
                                  backgroundColor: "transparent",
                                  zIndex: 1,
                                  padding: 0,
                                  cursor: "pointer",
                                  width: "auto",
                                }}
                              >
                                <ClearIcon
                                  sx={{ backgroundColor: "rgb(252, 132, 51)" }}
                                />
                              </IconButton>
                            ),
                          }}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup name="pharmacy_id" label={"Pharmacy Name"}>
                        <AutoComplete
                          {...getFieldProps("pharmacy_id")}
                          options={pharmacyOptions}
                          onChange={handlePharmacyName}
                          isOnChange
                        />
                      </FormGroup>
                    )}
                  </Column>
                  <Column>
                    <FormGroup
                      name="pharmacy_contact"
                      label={"Pharmacy Phone Number"}
                    >
                      <CustomPhoneInput
                        {...getFieldProps("pharmacy_contact")}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="prescripton_number"
                      label={"Prescription Number"}
                    >
                      <InputField {...getFieldProps("prescripton_number")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup
                      name="reason"
                      label={"Reason for Use"}
                      requiredField
                    >
                      <TextArea
                        rows={10}
                        placeholder="Enter the reason"
                        inputProps={{ maxLength: 300 }}
                        {...getFieldProps("reason")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
            {showSuccessMessage && (
              <CustomSnackbar
                title="Data added successfully!"
                desc="New prescription has been created successfully."
                open={open}
                okOnclick={() => {
                  setShowSuccessMessage(false);
                  // window.location.reload();
                  navigate("/prescription-and-medication");
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
}

const Col1 = styled.div`
  display: grid;
  grid-template-rows: repeat(7, 55px);

  @media screen and (max-width: 1053px) {
    grid-template-rows: auto;
  }

  @media screen and (max-width: 520px) {
    width: 350px;
    padding-left: 0px;
    grid-template-rows: auto;
  }
`;
const Col2 = styled.div`
  display: grid;
  grid-template-rows: repeat(7, 55px);
  margin-left: 60px;

  @media screen and (max-width: 1370px) {
    width: 350px;
    padding-left: 0px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  &.scroll2 {
    padding: 12px;
    justify-content: center;
    flex-grow: 1;
    padding-top: 1px;
    overflow-x: hidden;

    @media screen and (min-width: 1485px) {
      margin-left: 110px;
      width: 960px;
      justify-content: space-around;
    }

    @media screen and (max-width: 600px) {
      padding: 0px;
    }
  }
`;
