import { api, ImageAPI } from "../axios";

//archieve pet insurance
export const getArchivePetInsuranceByWorkspaceID = async (id) => {
  const res = await api.get(`/petInsurance/getArchieveDataByWorkspaceId/${id}`);
  return res;
};

export const getPetInsurance = async (id) => {
  const res = await api.get(`/petInsurance/getPetInsuranceById/${id}`);
  return res;
};

export const getAllItemListPetInsurance = async () => {
  const res = await api.get(`/petInsurance/getAllPetInsurance`);
  return res;
};

export const getPetInsuranceByWorkspaceID = async (w_id) => {
  const res = await api.get(`/petInsurance/getDataByWorkspaceId/${w_id}`);
  return res;
};

export const getSelectedInsuranceByID = async (id) => {
  const res = await api.get(`/petInsurance/getPetInsuranceById/${id}`);
  return res;
};

//pet_vet_insurance
export const createPetInsurance = async (formData) => {
  const res = await api.post(`/petInsurance/createPetInsurance`, formData);
  return res;
};

//insurance
export const getpetinsurancebyid = async (id) => {
  const response = await api.get(`/petInsurance/getPetInsuranceById/${id}`);
  return response;
};
export const Updatepetinsuance = async (id, formData) => {
  const res = await api.put(`/petInsurance/updatePetInsurance/${id}`, formData);
  return res;
};

//Delete PetInsurance
export const deleteInsuarance = async (id) => {
  const res = await api.delete(`/petInsurance/deletePetInsurance/${id}`);
  return res;
};

export const getInsuranceProvider = async (id) => {
  const res = await api.get(
    `/insurance_provider/getInsuranceProviderById/${id}`
  );
  return res;
};

export const getbyIDInsuranceProvider = async (id) => {
  const res = await api.get(
    `/insurance_provider/getInsuranceProviderById/${id}`
  );
  return res;
};

export const getProviderNamesByWorkspaceId = async (id) => {
  const res = await api.get(`/petInsurance/getProvidersDataByWorkspaceID/${id}`);
  return res;
};

export const getSubscriberNamesByWorkspaceId = async (id) => {
  const res = await api.get(`/petInsurance/getSubscribersDataByWorkspaceID/${id}`);
  return res;
};

//set insurance archive
export const setPetInsuranceArchieve = async (id) => {
  const res = await api.post(`/petInsurance/setArchieve/${id}`);
  return res;
};
// insurance unarchive
export const setPetInsuranceUnarchive = async (id) => {
  const res = await api.post(`/petInsurance/setunArchieve/${id}`);
  return res;
};

