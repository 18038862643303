import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { isEqual } from "date-fns";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import {
  getAllMembership,
  getMembershipById,
} from "../../../Api/Services/UserServices.js";
import viewIcon from "../../../Assets/Images/viewIcon.svg";
import { jsonToQueryString } from "../../../Utils.js";
import PawItemList from "../../Common/PawItemList.js";
import { Box, Button, Modal, Typography } from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";
import AddMembershipForm from "./AddMembershipForm.js";
import ViewMembershipDetail from "./ViewMembershipDetail.js";

export default function MembershipDetails() {
  const [filter, setFilter] = useState({
    limit: 10,
    from: 0,
    page: 1
  });
  const [open, setOpen] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [membershipDetails, setMembershipDetails] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const fetchIdRef = useRef(0);

  const { data = [], isLoading, mutate: fetchAPIData } = useSWR(
    `membershipInformation-${jsonToQueryString(filter)}`,
    async () => {
      try {
        const { limit } = filter
        const { data } = await getAllMembership(filter);
        const filteredResult = data?.data?.map((item) => {
          return {
            ...item,
            start_date: item.start_date
              ? moment(item.start_date).format("YYYY-MM-DD")
              : "-",
            end_date: item?.end_date
              ? moment(item?.end_date).format("YYYY-MM-DD")
              : "-",
            is_active: item?.is_active === "true" ? "Yes" : "No",
          };
        });
        if (data?.data?.length && limit) {
          setPageCount(Math.ceil(data?.totalCount / limit));
        }
        return filteredResult || [];
      } catch (error) {
        console.error("error: ", error);
        if (
          error?.response?.data?.message === "Access denied! Unautherized user."
        ) {
          navigate(-1);
        }
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
    // eslint-disable-next-line
  }, []);

  const openViewModal = async (id) => {
    setOpen(true);
    setIsViewModal(true);
    try {
      setLoading(true);
      const { data } = await getMembershipById(id);
      if (data?.data) {
        const res = data?.data[0];
        const filteredResult = [
          { name: "Membership Number", value: res?.membership_no },
          { name: "Username", value: res?.username },
          { name: "Active", value: res?.is_active === "true" ? "Yes" : "No" },
          { name: "Workspace Name", value: res?.workspace_name },
          { name: "Start Date", value: moment(res?.start_date).format("YYYY-MM-DD") },
          { name: "End Date", value: moment(res?.end_date).format("YYYY-MM-DD") },
        ];
        setMembershipDetails(filteredResult);
      } else {
        setMembershipDetails([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Usename",
        accessor: "username",
        width: "15vw",
      },
      {
        Header: `Membership Number`,
        accessor: "membership_no",
        width: "15vw",
      },
      {
        Header: `Active`,
        accessor: "is_active",
        width: "5vw",
      },
      {
        Header: `Start Date`,
        accessor: "start_date",
        width: "12.5vw",
      },
      {
        Header: `End Date`,
        accessor: "end_date",
        width: "12.5vw",
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        textAlign: "center",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div
              style={{ display: "flex", height: "40px", alignItems: "center" }}
            >
              <Button
                sx={{
                  minWidth: "40px",
                  background: "rgba(255, 133, 51, 0.20) !important",
                  borderRadius: "10px",
                  marginRight: "5px",
                  width: "24px",
                  cursor:
                    row?.original?.is_active === "Yes"
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => openViewModal(row?.original?.id)}
                disabled={row?.original?.is_active !== "Yes"}
              >
                <img
                  alt="view"
                  src={viewIcon}
                  style={{
                    color: "#000000",
                    cursor:
                      row?.original?.is_active === "Yes"
                        ? "pointer"
                        : "not-allowed",
                  }}
                />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  const defaultOptions = {
    pageSize: 10,
  };

  const openModal = () => {
    setOpen(true);
    setIsViewModal(false);
  };
  const props = {
    label: "Membership Information",
    isLoading: isLoading,
    columns: columns,
    columnsData: data,
    dataAddLink: "/memberShip-information/form",
    viewDataLink: (data) => `/membership-information/info/${data.id}`,
    details: "There are no membership details found.",
    isSelectHide: true,
    fetchData,
    pageCount,
    defaultOptions,
    addOnclick: openModal,
  };

  const handleClose = () => {
    setOpen(false);
    setIsViewModal(false);
  };

  return (
    <>
      <PawItemList {...props} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: '10px' }}>
            <Typography component="div" sx={{color: '#565868', fontSize: '18px', fontWeight: 400}}>
              {isViewModal ? "Membership Details" : "Add Membership Numbers"}
            </Typography>
            <Close onClick={handleClose} style={{ color: '#FF8355', cursor: 'pointer'}} />
          </div>
          {isViewModal ? (
            <ViewMembershipDetail data={membershipDetails} loading={loading} />
          ) : (
            <AddMembershipForm handleClose={handleClose} fetchAPIData={fetchAPIData} />
          )}
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  // height: "50%",
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  overflowX: "hidden",
  borderRadius: "8px",
  outline: "none"
};
