import React from "react";

import PrescriptionAndMedication from "../../Components/Item-List/Prescription&Medication";
import Layout from "../../Components/Side bar/Layout";

const Prescription = () => {

  return (
    <Layout>
      <PrescriptionAndMedication />
    </Layout>
  );
};

export default Prescription;
