import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { isEqual } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getUsersList } from "../../Api/Services/UserServices.js";
import { filterAscDescData, formatPhoneNumber } from "../../Utils.js";
import PawItemList from "../Common/PawItemList.js";

export default function AdminUserlist() {
  const { id: workspaceId } = useParams();
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [userData, setUserData] = useState([]);
  const fetchIdRef = useRef(0);

  const [selectedUserName, setSelectedUserName] = useState("");
  let navigate = useNavigate();
  const { data = [], isLoading } = useSWR(
    workspaceId ? `userData_${workspaceId}` : null,
    async () => {
      try {
        const { data } = await getUsersList(workspaceId);
        const filterData = data.data.map((item) => {
          return {
            ...item,
            name: item.first_name,
            id: item.id,
            phone_number: formatPhoneNumber(item?.contact_no),
          };
        });
        return filterData || [];
      } catch (error) {
        console.error('error: ', error);
        if (error?.response?.data?.message === "Access denied! Unautherized user.") {
          navigate(-1);
        }
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { limit, from, sort } = filter;
    if (filter && filter.from > -1) {
      if (data?.length && limit) {
        setPageCount(Math.ceil(data?.length / limit));
      }
      const allData = [...data];
      const filteredResult = filterAscDescData(allData, sort, from, limit);
      setUserData(filteredResult);
    }
  }, [filter, data]);

  const result = userData
    ? userData.map((user) => {
        const { id, first_name, email, image, last_name, contact_no, user_id } =
          user;

        return {
          id,
          user_id,
          image,
          first_name,
          last_name,
          email: email,
          phone_number: formatPhoneNumber(contact_no),
        };
      })
    : [];

  const filteredData =
    selectedUserName === ""
      ? result
      : result.filter((item) => {
          const userName = item.first_name.toLowerCase() || "";
          const query = selectedUserName.toLowerCase();
          return userName.includes(query);
        });

  const userNamesSet = new Set();
  const filteredUserNames = data.filter((user) => {
    const lowercaseName = user.first_name?.toLowerCase();
    if (!userNamesSet.has(lowercaseName)) {
      userNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "image",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.image ? (
            <img
              alt=""
              src={row?.original?.image}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        width: "12.5vw",
      },
      {
        Header: `Last Name`,
        accessor: "last_name",
        width: "12.5vw",
      },
      {
        Header: "Email",
        accessor: "email",
        width: "12.5vw",
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        width: "12.5vw",
      }
    ];
  }, []);

  const defaultOptions = {
    pageSize: 5,
  };

  const props = {
      label: "Organization Users Information",
      back: "/workspace-information",
    setSelectedUserName: setSelectedUserName,
    items: filteredUserNames,
    isLoading: isLoading,
    columns,
    columnsData: filteredData,
    viewDataLink: (data) => `/user-information/user-info/${data?.id}`,
    placeholder: "user name",
    details: "There are no other user details for this organization.",
    isSelectHide: true,
    isArchive: true,
    AddPetArchive: true,
    fetchData,
    pageCount,
    defaultOptions,
  };
  return <PawItemList {...props} />;
}
