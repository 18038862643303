import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../Assets/Styles/AboutUs/MeetTeam.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import useWindowDimensions from "./windowDiamension";
import {
  getAboutUsTeamParagraph,
  getAboutUsTeamSection,
} from "../../api/axios";
import useSWR from "swr";

function MeetTeam() {
  const { data: item = [] } = useSWR(
    `aboutUsTeamSection`,
    async () => {
      try {
        const { data } = await getAboutUsTeamSection();
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
        alert(error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: paragraph } = useSWR(
    `aboutUsTeamParagraph`,
    async () => {
      try {
        const { data } = await getAboutUsTeamParagraph();
        return data?.data?.[0] || [];
      } catch (error) {
        console.error("error: ", error);
        alert(error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { width } = useWindowDimensions();
  let sp = 1;
  if (width > 1350) {
    sp = 3;
  }
  if (width > 1100 && width < 1350) {
    sp = 3;
  }
  if (width > 650 && width < 1099) {
    sp = 2;
  }

  const items = [...item];
  const repeatedItems = [...items, ...items, ...items];

  return (
    <div className="TeamWrapper">
      <div className="TeamHeader">
        Meet the <span>Team</span>
      </div>
      <div className="TeamBody">
        <p>{paragraph?.paragraph}</p>
      </div>
      <Swiper
        className="ImgSlideShow"
        style={{ width: "100%" }}
        slidesPerView={sp}
        spaceBetween={1}
        slidesPerGroup={1}
        loop={false}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {repeatedItems?.map((items, index) => (
          <SwiperSlide className="Slide-component" key={index}>
            <div className="img-component">
              <img src={items?.image} alt="meetteam" />
              <div className="img-text">
                <h4>{items?.name}</h4>
                <p>
                  {items?.owner} <br /> {items?.position}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default MeetTeam;
