import React from "react";

import ArchivePrescriptionComponent from "../../Components/Selected-item-view-Ar/Prescription/PrescriptionComponent";

const ArchiveSecond = () => {

  return (
    <>
      <ArchivePrescriptionComponent />
    </>
  );
};

export default ArchiveSecond;
