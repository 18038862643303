import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import {
  getArchivePetInfoByWorkspaceID,
  getArchivePetNamesByWorkspaceID,
  setPetUnarchive,
} from "../../Api/Services/PetServices.js";
import viewIcon from "../../Assets/Images/viewIcon.svg";
import useAuth from '../../ContextProvider/AuthProvider'
import { filterAscDescData, formatDate } from "../../Utils.js";
import DetailsModal from "../Common/DetailsModal.js";
import PawItemList from "../Common/PawItemList.js";

import "./ArchiveGlobalItemList.css";
import { Button } from "@mui/material";


export default function ArchivePetInformation() {
  const { workspaceId: w_id } = useAuth();
  const [selectedPetName, setSelectedPetName] = useState("");
  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [petData, setPetData] = useState([]);
  const fetchIdRef = useRef(0);
  const navigate = useNavigate();

  const { data: result = [], isLoading } = useSWR(
    w_id ? `archivePetdata${w_id}` : null,
    async () => {
      const { data } = await getArchivePetInfoByWorkspaceID(w_id);
      return (data?.data || []).map((pet) => {
        const { id, name, family_name, dob, image } = pet;

        const petDob = dob
          ? formatDate(dob)
          : "N/A";

        return {
          id: id,
          petId: id,
          petFamilyName: family_name,
          petDob,
          petImage: image,
          name,
        };
      });
    },
    {
      revalidateOnFocus: false,
    }
  );

  const fetchData = useCallback((oFilter) => {
    const fetchId = ++fetchIdRef.current;
    if (fetchId === fetchIdRef.current && !isEqual(filter, oFilter)) {
      setFilter(oFilter);
    }
  }, []);

  useEffect(() => {
    const { limit, from, sort, ...props } = filter;
    if (filter && filter.from > -1) {
      if (result?.length && limit) {
        setPageCount(Math.ceil((result?.length) / limit));
      }
      const allData = [...result];
      const filteredResult = filterAscDescData(allData, sort, from, limit);
      setPetData(filteredResult);
    }
  }, [filter, result]);

  const { data: petNames = [] } = useSWR(
    w_id ? `petNames${w_id}` : null,
    async () => {
      const { data } = await getArchivePetNamesByWorkspaceID(w_id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );
  let filteredData = [];

  if (selectedPetName === "") {
    filteredData = petData;
  } else if (!petData) {
    filteredData = [];
  } else {
    filteredData = petData.filter((item) => {
      const petName = item.name || "";
      const query =
        selectedPetName && selectedPetName.length > 0
          ? selectedPetName[0].toLowerCase()
          : "";
      return petName.toLowerCase().includes(query);
    });
  }

  const petNamesSet = new Set();
  const filteredPetNames = petNames.filter((pet) => {
    const lowercaseName = pet.name.toLowerCase();
    if (!petNamesSet.has(lowercaseName)) {
      petNamesSet.add(lowercaseName);
      return true;
    }
    return false;
  });

  const [open, setOpen] = useState(false); // add state for the menu
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [unarchivePetId, setUnarchivePetId] = useState(null);

  const handleUnarchiveClick = (petId) => {
    setShowSuccessMessage(true);
    setOpen(true);
    setUnarchivePetId(petId); // Store the petId in a state variable
  };

  const handleUnarchive = (unarchivePetId) => {
    setIsUpdating(true);

    // const updatedPet = {
    //   is_archieved: true, // Set the desired unarchived status
    // };
    setPetUnarchive(unarchivePetId) // Use the stored petId from state
      .then((response) => {
        setIsUpdating(false);
        if (response.data.success === true) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setIsUpdating(false);
      });
  };
  const ButtonItem = [
    {
      name: "yes",
      onClick: () => {
        handleUnarchive(unarchivePetId);
        setShowSuccessMessage(false);
      },
    },
    {
      name: "no",
      onClick: () => {
        setOpen(false);
        setShowSuccessMessage(false);
      },
    },
  ];

  const defaultOptions = {
    pageSize: 5,
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "petImage",
        width: "8vw",
        disableSortBy: true,
        Cell: ({ row }) => {
          return row?.original?.petImage ? (
            <img
              src={row?.original?.petImage}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "10px",
                objectFit: "cover",
                margin: "0 auto",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: "50px",
                height: "50px",
              }}
            />
          );
        },
      },
      {
        Header: "Pet Name",
        accessor: "name",
        width: "12.5vw",
      },
      {
        Header: `Family Name`,
        accessor: "petFamilyName",
        width: "12.5vw",
      },
      {
        Header: "Date of Birth",
        accessor: "petDob",
        width: "12.5vw",
      },
      {
        Header: "",
        accessor: "petId",
        width: "8vw",
        isArchive: true,
        disableSortBy: true,
        disabled: isUpdating,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <UnarchiveIcon
              style={{
                color: "#000000",
              }}
              onClick={() =>
                handleUnarchiveClick(row?.original?.petId)
              }
            />
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        width: "8vw",
        disableSortBy: true,
        textAlign: "center",
        Cell: ({ row }) => {
          return (
            <Button
            sx={{
              minWidth: "40px",
              background: "rgba(255, 133, 51, 0.20) !important",
              borderRadius: "10px",
              marginRight: "5px",
              width: "24px",
            }}
            onClick={() => navigate(props.viewDataLink(row.original))}
          >
            <img
              alt="view"
              src={viewIcon}
              style={{
                color: "#000000",
                cursor: "pointer",
              }}
            />
          </Button>
          );
        },
      },
    ];
  }, []);

  const props = {
    label: "Archive Pet Information",
    setSelectedUserName: setSelectedPetName,
    items: filteredPetNames,
    dataAddLink: "/Pet-Information/Form",
    isLoading,
    isArchive: true,
    columns,
    columnsData: filteredData,
    viewDataLink: (data) => `/archive/pet-info/${data?.petId}`,
    placeholder: "pet name",
    details: "There are no pet details for this user.",
    AddPetArchive: true,
    fetchData,
    pageCount,
    defaultOptions,
  };

  return (
    <>
      <PawItemList {...props} />
      {showSuccessMessage && (
        <>
          <DetailsModal
            open={open}
            severity="warning"
            buttonList={ButtonItem}
            title="Do you want to unarchive this pet? "
            handleClose={()=> setOpen(false)}
          />
        </>
      )}
    </>
  );
}
