import React from "react";

import ArchiveConditionInfoComponent from "../../Components/Selected-item-view-Ar/ConditionsInfo/ConditionInfoComponent";

const ArchiveConditionsSecond = () => {

  return (
    <>
      <ArchiveConditionInfoComponent />
    </>
  );
};

export default ArchiveConditionsSecond;
