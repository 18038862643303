import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { CreateCondition } from "../../../Api/Services/ConditionsServices";
import { getPetNameByWorkspaceId } from "../../../Api/Services/PetServices";
import { useAuth } from "../../../ContextProvider/AuthProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../../Css";
import { ConditionsFormValidation } from "../../../From/Validation";
import AutoComplete from "../../Common/AutoComplete";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import InputField from "../../Common/InputField";
import CustomSnackbar from "../../Common/Snackbar";
import TextArea from "../../Common/TextArea";

import "../../Item-List/GlobalItemList.css";

const defaultValue = {
  conditions: "",
  condition_note: "",
  pet_id: "",
};

const ConditionFormSection = () => {
  const { workspaceId: w_id } = useAuth();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    const getPetNames = async () => {
      try {
        const { data } = await getPetNameByWorkspaceId(w_id);
        const filteredData = data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setItems(filteredData);
      } catch (error) {
        console.error("error: ", error);
      }
    };
    getPetNames();
  }, [w_id]);

  const handleSubmit = async (values) => {
    const value = {
      conditions: values?.conditions,
      condition_note: values?.condition_note,
      pet_id: values?.pet_id,
    };
    try {
      const AllergyRes = await CreateCondition(value);
      if (AllergyRes?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout title="Condition Information">
      <Formik
        initialValues={defaultValue}
        onSubmit={handleSubmit}
        validationSchema={ConditionsFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Condition Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="conditions"
                      label={"Condition"}
                      requiredField
                    >
                      {/* <AutoComplete
                        {...getFieldProps("conditions")}
                        options={ConditionType}
                        otherType="Other"
                        boxStyle={{ ...immunizationStyle }}
                        placeholder="Select a type"
                      /> */}
                      <InputField {...getFieldProps("conditions")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="condition_note" label={"Notes"}>
                      <TextArea
                        rows={7}
                        maxLength={300}
                        {...getFieldProps("condition_note")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet condition has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/conditions");
          }}
        />
      )}
    </FormLayout>
  );
};

export default ConditionFormSection;
