import React, { useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { getMembershipByMembershipNo } from "../../Api/axios";
import {
  getContactByUserID,
  getUserById,
} from "../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY, PET_OWNER_ID_KEY } from "../../Constant";
import CustomButton from "../Common/Button";
import Logout from "../Selected-item-view/Logout";
import petThemeImg from "../../Assets/Images/pet-theme-back.png";
import profileImg from "../../../Assets/Images/profile.png";

//getUserById
export default function SettingComponent() {
  const user_id = localStorage.getItem("pawprint_user_id");
  const membership_id =
    localStorage.getItem("membership_id") ||
    localStorage.getItem("sub_membership_id");
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const petOwner_id = localStorage.getItem(PET_OWNER_ID_KEY);

  const [userData, setUserData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [membershipData, setMembershipData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      getUserById(user_id)
        .then((response) => {
          setUserData(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user_id]);

  useEffect(() => {
    if (localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User") {
      getContactByUserID(user_id)
        .then((response) => {
          setContactData(response.data.data[0]);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user_id]);

  useEffect(() => {
    getMembershipByMembershipNo({ mem_no: membership_id })
      .then((response) => {
        setMembershipData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [membership_id]);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "10px",
          padding: "20px 20px 0px"
        }}
      >
        <FormTitle>My Profile</FormTitle>
      </Box>
      {isLoading ? (
        <Typography
          variant="subtitle1"
          style={{ color: "#000000", textAlign: "center", marginTop: "5vw" }}
        >
          <CircularProgress style={{ color: "black" }} />
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              src={petThemeImg}
              alt="myProfileImage"
              style={{ width: "100%", height: "150px", objectFit: "cover", boxShadow: "0px 0px 5px 0px #FF8533" }}
            />
          </Box>
          <ImageWrapper>
            <ImageBox>
              <Image src={userData?.image || profileImg} alt="profile" />
              <TitleBox>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: { xs: "18px", sm: "20px", md: "24px" },
                    fontWeight: 500,
                    paddingTop: "20px",
                  }}
                >
                  {userData?.first_name} {userData?.last_name}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px", md: "20px" },
                    fontWeight: "700",
                    margin: "10px 0px",
                  }}
                >
                  Your account is ready.
                </Typography> */}
              </TitleBox>
            </ImageBox>
          </ImageWrapper>
          <ProfileDetailsBox>
            <div>
              <TabBox>
                <p>Profile Details</p>
              </TabBox>
            </div>
            <div>
              <div>
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#CACED8",
                    margin: "0px 0px 20px",
                  }}
                >
                  Profile Details
                </p>
              </div>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Box sx={{ display: "flex" }}>
                  <Title>Email Address</Title>
                  <Description style={{ color: "#FF8533" }}>
                    {userData?.email ? userData?.email : "-"}
                  </Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Phone Number</Title>
                  <Description style={{ color: "#FF8533" }}>
                    {contactData?.contact_no || userData?.phone_number || "-"}
                  </Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Member ID</Title>
                  <Description>
                    {membershipData?.membership_no || userData?.membership_id || userData?.membership_no || "-"}
                  </Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Pet Owner ID</Title>
                  <Description>{petOwner_id || "-"}</Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Service Start Date</Title>
                  <Description>
                    {membershipData !== undefined ? (
                      new Date(membershipData?.start_date).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Service End Date</Title>
                  <Description>
                    {membershipData !== undefined ? (
                      new Date(membershipData?.end_date).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </Description>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Title>Role</Title>
                  <Description>{role}</Description>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                // justifyContent="center"
                marginTop="10px"
              >
                <CustomButton
                  style={{ width: "auto", marginLeft: 0 }}
                  label="Edit your Profile"
                  onClick={() => {
                    navigate(`/settings/edit-user-information/${user_id}`);
                  }}
                />
                <CustomButton
                  style={{ width: "auto" }}
                  label="Reset your Password"
                  onClick={() => {
                    navigate(`/settings/profile-information/${user_id}`);
                  }}
                />
              </Box>
            </div>
          </ProfileDetailsBox>
        </>
      )}
      <Logout />
    </Container>
  );
}

const Image = styled.img`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  object-fit: cover;
  border: 8px solid rgba(255, 133, 51, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* margin: 0 auto; */
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 30px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (max-width: 900px) {
    width: 120px;
    height: 120px;
    margin-left: 10px;
  }
  @media (max-width: 450px) {
    width: 100px;
    height: 100px;
    margin-left: 0px;
  }
`;

const FormTitle = styled.h2`
  text-align: left;
  color: #232323;
  font-size: 23px;
  font-weight: 600;
  font-style: normal;
`;

const Container = styled.div`
  // padding: 20px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  width: 200px;
  margin: 0;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const TabBox = styled.div`
  width: 180px;
  background: rgba(255, 133, 51, 0.2);
  border-radius: 8px;
  margin: 0;

  p {
    font-size: 16px;
    padding: 12px;
    color: #ff8533;
    font-weight: 700;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 150px;
  padding: 0px 20px;
  @media (max-width: 900px) {
    height: 100px;
  }
  @media (max-width: 450px) {
    height: 70px;
  }
`;

const ImageBox = styled.div`
  position: absolute;
  top: -50px;
  display: flex;
`;

const ProfileDetailsBox = styled.div`
  margin-left: 30px;
  display: flex;
  gap: 40px;
  padding: 0px 20px 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-left: 10px;
    gap: 10px;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    margin-left: 0px;
    gap: 0px;
  }
`;

const TitleBox = styled.div`
  margin: 50px 0px 0px 40px;

  @media (max-width: 900px) {
    margin: 30px 0px 0px 20px;
  }
  @media (max-width: 450px) {
    margin: 30px 0px 0px 10px;
  }
`;
