import React from "react";

import Conditions from "../../Components/Item-List/Conditions";
import Layout from "../../Components/Side bar/Layout";

const ConditionsInfo = () => {

  return (
    <Layout>
      <Conditions />
    </Layout>
  );
};

export default ConditionsInfo;
