import React, { useState } from "react";
import HttpCommon from "../../Utils/http-common";
import styled from "styled-components";
import FormGroup from "../Common/FormGroup";
import { useFormikContext } from "formik";

function MembershipForm({ onPrevious }) {
  const [isLoading, setIsLoading] = useState(false);
  const { values, setFieldValue, setFieldError, errors, isSubmitting } =
    useFormikContext();

  const handleApplyMembershipNumber = async () => {
    try {
      if (values?.isValidMembershipNumber) {
        setFieldValue("isValidMembershipNumber", false);
        setFieldValue("membershipNumber", "");
        return;
      }
      if (values?.membershipNumber) {
        setIsLoading(true);
        const data = {
          membership_number: values?.membershipNumber,
        };
        const res = await HttpCommon.post(`/mem/checkMembershipNumber`, data);
        if (res?.data?.success) {
          setFieldValue("isValidMembershipNumber", true);
        } else {
          setFieldValue("isValidMembershipNumber", false);
          setTimeout(() => {
            setFieldError("membershipNumber", "Membership Number is Invalid");
          });
        }
        setIsLoading(false);
      } else {
        setFieldError("membershipNumber", "membership number is required");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("error: ", error);
    }
  };

  return (
    <>
      <Column>
        <div
          style={{
            display: "flex",
            alignItems: errors?.membershipNumber ? "center" : "end",
          }}
        >
          <FormGroup
            name="membershipNumber"
            labelClassName="margin-top"
            style={{ marginRight: "10px" }}
            label="Membership Number"
          >
            <input
              className="membershipInput"
              placeholder="Membership Number"
              type="text"
              value={values?.membershipNumber}
              onChange={(e) =>
                setFieldValue("membershipNumber", e.target.value)
              }
              style={{
                borderColor: errors?.membershipNumber ? "#DC3838" : "#808080",
              }}
              disabled={values?.isValidMembershipNumber}
            />
          </FormGroup>
          <button
            type="button"
            style={{
              maxWidth: 100,
              margin: "0 0 0 10px",
              background: "#fc8433",
              height: "40px",
              padding: 0,
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleApplyMembershipNumber}
          >
            {values?.isValidMembershipNumber ? (
              "Cancel"
            ) : isLoading ? (
              <Loader />
            ) : (
              "Apply"
            )}
          </button>
        </div>
        {values?.isValidMembershipNumber && (
          <p style={{ fontSize: 16, color: "green", margin: 0 }}>
            {`${values?.membershipNumber}`}
            &nbsp;Membership Number is applied
          </p>
        )}
      </Column>
      <Buttons>
        {/* <button onClick={onPrevious}>Back</button> */}
        {isSubmitting ? (
          <button disabled={isSubmitting}>
            <Loader />
          </button>
        ) : (
          <button type="submit" disabled={!values?.isValidMembershipNumber}>
            Sumbit
          </button>
        )}
      </Buttons>
    </>
  );
}

export default MembershipForm;

const Buttons = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;

  button {
    height: max-content;
    width: max-content;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;
const Loader = styled.div`
  justify-self: center;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 8px;
  height: 8px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;

  .membershipInput {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;

    :focus {
      border: none;
    }
  }
`;
