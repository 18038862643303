import React from "react";

import PetInsurance from "../../Components/Item-List/PetInsurance";
import Layout from "../../Components/Side bar/Layout";

const ItemListPetInsuarance = () => {

  return (
    <Layout>
      <PetInsurance />
    </Layout>
  );
};

export default ItemListPetInsuarance;
