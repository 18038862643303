import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  createPetInsurance,
  getProviderNamesByWorkspaceId,
  getSubscriberNamesByWorkspaceId,
} from "../../Api/Services/PetInsuranceServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { getUsersByWorkspaceId } from "../../Api/Services/UserServices";
import useAuth from '../../ContextProvider/AuthProvider'
import { FormHeaderBox, FormHeaderTitle, Body, Column, ButtonBoxStyle, CancelButton } from "../../Css";
import { PetInsuranceFormValidation } from "../../From/Validation";
import { DurationOptions } from "../../Options";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import CustomPhoneInput from "../Common/PhoneInput";
import CustomSnackbar from "../Common/Snackbar";

const defaultValue = {
  plan: "",
  member_id: "",
  member_name: "",
  effective_date: "",
  duration: "",
  group_name: "",
  pet_id: "",
  provider_name: "",
  provider_phone: "",
  subscriber_name: "",
};

const Petinsurance = () => {
  const { workspaceId: w_id } = useAuth();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [providerNames, setProviderNames] = useState([]);
  const [subscriberNames, setSubscriberNames] = useState([]);
  const [memberNames, setMemberNames] = useState([]);

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getProviderNames = async () => {
    try {
      const { data } = await getProviderNamesByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      filteredData.push({ value: "Type here", label: "Type here" });
      setProviderNames(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getSubscriberNames = async () => {
    try {
      const { data } = await getSubscriberNamesByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      filteredData.push({ value: "Type here", label: "Type here" });
      setSubscriberNames(filteredData);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const getUsersByWorkspaceID = async () => {
    try {
      const res = await getUsersByWorkspaceId(w_id);
      const filteredData = res?.data?.data?.map((item) => {
        return {
          value: `${item?.first_name} ${item?.last_name}`,
          label: `${item?.first_name} ${item?.last_name}`,
        };
      });
      if (filteredData?.length === 0) {
        <></>;
      } else {
        setMemberNames(filteredData);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetNames();
    getProviderNames();
    getSubscriberNames();
    getUsersByWorkspaceID();
  }, []);

  const sendData = async (values) => {
    const data = {
      plan: values?.plan,
      member_id: values?.member_id,
      member_name: values?.member_name,
      start_date: values?.effective_date,
      effective_date: values?.effective_date,
      duration: values?.duration,
      group_name: values?.group_name,
      // user_id: user_id,
      w_id: w_id,
      pet_id: values?.pet_id,
      provider_name: values?.provider_name,
      provider_phone: values?.provider_phone,
      subscriber_name: values?.subscriber_name,
    };
    try {
      const insuranceres = await createPetInsurance(data);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <FormLayout title="Pet Insurance Information">
        <Formik
          initialValues={defaultValue}
          onSubmit={sendData}
          validationSchema={PetInsuranceFormValidation}
          enableReinitialize
        >
          {({ getFieldProps, values, isSubmitting, setFieldValue }) => (
            <Form>
            <Box sx={FormHeaderBox}>
            <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                Pet Insurance Details
                </Typography>
              </Box>
              <Body className="scroll2" style={{ gap: "10px 20px" }}>
                <Column>
                  <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                    <AutoComplete
                      {...getFieldProps("pet_id")}
                      options={items}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="plan" label={"Plan"} requiredField>
                    <InputField {...getFieldProps("plan")} />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="provider_name"
                    label={"Insurance Provider Name"}
                    requiredField
                  >
                    <AutoComplete
                      {...getFieldProps("provider_name")}
                      options={providerNames}
                      otherType="Type here"
                      placeholder="Select a Provider Name"
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="group_name" label={"Group"} requiredField>
                    <InputField {...getFieldProps("group_name")} />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="member_name"
                    label={"Member Name"}
                    requiredField
                  >
                    <AutoComplete
                      freeSolo
                      {...getFieldProps("member_name")}
                      options={memberNames}
                      otherType="Type here"
                      placeholder="Select a Member Name"
                      onInputChange={(e, value) =>
                        setFieldValue("member_name", value)
                      }
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="effective_date"
                    label={"Effective Date"}
                    requiredField
                  >
                    <InputField
                      type="date"
                      {...getFieldProps("effective_date")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="member_id" label={"Insurance Member ID"} requiredField>
                    <InputField {...getFieldProps("member_id")} />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="subscriber_name"
                    label={"Subscriber Name"}
                    requiredField
                  >
                    <AutoComplete
                      {...getFieldProps("subscriber_name")}
                      options={subscriberNames}
                      otherType="Type here"
                      placeholder="Select a Subscriber Name"
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup
                    name="provider_phone"
                    label={"Phone Number"}
                    requiredField
                  >
                    <CustomPhoneInput
                      {...getFieldProps("provider_phone")}
                    />
                  </FormGroup>
                </Column>
                <Column>
                  <FormGroup name="duration" label={"Duration"} requiredField>
                    <AutoComplete
                      {...getFieldProps("duration")}
                      options={DurationOptions}
                    />
                  </FormGroup>
                </Column>
                </Body>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                    />
                </Box>
              </Box>
                    </Box>
            </Form>
          )}
        </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet insurance has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/pet-insurance");
          }}
        />
      )}
    </FormLayout>
  );
};

export default Petinsurance;
