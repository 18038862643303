import React from "react";
import styled from "styled-components";
import { getForIndividualsComment } from "../../api/axios";
import useSWR from "swr";

export default function SocialProofBox() {
  const { data: data } = useSWR(
    `getForIndividualsComment`,
    async () => {
      try {
        const { data } = await getForIndividualsComment();
        const commentData = data.data[0];
        return { ...commentData, index: data.data.indexOf(commentData) };
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <SocialProofBoxWrapper>
      <SocialProofHeader>
        <h1>What other pet owners say</h1>
      </SocialProofHeader>
      <SocialProofContainer>
        <SPConatiner1>
          <blockquote>{data?.comment1}</blockquote>
          <SPWho>
            <h4>{data?.owner_name1}</h4>
          </SPWho>
        </SPConatiner1>
        <SPConatiner2>
          <blockquote>{data?.comment2}</blockquote>
          <SPWho>
            <h4>{data?.owner_name2}</h4>
          </SPWho>
        </SPConatiner2>
        <SPConatiner3>
          <blockquote>{data?.comment3}</blockquote>
          <SPWho>
            <h4>{data?.owner_name3}</h4>
          </SPWho>
        </SPConatiner3>
        <SPConatiner4>
          <blockquote>{data?.comment4}</blockquote>
          <SPWho>
            <h4>{data?.owner_name4}</h4>
          </SPWho>
        </SPConatiner4>
      </SocialProofContainer>
    </SocialProofBoxWrapper>
  );
}
export const SocialProofBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 140px 50px;
  flex-direction: column;
  @media (max-width: 1150px) {
    padding: 50px 100px 0;
  }
  @media (max-width: 950px) {
    padding: 50px 50px 0;
  }
  /* @media (max-width: 850px) {
    padding: 50px 100px 0;
  } */
  @media (max-width: 850px) {
    padding: 0px 0px 0;
  }
`;
export const SocialProofHeader = styled.div`
  text-align: center;
  h1 {
    font-size: 50px;
    color: rgb(31, 85, 165);
    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 25px;

    @media (max-width: 1100px) {
      font-size: 45px;
    }
    @media (max-width: 975px) {
      font-size: 40px;
      line-height: 40px;
    }
    @media (max-width: 895px) {
      font-size: 38px;
    }
    @media (max-width: 715px) {
      font-size: 30px;
    }
  }
  p {
    padding: 0 10vw;
    font-size: 1.375rem;
    color: #249fda;
  }
`;

export const blockquote = styled.div`
  width: 500px;
  height: 100px;
`;

export const SocialProofContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  color: #123444;
  font-weight: 500;
  /* blockquote{
    height: 9vw;
  } */
  @media (max-width: 850px) {
    max-width: 100%;
    grid-template-columns: repeat(1, 1fr);
    font-size: 17px;
  }
  @media (max-width: 1250px) {
    font-size: 16px;
  }
`;

export const SPConatiner1 = styled.div`
  border-bottom: ridge;
  border-right: ridge;
  font-size: 20px;

  blockquote {
    display: grid;
    align-content: center;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    height: 200px;
  }

  @media (max-width: 850px) {
    border-right: none;
  }
  @media (max-width: 470px) {
    font-size: 15px;
    blockquote {
      margin: 5px 0px;
    }
  }
  @media (max-width: 849px) {
    blockquote {
      height: 120px;
    }
  }
`;
export const SPConatiner2 = styled.div`
  border-bottom: ridge;
  font-size: 20px;

  blockquote {
    display: grid;
    align-content: center;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    height: 200px;
  }

  @media (max-width: 849px) {
    blockquote {
      height: 120px;
    }
  }
  @media (max-width: 650px) {
    blockquote {
      height: 150px;
    }
  }
  @media (max-width: 600px) {
    blockquote {
      height: 170px;
    }
  }

  @media (max-width: 470px) {
    font-size: 15px;
    blockquote {
      margin: 15px 0px;
    }
  }
`;
export const SPConatiner3 = styled.div`
  border-right: ridge;
  font-size: 20px;
  p {
    color: transparent;
  }

  blockquote {
    display: grid;
    align-content: center;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    height: 200px;
  }
  @media (max-width: 1310px) {
    blockquote {
      height: 260px;
    }
  }
  @media (max-width: 1035px) {
    blockquote {
      height: 300px;
    }
  }
  @media (max-width: 990px) {
    blockquote {
      height: 340px;
    }
  }
  @media (max-width: 849px) {
    blockquote {
      height: 120px;
    }
  }
  @media (max-width: 730px) {
    blockquote {
      height: 150px;
    }
  }
  @media (max-width: 650px) {
    blockquote {
      height: 180px;
    }
  }
  @media (max-width: 600px) {
    blockquote {
      height: 200px;
    }
  }
  @media (max-width: 540px) {
    blockquote {
      height: 240px;
    }
  }

  @media (max-width: 850px) {
    border-right: none;
    border-bottom: ridge;
    p {
      display: none;
    }
  }
  @media (max-width: 470px) {
    font-size: 15px;
    blockquote {
      margin: 15px 0px;
    }
  }
`;
export const SPConatiner4 = styled.div`
  font-size: 20px;

  blockquote {
    display: grid;
    align-content: center;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    height: 200px;
  }

  @media (max-width: 850px) {
    border-bottom: ridge;
  }
  @media (max-width: 849px) {
    blockquote {
      height: 120px;
    }
  }
  @media (max-width: 600px) {
    blockquote {
      height: 150px;
    }
  }

  @media (max-width: 470px) {
    font-size: 15px;
    blockquote {
      margin: 15px 0px;
    }
  }
`;

export const SPWho = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #123444; */
  color: #000000;
  font-size: 18px;
  h4 {
    margin-left: 10px;
    @media (max-width: 1250px) {
      font-size: 17px;
    }
    @media (max-width: 1025px) {
      font-size: 15px;
    }
    @media (max-width: 470px) {
      font-size: 14px;
    }
  }
`;
