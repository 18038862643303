import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  deleteInsuarance,
  getSelectedInsuranceByID,
} from "../../../Api/Services/PetInsuranceServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import {
  getPetHistoryById,
} from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import {
  addItemtoObject,
  formatDate,
  formatPhoneNumber,
} from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function PetInsuranceComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [petInfo, setPetInfo] = useState([]);

  const { data: insuranceInfo, isLoading } = useSWR(
    id ? `insuranceInfo${id}` : null,
    async () => {
      const { data } = await getSelectedInsuranceByID(id);
      if (data?.data) {
        const pet_id = data?.data.pet_id;
        const { data: petData } = await getPetByID(pet_id);
        setPetInfo(petData?.data);
      }
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petInsuranceHistory } = useSWR(
    id ? `petInsuranceHistory${id}` : null,
    async () => {
      const { data } = await getPetHistoryById("petInsurance", id);
      const propertiesToUpdate = ["pet_name"];
      const propertiesToDelete = ["id", "pet_id", "w_id"];
      const filteredData = data?.data?.map((item) =>
        addItemtoObject(item, propertiesToUpdate, propertiesToDelete)
      );
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await deleteInsuarance(id);
  };

  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    onBack: () => {
      navigate("/pet-insurance");
    },
    label: "Pet Insurance Information",
    isLoading,
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "name",
      },
      {
        label: "Insurance provider name",
        value: "providerName",
      },
      {
        label: "Member ID",
        value: "memberId",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        name: petInfo?.name,
        providerName: insuranceInfo?.provider_name
          ? insuranceInfo?.provider_name.slice(0, 20)
          : "",
        memberId: insuranceInfo?.member_id,
      },
    ],
    detailsData: [
      [
        { name: "Member Name", value: insuranceInfo?.member_name },
        { name: "Subscriber Name", value: insuranceInfo?.subscriber_name },
        {
          name: "Phone",
          value: formatPhoneNumber(insuranceInfo?.provider_phone),
        },
        { name: "Plan", value: insuranceInfo?.plan },
        { name: "Group", value: insuranceInfo?.group_name },
        {
          name: "Effective Date",
          value: formatDate(insuranceInfo?.effective_date),
        },
      ],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/pet-insurance/pet-insurance-edit-form/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Pet Insurance History",
    historyColumnsData: petInsuranceHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
