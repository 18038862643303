import React from "react";

import Layout from "../../Components/Side bar/Layout";
import Workspacelist from "../../Components/SuperAdmin/Workspacelist";

const WorkspaceList = () => {

  return (
    <Layout>
      <Workspacelist />
    </Layout>
  );
};

export default WorkspaceList