import { api } from "../axios";

export const getCondition = async (id) => {
  const res = await api.get(`/conditions/getConditionById/${id}`);
  return res;
};

export const getAllItemListConditions = async () => {
  const res = await api.get(`/conditions/getAllConditions`);
  return res;
};

export const getConditionByWorkspaceID = async (id) => {
  const res = await api.get(`/conditions/getDataByWorkspaceId/${id}`);
  return res;
};

//condition
export const CreateCondition = async (formData) => {
  const res = await api.post(`/conditions/createCondition`, formData);
  return res;
};
export const getConditionById = async (id) => {
  const res = await api.get(`/conditions/getConditionById/${id}`);
  return res;
};
export const UpdateConditionById = async (id, formData) => {
  const res = await api.put(`/conditions/updateCondition/${id}`, formData);
  return res;
};

export const getConditionByID = async (id) => {
  const res = await api.get(`/conditions/getConditionById/${id}`);
  return res;
};

//Delete condition
export const DeleteConditions = async (id) => {
  const res = await api.delete(`/conditions/deleteCondition/${id}`);
  return res;
};

//archieve condition
export const getArchiveConditionByWorkspaceID = async (id) => {
  const res = await api.get(`/conditions/getArchieveDataByWorkspaceId/${id}`);
  return res;
};

//set Condition archive
export const setConditionArchieve = async (id) => {
  const res = await api.post(`/conditions/setArchieve/${id}`);
  return res;
};
// condition unarchive
export const setConditionUnarchive = async (id) => {
  const res = await api.post(`/conditions/setunArchieve/${id}`);
  return res;
};
