import React, { useState, useEffect, useRef } from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Box, Button, Typography, InputLabel } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";

import {
  getbyIDDocument,
  getDocumentByID,
  UpdateDocumentById,
} from "../../Api/Services/DocumentServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { useAuth } from "../../ContextProvider/AuthProvider/AuthProvider";
import {
  inputstyle,
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { EditDocumentFormValidation } from "../../From/Validation";
import { formatDate } from "../../Utils";
import AutoComplete from "../Common/AutoComplete";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import FormLayout from "../Common/FormHeader";
import InputField from "../Common/InputField";
import "../Item-List/GlobalItemList.css";
import CustomSnackbar from "../Common/Snackbar";

const defaultValue = {
  documment_name: "",
  date: "",
  documment: "",
  pet_id: "",
};

export default function DocumentEdit() {
  const [initialValue, setInitialValue] = useState(defaultValue);
  const { id } = useParams();
  const { petOwnerId: user_id, workspaceId: w_id } = useAuth();

  const navigate = useNavigate();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const [items, setItems] = useState([]);
  const inputRef = useRef(null);

  const handleDivClick = () => {
    inputRef.current.click();
  };

  const getDocumentById = async () => {
    try {
      const { data } = await getbyIDDocument(id);
      setInitialValue({
        ...initialValue,
        documment_name: data?.data?.[0]?.documment_name,
        date: formatDate(data?.data?.[0]?.date),
        pet_id: data?.data?.[0]?.pet_id,
      });
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    getPetName();
    getDocumentById();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getPetName = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const filteredData = data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setItems(filteredData);
    } catch (error) {
      console.error("error: ", error);
      alert(error);
    }
  };

  const updateForm = async (values) => {
    const formData = new FormData();
    formData.append("documment_name", values?.documment_name);
    formData.append("date", values?.date);
    formData.append("documment", values?.documment);
    formData.append("pet_id", values?.pet_id);
    formData.append("user_id", user_id);
    formData.append("w_id", w_id);
    try {
      await UpdateDocumentById(id, formData);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (error) {
      console.error("error: ", error);
      alert(error);
    }
  };

  const [documentData, setDocumentData] = useState([]);

  
  useEffect(() => {
    const getDocumentData = async () => {
      try {
        const { data } = await getDocumentByID(id);
        setDocumentData(data.data[0]);
      } catch (error) {
        console.error("error: ", error);
      }
    };
    if (id) {
      getDocumentData();
    }
  }, [id]);

  const componentPDF = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "Document",
  });

  const handleDownload = () => {
    window.open(documentData.documment);
  };
  const getCurrentDate = () => {
    const now = new Date();
    let month = (now.getMonth() + 1).toString();
    let day = now.getDate().toString();

    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${now.getFullYear()}-${month}-${day}`;
  };

  return (
    <FormLayout title="Edit Document">
      <Formik
        initialValues={initialValue}
        onSubmit={updateForm}
        validationSchema={EditDocumentFormValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting, setFieldTouched, setFieldValue, errors, touched }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Document Details
                </Typography>
              </Box>
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="documment_name"
                      label={"Document Name"}
                      requiredField
                    >
                      <InputField {...getFieldProps("documment_name")} />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="date" label={"Date"} requiredField>
                      <InputField
                        type="date"
                        {...getFieldProps("date")}
                        inputProps={{ max: getCurrentDate() }}
                      />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                  <FormGroup name="documment" label={"Upload Document"}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Input4 style={errors?.documment && touched.documment && { borderColor: "rgb(230, 0, 0)" }}>
                        <input
                          type="file"
                          ref={inputRef}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            setFieldTouched("documment", true);
                            setFieldValue("documment", file);
                          }}
                        />
                        {values?.documment ? (
                          <>
                            {values?.documment && (
                              <div style={{ color: "black" }}>
                                <p>{values?.documment?.name}</p>
                                {/* <p>Type: {values?.documment?.type}</p> */}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <object
                              data={documentData.documment}
                              type="application/pdf"
                              width="100%"
                              height="95%"
                            > </object>
                          </>
                        )}
                      </Input4>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Button
                          style={{
                            color: "white",
                            // height: "25px",
                            marginTop: "10px",
                            background: '#1c93ed'
                          }}
                          onClick={handleDivClick}
                        >
                          Upload New Document
                        </Button>
                        <Button
                          sx={{
                            color: "white",
                            // height: "25px",
                            marginLeft: { xs: "0px", sm: "80px" },
                            marginTop: "10px",
                            background: '#1c93ed',
                            '&:hover': {
                              background: '#1c93ed'
                            }
                          }}
                          onClick={handleDownload}
                        >
                          View & download
                        </Button>
                      </Box>
                    </Box>
                  </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Saving..." : "Save"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                  <CustomButton
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    onClick={handlePrint}
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your document information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/document/info/${id}`);
          }}
        />
      )}
    </FormLayout>
  );
}

const Input4 = styled.div`
  width: calc(100% - 20px);
  height: 180px;
  background: rgba(255, 133, 51, 1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  backdrop-filter: blur(10px);
  border-radius: 11px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;

  // @media (max-width: 599px) {
  //   width: 230px;
  // }
`;
