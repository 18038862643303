import React from "react";
import { getAboutUsOurMisson } from "../../api/axios";
import "../../Assets/Styles/AboutUs/OurMission.css";
import useSWR from "swr";

function OurMission() {
  const { data: misson } = useSWR(
    `aboutUsOurMisson`,
    async () => {
      try {
        const { data } = await getAboutUsOurMisson();
        return data?.data?.[0] || [];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <div className="MissionWrapper">
      <div className="MissionHeader">
        Our<span className="Mission"> Mission</span>
      </div>
      <div className="MissionText">
        <p>{misson?.paragraph}</p>
      </div>
    </div>
  );
}

export default OurMission;
