import { api } from "../axios";

export const getImmunization = async (id) => {
  const res = await api.get(`/immunization/ImmunizationGetById/${id}`);
  return res;
};

export const getAllItemListImmunization = async () => {
  const res = await api.get(`/immunization/getAllImmunization`);
  return res;
};

export const getImmunizationByWorkspaceID = async (id) => {
  const res = await api.get(`/immunization/getDataByWorkspaceId/${id}`);
  return res;
};

//immunization
export const getImmunizationById = async (id) => {
  const res = await api.get(`/immunization/ImmunizationGetById/${id}`);
  return res;
};
export const CreateImmunization = async (formData) => {
  const res = await api.post(`/immunization/createImmunization`, formData);
  return res;
};
export const UpdateImmunizationById = async (id, formData) => {
  const res = await api.put(`/immunization/updateImmunization/${id}`, formData);
  return res;
};

export const getImmunizationByID = async (id) => {
  const res = await api.get(`/immunization/ImmunizationGetById/${id}`);
  return res;
};

//Delete Immunization
export const deleteImmunization = async (id) => {
  const res = await api.delete(`/immunization/deleteImmunization/${id}`);
  return res;
};

//archieve immunization
export const getArchiveImmunizationByWorkspaceID = async (id) => {
  const res = await api.get(`/immunization/getArchieveDataByWorkspaceId/${id}`);
  return res;
};

//set immunization archive
export const setImmunizationArchieve = async (id) => {
  const res = await api.post(`/immunization/setArchieve/${id}`);
  return res;
};
// immunization unarchive
export const setImmunizationUnarchive = async (id) => {
  const res = await api.post(`/immunization/setunArchieve/${id}`);
  return res;
};

