import React, { useState } from "react";

import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import CustomButton from "../../../Components/Common/Button";
import FormGroup from "../../../Components/Common/FormGroup";
import InputField from "../../../Components/Common/InputField";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Column,
  ButtonBoxStyle
} from "../../../Css";
import { AddMembershipValidation } from "../../../From/Validation";
import { AddMembership } from "../../../Api/Services/UserServices";

const defaultValue = {
  no_of_records: "",
};

const AddMembershipForm = ({ handleClose, fetchAPIData }) => {
  const [submitted, setSubmitted] = useState([]);

  const handleSubmit = async (values) => {
    const value = {
      ...values
    };
    try {
      const res = await AddMembership(value);
      if (res?.data?.data?.ids?.length) {
        setSubmitted(res?.data?.data?.ids);
        fetchAPIData()
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return (
    <>
      {submitted?.length ? (
        <>
          <h3 style={{ textAlign: "center", color: "#565868", fontSize: '20px' }}>
            Membership numbers has been created successfully.
          </h3>
          <p style={{ textAlign: "center", color: "#565868", fontSize: '18px' }}>Below are generated IDs</p>
          {submitted?.map((item) => {
            return <p style={{ textAlign: "center", color: "#565868", fontSize: '16px' }}>{item}</p>;
          })}
          <div style={{ textAlign: "center" }}>
            <CustomButton
              label="Okay"
              onClick={handleClose}
              style={{ textAlign: "center" }}
            />
          </div>
        </>
      ) : (
        <Formik
          initialValues={defaultValue}
          onSubmit={handleSubmit}
          validationSchema={AddMembershipValidation}
          enableReinitialize
          // innerRef={formRef}
        >
          {({ getFieldProps, values, isSubmitting }) => (
            <Form>
              <Box sx={FormHeaderBox}>
                <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                  <Typography variant="h6" sx={FormHeaderTitle}>
                    Add Membership
                  </Typography>
                </Box>
                <div className="scroll2" style={{ width: "100%" }}>
                  <Body style={{ gap: "10px 20px" }}>
                    <Column>
                      <FormGroup
                        name="no_of_records"
                        label={"Number Of Membership"}
                        requiredField
                      >
                        <InputField {...getFieldProps("no_of_records")} />
                      </FormGroup>
                    </Column>
                  </Body>
                </div>
                <Box Box sx={ButtonBoxStyle}>
                  <Box display="flex">
                    <CustomButton
                      label={isSubmitting ? "Submitting..." : "Submit"}
                      disabled={isSubmitting}
                      type="submit"
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddMembershipForm;

const Body = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 20px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
