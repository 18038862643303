import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ChangePassword.css";
import pawPrintlogo from "../../Assets/Images/Navbar/pawprint-icon.png";
import HttpCommon from "../../Utils/http-common";
import swal from "sweetalert";
import closedEye from "../../Assets/Images/closed_eye.png";
import openedEye from "../../Assets/Images/open_eye.png";
import styled from "styled-components";

const ChangePassword = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get("id");
  const token = queryParameters.get("token");
  const navigate = useNavigate();
  const initialvalues = { newPassword: "", confirmPassword: "" };
  const [Values, setValues] = useState(initialvalues);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [conPasswordShown, setConPasswordShown] = useState(false);

  const [Errors, setErrors] = useState({});

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setValues({ ...Values, [name]: value });
  };

  const passwordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
  );
  const confirmPasswordRegex = new RegExp(
    /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordRegex.test(Values.newPassword)) {
      setErrors({
        message:
          "Password must be at least 6 characters long and contain at least 1 uppercase letter and 1 special character",
      });
      setValues(initialvalues);
      return;
    }
    if (!confirmPasswordRegex.test(Values.confirmPassword)) {
      setErrors({
        message:
          "Confirm Password must be at least 6 characters long and contain at least 1 uppercase letter and 1 special character",
      });
      setValues({ ...Values, confirmPassword: "" });
      return;
    } else if (Values.newPassword !== Values.confirmPassword) {
      setErrors({
        message: "New password and confirm password does not match",
      });
      setValues({ ...Values, confirmPassword: "" });
      return;
    }

    const res = await HttpCommon.post(
      `/user/forget-password/${id}/${token}`,
      Values
    );
    if (res.status === 200) {
      window.open("/success-page", "_blank");
      window.close();
      navigate("/success-page");
    } else if (res.status === 407) {
      swal("Error!", "Email expired. Try again.", "error");
      navigate("/forget-password");
    } else if (res.status === 503) {
      swal("Error!", "Something went wrong", "error");
      navigate("/forget-password");
    } else if (res.status === 500) {
      swal("Error!", "Cannot change now. Try again later.", "error");
    }
  };

  const handleShowNewPassword = () => {
    setNewPasswordShown(!newPasswordShown);
  };

  const handleShowConPassword = () => {
    setConPasswordShown(!conPasswordShown);
  };

  return (
    <div className="main">
      <div className="container">
        <form>
          <div className="headerImage">
            <img src={pawPrintlogo} className="pawPrintlogo" alt="logo" />
          </div>
          <h1>Forgot Password?</h1>

          <p>{Errors.message}</p>
          <div className="field">
            <label>New Password</label>
            <img
              className="pswd"
              src={require("../../Assets/Images/lock.png")}
              alt="user"
            />
            <input
              type={newPasswordShown ? "text" : "password"}
              name="newPassword"
              className="textarea"
              value={Values.newPassword}
              onChange={handleChange}
            />
            {Values.newPassword !== "" ? (
              <Image
                src={newPasswordShown ? openedEye : closedEye}
                alt="hide password"
                onClick={handleShowNewPassword}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="field">
            <label>Confirm Password</label>
            <img
              className="pswd"
              src={require("../../Assets/Images/lock.png")}
              alt="user"
            />
            <input
              type={conPasswordShown ? "text" : "password"}
              name="confirmPassword"
              className="textarea"
              value={Values.confirmPassword}
              onChange={handleChange}
            />
            {Values.confirmPassword !== "" ? (
              <Image
                src={conPasswordShown ? openedEye : closedEye}
                alt="hide password"
                onClick={handleShowConPassword}
              />
            ) : (
              <></>
            )}
          </div>

          <button className="btn1" onClick={handleSubmit}>
            Change Password
          </button>
          <br />
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;

const Image = styled.img`
  position: absolute;
  padding-top: 38px;
  right: 25px;
  width: 18px;
  height: 18px;

  @media screen and (max-width: 500px) {
    right: 70px;
  }

  @media screen and (max-width: 320px) {
    right: 90px;
  }
`;
