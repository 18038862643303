import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getPetByID } from "../../../Api/Services/PetServices";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchivePetInfo() {
  const { id: pId } = useParams();
  const navigate = useNavigate();

  const {
    data: petInfo,
    isLoading
  } = useSWR(
    pId ? `data${pId}` : null,
    async () => {
      const {
        data: { data },
      } = await getPetByID(pId);
      const { image, name, family_name, userDetails } =
        data;
      return {
        ...data,
        petId: data?.id,
        petImage: image,
        petName: name,
        familyName: family_name,
        first_name: userDetails ? userDetails.first_name : "",
        last_name: userDetails ? userDetails.last_name : "",
        profilePic: userDetails ? (
          userDetails.image
        ) : (
          <AccountCircleIcon
            sx={{
              fontSize: 55,
              color: "white",
            }}
          />
        ),
      };
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Pet Infomation",
    isLoading: isLoading,
    onBack: () => {
      navigate("/archive/pet-information");
    },
    petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Family Name",
        value: "familyName",
      },
      {
        label: "Date of Birth",
        value: "dob",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        familyName: petInfo?.familyName.slice(0, 20),
        dob: formatDate(petInfo?.dob_date),
      },
    ],
    detailsData: [
      [
        { name: "Breed", value: petInfo?.breed },
        { name: "Pet Type", value: petInfo?.pet_type },
        { name: "Weight", value: petInfo?.weight },
        { name: "Gender", value: petInfo?.gender },
        { name: "Fur Color", value: petInfo?.fur_color },
        { name: "Eye Color", value: petInfo?.eye_Color },
        { name: "Marks", value: petInfo?.marks },
        { name: "Chip Availability", value: petInfo?.chip_availability },
        { name: "Register No.", value: petInfo?.reg_number },
        { name: "Company Name", value: petInfo?.company_name },
      ],
      [
        { name: "Contact No.", value: petInfo?.company_contact_number },
        { name: "Street Address", value: petInfo?.street_address },
        { name: "AptCity", value: petInfo?.apt_city },
        { name: "State", value: petInfo?.state },
        { name: "Zip Code", value: petInfo?.zip_code },
        { name: "Country", value: petInfo?.country },
      ],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
      <OwnerInfo
        key={petInfo?.id}
        profiePic={petInfo?.profilePic}
        firstName={petInfo?.first_name}
        lastName={petInfo?.last_name}
        location={petInfo?.country}
      />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
