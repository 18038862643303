import React from "react";
import styled, { keyframes } from "styled-components";
import { H1, BodyIntro } from "../../../Assets/Styles/GlobleStyles/TextStyles";
import { useNavigate } from "react-router-dom";
import { getButton, getHomeHeader } from "../../../api/axios";
import useSWR from "swr";

export default function HomeSection1() {
  let navigate = useNavigate();

  const { data: header = [] } = useSWR(
    `getHomeHeader`,
    async () => {
      try {
        const { data } = await getHomeHeader();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: button } = useSWR(
    `homegetButton`,
    async () => {
      try {
        const { data } = await getButton();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>
            {/* <b>Keeping track of your <span style={{color:'rgb(0, 143, 211)'}}>pet's medical records</span><span style={{color:'#ffcc99'}}> doesn't have to be</span><span style={{color:'#ffa366'}}> complicated.</span></b> */}
            <b>
              {header?.header_paragraph_1}{" "}
              <span style={{ color: "rgb(0, 143, 211)" }}>
                {header?.header_paragraph_2}
              </span>
              <span style={{ color: "#ffcc99" }}>
                {" "}
                {header?.header_paragraph_3}
              </span>
              <span style={{ color: "#ffa366" }}>
                {" "}
                {header?.header_paragraph_4}
              </span>
            </b>
          </Title>
          <hr />
          <Description>
            <b>
              With PawprintID&#174;, you'll never have to worry about misplaced
              paperwork again.
            </b>
          </Description>
          <ButtonWrapper>
            <Button
              onClick={() => {
                navigate(button?.buttonLink);
              }}
            >
              <b>{button?.button_name}</b>
            </Button>
          </ButtonWrapper>
        </TextWrapper>
        <ImageWraper>
          <PawprintImg src={header?.header_image} />
          </ImageWraper>
      </ContentWrapper>
    </Wrapper>
  );
}

const animation = keyframes`
    0% {opacity:0; transform:translateY(-10px); filter:blur(10 px)}
    100% {opacity: 1; transform:translateY(0px); filter:blur(0 px)}
`;
const Wrapper = styled.div`
  background: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 100px 0px 0px 0px;
`;
const ContentWrapper = styled.div`
  padding: 100px 0px 50px;
  margin: 0 auto;
  align-items: space-around;
  justify-content: space-between;

  display: grid;
  gap: 10px;
  grid-template-columns: 550px auto;

  @media (max-width: 1200px) {
    gap: 0px;
    grid-template-columns: 480px auto;
  }
  @media (max-width: 1130px) {
    padding-top: 50px;
  }
  @media (max-width: 1000px) {
    gap: 0px;
    grid-template-columns: 400px auto;
  }
  @media (max-width: 800px) {
    gap: 0px;
    grid-template-columns: 350px auto;
    // padding: 100px 40px;
  }
  @media (max-width: 770px) {
    gap: 30px;
  }
  @media (max-width: 715px) {
    // padding-top: 50px;
    max-width: 970px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const TextWrapper = styled.div`
  @media (max-width: 715px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  * {
    opacity: 0;
    animation: ${animation} 1s forwards;

    :nth-child(1) {
      animation-delay: 0s;

      @media (max-width: 940px) {
        padding-bottom: 15px;
      }
    }
    :nth-child(2) {
      animation-delay: 0s;
    }
    :nth-child(3) {
      animation-delay: 0s;
    }
  }
`;
const Title = styled(H1)`
  font-size: 50px;
  // line-height: 80px;
  color: #fc8433;
  letter-spacing: -0.011em;
  margin: 0 auto;
  text-align: left;

  @media (max-width: 1100px) {
    font-size: 45px;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    letter-spacing: -0.01em;
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }
`;
const Description = styled(BodyIntro)`
  /* color: rgb(0, 143, 211); */
  font-size: 25px;
  color: #000000;
  text-align: left;
  letter-spacing: 0.02em;
  white-space: pre-line;
  margin: 0 auto;
  line-height: 35px;
  padding-top: 20px;

  @media (max-width: 1250px) {
    font-size: 23px;
    line-height: 30px;
  }
  @media (max-width: 1145px) {
    font-size: 21px;
  }
  @media (max-width: 1100px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 715px) {
    text-align: center;
    padding-top: 0px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 50px;
  @media (max-width: 715px) {
    padding-top: 15px;
  }
`;

const ImageWraper = styled.div`
  text-align: end;
`;

const Button = styled.div`
  background-color: #008fd3;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  color: white;
  padding: 15px 32px;
  text-align: center;
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 24px;
  margin: 0 auto;
  cursor: pointer;

  :hover {
    transform: translateY(-3px);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 20px 20px rgba(0, 0, 0, 0.15),
      inset 0px 0px 0px 0.5px rgba(0, 0, 0, 0.15);
  }

  *,
  & {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @media (max-width: 1145px) {
    font-size: 20px;
    padding: 13px 30px;
  }
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;
const PawprintImg = styled.img`
  max-width: 90%;
  max-height: 100%;
  width: auto;
  height: auto;
  @media (max-width: 1220px) {
    max-width: 50vw;
  }
  @media (max-width: 1150px) {
    max-width: 100%;
  }
  @media (max-width: 1025px) {
    max-width: 100%;
  }
  @media (max-width: 715px) {
    max-width: 90vw;
    max-height: 90vh;
    width: auto;
    height: auto;
  }
`;
