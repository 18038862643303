import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getJournalById } from "../../../Api/Services/JournalServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

export default function ArchiveJournalInfom() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [petId, setPetId] = useState("");

  const { data: journalInfo, isLoading: IsJournalInfoLoading } = useSWR(
    id ? `journalInfo${id}` : null,
    async () => {
      const { data } = await getJournalById(id);
      setPetId(data?.data?.petid)
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data , isLoading: isPetLoading} = useSWR(
    petId ? `petdata${petId}` : null,
    async () => {
      const { data } = await getPetByID(petId);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Journal",
    isLoading: IsJournalInfoLoading || isPetLoading,
    onBack: () => {
      navigate("/archive/journal");
    },
    petInfo: data,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Date",
        value: "date",
      },
    ],
    columnsData: [
      {
        image: data?.image,
        pet_name: data?.name,
        date: formatDate(journalInfo?.date),
      },
    ],
    detailsData: [[{ name: "Notes", value: journalInfo?.note }]],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));