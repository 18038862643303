import React, { useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";

import "react-phone-input-2/lib/style.css";
import {
  deleteUserInfo,
  getAllRoles,
  getHistoryById,
  getInviteUserById,
} from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import useAuth from "../../../ContextProvider/AuthProvider/AuthProvider";
import withAuth from "../../../ContextProvider/AuthProvider/withAuth";
import {
  deepJSONDifferences,
  formatDate,
  formatPhoneNumber,
} from "../../../Utils";
import ParDataView from "../../Common/PawDataView";

function UserInfo() {
  const { id } = useParams();
  const [userWorkspaceId, setUserWorkspaceId] = useState("");
  const { workspaceId, userId } = useAuth();
  const navigate = useNavigate();

  const { data: user, isLoading } = useSWR(
    id && workspaceId ? `user${id}` : null,
    async () => {
      const { data } = await getInviteUserById(id, workspaceId);
      setUserWorkspaceId(data?.data?.userWorkspace?.[0]?.id);
      return data?.data || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: userHistory } = useSWR(
    userWorkspaceId ? `userHistory${userWorkspaceId}` : null,
    async () => {
      const role = await getAllRoles();
      const { data } = await getHistoryById("user", userWorkspaceId);
      const filteredData = data?.data?.map((item) => {
        const role_name = role?.data?.data?.filter(
          (role) => role.id === JSON.parse(item?.oldData)?.[0]?.role_id
        );
        const newDataRole_name = role?.data?.data?.filter(
          (role) => role.id === Number(JSON.parse(item?.newData)?.roleId)
        );
        const roleNames = role_name?.map((filteredRole) => {
          return { role: filteredRole?.name };
        });
        const newroleNames = newDataRole_name?.map((filteredRole) => {
          return { role: filteredRole?.name };
        });
        return {
          ...item,
          // roleName: role_name?.[0]?.name,
          oldData: JSON.stringify({ role: role_name?.[0]?.name }),
          newData: JSON.stringify({ role: newDataRole_name?.[0]?.name }),
          created_at: formatDate(item?.created_at),
          updatedBy: `${item?.first_name} ${item?.last_name}`,
          data: deepJSONDifferences(roleNames?.[0], newroleNames?.[0]),
        };
      });
      return filteredData || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await deleteUserInfo(workspaceId, id);
  };

  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "User Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/user-information");
    },
    petInfo: user,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "First Name",
        value: "first_name",
      },
      {
        label: "Last Name",
        value: "last_name",
      },
    ],
    columnsData: [
      {
        image: user?.image,
        first_name: user?.first_name,
        last_name: user?.last_name,
      },
    ],
    detailsData: [
      [
        { name: "Email", value: user?.email },
        { name: "Phone Number", value: formatPhoneNumber(user?.contact_no) },
        { name: "Role", value: user?.role_name },
      ],
    ],
    allowEditOrDelete: role === "User" && id !== userId,
    editLink: `/user-information/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "User History",
    historyColumnsData: userHistory,
  };

  return (
    <>
      <FullPageWrapper>
        <ParDataView {...props} />
      </FullPageWrapper>
    </>
  );
}

export default withAuth(UserInfo);
const FullPageWrapper = styled("div")(() => ({}));
