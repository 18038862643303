import React from "react";
import styled, { keyframes } from "styled-components";
import { getHomeParagraph } from "../../../../api/axios";
import useSWR from "swr";

export default function ParagraphCard() {
  const { data: paragraph } = useSWR(
    `getHomeParagraph`,
    async () => {
      try {
        const { data } = await getHomeParagraph();
        return data?.data?.[0];
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <Wrapper>
      <CardDetails>
        <Image src={paragraph?.paragraph_image} />
        <Description1>
          <p>{paragraph?.paragraph}</p>
        </Description1>
      </CardDetails>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  // overflow: hidden;

  @media (max-width: 900px) {
    max-width: 850px;
  }

  @media (max-width: 550px) {
    max-width: 450px;
  }

  @media (max-width: 370px) {
    max-width: 100%;
  }
`;
const CardDetails = styled.div`
  margin: 0 auto;
  padding: 50px 0px;
  // padding-bottom: 0px;
  display: grid;
  grid-template-columns: 360px auto;
  gap: 50px;

  @media (max-width: 1150) {
    gap: 30px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 300px auto;
    gap: 60px;
    padding: 100px 0px 60px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 60px 0px 20px;
  }

  @media (max-width: 460px) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 60px 0px 30px;
  }

  @media (max-width: 360px) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px 0px 30px;
  }
`;

const Image = styled.img`
  border-radius: 60px;
  border: 1px solid #ff8533;
  height: 450px;
  width: 350px;
  object-fit: cover;
  box-shadow: -30px 30px 40px rgba(0, 75, 117, 0.15);
  transition: all 1s;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 1180px) {
    height: 450px;
  }

  @media (max-width: 1000px) {
    height: 450px;
    width: 320px;
  }

  @media (max-width: 900px) {
    height: 340px;
    width: 255px;
    margin: 0 auto;
  }

  @media (max-width: 450px) {
    height: 320px;
    width: 240px;
    margin: 0 auto;
  }

  @media (max-width: 385px) {
    height: 290px;
    width: 220px;
    margin: 0 auto;
  }
`;

const Description1 = styled.div`
  /* color: #123444; */
  color: #000000;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  text-align: justify;
  padding: 0px 0px 0 0;
  white-space: pre-line;
  margin: 0 auto;
  transition: all 1s;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 1280px) {
    font-size: 18.5px;
    padding-top: 0;
  }
  @media (max-width: 1150px) {
    font-size: 18px;
    line-height: 27px;
  }
  @media (max-width: 1000px) {
    font-size: 17px;
  }
  @media (max-width: 835px) {
    font-size: 16px;
  }
  @media (max-width: 900px) {
    padding: 0px 0px 20px;
    font-size: 17px;
  }

  @media (max-width: 550px) {
    padding: 0px 0px 0;
    font-size: 16px;
  }

  @media (max-width: 460px) {
    padding: 0px 10px 30px 0;
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: 360px) {
    padding: 0px 10px 30px 0;
    font-size: 15px;
    line-height: 24px;
  }
`;
