import React from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getMedicationHistoryById } from "../../../Api/Services/MedicationServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import {
  DeleteSurgery,
  getSurgeryByID,
} from "../../../Api/Services/SurgeryServices";
import { getVetByID } from "../../../Api/Services/VetServices";
import { PAWPRINT_USER_ROLE_KEY } from '../../../Constant'
import { addItemtoObject, formatDate, formatPhoneNumber } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function SurgeryInfoComponent() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: surgeryData, isLoading: IsSurgeryDataLoading } = useSWR(
    id ? `surgeryData${id}` : null,
    async () => {
      const { data } = await getSurgeryByID(id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petData, isLoading: isPetLoading } = useSWR(
    surgeryData?.pet_id ? `petData${surgeryData?.pet_id}` : null,
    async () => {
      const { data } = await getPetByID(surgeryData?.pet_id);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: vetData, isLoading: IsVetDataLoading } = useSWR(
    surgeryData?.vet_id ? `vetData${surgeryData?.vet_id}` : null,
    async () => {
      const { data } = await getVetByID(surgeryData?.vet_id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: surgeryHistory } = useSWR(
    id ? `surgeryHistory${id}` : null,
    async () => {
      try {
        const { data } = await getMedicationHistoryById("surgery-treatement", id);
        const propertiesToUpdate = ['pet_name', 'vet'];
        const propertiesToDelete = [ 'id', 'pet_id', 'vet_id' ];
        const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
        return filteredData || [];
      } catch (error) {
        console.error('error: ', error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await DeleteSurgery(id);
  };
  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Procedures and Treatments",
    isLoading: IsSurgeryDataLoading || IsVetDataLoading || isPetLoading,
    onBack: () => {
      navigate("/surgery-and-treatment");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Procedure Date",
        value: "date",
      },
      {
        label: "Vet Name",
        value: "vet_full_name",
      },
      {
        label: "Procedure Type",
        value: "surgery_type",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        date: formatDate(surgeryData?.date),
        vet_full_name: vetData?.vet_full_name,
        surgery_type: surgeryData?.surgery_type,
      },
    ],
    detailsData: [
      [{ name: "Email", value: vetData?.email },
      { name: "Fax", value: vetData?.fax },
      { name: "Phone Number", value: formatPhoneNumber(vetData?.primary_phone_number) },
      { name: "Description", value: surgeryData?.description }],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/surgery-and-treatment/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Procedures and Treatments History",
    historyColumnsData: surgeryHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
