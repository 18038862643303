import React from "react";

import ArchiveConditionItem from "../../Components/Archive-Items/ArchiveCondition";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveCondition() {
  return (
    <Layout>
      <ArchiveConditionItem />
    </Layout>
  );
}
