import React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
// import Logout from './Logout';

export default function OwnerInfo(props) {
  return (
    <>
      <Wrapper>
        <Owner>
          {props.profilePic ? (
            <OwnerLogo src={props.profiePic} alt={"logo"} />
          ) : (
            <AccountCircleIcon
              sx={{ marginLeft: 2, fontSize: 65, color: "white" }}
            />
          )}
          {/* <OwnerLogo src={props.profiePic} /> */}
          <OwnerTitleGroup>
            <Title>Owner</Title>
            <OwnerName>
              {props.firstName} {props.lastName}{" "}
            </OwnerName>
            {/* <OwnerText> {props.location}</OwnerText>             */}
          </OwnerTitleGroup>
        </Owner>
      </Wrapper>
      {/* <Logout/> */}
    </>
  );
}

const Wrapper = styled("div")(() => ({
  width: "28%",
  height: "80px",
  backgroundColor: "rgba(0, 7, 13, 0.568)",
  bottom: "0px",
  left: "50%",
  position: "absolute",
  transform: "translateX(-50%)",
  borderTopLeftRadius: "19px",
  borderTopRightRadius: "19px",
  "@media (max-width: 1338px)": {
    width: "35%",
  },
  "@media (max-width: 910px)": {
    width: "35vw",
  },
  "@media (max-width: 725px)": {
    width: "40vw",
  },
  "@media (max-width: 610px)": {
    width: "49vw",
  },
}));
const Owner = styled("div")(() => ({
  display: "grid",
  gridGap: "60px",
  gridTemplateColumns: "40px auto",
  margin: "15px 2px 2px 25px",
  alignItems: "center",
}));
const OwnerLogo = styled("img")(() => ({
  borderRadius: "20px",
  width: "55px",
  height: "55px",
}));
const OwnerTitleGroup = styled("div")(() => ({
  color: "white",
}));
const OwnerName = styled("div")(() => ({}));
const OwnerText = styled("div")(() => ({}));
const Title = styled("div")(() => ({}));
