import React, { useRef, useState } from "react";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { updateUserPasswordById } from "../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../Constant";
import { Body, ButtonBoxStyle, Column, FormHeaderBox } from "../../Css";
import { ResetPasswordValidation } from "../../From/Validation";
import CustomButton from "../Common/Button";
import FormGroup from "../Common/FormGroup";
import PasswordField from "../Common/PasswordField";
import CustomSnackbar from "../Common/Snackbar";

const defaultValue = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export default function UserEdit() {
  const user_id = localStorage.getItem("pawprint_user_id");
  const [initialValue, setInitialValue] = useState(defaultValue);
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    if (values.oldPassword === values.newPassword) {
      setFieldError(
        "newPassword",
        "New password cannot be the same as the Old password"
      );
      setSubmitting(false);
      // setOpen(true);
      return;
    }

    const formData = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    };
    if (
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "user" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) ===
        "General Authorized User" ||
      localStorage.getItem(PAWPRINT_USER_ROLE_KEY) === "View Only User"
    ) {
      updateUserPasswordById(user_id, formData)
        .then((response) => {
          setOpenSuccess(true);
        })
        .catch((error) => {
          console.error(error);

          const message =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Password change failed";

          // setOpen(true);
          setFieldError("oldPassword", message);
          setSubmitting(false);
        });
    }
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <ArrowBackIosNewRoundedIcon
          sx={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <FormTitle>Reset Your Password</FormTitle>
      </Box>
      <FormWrapper>
        <Formik
          initialValues={initialValue}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validationSchema={ResetPasswordValidation}
          validateOnMount={false}
          enableReinitialize
        >
          {({ getFieldProps, isSubmitting, values, errors }) => (
            <Form>
              <Box sx={FormHeaderBox}>
                <div className="scroll2" style={{ width: "100%" }}>
                  <Body>
                    <Column>
                      <FormGroup
                        name="oldPassword"
                        label={"Enter your old password"}
                        requiredField
                      >
                        <PasswordField
                          {...getFieldProps("oldPassword")}
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </FormGroup>
                    </Column>
                  </Body>
                  <Body style={{ marginTop: "10px" }}>
                    <Column>
                      <FormGroup
                        name="newPassword"
                        label={"Enter your new password"}
                        requiredField
                      >
                        <PasswordField
                          {...getFieldProps("newPassword")}
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </FormGroup>
                    </Column>
                  </Body>
                  <Body style={{ marginTop: "10px" }}>
                    <Column>
                      <FormGroup
                        name="confirmPassword"
                        label={"Confirm your password"}
                        requiredField
                      >
                        <PasswordField
                          {...getFieldProps("confirmPassword")}
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                        />
                      </FormGroup>
                    </Column>
                  </Body>
                </div>
                <Box sx={ButtonBoxStyle}>
                  <CustomButton
                    type="submit"
                    disabled={isSubmitting}
                    label={isSubmitting ? "Saving..." : "Save"}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </FormWrapper>
      {openSuccess && (
        <>
          <CustomSnackbar
            title="Updated Successfully!"
            desc="Your password has been updated."
            open={openSuccess}
            okOnclick={() => {
              setOpenSuccess(false);
              navigate("/settings");
            }}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 20px;
`;

const FormTitle = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
`;

const FormWrapper = styled.div`
  justify-content: center;
  align-items: center;
  // margin: 0 auto;
  // width: 90%;
`;
