import React, { useEffect } from "react";
import { useFormikContext } from "formik";

import InputField from "../Common/InputField";
import FormGroup from "../Common/FormGroup";
import SelectField from "../Common/AutoComplete";
import { usStates } from "./constant";
import { Body, Check, Column, Header, Wrapper } from "../../Css";

function ShippingInformation({
  onPrevious,
  setInitialValue,
  setValueinFormData,
}) {
  const { getFieldProps, values, touched } = useFormikContext();

  useEffect(() => {
    touched.fName = false;
    touched.lName = false;
    touched.address_line_1 = false;
    touched.admin_area_2 = false;
    touched.billing_postal_code = false;
    touched.admin_area_1 = false;
  }, []);

  const handleCheck = () => {
    if (!values.infoChecked) {
      setInitialValue({
        ...values,
        fName: values?.firstnameB,
        lName: values?.lastnameB,
        address_line_1: values?.streetAddB,
        address_line_2: values?.addressB,
        admin_area_2: values?.CityB,
        billing_postal_code: values?.zipcodeB,
        admin_area_1: values?.stateB,
        infoChecked: true,
      });
    } else {
      setInitialValue({
        ...values,
        fName: "",
        lName: "",
        address_line_1: "",
        address_line_2: "",
        admin_area_2: "",
        billing_postal_code: "",
        admin_area_1: "",
        infoChecked: false,
      });
    }
  };

  useEffect(() => {
    setValueinFormData();
  }, [values]);

  return (
    <div>
      <Wrapper>
        <Header>Shipping Information</Header>
        <Check>
          <input
            type="checkbox"
            onChange={handleCheck}
            checked={values.infoChecked}
          ></input>
          Same as Billing address?
        </Check>
        <Body>
          <Column>
            <FormGroup
              label="First Name"
              name="fName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="fName"
                {...getFieldProps("fName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Last Name"
              name="lName"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lName"
                {...getFieldProps("lName")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Street address"
              name="address_line_1"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_1"
                {...getFieldProps("address_line_1")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Address 2"
              name="address_line_2"
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="address_line_2"
                {...getFieldProps("address_line_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="City"
              name="admin_area_2"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="admin_area_2"
                {...getFieldProps("admin_area_2")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="State / Province / Region"
              name="admin_area_1"
              labelClassName="margin-top"
            >
              <SelectField
                {...getFieldProps("admin_area_1")}
                name="admin_area_1"
                options={usStates}
                placeholder="Select State"
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Zip Code"
              name="billing_postal_code"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="billing_postal_code"
                {...getFieldProps("billing_postal_code")}
              />
            </FormGroup>
          </Column>
          <Column></Column>
          <button
            onClick={onPrevious}
          >
            Back
          </button>
          <button type="next">Next</button>
        </Body>
      </Wrapper>
    </div>
  );
}

export default ShippingInformation;
