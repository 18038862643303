import React from "react";

import ArchivePetInsuranceComponent from "../../Components/Selected-item-view-Ar/PetInsuarance/PetInsuranceComponent";

const ArchivePetInsuSecond = () => {

  return (
    <>
      <ArchivePetInsuranceComponent />
    </>
  );
};

export default ArchivePetInsuSecond;
