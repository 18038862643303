import React, { useEffect, useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import HttpCommon from "../../Utils/http-common";
import "../Registration/Register.css";
import { Form, Formik } from "formik";
import { RegisterValidation } from "../../From/Validation";
import NewAccountRegister from "./NewAccountRagister";
import BillingInformation from "../../Components/register/BilingInformation";
import ShippingInformation from "../../Components/register/ShippingInformation";
import PaymentInfo from "../../Components/register/paymentinfo";
import useSWR from "swr";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

function Register() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const formData = useRef();

  const [steps, setSteps] = useState(0);

  const stripe = useStripe();
  const elements = useElements();
  // const { stripe, elements } = useStripe();
  const defaultValue = {
    firstname: "",
    email: "",
    password: "",
    phoneNumber: "",
    lastname: "",
    confirmpassword: "",
    code: "",
    firstnameB: "",
    streetAddB: "",
    CityB: "",
    zipcodeB: "",
    lastnameB: "",
    stateB: "",
    card: "",
    expiry: "",
    securityCode: "",
    fName: "",
    lName: "",
    address_line_1: "",
    address_line_2: "",
    admin_area_2: "",
    billing_postal_code: "",
    admin_area_1: "",
    nfoChecked: false,
    cardnumber: "",
    hasMembershipNumber: false,
    membershipNumber: "",
    isValidMembershipNumber: false,
  };
  const [initialValue, setInitialValue] = useState(defaultValue);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: roleData } = useSWR(
    `/role/getRoleByRoleName`,
    async () => {
      try {
        const { data } = await HttpCommon.post("/role/getRoleByRoleName", {
          name: "user",
        });
        return data?.data;
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const registerUser = async (values, token) => {
    try {
      const newUser = {
        ...values,
        role_id: roleData?.id,
        token,
      };
      await HttpCommon.post("/user/sign-up", newUser);
      setIsFormSubmitted(true);
      const formData = {
        ...values,
      };
      delete formData?.password;
      delete formData?.confirmpassword;
      ReactGA.gtag("event", "new_user_checkout", {
        form_data: formData,
        status: "Submitted",
      });
      swal({
        title: "Account successfully created!",
        text: "You need to verify email address. Check your email!",
        icon: "success",
      }).then(() => {
        navigate("/account");
      });
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        swal("Network Error!", "Failed to connect server.", "error");
      } else if (err?.response?.data?.code === 500) {
        swal("Network Error!", err?.response?.data?.message, "error");
      }
    }
  };
  const handleSubmit = async (values, { setFieldError }) => {
    await HttpCommon.post("/user/checkEmail", { email: values.email }).then(
      (res) => {
        if (res.data.message === "Email Exists.") {
          setFieldError(
            "email",
            "This Email Is Already Associated With An Account"
          );
        } else {
          setSteps(steps + 1);
          window.scrollTo(0, 580);
        }
      }
    );
  };

  useEffect(() => {
    return () => {
      if (!isFormSubmitted) {
        const data = {
          ...formData?.current?.values,
        };
        delete data?.password;
        delete data?.confirmpassword;
        ReactGA.gtag("event", "new_user_checkout", {
          ...data,
          status: "NotSubmitted",
        });
      }
    };
  }, []);

  const setValueinFormData = () => {
    if (formRef?.current) {
      formData.current = formRef?.current;
    }
  };

  const onSubmit = async (values, { setFieldError }) => {
    if (values?.hasMembershipNumber && values?.isValidMembershipNumber) {
      await registerUser(values, "", { setFieldError });
    } else if (steps === 2) {
      try {
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          alert("NOT LOADED");
          return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(cardNumberElement);
        await registerUser(values, result.token, { setFieldError });
      } catch (err) {
        console.error(err, err.message);
      }
    } else {
      setSteps(steps + 1);
      window.scrollTo(0, 580);
    }
  };

  const getStatedItem = useMemo(() => {
    switch (steps) {
      case 0:
        return (
          <NewAccountRegister
            setValueinFormData={setValueinFormData}
            steps={steps}
            setSteps={setSteps}
          />
        );
      case 1:
        return (
          <BillingInformation
            onPrevious={() => setSteps(steps - 1)}
            setInitialValue={setInitialValue}
            setValueinFormData={setValueinFormData}
          />
        );
      // case 2:
      //   return (
      //     <ShippingInformation
      //       onPrevious={() => setSteps(steps - 1)}
      //       setInitialValue={setInitialValue}
      //       setValueinFormData={setValueinFormData}
      //     />
      //   );
      case 2:
        return (
          <PaymentInfo
            onPrevious={() => setSteps(steps - 1)}
            setValueinFormData={setValueinFormData}
          />
        );
      default:
        return <></>;
    }
  }, [steps]);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={RegisterValidation[steps]}
        innerRef={formRef}
      >
        <Form>{getStatedItem}</Form>
      </Formik>
    </Wrapper>
  );
}

export default Register;

const Wrapper = styled.div`
  font-family: sans-serif;
  padding: 200px 0 100px 0px;
  text-align: center;
  min-height: 63vh;
  @media (max-width: 650px) {
    padding: 140px 0 100px 0px;
  }
`;
