import React, { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormikContext } from "formik";
import { get } from "lodash";

function PasswordField({
  id,
  name,
  label,
  autoComplete = "off",
  sx,
  helperText,
  onBlur,
  onChange,
  width,
  required,
  value,
  errorMessage,
  inputSx,
  boxStyle,
  inputRef,
  ...params
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const { errors, values, touched, setFieldValue } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);

  return (
    <>
      <Box sx={{ ...BoxFieldStyle, ...boxStyle }}>
        <TextField
          inputRef={inputRef}
          id={id}
          name={name}
          label=""
          type={showPassword ? "text" : "password"}
          autoComplete={autoComplete}
          variant="outlined"
          sx={{ height: 35, ...sx }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={fieldTouched && error}
          helperText={helperText ? helperText : ""}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              top: "40px",
              left: "0px",
              color: "transparent",
              "&.Mui-error": {
                color: "#d32f2f",
              },
            },
          }}
          {...params}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  sx={{
                    color: "#fff",
                    padding: 0,
                    background: "transparent !important",
                    zIndex: 1,
                    width: "auto",
                  }}
                >
                  {showPassword ? (
                    <VisibilityOff sx={{ color: "black" }} />
                  ) : (
                    <Visibility sx={{ color: "black" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
}

export default PasswordField;



const BoxFieldStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    fontFamily: "sans-serif",
    border: "1px solid #FF8533",
    borderRadius: "6px",
    // width: "100%",
    height: "38px",
    margin: "none",
    padding: "none",
    textAlign: "center",
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    background: "#FFF",
    // boxShadow:'none'
  },
  "& .MuiOutlinedInput-input": {
    position: "relative",
    zIndex: 1,
    padding: "5px 14px"
  },
  "& .MuiInputBase-root ": {
    // width: "230px",
    width: '100%',
    height: "35px",
  },
  "& .MuiFormControl-root": {
    width: "100%",
    padding: "0px 0px",
    height: "35px",
    color: "white",
  },
  "& .MuiOutlinedInput-root:hover ": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF8533",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#FF8533",
    },
    "& .MuiSvgIcon-root": {
      zIndex: 2,
    },
    "& .MuiButtonBase-root": {
      zIndex: 2,
    },
  },
  "@media (max-width: 600px)": {
    width: "99%",
  },
};
