import React from "react";

import ArchiveSurgeryInfoComponent from "../../Components/Selected-item-view-Ar/SurgeryTreatInfo/SurgeryInfoComponent";

const ArchiveSurgerySecond = () => {

  return (
    <>
      <ArchiveSurgeryInfoComponent />
    </>
  );
};

export default ArchiveSurgerySecond;
