import React from "react";
import styled from "styled-components";
import "../../Assets/Styles/ContactUs/contact.css";

export default function Contacts() {

  return (
    <div>
      <ContactTitle>Contact Us</ContactTitle>
    </div>
  );
}

const ContactTitle = styled.h1`
  /* font-family: "omnes", sans-serif;
  font-style: normal; */
  line-height: 112.4%;
  color: #008fd3;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 170px 0px 0px 0px;
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  @media (max-width: 1100px) {
    font-size: 45px;
  }
  @media (max-width: 975px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (max-width: 895px) {
    font-size: 38px;
  }
  @media (max-width: 855px) {
    letter-spacing: -0.01em;
    font-size: 35px;
  }
  @media (max-width: 715px) {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
  }
`;
