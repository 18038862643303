import React from "react";

import SettingComponent from "../../Components/Setting/Setting";
import Layout from "../../Components/Side bar/Layout";

const Settings = () => {

  return (
    <Layout>
      <SettingComponent />
    </Layout>
  );
};

export default Settings;
