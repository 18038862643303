import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function MenuButton(props) {
  const { item } = props;
  let navigate = useNavigate();
  return (
    <div>
      <div
        onClick={() => {
          navigate(item.link);
        }}
        style={{ margin: "16px 0px"}}
      >
        <MenuItem title={item.title}>
          <img src={item.icon} alt={item.title} />
          {item.title}
        </MenuItem>
      </div>
    </div>
  );
}

export default MenuButton;
const MenuItem = styled.div`
  color: rgba(255, 255, 255, 0.8);
  display: grid;
  grid-template-columns: 24px auto;
  gap: ${(props) => (props.title ? "10px" : "0px")};
  align-items: center;
  padding: 0px 5px;
  transition: 0.5s ease-out;
  border-radius: 10px;
  justify-items: center;

  :hover {
    background: rgba(0, 143, 211, 1);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
  }
`;
