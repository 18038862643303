import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { getPetByID } from "../../../Api/Services/PetServices";
import { getSurgeryByID } from "../../../Api/Services/SurgeryServices";
import { getVetByID } from "../../../Api/Services/VetServices";
import { formatDate } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function ArchiveSurgeryInfoComponent() {
  const { id } = useParams();
  const [petId, setPetId] = useState("");
  const [vetId, setVetId] = useState("");
  const navigate = useNavigate();

  const { data: surgeryData = [], isLoading } = useSWR(
    id ? `surgeryData${id}` : null,
    async () => {
      const { data } = await getSurgeryByID(id);
      setPetId(data?.data?.[0]?.pet_id);
      setVetId(data?.data?.[0]?.vet_id);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: petData = {}, isLoading: isPetLoading } = useSWR(
    petId ? `petData${petId}` : null,
    async () => {
      const { data } = await getPetByID(petId);
      return data?.data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: vetData = [], isLoading: isVetLoading } = useSWR(
    vetId ? `vetData${vetId}` : null,
    async () => {
      const { data } = await getVetByID(vetId);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const props = {
    label: "Archive Procedures and Treatments",
    isLoading: isLoading || isPetLoading || isVetLoading,
    onBack: () => {
      navigate("/archive/surgery-treatment");
    },
    petInfo: petData,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Procedure Date",
        value: "date",
      },
      {
        label: "Vet Name",
        value: "vet_full_name",
      },
      {
        label: "Procedure Type",
        value: "surgery_type",
      },
    ],
    columnsData: [
      {
        image: petData?.image,
        pet_name: petData?.name,
        date: formatDate(surgeryData?.date),
        vet_full_name: vetData?.vet_full_name,
        surgery_type: surgeryData?.surgery_type,
      },
    ],
    detailsData: [
      [
        { name: "Email", value: vetData?.email },
        { name: "Fax", value: vetData?.fax },
        { name: "Phone Number", value: vetData?.primary_phone_number },
        { name: "Description", value: surgeryData?.description },
      ],
      [
        { name: "Street Address", value: vetData?.street_address },
        { name: "Street Address 2", value: vetData?.street_address_2 },
        { name: "City", value: vetData?.city },
        { name: "Country", value: vetData?.country },
        { name: "Zip Code", value: vetData?.zip_code },
      ],
    ],
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petData?.userDetails?.first_name}
          lastName={petData?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
