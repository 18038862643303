import React from "react";

import ArchiveVet from "../../Components/Archive-Items/ArchiveVet&Therapist";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveAllergy() {
  return (
    <Layout>
      <ArchiveVet />
    </Layout>
  );
}
