import React from "react";

import { Close } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Snackbar } from "@mui/material";

import successIcon from "../../Assets/Images/successIcon.svg";


function CustomSnackbar({ okOnclick, title, desc, open }) {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "37px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 100,
        }}
      >
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          sx={{
            position: "absolute",
            top: "0%",
            left: "50%",
            transform: "translateX(-50%)",
            "& .MuiPaper-root": {
              justifyContent: "center",
            },
          }}
        >
          <Alert
            variant="filled"
            severity="success"
            width="40vw"
            sx={{
              minWidth: "25vw",
              minHeight: "8vw",
              background: "white",
              color: "black",
              borderRadius: '10px',
              "& .MuiAlert-icon": {
                display: "none",
                marginRight: "8px",
              },
            }}
          >
            <Box sx={{ position: 'absolute', right: '10px'}}>
              <Close onClick={okOnclick} style={{ color: '#FF8355', cursor: 'pointer'}} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: 'center', marginTop: '30px' }}>
              <img src={successIcon} alt='success' style={{ marginBottom: '20px' }}/>
              <AlertTitle sx={{ color: '#565868', fontSize: '18px', fontWeight: 400, margin: "1px 0" }}>{title}</AlertTitle>
            </Box>
            <p style={{ color: '#565868', fontSize: '16px', fontWeight: 400 }}>{desc}</p>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button size="small" onClick={okOnclick} sx={btnStyle}>
                OK
              </Button>
            </Box>
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default CustomSnackbar;

const btnStyle = {
  mx: 0.5,
  color: "white",
  background: "#FF8533 !important",
  borderRadius: "10px",
  width: '84px',
  textTransform: "none",
  fontSize: "13px",
  fontWeight: 500,
  backdropFilter: "blur(10px)",
  height: '34px',
  marginBottom: '14px',
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid #FF8533",
  },
};