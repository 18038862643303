import React from "react";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import "../Item-List/GlobalItemList.css";
import "react-phone-input-2/lib/style.css";
import Layout from "../Side bar/Layout";

export default function FormLayout({ title, children, onBack }) {
  const navigate = useNavigate();

  return (
    <Layout>
      <FullPageWrapper>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid
            item
            xs={6}
            sm={12}
            lg={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              display={"flex"}
              sx={{
                justifyContent: "left",
                alignItems: "center",
                marginTop: "30px",
              }}
              pb="15px"
            >
              <ArrowBackIosNewRoundedIcon
                sx={{ color: "black", cursor: "pointer" }}
                onClick={() => onBack ? onBack() : navigate(-1)}
              />
              <Typography
                variant="h6"
                color="black"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "23px",
                  paddingLeft: {
                    sm: "20px",
                    md: "40px",
                    xs: "20px",
                    lg: "40px",
                    xl: "40px",
                  },
                }}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
        </Box>
        {children}
      </FullPageWrapper>
    </Layout>
  );
}

export const FullPageWrapper = styled("div")(() => ({
  padding: "20px",

  "@media (max-width: 1024px)": {
    paddingTop: "1rem",
  },
}));
