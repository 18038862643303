import React from "react";

import FormFooter from "../../Components/SurgeryandTreatInfo/FormFooter";
import FormHeader2 from "../../Components/SurgeryandTreatInfo/FormHeader2";
import FormSection from "../../Components/SurgeryandTreatInfo/FormSection";

const SecondStep = () => {
  return (
    <>
      <FormHeader2 />
      <FormSection />
      {/* <FormFooter /> */}
    </>
  );
};

export default SecondStep;
