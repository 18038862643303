import React from "react";

import Allergy from "../../Components/Item-List/Allergy";
import Layout from "../../Components/Side bar/Layout";

const AllergyInfo = () => {
  return (
    <Layout>
      <Allergy />
    </Layout>
  );
};

export default AllergyInfo;
