import React, { useState, useRef } from "react";

import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  getInviteUserById,
  updateSubUserById,
  updateUserImageById,
} from "../../../Api/Services/UserServices";
import useAuth from "../../../ContextProvider/AuthProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  CancelButton,
  ButtonBoxStyle,
  newSmallFieldBoxStyle,
} from "../../../Css";
// import API end points ---------------------------------------
import "react-phone-input-2/lib/style.css";
import { UserFormEditValidation } from "../../../From/Validation";
import CustomButton from "../../Common/Button";
import FormGroup from "../../Common/FormGroup";
import FormLayout from "../../Common/FormHeader";
import ImageUpload from "../../Common/ImageUpload";
import InputField from "../../Common/InputField";
import CustomPhoneInput from "../../Common/PhoneInput";
import CustomSnackbar from "../../Common/Snackbar";
import { uploadS3Bucket } from "../../../Api/axios";

const defaultValue = {
  first_name: "",
  last_name: "",
  email: "",
  role_id: "",
  phone_number: "",
};

export default function UserCreateForm() {
  const { id } = useParams();
  let navigate = useNavigate();
  const { workspaceId } = useAuth();
  const [initialValue, setInitialValue] = useState(defaultValue);

  // Alert popup
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  // useStates for user data
  const [image, setImage] = useState("");

  const { mutate: fetchgetVetInfo } = useSWR(
    id && workspaceId ? `userInfo${id}` : null,
    async () => {
      try {
        const res = await getInviteUserById(id, workspaceId);
        const userData = res?.data?.data;
        setInitialValue({
          ...initialValue,
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          email: userData?.email,
          role_id: userData?.role_id,
          phone_number: userData?.contact_no,
        });
        setImage(userData?.image);
      } catch (error) {
        console.error("error: ", error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  // Function to Edit user image
  const updateImage = async () => {
    try {
      // var formData = new FormData();
      // if (image instanceof File) {
      //   formData.append("image", image);
      // }
      const updateData = {
        type: image?.type,
        file_name: `${Date.now()}-${image?.name}`,
      };
      const res = await updateUserImageById(id, updateData);
      const url = res?.data?.url;
      const decodedUrl = decodeURIComponent(url);
      const uploadRes = await uploadS3Bucket(decodedUrl, image);
      if (uploadRes?.status === 200) {
        console.log("success", uploadRes);
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };
  const styles = {
    radio: {
      "& .MuiRadio-root": {
        color: `#FF8533`,
        "&.Mui-checked": {
          color: `#FF8533`,
        },
      },
    },
  };
  // Function to Edit user data
  const updateForm = async (values) => {
    try {
      const res = await updateSubUserById(id, workspaceId, values);
      if (image instanceof File) {
        const res = await updateImage();
      }
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error("User update failed:", err);
    }
  };

  // Submit function
  const componentPDF = useRef();

  return (
    <FormLayout title="Edit User Information">
      <Formik
        initialValues={initialValue}
        onSubmit={updateForm}
        validationSchema={UserFormEditValidation}
        validateOnMount={false}
        enableReinitialize
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              {/* ------------------------ Title section ---------------------*/}
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  User Details
                </Typography>
              </Box>
              {/* -------------------------------Form Section --------------------------- */}
              <div
                className="scroll2"
                ref={componentPDF}
                style={{ width: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <ImageUpload
                    alt="Uploaded_Image"
                    id="imageInput"
                    image={image}
                    setImage={setImage}
                    accept="image/*"
                    width="170px"
                    height="170px"
                  />
                </Box>
                <Body>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="first_name"
                          label={"First Name"}
                          requiredField
                        >
                          <InputField
                            {...getFieldProps("first_name")}
                            disabled
                          />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="last_name"
                          label={"Last Name"}
                          requiredField
                        >
                          <InputField
                            {...getFieldProps("last_name")}
                            disabled
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column style={{ gap: "21px", height: "max-content" }}>
                    <FormGroup
                      name="email"
                      label={"Email Address"}
                      requiredField
                    >
                      <InputField {...getFieldProps("email")} disabled />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup name="phone_number" label={"Phone Number"}>
                      <CustomPhoneInput
                        {...getFieldProps("phone_number")}
                        disabled
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box sx={{ marginTop: "0.3vw" }}>
                      <Box sx={{ position: "relative" }}>
                        <FormGroup name="role_id" label={"Role"} requiredField>
                          <RadioGroup
                            name="role_id"
                            {...getFieldProps("role_id")}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              color: "white",
                            }}
                            sx={styles.radio}
                          >
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="General Authorized User"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "0.9rem",
                                  color: "black",
                                },
                              }}
                            />
                            <FormControlLabel
                              value="4"
                              control={<Radio />}
                              label="View Only User"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "0.9rem",
                                  color: "black",
                                },
                              }}
                            />
                          </RadioGroup>
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                </Body>
              </div>
              {/* Button section */}
              <Box sx={ButtonBoxStyle}>
                <CustomButton
                  label="Cancel"
                  type="button"
                  style={CancelButton}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  label={isSubmitting ? "Saving..." : "Save"}
                  disabled={isSubmitting}
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {/* Popup Alert */}
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data updated successfully!"
          desc="Your user information has been updated."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate(`/user-information/user-info/${id}`);
            // window.location.reload();
          }}
        />
      )}
    </FormLayout>
  );
}
