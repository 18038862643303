import React from "react";

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Box, Button } from "@mui/material";
import styled from "styled-components";

const FormFooter = () => {
  return (
    <FooterWrapper>
     <Box display="flex" alignItems="center" justifyContent="center" p={4}>
          <Box display="flex">
            <Button
              variant="outlined"
              sx={SavebtnStyle}
              startIcon={<SaveOutlinedIcon />}
            ></Button>

            <Button
              variant="outlined"
              sx={PrintbtnStyle}
              startIcon={<LocalPrintshopOutlinedIcon />}
            ></Button>
          </Box>
        </Box>
    </FooterWrapper>
  );
};

export default FormFooter;

const FooterWrapper = styled.div`
  justify-content: center;
  text-align: center;
  align-items: center;
`;


const SavebtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "11px 0 0 11px",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  height: 40,
  backdropFilter: "blur(10px)",
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
const PrintbtnStyle = {
  color: "white",
  background: "#1c93ed !important",
  border: "2px solid rgba(255, 255, 255, 0.5)",
  boxshadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderRadius: "0 11px 11px 0",
  width: 100,
  textTransform: "none",
  fontSize: "13px",
  backdropFilter: "blur(10px)",
  height: 40,
  "& .MuiButton-startIcon": {
    margin: "0px",
  },
  "&:hover": {
    border: "2px solid white",
  },
};
