import React from "react";

import PetInsuranceComponent from "../../Components/Selected-item-view/PetInsuarance/PetInsuranceComponent";

const PetInsuSecond = () => {

  return (
    <>
      <PetInsuranceComponent />
    </>
  );
};

export default PetInsuSecond;
