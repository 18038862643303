import { api } from "../axios";

export const getEmergencyContact = async (id) => {
  const res = await api.get(`/emergency/EmergencyContactById/${id}`);
  return res;
};
export const getEmergencyAddress = async (id) => {
  const res = await api.get(`/emergency/getEmergencyAddressById/${id}`);
  return res;
};

// emercency contact item list
export const getEmergencyList = async () => {
  const res = await api.get(`/emergency/getAllEmergencyContact`);
  return res;
};
// pet emercency contact item list
export const getPetEmergency = async () => {
  const res = await api.get(`/emergency/getAllPetEmergency`);
  return res;
};

// --------------------------
export const getAllEmergencyContact = async () => {
  const res = await api.get(`/emergency/getAllEmergencyContact`);
  return res;
};

// get emergency by user id
export const getAllEmergencyByWorkspaceId = async (w_id) => {
  const res = await api.get(`/emergency/getByWorkspaceId/${w_id}`);
  return res;
};

export const getEmergencyAddressById = async (id) => {
  const res = await api.get(`/emergency/getEmergencyAddressById/${id}`);
  return res;
};

export const EmergencyContactById = async (id) => {
  const res = await api.get(`/emergency/EmergencyContactById/${id}`);
  return res;
};

//Emergency Contact
export const createEmergencyContact = async (formData) => {
  const res = await api.post(`/emergency/addAllData`, formData);
  return res;
};

export const getEmergencyContactById = async (id) => {
  const res = await api.get(`/emergency/getDataByEmId/${id}`);
  return res;
};

export const getidfnamebyWorkspace = async (id) => {
  const res = await api.get(`/emergency/getidfnamebyWorkspace/${id}`);
  return res;
};

// export const updateEmergencyContact = async (id, formData) => {
//   const res = await api.put(
//     `/emergency/updateEmergencyContact/${id}`,
//     formData
//   );
//   return res;
// };

export const updateEmergencyContact = async (id, formData) => {
  const res = await api.put(`/emergency/updateAllData/${id}`, formData);
  return res;
};

export const deleteEmergency = async (id) => {
  const res = await api.delete(`/emergency/deleteEmergencyContact/${id}`);
  return res;
};

//archieve emergency
export const getArchiveEmergencyByWorkspaceID = async (id) => {
  const res = await api.get(`/emergency/getArchieveByWorkspaceId/${id}`);
  return res;
};

// emergency archive
export const setArchieve = async (id) => {
  const res = await api.post(`/emergency/setArchieve/${id}`);
  return res;
};
// emergency unarchive
export const setEmergencyUnarchive = async (id) => {
  const res = await api.post(`/emergency/setunArchieve/${id}`);
  return res;
};