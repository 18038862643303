import * as React from "react";

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const ItemBox = ({ children, to }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
  };
  return (
    <Box
      sx={{
        width: { md: "100%", xl: "80%"},
        height: "15vh",
        cursor: "pointer",
        backgroundColor: "rgb(252, 132, 51)",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "rgb(252, 132, 51)",
          opacity: [0.9, 0.8, 0.7],
        },
      }}
      onClick={handleClick}
    >
      <Typography
        sx={{
          color: "white",
          fontSize: "15px",
          textAlign: "center",
          paddingTop: "6vh", // adjust the paddingTop value to center the text vertically
        }}
        variant="h5"
      >
        {children}
      </Typography>
    </Box>
  );
};

const ArchiveItems = [
  {
    link: "/archive/pet-information",
    title: "Pet Information",
  },
  {
    link: "/archive/emergency-contact",
    title: "Emergency Contacts",
  },
  {
    link: "/archive/vet-therapist",
    title: "Vets and Therapists",
  },
  {
    link: "/archive/prescription-medication",
    title: "Prescriptions and Medications",
  },
  {
    link: "/archive/pet-insurance",
    title: "Pet Insurance Information",
  },
  {
    link: "/archive/immunization",
    title: "Immunizations",
  },
  {
    link: "/archive/allergy",
    title: "Allergies",
  },
  {
    link: "/archive/condition",
    title: "Conditions",
  },
  {
    link: "/archive/surgery-treatment",
    title: "Procedures and Treatments",
  },
  {
    link: "/archive/journal",
    title: "Journal",
  },
  {
    link: "/archive/document",
    title: "Documents",
  },
];
export default function ArchiveList() {
  return (
    <ArchiveFullPageWrapper>
      <ArchiveTitle>Archived Items</ArchiveTitle>
      <Box sx={{ flexGrow: 1 }}>
          <MainGrid>
            {ArchiveItems.map((data, index) => (
              <Column key={index}>
                <ItemBox to={data.link}>{data.title}</ItemBox>
              </Column>
            ))}
          </MainGrid>
      </Box>
    </ArchiveFullPageWrapper>
  );
}
const ArchiveFullPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.7rem 9rem 0 9rem;

    @media (max-width: 1500px) {
      padding: 1.7rem 5rem 0 5rem;
    }
    @media (max-width: 1116px) {
      padding: 1.7rem 2rem 0 2rem;
    }
`;

const ArchiveTitle = styled.p(
  ({ theme }) => ({
    fontSize: "20px",
    color: "black",
    padding: "0 0 1.5rem 0.5rem",
  }),
  ({ theme }) => theme.sx
);

const MainGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(350px,1fr));
  grid-gap: 20px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;

  @media (max-width: 1825px) {
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
  }
  @media (max-width: 1343px) {
    grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
  }
  @media (max-width: 525px) {
    grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
  } 
`;

const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;
`;