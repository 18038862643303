import React from "react";
import "../../Assets/Styles/AboutUs/HowWork.css";

function HowWork() {
  return (
    <div className="WorkWrapper">
      <div className="WorkHeader">
        How <span>PawprintID&#174;</span> Works
      </div>
      <div className="WorkText">
        <p>
          You can create an account in minutes, and you're able to manage your
          pet's records from any device.
          <br />
          In the event that your pet becomes ill or needs immediate medical
          attention, PawprintID&#174; provides you with an easy way to share
          critical details about your furry friend with a local animal hospital
          or specialist.
        </p>
      </div>
    </div>
  );
}

export default HowWork;
