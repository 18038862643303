import React from "react";

import ArchiveDocumentInfoComponent from "../../Components/Selected-item-view-Ar/Document/DocumentInfoComponent";

const ArchiveDocumentSecond = () => {

  return (
    <>
      <ArchiveDocumentInfoComponent />
    </>
  );
};

export default ArchiveDocumentSecond;
