import { api } from "../axios";

export const getMeditation = async (id) => {
  const res = await api.get(`/medication/getMedicationById/${id}`);
  return res;
};

// get medication by user id
export const getAllMedicationByWorkspaceId = async (w_id) => {
  const res = await api.get(`/medication/getByWorkspaceId/${w_id}`);
  return res;
};

// prescription & medication item list
export const getPresMediList = async () => {
  const res = await api.get(`/medication/getAllMedication`);
  return res;
};

// Medication POST
// export const createMedication = async (formData) => {
//   const res = await api.post(`/medication/addAllData`, formData);
//   return res;
// };

export const updateMedication = async (id, formData) => {
  const res = await api.put(`/medication/updateMedication/${id}`, formData);
  return res;
};

//Prescription
export const getMedicationById = async (id) => {
  const res = await api.get(`/medication/getMedicationById/${id}`);
  return res;
};

export const postAllMedicationData = async (value) => {
  const res = await api.post(`/medication/createMedication`, value);
  return res;
};

//Delete Medication
export const deletePrescription = async (id) => {
  const res = await api.delete(`/medication/deleteMedication/${id}`);
  return res;
};

//archieve prescription
export const getArchivePrescriptionByWorkspaceID = async (id) => {
  const res = await api.get(`/medication/getArchieveByWorkspaceId/${id}`);
  return res;
};

//get medication history
export const getMedicationHistoryById = async (model, id) => {
  const res = await api.get(`/history/getMedicationHistoryById/${model}/${id}`);
  return res;
};

//set prescription archive
export const setPrescriptionArchieve = async (id) => {
  const res = await api.post(`/medication/setArchieve/${id}`);
  return res;
};
// prescription unarchive
export const setPrescriptionUnarchive = async (id) => {
  const res = await api.post(`/medication/setunArchieve/${id}`);
  return res;
};
