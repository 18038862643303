import styled from "styled-components";
import React from "react";
import { getFooter } from "../../api/axios";
import useSWR from "swr";
import { Container } from "@mui/material";

const Footer = () => {
  //fetch footer data
  const { data = [] } = useSWR(
    `aboutUsgetFooter`,
    async () => {
      try {
        const { data } = await getFooter();
        return data?.data || [];
      } catch (error) {
        console.error("error: ", error);
        alert(error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  return (
    <Body>
      {data.map((item, index) => {
        return (
          <Container maxWidth="xl" key={index}>
            <Footergroup>
              <LeftBox>
                <Newgroup>
                  <Text key={item.id}>{item.paragraph}</Text>

                  <Content>
                    <p>
                      <a href="/account">Member Login</a>
                    </p>
                    <p>
                      <a href="/resources">Resources</a>
                    </p>
                    <p>
                      <a href="/aboutus">About Us</a>
                    </p>
                    <p>
                      <a href="/Resources">Blog</a>
                    </p>
                    <p>
                      <a
                        href="https://drive.google.com/file/d/192S0MBkmXGY9JUcFdQKxOmKySe4yMsvM/view?usp=share_link"
                        download
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Content>
                </Newgroup>
              </LeftBox>
              <ContactImageGroup>
                <ParentComapanyBox>
                  <Textlink>
                    <div>Visit our parent company</div>
                    <Foot>
                      <RO>
                        <a href="https://footprintid.com/">FootprintID </a>
                        <a href="https://footprintid.com/">
                          <img
                            src={require("../../Assets/Images/Footer/favicon.ico")}
                            alt="pawprintlogo"
                          />
                        </a>
                      </RO>
                    </Foot>
                  </Textlink>
                </ParentComapanyBox>
                <ContactDetails>
                  <SocialMedia>
                    <a href={item.facebook} target="_blank" rel="noreferrer">
                      <img
                        src={require("../../Assets/Images/Footer/icons8-facebook-48.png")}
                        alt="facebookicon"
                      />
                    </a>
                    <a href={item.twitter} target="_blank" rel="noreferrer">
                      <img
                        src={require("../../Assets/Images/Footer/icons8-twitter-48.png")}
                        alt="twittericon"
                      />
                    </a>
                    <a href={item.linkedin} target="_blank" rel="noreferrer">
                      <img
                        src={require("../../Assets/Images/Footer/icons8-linkedin-48.png")}
                        alt="linkedinicon"
                      />
                    </a>
                    <a href={item.instagram} target="_blank" rel="noreferrer">
                      <img
                        src={require("../../Assets/Images/Footer/icons8-instagram-48.png")}
                        alt="instergramicon"
                      />
                    </a>
                    <a href={item.pinterest} target="_blank" rel="noreferrer">
                      <img
                        src={require("../../Assets/Images/Footer/icons8-pinterest-48.png")}
                        alt="pinteresticon"
                      />
                    </a>
                  </SocialMedia>
                  <AppStore>
                    <AppWrapper>
                      <a href="https://apps.apple.com/us/app/pawprintid/id1551145317">
                        <img
                          src={require("../../Assets/Images/Footer/app-store.png")}
                          alt="appstore"
                          style={{
                            // maxWidth: "10vw",
                            width: "132px",
                            height: "50px",
                            borderRadius: "10px",
                          }}
                        />
                      </a>

                      <a href="https://play.google.com/store/apps">
                        <img
                          src={require("../../Assets/Images/Footer/playStore.jpg")}
                          alt="playstore"
                          style={{
                            // maxWidth: "10vw",
                            width: "132px",
                            height: "50px",
                            borderRadius: "10px",
                          }}
                        />{" "}
                      </a>
                    </AppWrapper>
                  </AppStore>
                </ContactDetails>
              </ContactImageGroup>
            </Footergroup>
            <Resounces>
              <p>
                <a href="/account">Member Login</a>
              </p>
              <p>
                <a href="/resources">Resources</a>
              </p>
              <p>
                <a href="/aboutus">About Us</a>
              </p>
              <p>
                <a href="/Resources">Blog</a>
              </p>
              <p>
                <a
                  href="https://drive.google.com/file/d/192S0MBkmXGY9JUcFdQKxOmKySe4yMsvM/view?usp=share_link"
                  download
                >
                  Privacy Policy
                </a>
              </p>
            </Resounces>
            <BottomBox>
              <CopyRight>
                {/* <p> */}
                &copy;{new Date().getFullYear()} Copyright PawprintID, All
                Rights Reserved
                {/* </p> */}
              </CopyRight>
              <ContactBox>
                <Paragraph>
                  <img
                    src={require("../../Assets/Images/Footer/location-icon.png")}
                    alt="addressicon"
                  />
                  &nbsp;
                  {item.mailing_address}
                </Paragraph>
                <Paragraph>
                  <img
                    src={require("../../Assets/Images/Footer/telephone-icon.png")}
                    alt="telephoneicon"
                  />
                  &nbsp;
                  <a href="tel:855-374-3411">{item.phoneNo}</a>
                </Paragraph>
                <Paragraph>
                  <img
                    src={require("../../Assets/Images/Footer/email-icon.png")}
                    alt="emailicon"
                  />
                  &nbsp;
                  <a href="mailto:info@pawprintid.com">{item.email_address}</a>
                </Paragraph>
              </ContactBox>
            </BottomBox>
          </Container>
        );
      })}
    </Body>
  );
};

export default Footer;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 25px;

  a {
    line-height: 0;
  }

  @media (max-width: 850px) {
    gap: 20px;
    justify-content: start;
  }
  @media (max-width: 1200px) {
    flex-direction: row;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const RO = styled.div`
  a {
    text-decoration: none;
  }
  img {
    height: 20px;
    width: 20px;
  }
`;

const Foot = styled.div`
  padding-top: 10px;
  @media (max-width: 1280px) {
    justify-content: start;
  }
`;
const Textlink = styled.div`
  padding-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;

  a {
    color: rgba(0, 143, 211, 1);
  }

  @media (max-width: 1200px) {
    text-align: start;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const Body = styled.div`
  background: #fc8433;
  @media (max-width: 450px) {
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #fc8433;
  }
`;
const Footergroup = styled.div`
  padding: 0px;
  display: flex;
  padding-bottom: 30px;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  @media (max-width: 850px) {
    padding: 25px 0px;
    grid-template-columns: repeat(1, auto);
  }
  @media (max-width: 450px) {
    margin: 0px;
    padding: 5px 0px;
    grid-template-columns: repeat(1, auto);
  }

  @media (max-width: 340px) {
    padding: 5px 0px;
    grid-template-columns: repeat(1, auto);
    margin: 0px;
  }
`;
const Newgroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) {
    width: 70%;
    gap: 10px;
  }
  @media (max-width: 1050px) {
    width: 80%;
    gap: 10px;
  }
  @media (max-width: 930px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ParentComapanyBox = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1200px) {
    width: auto;
  }
`;

const LeftBox = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;
const ContactImageGroup = styled.div`
  display: flex;
  width: 50%;
  grid-gap: 40px;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    gap: 150px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const CopyRight = styled.p`
  color: white;
  margin: 0px;
  padding: 30px 0;

  @media (max-width: 1200px) {
    padding-top: 10px;
  }
`;

const ContactBox = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 750px) {
    width: 100%;
    gap: 0px;
    flex-direction: column;
    align-items: center;
  }
`;

const BottomBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid black;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const Text = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
  @media (max-width: 1280px) {
    margin-top: 30px;
    padding-top: 0px;
  }
  @media (max-width: 1200px) {
    text-align: center;
  }
  @media (max-width: 850px) {
    margin-top: 10px;
  }
`;
const Content = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #ffffff;
  display: flex;
  gap: 30px;
  padding-top: 30px;
  text-align: left;
  p {
    margin: 10px 0px;
  }
  a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, auto);
    text-align: center;
    align-items: center;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;
const Resounces = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: #ffffff;
  display: flex;
  gap: 0px 30px;
  flex-wrap: wrap;
  padding-bottom: 30px;
  justify-content: center;
  p {
    margin: 10px 0px;
  }
  a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`;
const ContactDetails = styled.div`
  padding-top: 30px;
  text-align: right;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  color: #ffffff;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  @media (max-width: 1280px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 850px) {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;
const SocialMedia = styled.div`
  display: flex;
  justify-content: end;
  gap: 30px;

  img {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 425px) {
    gap: 10px;
  }
`;

const AppStore = styled.div`
  padding-top: 15px;
  /* width:30px;
  height:10px; */
  @media (max-width: 1280px) {
    text-align: end;
  }
`;

const Paragraph = styled.p`
  display: flex;
  align-items: center;
  justify-content: end;
  color: white;
  margin: 0px;
  padding: 30px 0;

  a {
    color: white;
    text-decoration: none;
  }
  img {
    height: 25px;
    width: 25px;
  }
  @media (max-width: 1200px) {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  @media (max-width: 850px) {
    justify-content: start;
  }
  @media (max-width: 750px) {
    padding: 10px 0;
  }
`;
