import React from "react";

import ArchiveDocumentItem from "../../Components/Archive-Items/ArchiveDocument";
import Layout from "../../Components/Side bar/Layout";

export default function ArchiveDocument() {
  return (
    <Layout>
      <ArchiveDocumentItem />
    </Layout>
  );
}
