import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const UserFormValidation = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[aA-zZ\s]+$/, "Please enter Only alphabets"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[aA-zZ\s]+$/, "Please enter Only alphabets"),
  email: Yup.string()
    .email(`Invalid email format`)
    .required("Email is required")
    // .matches(
    //   /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
    //   "Please enter valid email address"
    // ),
    .test(
      "is-lowercase-and-no-slash",
      'Email should be in lowercase and cannot contain "/"',
      (value) => !value.match(/[A-Z/]/)
    ),
  role_id: Yup.string().required("Please Select role"),
  password: Yup.string()
    .min(6, "Password should be at least 6 characters long")
    .minLowercase(1, "Password should contain at least one lowercase letter")
    .minUppercase(1, "Password should contain at least one uppercase letter")
    .minNumbers(1, "Password should contain at least one number")
    .minSymbols(1, "Password should contain at least one special character")
    .required("Please enter your password "),
  confPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), ""], "Password does not match"),
  phone_number: Yup.string().min(
    10,
    "Phone number must have at least 10 characters"
  ),
});
export const UserFormEditValidation = Yup.object({
  first_name: Yup.string()
    .required("First name is required")
    .matches(/^[aA-zZ\s]+$/, "Please enter Only alphabets"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(/^[aA-zZ\s]+$/, "Please enter Only alphabets"),
  email: Yup.string()
    .email(`Invalid email format`)
    .required("Email is required"),
  role_id: Yup.string().required("Please Select role"),
  phone_number: Yup.string().min(
    10,
    "Phone number must have at least 10 characters"
  ),
});

export const PetInfoFormValidation = Yup.object({
  pet_type: Yup.string().required("Pet type is required"),
  name: Yup.string().required("Pet name is required"),
  breed: Yup.string().required("Breed is required"),
  family_name: Yup.string().required("Family name is required"),
  dob: Yup.date()
    .required("Date is required")
    .max(new Date(), "Future dates are not allowed"),
  weight: Yup.number()
    .min(1, "Invalid number")
    .required("Required")
    .typeError("weight type is required"),
  weightMeasurement: Yup.string().required("Required"),
  gender: Yup.string().required("Gender is required"),
  fur_color: Yup.string().required("Color is required"),
  eye_Color: Yup.string().required("Eye color is required"),
  company_contact_number: Yup.string()
  .min(
    10, "Company Phone Number is not valid"),
  zip_code: Yup.number().typeError("Enter valid Zip code"),
});

export const VetAndTherapistFormValidation = Yup.object({
  email: Yup.string()
    .email(`Invalid email format`)
    .required("Email is required")
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      "Invalid email format"
    ),
  // street_address: Yup.string().required("Street address is required"),
  // city: Yup.string().required("City is required"),
  // zip_code: Yup.number()
  //   .test("len", "Max 12 numbers", (val) => val.toString().length <= 12)
  //   .required("Enter a digit code"),
  primary_phone_number: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must have at least 10 characters"),
  fax: Yup.string()
  .matches(/^\+?\d{1,4}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}(\s?x\d{1,5})?$/, 'Invalid fax number'),
  type: Yup.string().required(" Vet type is required"),
  vet_id: Yup.string().required(" Vet full name is required"),
  vet_practice_id: Yup.string().required("Veterinary name is required"),
  vet_practice_name: Yup.string().when(
    "vet_practice_id",
    (vet_practice_id, field) => {
      return vet_practice_id[0] === "other"
        ? field.required("Veterinary name is required")
        : field;
    }
  ),
  pet_id: Yup.string().required("Pet name is required"),
  vet_portal: Yup.string().url('Invalid URL format')
});

export const EditVetAndTherapistFormValidation = Yup.object({
  email: Yup.string()
    .email(`Invalid email format`)
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      "Invalid email format"
    ),
  // street_address: Yup.string().required("Street address is required"),
  // city: Yup.string().required("City is required"),
  // zip_code: Yup.number()
  //   .test("len", "Max 12 numbers", (val) => val.toString().length <= 12)
  //   .required("Zip code is reuqired")
  //   .typeError("Enter a digit code"),
  // fax: Yup.number().typeError("Enter valid Fax"),
  type: Yup.string().required("Vet type is required"),
  vet_full_name: Yup.string().required("Vet full name is required"),
  vet_id: Yup.string().required(" Vet full name is required"),
  vet_practice_id: Yup.string().required("Veterinary name is required"),
  pet_id: Yup.string().required("Pet name is required"),
  vet_portal: Yup.string().url('Invalid URL format'),
  fax: Yup.string()
  .matches(/^\+?\d{1,4}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}(\s?x\d{1,5})?$/, 'Invalid fax number'),
});

export const EmergencyFromValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  relationship: Yup.string().required("Relationship is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last Name is required"),
  phone_number: Yup.string()
    .required("Primary phone number is required")
    .min(10, "Primary phone number must have at least 10 characters"),
  email: Yup.string()
    .email(`Invalid email format`)
    .required("Email is required"),
  // street_address: Yup.string().required("street_address is required"),
  // apt_city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  // zip_code: Yup.string().required("zip_code is required"),
  // country: Yup.string()
  //   .required("country is required")
  //   .matches(/^[aA-zZ\s]+$/, "Please enter Only alphabets"),
});

const allowedFileTypes = [
  '.jpg', '.JPG', '.JPEG', '.jpeg',
  '.png', '.PNG', '.pdf', '.docx', '.xlsx', '.csv'
];

export const DocumentFormValidation = Yup.object({
  documment_name: Yup.string().required("Document name is required"),
  pet_id: Yup.string().required("Pet name is required"),
  date: Yup.date()
    .required("Date is required")
    .max(new Date(), "Future dates are not allowed"),
  documment: Yup.mixed().required("Document is required").test('fileType', 'Invalid file type', (value) => {
    if (!value) {
      return true;
    }

    const fileExtension = value.name.slice(((value.name.lastIndexOf(".") - 1) >>> 0) + 2);
    return allowedFileTypes.includes('.' + fileExtension);
  }),
});

export const EditDocumentFormValidation = Yup.object({
  documment_name: Yup.string().required("Document name is required"),
  pet_id: Yup.string().required("Pet name is required"),
  date: Yup.date()
    .required("Date is required")
    .max(new Date(), "Future dates are not allowed"),
  documment: Yup.mixed().test('fileType', 'Invalid file type', (value) => {
      if (!value) {
        return true;
      }
  
      const fileExtension = value.name.slice(((value.name.lastIndexOf(".") - 1) >>> 0) + 2);
      return allowedFileTypes.includes('.' + fileExtension);
    }),
});

export const JournalFormValidation = Yup.object({
  note: Yup.string().required("Notes is required"),
  user: Yup.string().required("User name is required"),
  petid: Yup.string().required("Pet name is required"),
  date: Yup.date()
    .required("Date is required")
    .max(new Date(), "Future dates are not allowed"),
});

export const SurgeryFormValidation = Yup.object({
  vet_id: Yup.string().required("Vet name is required"),
  pet_id: Yup.string().required("Pet name is required"),
  surgery_type: Yup.string().required("Surgery type is required"),
  description: Yup.string().required("Description is required"),
  date: Yup.date().required("Date is required"),
});

export const ConditionsFormValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  conditions: Yup.string().required("Condition is required"),
});

export const AllergyFormValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  allergy_type: Yup.string().required("Allergy type is required"),
});

export const ImmunizationFormValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  vaccination_type: Yup.string().required("Vaccination type is required"),
  date: Yup.date().required("Date is required"),
});

export const PetInsuranceFormValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  plan: Yup.string()
    .required("Plan is required")
    .test(
      "is-not-only-spaces",
      "Plan cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  provider_name: Yup.string()
    .required("Insurance provider name is required")
    .test(
      "is-not-only-spaces",
      "Insurance provider name cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  group_name: Yup.string()
    .required("Group name is required")
    .test(
      "is-not-only-spaces",
      "Group name cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  member_name: Yup.string()
    .required("Member name is required")
    .test(
      "is-not-only-spaces",
      "Member name cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  member_id: Yup.string()
    .required("Insurance Member ID is required")
    .test(
      "is-not-only-spaces",
      "Insurance Member ID cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  subscriber_name: Yup.string()
    .required("Subscriber is required")
    .test(
      "is-not-only-spaces",
      "Subscriber cannot be just spaces",
      (value) => !/^\s*$/.test(value)
    ),
  provider_phone: Yup.string()
    .required("Insurance phone is required")
    .matches(/^[0-9]{10,11}$/, "Insurance phone is not valid"),
  duration: Yup.string().required("Duration is required"),
  effective_date: Yup.date().required("Effective date is required"),
});

export const PrescriptionFormValidation = Yup.object({
  pet_id: Yup.string().required("Pet name is required"),
  vet_id: Yup.string().required("Enter prescibing vet"),
  type: Yup.string().required("Enter medication type"),
  medication_name: Yup.string().required("Enter medication name"),
  frequencyMeasurement: Yup.string().required("Enter measurement"),
  dosageMeasurement: Yup.string().required("Enter measurement"),
  // durationMeasurement: Yup.string().required("Enter measurement"),
  reason: Yup.string().required("Enter reason for use"),
  pharmacy_name: Yup.string().notRequired(),
  // pharmacy_id: Yup.string().when("pharmacy_name", (pharmacy_name, field) => {
  //   console.log("pharmacy_name==---: ", pharmacy_name);
  //   return pharmacy_name[0] ? field : field.required("Enter Pharmacy Name");
  // }),
  frequency: Yup.number()
    .required("This cannot be empty.")
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  dosage: Yup.number()
    .required("This cannot be empty.")
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  durtion: Yup.number()
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  // durtion: Yup.number().when("durationMeasurement", (durtion, field) => {
  //   return durtion[0] === "Ongoing" ? field.typeError("Enter only numbers.") : field.required("This cannot be empty.")
  //     .typeError("Enter only numbers.")
  //     .test("is-not-zero", "Value cannot be zero", (value) => value !== 0);
  // }),
  start_date: Yup.date().required("Enter start date").max(new Date(), "Future dates are not allowed"),
});

export const EditPrescriptionFormValidation = Yup.object({
  vet_id: Yup.string().required("Enter prescibing vet"),
  type: Yup.string().required("Enter medication type"),
  medication_name: Yup.string().required("Enter medication name"),
  frequencyMeasurement: Yup.string().required("Enter measurement"),
  dosageMeasurement: Yup.string().required("Enter measurement"),
  durationMeasurement: Yup.string().required("Enter measurement"),
  reason: Yup.string().required("Enter reason for use"),
  pharmacy_name: Yup.string().notRequired(),
  pharmacy_id: Yup.string().required("Enter Pharmacy Name"),
  frequency: Yup.number()
    .required("This cannot be empty.")
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  dosage: Yup.number()
    .required("This cannot be empty.")
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  durtion: Yup.number()
    .required("This cannot be empty.")
    .typeError("Enter only numbers.")
    .test("is-not-zero", "Value cannot be zero", (value) => value !== 0),
  start_date: Yup.date().required("Enter start date"),
});

export const UserProfileEditValidation = Yup.object({
  last_name: Yup.string().required("Last Name cannot be empty!"),
  first_name: Yup.string().required("First Name cannot be empty!"),
});

export const ProviderValidation = Yup.object({
  date: Yup.date()
    .required("Date is required"),
  pet_id: Yup.string()
    .required("Pet name is required"),
});

export const InviteProviderValidation = Yup.object({
  pet_id: Yup.string()
    .required("Pet name is required"),
  last_name: Yup.string().required("Last Name cannot be empty!"),
  first_name: Yup.string().required("First Name cannot be empty!"),
  email: Yup.string()
  .email(`Invalid email format`)
  .required("Email is required")
});

export const ResetPasswordValidation = Yup.object({
  oldPassword: Yup.string()
    .required("Please enter your password "),
  newPassword: Yup.string()
    .min(6, "Password should be at least 6 characters long")
    .minLowercase(1, "Password should contain at least one lowercase letter")
    .minUppercase(1, "Password should contain at least one uppercase letter")
    .minNumbers(1, "Password should contain at least one number")
    .minSymbols(1, "Password should contain at least one special character")
    .required("Please enter your password "),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), ""], "Password does not match"),
});

export const AddMembershipValidation = Yup.object({
  no_of_records: Yup.number()
  .required("This Field is required.")
  .typeError("Enter only numbers.")
});