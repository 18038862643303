import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import { createProvider } from "../../Api/Services/ProviderServices";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import InputField from "../../Components/Common/InputField";
import CustomSnackbar from "../../Components/Common/Snackbar";
import useAuth from "../../ContextProvider/AuthProvider";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  newSmallFieldBoxStyle,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { InviteProviderValidation } from "../../From/Validation";
import AutoComplete from "../Common/AutoComplete";
import FormLayout from "../Common/FormHeader";

const defaultValue = {
  first_name: "",
  last_name: "",
  email: "",
  pet_id: "",
};

const ProviderForm = () => {
  const { workspaceId: w_id } = useAuth();
  const [initialValue, setInitialValue] = useState(defaultValue);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [petNameOptions, setPetNameOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const getPetNames = async () => {
    try {
      const { data } = await getPetNameByWorkspaceId(w_id);
      const petnames = data?.data?.map((pet) => {
        return {
          value: pet?.id,
          label: pet?.name,
        };
      });
      if (petnames.length === 0) {
        console.error("No pet names found for workspace ID:", w_id);
      } else {
        setPetNameOptions(petnames);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  useEffect(() => {
    if (w_id) {
      getPetNames();
    }
  }, [w_id]);

  const handleSubmit = async (values) => {
    const value = {
      ...values,
      w_id: w_id,
    };
    try {
      const res = await createProvider(value);
      setShowSuccessMessage(true);
      setOpen(true);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return (
    <FormLayout title="Provider Information">
      <Formik
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={InviteProviderValidation}
        enableReinitialize
        // innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Provider Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        name="pet_id"
                        options={petNameOptions}
                        placeholder="Select a Pet Name"
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <Box
                      sx={{ ...newSmallFieldBoxStyle, paddingBottom: "0px" }}
                    >
                      <Box style={{ width: "50%" }}>
                        <FormGroup
                          name="first_name"
                          label={"First Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("first_name")} />
                        </FormGroup>
                      </Box>
                      <Box style={{ paddingLeft: "5px", width: "50%" }}>
                        <FormGroup
                          name="last_name"
                          label={"Last Name"}
                          requiredField
                        >
                          <InputField {...getFieldProps("last_name")} />
                        </FormGroup>
                      </Box>
                    </Box>
                  </Column>
                  <Column>
                    <FormGroup name="email" label={"Email"} requiredField>
                      <InputField {...getFieldProps("email")} />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box Box sx={ButtonBoxStyle}>
                <Box display="flex">
                  <CustomButton
                    label="Cancel"
                    type="button"
                    style={CancelButton}
                    onClick={() => navigate(-1)}
                  />
                  <CustomButton
                    label={isSubmitting ? "Submitting..." : "Submit"}
                    disabled={isSubmitting}
                    type="submit"
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New provider has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/provider-information");
          }}
        />
      )}
    </FormLayout>
  );
};

export default ProviderForm;
