import React from "react";
import { useFormikContext } from "formik";
import { get } from "lodash";

function SelectField({
  id,
  name,
  label,
  options,
  sx,
  helperText,
  onBlur,
  onChange,
  width,
  required,
  value,
  errorMessage,
  inputSx,
  boxStyle,
  hasError,
  InputProps,
    className,
    placeholder,
  ...params
}) {
  const { errors, touched } = useFormikContext();
  const error = get(errors, name);
  const fieldTouched = get(touched, name);

  return (
    <>
      <div>
        <select
          id={id}
          name={name}
          width={width}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={fieldTouched && error}
          className={className}
                  style={{ borderColor: error && fieldTouched ? "#DC3838" : "#808080" }}
                  placeholder={placeholder}
              >
                  <option value="" disabled>{placeholder}</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

export default SelectField;
