import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { CreateAllergy } from "../../Api/Services/AllergyServices";
import { getPetNameByWorkspaceId } from "../../Api/Services/PetServices";
import AutoComplete from "../../Components/Common/AutoComplete";
import CustomButton from "../../Components/Common/Button";
import FormGroup from "../../Components/Common/FormGroup";
import FormLayout from "../../Components/Common/FormHeader";
import InputField from "../../Components/Common/InputField";
import CustomSnackbar from "../../Components/Common/Snackbar";
import TextArea from "../../Components/Common/TextArea";
import "../Immunization/immunization.css";
import { WORKSPACEID_KEY } from "../../Constant";
import {
  FormHeaderBox,
  FormHeaderTitle,
  Body,
  Column,
  ButtonBoxStyle,
  CancelButton,
} from "../../Css";
import { AllergyFormValidation } from "../../From/Validation";
import { allergiesNamesOptions, AllergyTypes } from "../../Options";

const defaultValue = {
  allergy_type: "",
  allergy_name: "",
  reaction: "",
  information: "",
  pet_id: "",
};

const AllergyForm = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const formRef = useRef();
  const [items, setItems] = useState([]);
  const [allergyNamesOptions, setAllergyNamesOptions] = useState([]);
  const w_id = localStorage.getItem(WORKSPACEID_KEY);

  
  useEffect(() => {
    const getPetNames = async () => {
      try {
        const { data } = await getPetNameByWorkspaceId(w_id);
        const filteredData = data?.data?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setItems(filteredData);
      } catch (error) {
        console.error("error: ", error);
      }
    };
    getPetNames();
  }, [w_id]);

  const handleSubmit = async (values) => {
    const value = {
      allergy_type: values?.allergy_type,
      allergy_name: values?.allergy_name,
      reaction: values?.reaction,
      information: values?.information,
      pet_id: values?.pet_id,
    };
    try {
      const AllergyRes = await CreateAllergy(value);
      if (AllergyRes?.data?.success) {
        setShowSuccessMessage(true);
        setOpen(true);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleAllergyTypeChange = (e, newValue) => {
    const selectedType = allergiesNamesOptions?.filter((item) => item.type === newValue?.value);
    const filteredAllergy = selectedType?.[0]?.names?.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    filteredAllergy.push({ value: "Other", label: "Other" });
    setAllergyNamesOptions(filteredAllergy);
    formRef?.current?.setFieldValue("allergy_name", "");
  }

  return (
    <FormLayout title="Allergy Information">
      <Formik
        initialValues={defaultValue}
        onSubmit={handleSubmit}
        validationSchema={AllergyFormValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {({ getFieldProps, values, isSubmitting }) => (
          <Form>
            <Box sx={FormHeaderBox}>
              <Box sx={{ borderBottom: "1px solid #FF8533", width: "100%" }}>
                <Typography variant="h6" sx={FormHeaderTitle}>
                  Allergy Details
                </Typography>
              </Box>
              <div className="scroll2" style={{ width: "100%" }}>
                <Body style={{ gap: "10px 20px" }}>
                  <Column>
                    <FormGroup name="pet_id" label={"Pet Name"} requiredField>
                      <AutoComplete
                        {...getFieldProps("pet_id")}
                        options={items}
                        textFieldStyle={{
                          width: {
                            xs: 250,
                            sm: 443,
                            md: 443,
                            lg: 443,
                            xl: 443,
                          },
                          height: "35px",
                        }}
                      />
                    </FormGroup>
                  </Column>
                  <Column>
                    <FormGroup
                      name="allergy_type"
                      label={"Allergy Type"}
                      requiredField
                    >
                      <AutoComplete
                        {...getFieldProps("allergy_type")}
                        options={AllergyTypes}
                        otherType="Other"
                        placeholder="Select a type"
                        onChange={handleAllergyTypeChange}
                        isOnChange
                      />
                    </FormGroup>
                  </Column>
                 {values?.allergy_type && <Column>
                    <FormGroup name="allergy_name" label={"Allergy Name"}>
                      <AutoComplete
                        {...getFieldProps("allergy_name")}
                        options={allergyNamesOptions}
                        otherType="Other"
                        placeholder="Select a Allergy name"
                      />
                    </FormGroup>
                  </Column>}
                  <Column>
                    <FormGroup name="reaction" label={"Reaction"}>
                      <InputField {...getFieldProps("reaction")} />
                    </FormGroup>
                  </Column>
                </Body>
                <Body style={{ gap: "10px 20px", marginTop: "10px" }}>
                  <Column>
                    <FormGroup name="information" label={"Information"}>
                      <TextArea
                        maxLength={300}
                        rows={5}
                        {...getFieldProps("information")}
                      />
                    </FormGroup>
                  </Column>
                </Body>
              </div>
              <Box sx={ButtonBoxStyle}>
                <CustomButton
                  label="Cancel"
                  type="button"
                  style={CancelButton}
                  onClick={() => navigate(-1)}
                />
                <CustomButton
                  label={isSubmitting ? "Submtting..." : "Submit"}
                  disabled={isSubmitting}
                  type="submit"
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
      {showSuccessMessage && (
        <CustomSnackbar
          title="Data added successfully!"
          desc="New pet allergy has been created successfully."
          open={open}
          okOnclick={() => {
            setShowSuccessMessage(false);
            navigate("/allergy");
          }}
        />
      )}
    </FormLayout>
  );
};

export default AllergyForm;
