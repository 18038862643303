import React, { useEffect } from "react";
import styled from "styled-components";
import "../Registration/Register.css";
import FormGroup from "../../Components/Common/FormGroup";
import InputField from "../../Components/Common/InputField";
import PasswordInput from "../../Components/Common/FormGroup/PasswordInput";
import PhoneInput from "../../Components/Common/PhoneInput";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { Header, Wrapper } from "../../Css";
import MembershipForm from "../../Components/register/MembershipForm";
import HttpCommon from "../../Utils/http-common";

function NewAccountRegister({ setValueinFormData, steps, setSteps }) {
  const navigate = useNavigate();

  const {
    setFieldValue,
    values,
    getFieldProps,
    errors,
    setFieldTouched,
    setFieldError,
  } = useFormikContext();

  const paste = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setValueinFormData();
  }, [values]);

  const handleBuyNewMembership = async (e, isNewMember) => {
    e.stopPropagation();
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((fieldName) => {
        setFieldTouched(fieldName, true);
      });
      return;
    }
    let emailExists = false;
    await HttpCommon.post("/user/checkEmail", { email: values.email }).then(
      (res) => {
        if (res.data.message === "Email Exists.") {
          setFieldValue("hasMembershipNumber", false);
          setFieldValue("isValidMembershipNumber", false);
          setTimeout(() => {
            setFieldError(
              "email",
              "This Email Is Already Associated With An Account"
            );
          });
          emailExists = true;
          return;
        }
      }
    );
    if (emailExists) {
      return;
    }
    if (isNewMember) {
      setFieldValue("hasMembershipNumber", false);
      setFieldValue("isValidMembershipNumber", false);
      setSteps(steps + 1);
      window.scrollTo(0, 580);
    } else {
      setFieldTouched("membershipNumber", true);
      setFieldValue("hasMembershipNumber", true);
      window.scrollTo({ top: 600, behavior: "smooth" });
    }
  };

  return (
    <>
      <Header>New Account Registration</Header>
      <Main>
        <Body>
          <Column>
            <FormGroup
              label="First Name"
              name="firstname"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="firstname"
                {...getFieldProps("firstname")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Last Name"
              name="lastname"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="lastname"
                {...getFieldProps("lastname")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Email Address"
              name="email"
              requiredField
              labelClassName="margin-top"
            >
              <InputField
                type="text"
                name="email"
                className="textarea"
                {...getFieldProps("email")}
              />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Phone Number"
              name="phoneNumber"
              requiredField
              labelClassName="margin-top"
            >
              <Country>
                <PhoneInput
                  dialCodeName="phone_code"
                  name="phoneNumber"
                  onChange={(value, country) => {
                    setFieldValue("phone_code", country.dialCode);
                  }}
                />
              </Country>
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Password"
              name="password"
              requiredField
              labelClassName="margin-top"
            >
              <PasswordInput {...getFieldProps("password")} />
            </FormGroup>
          </Column>
          <Column>
            <FormGroup
              label="Confirm Password"
              name="confirmpassword"
              requiredField
              labelClassName="margin-top"
            >
              <PasswordInput
                {...getFieldProps("confirmpassword")}
                onPaste={paste}
                onDrop={paste}
              />
            </FormGroup>
          </Column>
          <button
            type={"button"}
            style={{ padding: "10px" }}
            onClick={(e) => handleBuyNewMembership(e, true)}
          >
            Buy New PawprintID® Membership
          </button>
          <button
            type={"button"}
            onClick={(e) => handleBuyNewMembership(e, false)}
            style={{ padding: "10px" }}
          >
            Register Your Account
            <div style={{ fontSize: "10px", fontWeight: 600 }}>
              If You Have Already Received Your PawprintID® Membership
            </div>
          </button>
        </Body>
        <p id="account" style={{ margin: 0, padding: "10px 0px 0px 0px" }}>
          or
        </p>
        <p
          id="l4"
          onClick={() => {
            navigate("/account");
          }}
          style={{
            paddingTop: "10px",
            fontSize: "18px",
            margin: 0,
            cursor: "pointer",
          }}
        >
          Login
        </p>
      </Main>
      {values?.hasMembershipNumber && (
        <div style={{ marginTop: "50px" }}>
          <Header style={{ width: "50%", padding: "10px 30px" }}>
            <p>Membership Number Information</p>
          </Header>
          <MembershipBody>
            <MembershipForm />
          </MembershipBody>
        </div>
      )}
    </>
  );
}

export default NewAccountRegister;

const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;

  h2 {
    color: rgb(31, 85, 165);
  }

  button {
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
const Column = styled.div`
  display: grid;
  text-align: left;
  gap: 14px;

  label {
    padding: 10px 0;
    color: rgb(31, 85, 165);
    font-weight: 700;
  }

  input {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
    width: -webkit-fill-available;

    input:-internal-autofill-selected {
      appearance: menulist-button;
      background-image: none !important;
      background-color: -internal-light-dark(
        rgb(232, 240, 254),
        rgba(70, 90, 126, 0.4)
      ) !important;
      color: fieldtext !important;
    }

    :focus {
      border: none;
    }
  }

  p {
    color: red;
    font-size: 14px;
    padding: 0px;
  }
`;

const Main = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0px;
  padding: 30px;

  button {
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;
    margin-top: 30px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
    @media (max-width: 650px) {
      margin-top: 10px;
    }
  }

  @media (max-width: 650px) {
    // margin: 0 12px;
    padding: 10px;
  }
`;

const Country = styled.div`
  .react-tel-input .form-control {
    border-radius: 5px;
    border: 1px solid gray;
    height: 41px !important;
    width: 100%;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent !important;
    border: transparent !important;
    border-radius: 3px 0 0 3px;
  }

  input:-internal-autofill-selected {
    background-image: none !important;
    background: rgba(255, 255, 255, 0.2) !important;
    color: fieldtext !important;
  }
`;

const MembershipBody = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  width: 50%;
  padding: 30px;
  background: rgba(0, 0, 0, 0.05);
  h2 {
    color: rgb(31, 85, 165);
  }

  button {
    background-color: rgb(31, 85, 165);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 15px;

    :hover {
      background-color: rgb(31, 85, 150);
    }

    :disabled {
      background-color: gray;
    }
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 12px;
  }
`;
