import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { DeleteDocumet, getDocumentByID } from "../../../Api/Services/DocumentServices";
import { getPetByID } from "../../../Api/Services/PetServices";
import { getPetHistoryById } from "../../../Api/Services/UserServices";
import { PAWPRINT_USER_ROLE_KEY } from "../../../Constant";
import { addItemtoObject } from "../../../Utils";
import ParDataView from "../../Common/PawDataView";
import OwnerInfo from "../OwnerInfo";

export default function DocumentInfoComponent() {
  const { id } = useParams();
  const [petInfo, setPetInfo] = useState([]);
  const navigate = useNavigate();

  const { data: documentInfo, isLoading } = useSWR(
    id ? `documentInfo${id}` : null,
    async () => {
      const { data } = await getDocumentByID(id);
      const pet_id = data?.data?.[0]?.pet_id;
      const response = await getPetByID(pet_id);
      setPetInfo(response?.data?.data);
      return data?.data?.[0] || [];
    },
    {
      revalidateOnFocus: false,
    }
  );

  const { data: documentHistory } = useSWR(
    id ? `documentHistory${id}` : null,
    async () => {
      try {
        const { data } = await getPetHistoryById("document", id);
        const propertiesToUpdate = ['pet_name'];
        const propertiesToDelete = [ 'id', 'pet_id' ];
        const filteredData = data?.data?.map((item) => addItemtoObject(item, propertiesToUpdate, propertiesToDelete));
        return filteredData || [];
      } catch (error) {
        console.error('error: ', error);
      }
    },
    {
      revalidateOnFocus: false,
    }
  );

  const onDelete = async () => {
    return await DeleteDocumet(id);
  };

  const role = localStorage.getItem(PAWPRINT_USER_ROLE_KEY);
  const props = {
    label: "Document Information",
    isLoading: isLoading,
    onBack: () => {
      navigate("/document");
    },
    petInfo: petInfo,
    columns: [
      {
        label: "",
        value: "image",
      },
      {
        label: "Pet Name",
        value: "pet_name",
      },
      {
        label: "Document Name",
        value: "documment_name",
      },
    ],
    columnsData: [
      {
        image: petInfo?.image,
        pet_name: petInfo?.name,
        documment_name: documentInfo?.documment_name,
      },
    ],
    detailsData: [
      [{ name: "Date", value: documentInfo?.date }, { name: 'Document Link', value: documentInfo?.documment, pdf: true}],
    ],
    allowEditOrDelete:
      role === "User" || role === "user" || role === "General Authorized User",
    editLink: `/document/edit/${id}`,
    onDelete,
    isHistory: true,
    historyTitle: "Document History",
    historyColumnsData: documentHistory,
  };

  return (
    <FullPageWrapper>
      <ParDataView {...props} />
        <OwnerInfo
          firstName={petInfo?.userDetails?.first_name}
          lastName={petInfo?.userDetails?.last_name}
        />
    </FullPageWrapper>
  );
}

const FullPageWrapper = styled("div")(() => ({}));
